import React from 'react'

const SkeletonLoaderRanking = (props) => {
  return (
    <>
      <div className={`skeleton-card rounded-xl transition fifth-background shadow-effect-one mb-2 py-1 w-full`}>
        {/* Row 1 */}
        <div className={`flex justify-center my-3 w-full`}>
          <div className={`skeleton skeleton-text-thicker mx-1`}></div>
        </div>
        {/* Row 2 */}
        <div className={`flex justify-center`}>
          <div className={`mx-auto`}>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
            <div className={`skeleton skeleton-text-300 mb-2`}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SkeletonLoaderRanking;
