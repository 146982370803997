import React, { Component } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { globals } from '../../config/globals';

const Link = Quill.import('formats/link')
Link.PROTOCOL_WHITELIST = ['http', 'https']

class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url)
    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol) {
      return sanitizedUrl.startsWith(protocol)
    })

    if (hasWhitelistedProtocol) return sanitizedUrl

    // if not, then append only 'http' to not to be a relative URL
    return `https://${sanitizedUrl}`
  }
}
Quill.register(CustomLinkSanitizer, true)

class QuillEditorForLists extends Component {
  // Changing hyperlink tooltip because default one is https://quilljs.com/
  htmlLink = `${globals.platformName}`;
  componentDidMount() {
    const input = document.querySelector("input[data-link]");
    input.dataset.link = this.htmlLink;
    input.placeholder = this.htmlLink;
  }
  componentDidUpdate() {
    const input = document.querySelector("input[data-link]");
    input.dataset.link = this.htmlLink;
    input.placeholder = this.htmlLink;
  }
  formats = [
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "code",
    `clean`
  ];
  modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "blockquote", "code"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" }
        ],
        ["link", "clean"]
      ],
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };
  render() {
    const { handleEditorChange, content, selectedPlaceholder, quillTheme } = this.props;
    return (
      <ReactQuill
          ref={(el) => this.quill = el}// must pass ref here
          theme={quillTheme}
          placeholder={selectedPlaceholder}
          value={content}
          onChange={handleEditorChange}
          formats={this.formats}
          modules={this.modules}
          scrollingContainer='body'
        />
    )
  }
}
export default QuillEditorForLists;