import React from 'react';

const EditorButtons = props => {
  const {
    isNewPost,
    togglePaymentModal,
    saveDraft,
    savingDraft,
    overallError,
    toggleConfirmCancelModal,
    // isListOrPollCategory,
    // userDatabaseData,
    // stampshotAddIsChecked,
    // handleStampshotCheckbox
  } = props
  return (
    <>
      <div className='m-2 text-left flex flex-wrap'>
        {isNewPost === true
          ?
          <>
            <button className={`py-1 px-2 gradient-background-green mr-2 mt-2 shadow-effect-seven
              text-white border-brand-green text-sm rounded-xl border-2 font-header`}
              onClick={e => togglePaymentModal(e, 'CREATE_POST')}
              disabled={savingDraft ? true : false}
            >
              Stamp It
            </button>
            <button onClick={(e) => saveDraft(e)}
              className={`py-1 px-2 cursor-pointer self-center mr-2 mt-2 gradient-background-orange shadow-effect-seven
              text-white text-sm rounded-xl border-2 font-header border-orange-700`}
              disabled={savingDraft ? true : false}
            >
              {savingDraft ? 'Saving...' : 'Save Draft'}
            </button>
          </>
          :
          <button className={`py-1 px-2 gradient-background-green mr-2 mt-2 shadow-effect-seven
          text-white border-brand-green text-sm rounded-xl border-2 font-header `}
            onClick={e => togglePaymentModal(e, 'UPDATE_POST')}
          >
            Finalize Edits
          </button>
        }
        <button onClick={(e) => toggleConfirmCancelModal(e)}
          className={`py-1 px-2 cursor-pointer self-center mr-2 mt-2 gradient-background-red shadow-effect-seven
          text-white text-sm rounded-xl border-2 font-header border-red-700
        `}>
          Cancel
        </button>
        {/* Got rid of Stampshot toggler for now. Stampshot = stamp to blockchain. Would need to stay timed stampshot */}
        {/* {isListOrPollCategory && isNewPost && userDatabaseData?.membership
          ?
          <>
            <div className={`px-3 mt-2 py-1 gradient-background-four shadow-effect-seven sixth-border rounded-xl border-2`}>
              
              <input
                aria-label="Stampshot Selection"
                tabIndex='-1'
                type="checkbox"
                className="checkbox-2"
                id="stampshot-checkbox"
                checked={stampshotAddIsChecked}
                onChange={handleStampshotCheckbox}
              />
              <label className={``} htmlFor="stampshot-checkbox">
                <div className={`text-sm ${stampshotAddIsChecked ? `ml-8` : `ml-36px`}`}>
                  {stampshotAddIsChecked
                    ? `Stampshot Added`
                    : `Add Stampshot?`
                  }
                </div>
              </label>
            </div>
            <div className={`mt-2 flex items-center `}>
              <div className="stampshot-tooltip cursor-pointer flex justify-center items-center text-center ml-2 rounded-xl shadow-effect-seven
              gradient-background-four h-5 w-5"
                data-tooltip="Stampshot content freezes within 2 days, then results are stamped to the Blockchain"
              >
                ?
              </div>
            </div>
          </>
          : <></>
        } */}
      </div>
      {overallError
        ?
        <div className={`ml-2 text-xs font-header mt-2 text-red-600`}>
          {overallError}
        </div>
        : null
      }
    </>
  )
}

export default EditorButtons