import React, { Component } from 'react'
import { connect } from 'react-redux';
import { opacityChange } from '../store/reducers/opacitySlice';

class PrivacyPage extends Component {
  
  componentDidMount() {
    setTimeout(() => {
      this.props.opacityChange('addPrivacyOpacity')
    }, 100)
  }

  render() {
    const { privacyOpacity } = this.props;
    return (
      <>
        <main className={`flex md:inline-flex mt-2 mx-auto w-full sm:max-w-3xl md:max-w-4xl`} id='main-content'>
          <div className={`w-full mb-3 rounded-xl px-1 ${privacyOpacity} transition-to-opacity`}>
            <div className={`gradient-background-four shadow-effect-eight first-text p-2 rounded-xl`}>
              <h2 className={`mb-2 text-center logo-style eleventh-text`}>Privacy Page</h2>
              <hr className={`hidden md:block border-brand-green border-2 outsetBorder mx-1 mb-3`} />
              <div className={`text-left text-sm`}>
                Not interested in harvesting your data. We won't even know your name, just your HandCash Handle.
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privacyOpacity: state.opacity.privacyOpacity
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);
