import axios from 'axios';

const authAxios = axios.create({
// configuration can be made here
});

authAxios.interceptors.request.use(config => {
  const localStorageAuth = JSON.parse(localStorage.getItem('handCashAuthData'));
  if (!localStorageAuth) {
    return config;
  }
  
  config.headers['Authorization'] = 'Bearer ' + localStorageAuth.authToken;
  return config

}, error => {
  console.error('Interceptor Error: ', error)
  Promise.reject(error)
})

export default authAxios;
