import React, { Component } from 'react'
import DataListConductor from './DataListConductor';
import Moment from 'react-moment';
import { Icon } from '@iconify/react';
import fileExcel from '@iconify/icons-fa-solid/file-excel';
import textDocumentEdit from '@iconify/icons-fluent-mdl2/text-document-edit';

// import { globals } from '../../config/globals'

class PrivateProfilePage extends Component {

  render() {
    const {
      userData,
      profilePageOpacity,
      toggleDataList,
      dataListOpacity,
      privSelect,
      togglePaymentModal,
      noExcelFileYet,
    } = this.props;
    return (
      <>
        {/* This check prevents an error where the page is loaded faster than the database retrieval. */}
        {userData
          ?
          <main className={`pb-3 px-1 mt-2 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition`} id='main-content'>
            <div className={`w-full rounded-xl px-1 mb-3 first-text shadow-effect-seven`}>
              {/* Opacity & container div*/}
              <div className={``}>
                <div className={`${profilePageOpacity} transition-to-opacity`}>
                  <div className={`gradient-background-four rounded-t-xl -mx-1`}>
                    <div className={`flex text-xs rounded-lg pb-1`}>
                      {/* Left */}
                      <div className={`flex-1 justify-start pt-3 pl-3 pr-1`}>
                        {/* Joined Date */}
                        <div className={`underline text-underline-under`}>Joined: <Moment format="MM-DD-YYYY">{userData.createdAt}</Moment></div>
                        {/* Membership */}
                        {userData.membership
                          ?
                          <>
                            <div className={`text-brand-green font-bold my-1`}>*Member*</div>
                          </>
                          : <></>
                        }
                      </div>
                      {/* Center */}
                      <div className={`flex-1 justify-center text-center w-auto pt-3 mt-1`}>
                        <div className={``}>
                          {/* Avatar */}
                          <span className={`transition ${userData.membership ? `member-tooltip-large` : ``}`} data-member='profile' data-tooltip='Member'>
                            <div className={`p-2 -m-2 ${userData.membership ? `flip-hover` : ``}`}>
                              <img alt="Avatar" src={userData.handCashAvatarUrl} width='80' height='80'
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = '/images/round-handcash-logo.png'
                                }}
                                className={`rounded-full mx-auto ${userData.membership ? `profile-member-shadow` : `shadow-effect-seven`}`}>
                              </img>
                            </div>
                          </span>
                          {/* Handle */}
                          <div className={`text-center my-2 thirteenth-text text-lg logo-style-two`}>
                            {userData.handCashHandle}
                          </div>
                        </div>
                      </div>
                      {/* Right */}
                      <div className={`flex-1 justify-end pt-3 pr-3 pl-1 text-right`}>
                        <div className={``}>Earned: <span className={`fifth-text font-bold`}>${userData.totalValueReceived}</span></div>
                        <div className={``}>Sent: <span className={`fifth-text font-bold`}>${userData.totalValueSent}</span></div>
                        <div className={``}><span className={``}>Thumbs: <span className={`fifth-text font-bold`}>{userData.totalThumbsReceived}</span></span></div>
                        <div className={`flex justify-end mt-1`}>
                          {noExcelFileYet === true
                            ? <>
                              <button className={`font-header gradient-background-twelve shadow-effect-seven py-px px-3 rounded-xl`}
                                onClick={e => togglePaymentModal(e, 'OPEN_EXCEL_MODAL')}
                              >
                                  <div className={`flex items-center justify-center -mr-2px`}>
                                    Excel
                                    <Icon className='ml-2px' icon={fileExcel} height={14} width={14} />

                                  </div>
                                  <div className={`flex justify-center -mt-2px`}>
                                    Download
                                  </div>
                              </button>
                            </>
                            :
                            <>

                            </>
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`flex justify-center pb-2`}>
                      {/* Following */}
                      <div className={`text-sm`}>
                        <button onClick={(e) => toggleDataList(e, "GET_FOLLOWERS")}
                          className={`${privSelect.followers} font-header py-px mr-1 px-3 rounded-xl button-ripple
                          cursor-pointer category-hover shadow-effect-seven eighth-text 
                          gradient-background-twelve`}>
                          {/* Button text */}
                          <span className={`font-bold grey-and-bold`}>[{userData.totalFollowers}]</span> Followers
                        </button>
                        <button onClick={(e) => toggleDataList(e, "GET_FOLLOWING")}
                          className={`${privSelect.following} font-header ml-1 py-px px-3 rounded-xl button-ripple
                          cursor-pointer category-hover shadow-effect-seven eighth-text 
                          gradient-background-twelve`}>
                          {/* Button text */}
                          Following <span className={`font-bold grey-and-bold`}>[{userData.totalFollowing}]</span>
                        </button>
                      </div>
                    </div>
                    {/* Sorts */}
                    <div className={`flex justify-center flex-wrap text-sm mb-1 font-header`}>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.profile} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_PROFILE")}
                      >
                        Profile
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.stats} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_STATS")}
                      >
                        Stats
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.posts} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_POSTS")}
                      >
                        Posts
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.comments} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_COMMENTS")}
                      >
                        Comments
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.tips} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_TIPS")}
                      >
                        Tips
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.paywalls} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_PAYWALLS")}
                      >
                        Purchases
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.thumbs} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_THUMBS")}
                      >
                        Thumbs
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.shares} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_SHARES")}
                      >
                        Shares
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.stampshots} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_STAMPSHOTS")}
                      >
                        Stampshots
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.votes} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_VOTES")}
                      >
                        Votes
                      </button>
                      <button className={`m-1 py-px px-2 flex items-center rounded-xl button-ripple transition cursor-pointer category-hover
                        ${privSelect.drafts} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_DRAFTS")}
                      >
                        <div className='mr-1 -mt-2px'><Icon icon={textDocumentEdit} height={12} width={12} /></div>
                        Drafts
                      </button>
                    </div>
                    {/* Sorts come above hr */}
                    <hr className={`sixth-border outsetBorder border mx-2 mt-3 mb-1 opacity-50`}></hr>
                    {/* Data comes below hr */}
                  </div>
                  {/* This is the wrapper div that will apply to all data displayed. */}
                  <div className={`${dataListOpacity} transition-to-opacity`}>
                    <div className={`gradient-background-five -mt-2 -mx-1 pb-3 rounded-b-xl`}>
                      <DataListConductor
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          : <></>
        }
      </>
    )
  }
}

export default PrivateProfilePage;