import React, { Component } from 'react'
import vote20Regular from '@iconify/icons-fluent/vote-24-filled';
import { Icon } from '@iconify/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import RadioButton from '../../components/Widgets/RadioButton'
import exploreData from '@iconify/icons-fluent-mdl2/explore-data';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);
ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = "'Open-Sans', 'sans-serif'";

class PollComponent extends Component {

  options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '# of Votes',
        position: 'bottom'
      },
    },
  };
  // const barChartdata = {
  //   labels: pollOptions,
  //   datasets: [
  //     {
  //       axis: 'y',
  //       data: pollData.map(item => { return item.numOptionVotes }),
  //       fill: false,
  //       label: 'Votes',
  //       backgroundColor: [
  //         'rgba(255, 99, 132, 0.2)',
  //         'rgba(54, 162, 235, 0.2)',
  //         'rgba(255, 206, 86, 0.2)',
  //         'rgba(75, 192, 192, 0.2)',
  //         'rgba(153, 102, 255, 0.2)',
  //         'rgba(255, 159, 64, 0.2)',
  //       ],
  //       borderColor: [
  //         'rgba(255, 99, 132, 1)',
  //         'rgba(54, 162, 235, 1)',
  //         'rgba(255, 206, 86, 1)',
  //         'rgba(75, 192, 192, 1)',
  //         'rgba(153, 102, 255, 1)',
  //         'rgba(255, 159, 64, 1)',
  //       ],
  //       borderWidth: 1,
  //     }
  //   ]
  // }
  // const pieChartData = {
  //   labels: pollOptions,
  //   datasets: [
  //     {
  //       label: 'Votes',
  //       data: pollData.map(item => { return item.numOptionVotes }),
  //       backgroundColor: [
  //         'rgba(255, 99, 132, 0.2)',
  //         'rgba(54, 162, 235, 0.2)',
  //         'rgba(255, 206, 86, 0.2)',
  //         'rgba(75, 192, 192, 0.2)',
  //         'rgba(153, 102, 255, 0.2)',
  //         'rgba(255, 159, 64, 0.2)',
  //       ],
  //       borderColor: [
  //         'rgba(255, 99, 132, 1)',
  //         'rgba(54, 162, 235, 1)',
  //         'rgba(255, 206, 86, 1)',
  //         'rgba(75, 192, 192, 1)',
  //         'rgba(153, 102, 255, 1)',
  //         'rgba(255, 159, 64, 1)',
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  render() {
    const {
    auth,
    togglePaymentModal,
    userAlreadyVoted,
    viewVotes,
    pollData,
    numPollVotes,
    toggleViewVotes,
    isRadioButtonSelected,
    handleRadioButtonSelection,
    toggleArrayModal,
    isFrozen,
    pieChartData,
    barChartData
  } = this.props
  const pollOptions = pollData.map(item => { return item.pollOption })
    return (
      <>
        {/* Container (3 columns on desktop, 1 on mobile) */}
        <div className={`md:flex md:mt-5 mt-3 mb-4 radio-container`}>
          {/* Left Side (desktop)*/}
          <div className={`flex-1 hidden md:block pl-3 float-right text-right`}>
            <div className={`${pollOptions.length < 3 ? `h-full flex w-full items-center` : ``}`}>
              <RadioButton
                id={`button-index-0`}
                handleChange={handleRadioButtonSelection}
                value={pollOptions[0]}
                optionNumber={1}
                userAlreadyVoted={userAlreadyVoted}
                isFrozen={isFrozen}
              />
            </div>
            {pollOptions.length >= 3
              ?
              <RadioButton
                id={`button-index-2`}
                handleChange={handleRadioButtonSelection}
                value={pollOptions[2]}
                optionNumber={3}
                userAlreadyVoted={userAlreadyVoted}
                isFrozen={isFrozen}
              />
              : <></>
            }
            {pollOptions.length >= 5
              ?
              <RadioButton
                id={`button-index-4`}
                handleChange={handleRadioButtonSelection}
                value={pollOptions[4]}
                optionNumber={5}
                userAlreadyVoted={userAlreadyVoted}
                isFrozen={isFrozen}
              />
              : <></>
            }
          </div>
          {/* Center */}
          <div className={`md:flex-1 md:flex md:justify-center items-center text-center`}>
            {/* MOBILE VERSION */}
            <div className={`md:hidden w-full`}>
              <div className={`underline text-underline-under mx-auto text-xl font-header mb-5`}>
                {numPollVotes} Total Votes
              </div>
              {pollOptions.map((option, i) => {
                return (
                  <RadioButton
                    key={i}
                    id={`sm-button-index-${i}`}
                    handleChange={handleRadioButtonSelection}
                    value={option}
                    optionNumber={i + 1}
                    mobile={true}
                    userAlreadyVoted={userAlreadyVoted}
                    isFrozen={isFrozen}
                  />
                )
              })}
            </div>
            <div className={`flex justify-center md:block mt-5 md:mt-0`}>
              <div className={`hidden md:block underline text-underline-under mx-auto font-header text-xl font-header mb-4`}>
                {numPollVotes} Total Votes
              </div>
              {userAlreadyVoted || isFrozen
                ? <></>
                : <button disabled={!isRadioButtonSelected} className={`mx-auto gradient-background-green text-white border-brand-green
                items-center flex rounded-xl px-3 py-2 font-header button-ripple border-2 offsetBorder mb-2`}
                  onClick={(e) => togglePaymentModal(e, 'SUBMIT_VOTE')}>
                  <Icon icon={vote20Regular} height={16} width={16} />
                  <span className={`ml-1`}>
                    {/* Text within Vote Button */}
                    {isRadioButtonSelected
                      ? auth?.id
                        ? userAlreadyVoted
                          ? `Only 1 Vote`
                          // Able to vote, as they haven't already
                          : `Submit Vote`
                        // Not logged in
                        : `Login to Vote`
                      // Nothing selected
                      : `Select Option`
                    }
                  </span>
                </button>
              }
              {numPollVotes === 0
                ? <></>
                :
                <button onClick={(e) => toggleArrayModal(e, 'VOTERS_ARRAY')} className={`mx-auto gradient-background-green text-white border-brand-green
                  items-center flex rounded-xl px-3 py-2 font-header button-ripple border-2 offsetBorder mb-2`}>
                  <Icon icon={exploreData} height={16} width={16} />
                  <span className={`ml-1`}>
                    View Voters
                  </span>
                </button>
              }
            </div>
          </div>
          {/* Right side (desktop) */}
          <div className={`flex-1 hidden md:block float-left pr-3`}>
            <div className={`${pollOptions.length < 3 ? `h-full flex w-full items-center` : ``}`}>
              <RadioButton
                id={`button-index-1`}
                handleChange={handleRadioButtonSelection}
                value={pollOptions[1]}
                optionNumber={2}
                userAlreadyVoted={userAlreadyVoted}
                isFrozen={isFrozen}
              />
            </div>
            {pollOptions.length >= 4
              ?
              <RadioButton
                id={`button-index-3`}
                handleChange={handleRadioButtonSelection}
                value={pollOptions[3]}
                optionNumber={4}
                userAlreadyVoted={userAlreadyVoted}
                isFrozen={isFrozen}
              />
              : <></>
            }
            {pollOptions.length >= 6
              ?
              <RadioButton
                id={`button-index-5`}
                handleChange={handleRadioButtonSelection}
                value={pollOptions[5]}
                optionNumber={6}
                userAlreadyVoted={userAlreadyVoted}
                isFrozen={isFrozen}
              />
              : <></>
            }
          </div>
        </div>
        {/* Chart Stuff on Mobile*/}
        {numPollVotes === 0
          ? <></>
          :
          <>
            {/* Break */}
            <hr className={`sixth-border outsetBorder border my-3 opacity-50 -mx-1`}></hr>
            <div className={`mx-auto relative`}>
              {viewVotes
                ? <></>
                :
                <div className={`absolute w-full flex justify-center items-center h-full mx-auto z-10 text-center `}>
                  <button className={`w-36 py-3 px-16 gradient-background-green text-white border-brand-green
              rounded-xl font-header button-ripple border offsetBorder rounded-xl`}
                    onClick={toggleViewVotes}
                  >
                    View Results
                  </button>
                </div>
              }
              <div className={`sm:flex sm:justify-between rounded-xl blur-transition transition-to-opacity 
              ${viewVotes ? `no-gloss` : `glossy`}`}>
                {/* For some reason, it doesn't work without setting the style as minHeight */}
                <div style={{ minHeight: '250px' }} className={`px-3 rounded-xl sm:mr-3 w-full`}>
                  <Bar options={this.options} data={barChartData} />
                </div>
                <div className={`max-w-xs mx-auto sm:pt-1 px-3 pb-3 rounded-xl`}>
                  <Pie data={pieChartData} />
                </div>
              </div>
            </div>
          </>
        }
      </>
    )
  }
}

export default PollComponent;