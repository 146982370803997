import React, { Component } from 'react'
import { toast } from 'react-toastify'
// Redux
import { connect } from 'react-redux'
import { storeUserDatabaseData } from '../../store/reducers/authSlice';
import { opacityChange } from '../../store/reducers/opacitySlice';
// Components
import PrivateProfilePage from './PrivateProfilePage';
import ProfilePageForPublic from './ProfilePageForPublic';
import PaymentModal from '../../components/Modals/PaymentModal';
import OverlayLoadingIcon from '../../components/Widgets/OverlayLoadingIcon';
import TipModal from '../../components/Modals/TipModal';
import DeleteModal from '../../components/Modals/DeleteModal';

// Others
import API from '../../utils/API';
import { globals } from '../../config/globals';
import ExcelJS from 'exceljs'
import moment from 'moment'

class ProfilePageContainer extends Component {
  state = {
    dataListOpacity: "opacity-100",
    dataIsLoaded: false,
    paramHandle: this.props.match.params.handCashHandle,
    pageOverlayActive: false,
    // Tips and Donation and Draft toggles
    showTipModal: false,
    tipAmount: .99,
    tipMessage: '',
    donationMessage: '',
    tipMessageError: '',
    donationMessageError: '',
    charCounterClass: `fifth-text`,
    donationCharCounterClass: `fifth-text`,
    draftId: '',
    draftIndex: '',
    excelSelectionType: '2023',
    // User stuff
    userAlreadyFollows: false,
    userDatabaseDataIsLoaded: false,
    // Modal stuff
    listDataType: 'GET_PROFILE',
    transactionType: '',
    showListItemModal: false,
    showPaymentModal: false,
    showDeleteDraftModal: false,
    hideListDataModal: true,
    // Content loading
    noExcelFileYet: true,
    nonPaymentOverlay: false,
    // Data Objects/Arrays
    publicUserData: {},
    drafts: [],
    publicUserNewestPosts: [],
    publicUserMostEarnedPosts: [],
    publicUserComments: [],
    // For Post Lists
    postsOpacity: 'opacity-0',
    commentsOpacity: 'opacity-0',
    draftsOpacity: 'opacity-0',
    followersOpacity: 'opacity-0',
    followingOpacity: 'opacity-0',
    newestViewForPosts: true,
    postsSelection: {
      newest: 'highlight-border',
      mostEarned: ''
    },
    // Selections of Content changes classes
    pubSelect: {
      profile: 'highlight-border',
      stats: '',
      posts: '',
      comments: '',
      tips: '',
      followers: '',
      following: '',
      shares: '',
      paywalls: '',
      votes: '',
      stampshots: '',
    },
    privSelect: {
      profile: 'highlight-border',
      stats: '',
      posts: '',
      comments: '',
      tips: '',
      purchases: '',
      thumbs: '',
      drafts: '',
      followers: '',
      following: '',
      shares: '',
      votes: '',
      stampshots: '',
    },
    draftsAreLoaded: false,
    // Pagination
    postsAreLoaded: false,
    commentsAreLoaded: false,
    followsAreLoaded: false,
    privatePostsLoaded: false,
    privateCommentsLoaded: false,
    paginatedCommentsAreLoaded: true,
    paginatedPostsAreLoaded: true,
    paginatedFollowsAreLoaded: true,
    pageNumberPublicNewestPosts: 1,
    pageNumberPublicMostEarnedPosts: 1,
    pageNumberPublicComments: 1,
    pageNumberPublicFollowing: 1,
    pageNumberPublicFollowers: 1,
    pageNumberPrivateComments: 1,
    pageNumberPrivateMostEarnedPosts: 1,
    pageNumberPrivateNewestPosts: 1,
    pageNumberPrivateFollowing: 1,
    pageNumberPrivateFollowers: 1,
    // No more results
    noMorePublicNewestPostResults: false,
    noMorePublicMostEarnedPostResults: false,
    noMorePrivateNewestPostResults: false,
    noMorePrivateMostEarnedPostResults: false,
    noMoreCommentResults: false, // this is public too
    noMorePublicFollowingResults: false,
    noMorePublicFollowerResults: false,
  }
  cancelToken = API.CancelToken.source();
  publicDataListArray = ['GET_PROFILE', 'GET_POSTS', 'GET_COMMENTS', 'GET_STATS',
    'GET_TIPS', 'GET_FOLLOWERS', 'GET_FOLLOWING', 'GET_PAYWALLS', 'GET_SHARES', 'GET_VOTES', 'GET_STAMPSHOTS'
  ]
  paginationArrayTypes = ['PRIVATE_COMMENTS', 'PRIVATE_NEWEST_POSTS', 'PRIVATE_MOST_EARNED_POSTS',
    'PRIVATE_FOLLOWING', 'PRIVATE_FOLLOWERS', 'PUBLIC_COMMENTS', 'PUBLIC_NEWEST_POSTS',
    'PUBLIC_MOST_EARNED_POSTS', 'PUBLIC_FOLLOWING', 'PUBLIC_FOLLOWERS'
  ]
  observer = React.createRef();

  componentDidMount() {
    this.checkLoginAndGetPublicData();
  };
  componentDidUpdate() {
    // No need to get new data if the handle hasn't changed. Only get new data if person clicks on another's name
    if (this.state.paramHandle === this.props.match.params.handCashHandle) return
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // If this is a logged in user in their own profile
    if (localStorageAuth && this.props.match.params.handCashHandle === localStorageAuth.handle) {
      let dataListType = sessionStorage.getItem('dataListForProfilePage')
      if (dataListType) {
        if (dataListType === 'GET_DRAFTS') {
          this.togglePrivateDataList('', 'GET_PROFILE')
        }
        else if (dataListType === 'GET_POSTS') {
          this.togglePrivateDataList('', 'GET_PROFILE')
        }
        else if (dataListType === 'GET_COMMENTS') {
          this.togglePrivateDataList('', 'GET_PROFILE')
        }
        else this.setPrivateDataListState(dataListType)
      }
      return (
        this.setState({
          paramHandle: this.props.match.params.handCashHandle,
          publicUserNewestPosts: [],
          publicUserMostEarnedPosts: [],
          publicUserComments: [],
          postsSelection: { newest: 'highlight-border' },
          newestViewForPosts: true,
          postsAreLoaded: false,
          commentsAreLoaded: false,
          followsAreLoaded: false,
          pageNumberPublicNewestPosts: 1,
          pageNumberPublicMostEarnedPosts: 1,
          pageNumberPublicComments: 1,
          pageNumberPublicFollowing: 1,
          pageNumberPublicFollowers: 1,
          // No more results
          noMorePublicNewestPostResults: false,
          noMorePublicMostEarnedPostResults: false,
          noMoreCommentResults: false, // this is public too
          noMorePublicFollowingResults: false,
          noMorePublicFollowerResults: false,
        }),
        setTimeout(() => {
          this.props.opacityChange('addProfilePageOpacity');
        }, 100)
      )
    }
    // If the param handle changed (AKA: User goes from one profile to another) and it is not their own
    if (this.state.paramHandle !== this.props.match.params.handCashHandle) {
      return (
        this.setState({
          paramHandle: this.props.match.params.handCashHandle,
          // listDataType: 'GET_STATS',
          publicUserNewestPosts: [],
          publicUserMostEarnedPosts: [],
          publicUserComments: [],
          dataIsLoaded: false,
          postsSelection: { newest: 'highlight-border' },
          newestViewForPosts: true,
          postsAreLoaded: false,
          commentsAreLoaded: false,
          followsAreLoaded: false,
          pageNumberPublicNewestPosts: 1,
          pageNumberPublicMostEarnedPosts: 1,
          pageNumberPublicComments: 1,
          pageNumberPublicFollowing: 1,
          pageNumberPublicFollowers: 1,
          // No more results
          noMorePublicNewestPostResults: false,
          noMorePublicMostEarnedPostResults: false,
          noMoreCommentResults: false, // this is public too
          noMorePublicFollowingResults: false,
          noMorePublicFollowerResults: false,
        }),
        setTimeout(() => {
          this.checkLoginAndGetPublicData()
        }, 400)
      )
    }
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled')
  };
  checkLoginAndGetPublicData = () => {
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // OWN PROFILE
    // If the auth handle in localStorage is the same as param handle, user is viewing own profile.
    if (localStorageAuth && localStorageAuth.handle === this.props.match.params.handCashHandle) {
      let dataListType = sessionStorage.getItem('dataListForProfilePage')
      if (dataListType) {
        // These are needed because userDatabaseData is not loaded fast enough sometimes.
        if (dataListType === 'GET_DRAFTS') {
          this.togglePrivateDataList('', 'GET_PROFILE')
        }
        else if (dataListType === 'GET_POSTS') {
          this.togglePrivateDataList('', 'GET_PROFILE')
        }
        else if (dataListType === 'GET_COMMENTS') {
          this.togglePrivateDataList('', 'GET_PROFILE')
        }
        else this.togglePrivateDataList('', dataListType)
      }
      return (
        this.setState({ dataIsLoaded: true }),
        setTimeout(() => {
          this.props.opacityChange('addProfilePageOpacity');
        }, 100)
      )
    }
    // NOT OWN PROFILE
    this.getPublicDataForUser(this.props.match.params.handCashHandle);
  }
  getPublicDataForUser = (handCashHandle) => {
    API.getPublicDataForUser(handCashHandle, this.cancelToken.token)
      .then(res => {
        // If user doesn't exist in the database
        if (res.data === null) {
          return (
            globals.toastError(toast, 'User not found. Redirecting to home page.'),
            this.props.opacityChange('removeProfilePageOpacity'),
            setTimeout(() => {
              this.props.history.push(`/`);
            }, 300)
          )
        }
        // If user exists in the database
        else {
          let publicData = res.data
          if (publicData.followers.length > 0) {
            const followersUpdate = this.firstLoadUpdateArray(publicData.followers, globals.fetchFifty)
            publicData.followers = followersUpdate
          }
          if (publicData.following.length > 0) {
            const followingUpdate = this.firstLoadUpdateArray(publicData.following, globals.fetchFifty)
            publicData.following = followingUpdate
          }
          // Set the data to publicUserData state
          this.setState({ publicUserData: publicData, dataIsLoaded: true })
          let localStorageAuth = localStorage.getItem('handCashAuthData');
          if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth)
          // Check if user follows
          if (localStorageAuth?.id && this.props.auth?.id && publicData.followerHandCashIds.length > 0) {
            if (publicData.followerHandCashIds.includes(localStorageAuth.id)) {
              this.setState({ userAlreadyFollows: true })
            }
          }
          // Check to see if the listDataType is stored in sessionStorage. If so, load that one.
          let dataListType = sessionStorage.getItem('dataListForProfilePage')
          if (dataListType && this.publicDataListArray.includes(dataListType)) {
            this.setPublicDataListState(dataListType)
            if (dataListType === 'GET_POSTS') {
              this.setState({ postsAreLoaded: false })
              this.getPostsForProfilePage()
            }
            if (dataListType === 'GET_COMMENTS') {
              this.setState({ commentsAreLoaded: false })
              this.getCommentsForProfilePage()
            }
            setTimeout(() => {
              this.props.opacityChange('addProfilePageOpacity')
            }, 100)
          }
          else {
            this.setPublicDataListState('GET_PROFILE')
            setTimeout(() => {
              this.props.opacityChange('addProfilePageOpacity')
            }, 100)
          }

        }
      })
      .catch(error => {
        console.error(error);
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'User not found. Redirecting to home page.');
        this.props.opacityChange('removeProfilePageOpacity')
        setTimeout(() => {
          this.props.history.push(`/`);
        }, 300);
      })
  }
  ////////////////
  // PAGINATION //
  ////////////////
  arrayPaginationLogic = (node) => {
    // Disconnects the current ref observer when it hits.
    if (this.observer.current) this.observer.current.disconnect()
    // BROKEN DOWN BY PRIVATE OR PUBLIC
    this.observer.current = new IntersectionObserver(entries => {
      // PRIVATE ENTRIES
      if (this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle) {
        // Private Comments
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_COMMENTS' && !this.props.userDatabaseData.noMoreCommentResults) {
          this.paginateArray('PRIVATE_COMMENTS')
        }
        // Newest Private Posts
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_POSTS'
          && this.state.newestViewForPosts && !this.props.userDatabaseData.noMorePrivateNewestPostResults) {
          this.paginateArray('PRIVATE_NEWEST_POSTS')
        }
        // Most Earned Posts
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_POSTS'
          && !this.state.newestViewForPosts && !this.props.userDatabaseData.noMorePrivateMostEarnedPostResults) {
          this.paginateArray('PRIVATE_MOST_EARNED_POSTS')
        }

        // Private Following
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_FOLLOWING' && !this.props.userDatabaseData.noMorePrivateFollowingResults) {
          this.paginateArray('PRIVATE_FOLLOWING')
        }
        // Private Followers
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_FOLLOWERS' && !this.props.userDatabaseData.noMorePrivateFollowerResults) {
          this.paginateArray('PRIVATE_FOLLOWERS')
        }
      }
      // PUBLIC ENTRIES
      else {
        // Public Comments
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_COMMENTS' && !this.state.noMoreCommentResults) {
          this.paginateArray('PUBLIC_COMMENTS')
        }
        // Public Newest Posts
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_POSTS'
          && this.state.newestViewForPosts && !this.state.noMorePublicNewestPostResults
        ) {
          this.paginateArray('PUBLIC_NEWEST_POSTS')
        }
        // Public Most Earned Posts
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_POSTS'
          && !this.state.newestViewForPosts && !this.state.noMorePublicMostEarnedPostResults) {
          this.paginateArray('PUBLIC_MOST_EARNED_POSTS')
        }
        // Following
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_FOLLOWING' && !this.state.noMorePublicFollowingResults) {
          this.paginateArray('PUBLIC_FOLLOWING')
        }
        // Followers
        if (entries[0].isIntersecting && this.state.listDataType === 'GET_FOLLOWERS' && !this.state.noMorePublicFollowerResults) {
          this.paginateArray('PUBLIC_FOLLOWERS')
        }
      }
    })
    // Observe the current node.
    if (node) this.observer.current.observe(node)
  }
  // This is my callback function for when the ref becomes .current (on the screen)
  refCallbackForArray = this.arrayPaginationLogic.bind(this); // <- this is essentially `useCallback`
  paginateArray = (type) => {
    if (!this.paginationArrayTypes.includes(type)) return
    switch (type) {
      case 'PUBLIC_COMMENTS':
        return (
          this.setState({ paginatedCommentsAreLoaded: false, pageNumberPublicComments: this.state.pageNumberPublicComments + 1 }, () => {
            API.getCommentsForProfilePage(this.props.match.params.handCashHandle, this.state.pageNumberPublicComments, this.cancelToken.token)
              .then(res => {
                const comments = res.data
                // If no data is retreived, return out of the function
                if (comments.length === 0) {
                  let updatedArray = this.state.publicUserComments
                  updatedArray.forEach(item => { item.ref = false })
                  this.setState({ paginatedCommentsAreLoaded: true, noMoreCommentResults: true, publicUserComments: updatedArray })
                }
                // Case if the search has some results, but not all.
                else if (comments.length < globals.fetchTwenty) {
                  const updatedArray = this.updateExistingArray(this.state.publicUserComments, comments, globals.fetchTwenty)
                  this.setState({ publicUserComments: updatedArray }, () => {
                    this.setState({ paginatedCommentsAreLoaded: true, noMoreCommentResults: true })
                  })
                }
                // Case if max items retrieved
                else {
                  const updatedArray = this.updateExistingArray(this.state.publicUserComments, comments, globals.fetchTwenty)
                  this.setState({ publicUserComments: updatedArray }, () => {
                    this.setState({ paginatedCommentsAreLoaded: true })
                  })
                }
              })
              .catch(error => {
                console.error(error);
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              });
          })
        )
      case 'PRIVATE_COMMENTS':
        return (
          this.setState({ paginatedCommentsAreLoaded: false, pageNumberPrivateComments: this.state.pageNumberPrivateComments + 1 }, () => {
            API.getCommentsForProfilePage(this.props.match.params.handCashHandle, this.state.pageNumberPrivateComments, this.cancelToken.token)
              .then(res => {
                const comments = res.data
                let updatedArray = comments
                let noMoreCommentResults = false
                if (comments.length < globals.fetchTwenty) noMoreCommentResults = true;
                this.props.storeUserDatabaseData({ type: 'addPrivateCommentsForProfilePage', updatedArray, noMoreCommentResults })
                this.setState({ paginatedCommentsAreLoaded: true })
              })
              .catch(error => {
                console.error(error);
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              });
          })
        )
      case 'PUBLIC_NEWEST_POSTS':
        return (
          this.setState({ paginatedPostsAreLoaded: false, pageNumberPublicNewestPosts: this.state.pageNumberPublicNewestPosts + 1 }, () => {
            API.getPostsForProfilePage(
              this.props.match.params.handCashHandle, 'noHandle',
              this.state.pageNumberPublicNewestPosts, this.cancelToken.token
            )
              .then(res => {
                const posts = res.data;
                // If no posts are returned
                if (posts.length === 0) {
                  let updatedArray = this.state.publicUserNewestPosts
                  updatedArray.forEach(item => { item.ref = false })
                  return this.setState({
                    publicUserNewestPosts: updatedArray, paginatedPostsAreLoaded: true,
                    noMorePublicNewestPostResults: true
                  })
                }
                // If less than 50 posts are returned
                if (posts.length < globals.fetchFifty) this.setState({ noMorePublicNewestPostResults: true });
                // If any or all 50 posts are returned
                const updatedArray = this.updateExistingArray(this.state.publicUserNewestPosts, posts, globals.fetchFifty)
                this.setState({ publicUserNewestPosts: updatedArray }, () => {
                  this.setState({ paginatedPostsAreLoaded: true })
                })
              })
              .catch(error => {
                console.error(error);
                this.setState({ paginatedPostsAreLoaded: true })
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              })
          })
        )
      case 'PRIVATE_NEWEST_POSTS':
        return (
          this.setState({ paginatedPostsAreLoaded: false, pageNumberPrivateNewestPosts: this.state.pageNumberPrivateNewestPosts + 1 }, () => {
            let possibleHandle = 'noHandle'
            if (this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle) possibleHandle = this.props.auth.handle;
            API.getPostsForProfilePage(
              this.props.match.params.handCashHandle, possibleHandle,
              this.state.pageNumberPrivateNewestPosts, this.cancelToken.token
            )
              .then(res => {
                const posts = res.data;
                const updatedArray = posts;
                let noMorePrivateNewestPostResults = false;
                if (posts.length < globals.fetchFifty) noMorePrivateNewestPostResults = true;
                this.props.storeUserDatabaseData({ type: 'addProfilePosts', updatedArray, noMorePrivateNewestPostResults })
                this.setState({ paginatedPostsAreLoaded: true })
              })
              .catch(error => {
                console.error(error);
                this.setState({ paginatedPostsAreLoaded: true })
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              })
          })
        )
      case 'PUBLIC_MOST_EARNED_POSTS':
        return (
          this.setState({ paginatedPostsAreLoaded: false, pageNumberPublicMostEarnedPosts: this.state.pageNumberPublicMostEarnedPosts + 1 }, () => {
            API.getMostEarnedPosts(
              this.props.match.params.handCashHandle, 'noHandle',
              this.state.pageNumberPublicMostEarnedPosts, this.cancelToken.token
            )
              .then(res => {
                const posts = res.data;
                // If no posts are returned
                if (posts.length === 0) {
                  let updatedArray = this.state.publicUserMostEarnedPosts
                  updatedArray.forEach(item => { item.ref = false })
                  return this.setState({
                    publicUserMostEarnedPosts: updatedArray, paginatedPostsAreLoaded: true,
                    noMorePublicMostEarnedPostResults: true
                  })
                }
                // If less than 50 posts are returned
                if (posts.length < globals.fetchFifty) this.setState({ noMorePublicMostEarnedPostResults: true });
                // If any or all 50 posts are returned
                const updatedArray = this.updateExistingArray(this.state.publicUserMostEarnedPosts, posts, globals.fetchFifty)
                this.setState({ publicUserMostEarnedPosts: updatedArray }, () => {
                  this.setState({ paginatedPostsAreLoaded: true })
                })
              })
              .catch(error => {
                console.error(error);
                this.setState({ paginatedPostsAreLoaded: true })
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              })
          })
        )
      case 'PRIVATE_MOST_EARNED_POSTS':
        return (
          this.setState({ paginatedPostsAreLoaded: false, pageNumberPrivateMostEarnedPosts: this.state.pageNumberPrivateMostEarnedPosts + 1 }, () => {
            let possibleHandle = 'noHandle'
            if (this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle) possibleHandle = this.props.auth.handle;
            API.getMostEarnedPosts(
              this.props.match.params.handCashHandle, possibleHandle,
              this.state.pageNumberPrivateMostEarnedPosts, this.cancelToken.token
            )
              .then(res => {
                const posts = res.data;
                const updatedArray = posts
                let noMorePrivateMostEarnedPostResults = false
                if (posts.length < globals.fetchFifty) noMorePrivateMostEarnedPostResults = true;
                this.props.storeUserDatabaseData({ type: 'addProfileMostEarnedPosts', updatedArray, noMorePrivateMostEarnedPostResults })
                this.setState({ paginatedPostsAreLoaded: true })
              })
              .catch(error => {
                console.error(error);
                this.setState({ paginatedPostsAreLoaded: true })
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              })
          })
        )
      case 'PUBLIC_FOLLOWING':
        return (
          this.setState({ paginatedFollowIsLoaded: false, pageNumberPublicFollowing: this.state.pageNumberPublicFollowing + 1 }, () => {
            API.getFollowData(
              this.props.match.params.handCashHandle, this.state.pageNumberPublicFollowing,
              'following', this.cancelToken.token
            )
              .then(res => {
                const items = res.data.following
                let publicData = this.state.publicUserData
                if (items.length === 0) {
                  let updatedArray = publicData.following
                  updatedArray.following.forEach(item => { item.ref = false })
                  publicData.following = updatedArray
                  return this.setState({
                    publicUserData: updatedArray, paginatedPostsAreLoaded: true,
                    noMorePublicFollowingResults: true
                  })
                }
                if (items.length < globals.fetchFifty) this.setState({ noMorePublicFollowingResults: true });
                const followsArray = this.state.publicUserData.following
                const updatedArray = this.updateExistingArray(followsArray, items, globals.fetchFifty)
                publicData.following = updatedArray
                this.setState({ publicUserData: publicData }, () => {
                  this.setState({ paginatedPostsAreLoaded: true })
                })
              })
              .catch(error => {
                console.error(error);
                this.setState({ paginatedPostsAreLoaded: true })
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              })
          })
        )
      case 'PRIVATE_FOLLOWING':
        return (
          this.setState({ paginatedFollowIsLoaded: false, pageNumberPrivateFollowing: this.state.pageNumberPrivateFollowing + 1 }, () => {
            API.getFollowData(
              this.props.match.params.handCashHandle, this.state.pageNumberPrivateFollowing,
              'following', this.cancelToken.token
            )
              .then(res => {
                const items = res.data.following
                const updatedArray = items
                let noMorePrivateFollowingResults = false
                if (items.length < globals.fetchFifty) noMorePrivateFollowingResults = true;
                this.props.storeUserDatabaseData({ type: 'getPrivateFollowing', updatedArray, noMorePrivateFollowingResults })
                this.setState({ paginatedPostsAreLoaded: true })
              })
              .catch(error => {
                console.error(error);
                this.setState({ paginatedPostsAreLoaded: true })
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              })
          })
        )
      case 'PUBLIC_FOLLOWERS':
        return (
          this.setState({ paginatedFollowIsLoaded: false, pageNumberPublicFollowers: this.state.pageNumberPublicFollowers + 1 }, () => {
            API.getFollowData(
              this.props.match.params.handCashHandle, this.state.pageNumberPublicFollowers,
              'followers', this.cancelToken.token
            )
              .then(res => {
                const items = res.data.followers
                let publicData = this.state.publicUserData
                if (items.length === 0) {
                  let updatedArray = publicData.followers
                  updatedArray.followers.forEach(item => { item.ref = false })
                  publicData.followers = updatedArray
                  return this.setState({
                    publicUserData: publicData, paginatedPostsAreLoaded: true,
                    noMorePublicFollowerResults: true
                  })
                }
                if (items.length < globals.fetchFifty) this.setState({ noMorePublicFollowerResults: true });
                const followsArray = this.state.publicUserData.followers
                const updatedArray = this.updateExistingArray(followsArray, items, globals.fetchFifty)
                publicData.followers = updatedArray
                this.setState({ publicUserData: publicData }, () => {
                  this.setState({ paginatedPostsAreLoaded: true })
                })
              })
              .catch(error => {
                console.error(error);
                this.setState({ paginatedPostsAreLoaded: true })
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              })
          })
        )
      case 'PRIVATE_FOLLOWERS':
        return (
          this.setState({ paginatedFollowIsLoaded: false, pageNumberPrivateFollowers: this.state.pageNumberPrivateFollowers + 1 }, () => {
            API.getFollowData(
              this.props.match.params.handCashHandle, this.state.pageNumberPrivateFollowers,
              'followers', this.cancelToken.token
            )
              .then(res => {
                const items = res.data.followers
                const updatedArray = items
                let noMorePrivateFollowerResults = false
                if (items.length < globals.fetchFifty) noMorePrivateFollowerResults = true;
                this.props.storeUserDatabaseData({ type: 'getPrivateFollowers', updatedArray, noMorePrivateFollowerResults })
                this.setState({ paginatedPostsAreLoaded: true })
              })
              .catch(error => {
                console.error(error);
                this.setState({ paginatedPostsAreLoaded: true })
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              })
          })
        )
      default: return
    }
  }
  firstLoadUpdateArray = (items, paginationAmount) => {
    if (!items || !paginationAmount || ![20, 50].includes(paginationAmount)) return []
    let addingToArray = items;
    if (paginationAmount === 20 && addingToArray.length === globals.fetchTwenty) {
      let itemFive = addingToArray[4];
      itemFive.ref = true;
    }
    if (paginationAmount === 50 && addingToArray.length === globals.fetchFifty) {
      let itemThirtyFive = addingToArray[34];
      itemThirtyFive.ref = true;
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    return addingToArray;
  }
  updateExistingArray = (existingArray, newData, paginationAmount) => {
    if (!existingArray || !newData || !paginationAmount || ![20, 50].includes(paginationAmount)) return []
    let addingToArray = existingArray
    addingToArray.forEach(post => {
      post.ref = false
      post.lastItem = false
    })
    // Add the new items to the array
    newData.map(post => {
      return addingToArray.push(post)
    })
    // Add ref, if applicable
    if (paginationAmount === 20 && newData.length === globals.fetchTwenty) {
      let itemFive = addingToArray[addingToArray.length - 15]
      itemFive.ref = true
      addingToArray.splice(addingToArray.length - 15, 1, itemFive)
    }
    if (paginationAmount === 50 && newData.length === globals.fetchFifty) {
      let itemThirtyFive = addingToArray[addingToArray.length - 5]
      itemThirtyFive.ref = true
      addingToArray.splice(addingToArray.length - 15, 1, itemThirtyFive)
    }
    // Add lastItem property (for displaying noMoreResults & Loading)
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    // So this takes the index of the addingToArray, grabs one item, and replaces it with lastItem.
    addingToArray.splice(addingToArray.length - 1, 1, lastItem)
    return addingToArray;
  }
  validationChecks = () => {
    let allChecksValid;
    // commentBody Validation
    let commentBodyIsValid;
    if (this.state.tierTwoComment.tierTwoCommentBody.length <= 1) {
      this.setState({
        commentBodyError: '*Your comment could use some material*'
      })
      commentBodyIsValid = false
    } else commentBodyIsValid = true;

    // This allChecksValid will return true or false to initiate the function in handleSubmitPost
    if (!commentBodyIsValid) {
      allChecksValid = false
      this.setState({ overallError: `*Psst, fix any errors above and try again*` })
    }
    else allChecksValid = true;
    return allChecksValid;
  }
  /////////////////
  // MODAL LOGIC //
  /////////////////
  togglePaymentModal = (e, typeOfTransaction) => {
    if (e) e.preventDefault();
    if (typeOfTransaction === 'UPDATE_COMMENT') {
      let validationReturnsTrue = this.validationChecks();
      if (!validationReturnsTrue) return globals.toastError(toast, 'Correct any errors and try again')
    }
    if (this.state.showPaymentModal === false) {
      this.setState({ showPaymentModal: true, transactionType: typeOfTransaction })
    }
    else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: typeOfTransaction })
        }, 400)
      })
    }
  };
  confirmPaymentModal = (e, typeOfTransaction) => {
    e.preventDefault();
    if (typeOfTransaction === 'TIP_USER') {
      const validationReturnsTrue = this.tipValidationCheck()
      if (!validationReturnsTrue) return globals.toastError(toast, 'Correct any errors and try again')
    }
    this.setState({ showTipModal: false }, () => {
      setTimeout(() => {
        this.setState({ showPaymentModal: true, transactionType: typeOfTransaction })
      }, 400)
    })
  }
  toggleListItemModal = (e) => {
    e.preventDefault();
    if (this.state.showListItemModal === false) {
      this.setState({ showListItemModal: true })
    }
    else {
      this.setState({ showListItemModal: false, listDataType: '' })
    }
  }
  toggleTipModal = (e) => {
    e.preventDefault();
    if (this.state.showTipModal === false) {
      this.setState({ showTipModal: true })
    }
    else {
      this.setState({ showTipModal: false })
    }
  }
  closeTipModal = (e) => {
    e.preventDefault();
    this.setState({ showTipModal: false })
  }
  toggleDeleteDraftModal = (e, draftId, draftIndexInArray) => {
    e.preventDefault();
    if (this.state.showDeleteDraftModal === false) {
      this.setState({ showDeleteDraftModal: true, draftId: draftId, draftIndex: draftIndexInArray })
    }
    else {
      this.setState({ showDeleteDraftModal: false, draftId: '', draftIndex: '' })
    }
  }
  ///////////////
  // FOLLOWING //
  ///////////////
  followValidation = () => {
    let followValidated = { valueReturned: true, errorMessage: '', }
    if (this.props.auth.id === this.state.publicUserData.handCashId) {
      followValidated.valueReturned = false;
      followValidated.errorMessage = `Cannot Follow yourself!`;
      return followValidated;
    }
    if (this.state.publicUserData.followerHandCashIds.includes(this.props.auth.id)) {
      followValidated.valueReturned = false;
      followValidated.errorMessage = `Already Following User!`;
      return followValidated;
    };
    return followValidated;
  }
  followUser = (e) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (this.props.auth?.id && localStorageAuth) {
      let followValidated = this.followValidation();
      if (!followValidated.valueReturned) {
        return (
          globals.toastError(toast, followValidated.errorMessage),
          this.setState({ showPaymentModal: false }, () => {
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400);
          })
        )
      }
      localStorageAuth = JSON.parse(localStorageAuth);
    };
    // If user is not logged in
    if (!this.props.auth?.id || !localStorageAuth?.authToken) {
      return (
        globals.toastError(toast, 'You must login to follow others'),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400);
        })
      )
    };
    // What data am I passing in?
    const followingData = {
      recipientHandCashId: this.state.publicUserData.handCashId,
      recipientHandCashHandle: this.state.publicUserData.handCashHandle,
      recipientHandCashAvatarURL: this.state.publicUserData.handCashAvatarUrl,
      followingCost: globals.followingCost,
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false
    }
    this.toggleOverlay();
    API.followUser(followingData)
      .then(res => {
        // Database response objects
        const followingDoc = res.data.createFollowingDoc
        const recipientUpdate = res.data.recipientUpdate
        const followerUpdate = res.data.followerUpdate
        // Updating publicUserData state
        let updatingPublicUserData = this.state.publicUserData;
        // Reassigning existing values to updated values
        updatingPublicUserData.followerHandCashIds = recipientUpdate.followerHandCashIds
        updatingPublicUserData.totalFollowers = recipientUpdate.totalFollowers
        updatingPublicUserData.totalFollowValueReceived = recipientUpdate.totalFollowValueReceived
        updatingPublicUserData.totalValueReceived = recipientUpdate.totalValueReceived
        if (updatingPublicUserData?.followers) updatingPublicUserData.followers.unshift(followingDoc)
        this.setState({ publicUserData: updatingPublicUserData, userAlreadyFollows: true })
        // Updating logged-in user data
        const totalFollowing = followerUpdate.totalFollowing
        const totalFollowValuePaid = followerUpdate.totalFollowValuePaid
        const totalValueSent = followerUpdate.totalValueSent
        this.props.storeUserDatabaseData({ type: 'updateForFollowing', totalFollowing, totalFollowValuePaid, totalValueSent, followingDoc })
        // Conclusion to function
        globals.toastSuccess(toast, `You are now following ${this.state.publicUserData.handCashHandle}!`);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false })
        }, 400)
      })
      .catch(err => {
        console.error(err);
        globals.toastError(toast, err.response.data.message);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false })
        }, 400)
      })
  }
  ////////////////////
  // Not being used //
  ////////////////////

  // getFollowingArray = () => {
  //   // Confirm handles match
  //   if (this.props.match.params.handCashHandle !== this.state.paramHandle) {
  //     return globals.toastError(toast, 'Unable to retrieve followers');
  //   }
  //   // Grabbing either public or private id and passing it into the API call
  //   let id = this.state.publicUserData._id;
  //   if (this.props.auth && this.props.userDatabaseData && this.props.auth.handle === this.state.paramHandle) {
  //     id = this.props.userDatabaseData._id;
  //   }
  //   // API call to get following array, and adding it to Redux.
  //   API.getFollowingArray(this.props.match.params.handCashHandle, this.props.dataLists.followingPageNumber, id)
  //     .then(res => {
  //       this.props.storeFollowing(res.data.following, 1, this.props.match.params.handCashHandle)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       globals.toastError(toast, err.response.data.message);
  //       setTimeout(() => {
  //         this.setState({ pageOverlayActive: false })
  //       }, 400)
  //     })
  // }
  // getFollowersArray = (e) => {
  //   e.preventDefault();
  //   // Data will probably be what page number it's on, or whatever.
  //   if (this.props.match.params.handCashHandle !== this.state.paramHandle) {
  //     return globals.toastError(toast, 'Unable to retrieve followers');
  //   }
  //   // Grabbing either public or private id and passing it into the API call
  //   let id = this.state.publicUserData._id;
  //   if (this.props.auth && this.props.userDatabaseData && this.props.auth.handle === this.state.paramHandle) {
  //     id = this.props.userDatabaseData._id
  //   }
  //   API.getFollowersArray(this.props.match.params.handCashHandle, this.props.dataLists.followersPageNumber, id)
  //     .then(res => {
  //       // Set Redux state. Add that data.
  //       // The page number assumes that all results are there. I'll have to add that logic later.
  //       this.props.storeFollowers(res.data.followers, 1, this.props.match.params.handCashHandle)
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       globals.toastError(toast, err.response.data.message);
  //     })
  // }
  /////////////
  // TIPPING //
  /////////////
  tipValidationCheck = () => {
    let allChecksValid = true
    if (this.state.tipMessage.length > 280) {
      this.setState({
        tipMessageError: `*Message must be less than 280 characters, hope you don't mind.*`
      })
      allChecksValid = false
    }
    let tipAmount = parseFloat(this.state.tipAmount)
    if (isNaN(tipAmount) || tipAmount === 0 || !this.state.tipAmount >= .01) {
      this.setState({
        tipMessageError: `*Please update the tip amount*`
      })
      allChecksValid = false
    }
    return allChecksValid
  }
  tipUser = (e) => {
    e.preventDefault();
    const validationReturnsTrue = this.tipValidationCheck()
    if (!validationReturnsTrue) return globals.toastError(toast, 'Fix any errors and try again')
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // If user is not logged in
    if (!this.props.auth?.id || !localStorageAuth.authToken) {
      return (
        globals.toastError(toast, 'Login to tip others'),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400);
        })
      )
    }
    const tipData = {
      recipientHandCashId: this.state.publicUserData.handCashId,
      recipientHandCashHandle: this.state.publicUserData.handCashHandle,
      recipientHandCashAvatarURL: this.state.publicUserData.handCashAvatarUrl,
      tipAmount: parseFloat(this.state.tipAmount),
      tipMessage: this.state.tipMessage,
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
    }
    this.toggleOverlay()
    API.tipUser(tipData)
      .then(res => {
        // Database response objects
        const tippingDoc = res.data.createTippingDoc
        const recipientUpdate = res.data.recipientUpdate
        const tipperUpdate = res.data.tipperUpdate
        // Updating publicUserData state
        let updatingPublicUserData = this.state.publicUserData;
        // Reassigning existing values to updated values
        updatingPublicUserData.totalTipsReceived = recipientUpdate.totalTipsReceived
        updatingPublicUserData.totalTipsValueReceived = recipientUpdate.totalTipsValueReceived
        updatingPublicUserData.totalValueReceived = recipientUpdate.totalValueReceived
        if (updatingPublicUserData?.tipsReceived) updatingPublicUserData.tipsReceived.unshift(tippingDoc)
        this.setState({ publicUserData: updatingPublicUserData })
        // Updating logged-in user data
        const totalTipsGiven = tipperUpdate.totalTipsGiven
        const totalTipValueGiven = tipperUpdate.totalTipValueGiven
        const totalValueSent = tipperUpdate.totalValueSent
        this.props.storeUserDatabaseData({ type: 'updateTipInfo', totalTipsGiven, totalTipValueGiven, totalValueSent, tippingDoc })
        // Conclusion to function
        globals.toastSuccess(toast, `You have successfully tipped ${this.state.publicUserData.handCashHandle} $${parseFloat(this.state.tipAmount).toFixed(2)}!`);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, tipAmount: .99, tipMessage: '' })
        }, 400)
      })
      .catch(err => {
        console.error(err);
        globals.toastError(toast, err.response.data.message);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, tipAmount: .99, tipMessage: '', })
        }, 400)
      })
  }
  handleTipAmountChange = (e) => {
    // This ensures 2 decimal places
    this.setState({ tipAmount: e.target.value, tipMessageError: `` });
    let source = document.querySelector('.updatingTipValue')
    source.addEventListener('change', () => {
      source.value = parseFloat(source.value).toFixed(2)
    });
  }
  handleTipMessageChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
    setTimeout(() => {
      // Determines character count
      this.checkLengthOnDescription('TIP');
      // If there are errors
      if (!this.state.tipMessageError === '') {
        setTimeout(() => {
          if (this.state.tipMessage.length > 1 && this.state.tipMessage.length <= 280) {
            this.setState({ tipMessageError: '' })
          }
        }, 400)
      }
    }, 100)
  }
  // This checks the length and changes color of the character # count to notify how many characters a person has.
  checkLengthOnDescription = (type) => {
    if (type === 'TIP') {
      const tipMessageLength = this.state.tipMessage.length;
      if (tipMessageLength <= 200) {
        return this.setState({ charCounterClass: `fifth-text` })
      } else if (tipMessageLength >= 200 && tipMessageLength <= 280) {
        return this.setState({ charCounterClass: 'text-orange-500' })
      } else {
        return this.setState({ charCounterClass: 'text-red-600' })
      }
    }
    if (type === 'DONATION') {
      const donationMessageLength = this.state.donationMessage.length;
      if (donationMessageLength <= 200) {
        return this.setState({ donationCharCounterClass: `fifth-text` })
      } else if (donationMessageLength >= 200 && donationMessageLength <= 280) {
        return this.setState({ donationCharCounterClass: 'text-orange-500' })
      } else {
        return this.setState({ donationCharCounterClass: 'text-red-600' })
      }
    }
  }
  ////////////////
  // DATA LISTS //
  ////////////////
  togglePrivateDataList = (e, value) => {
    if (e) e.preventDefault();
    if (e) globals.createRipple(e)
    const currentListDataValue = this.state.listDataType
    if (value === currentListDataValue) return
    this.setPrivateDataListState(value)
    sessionStorage.setItem('dataListForProfilePage', value)
    switch (value) {
      case 'GET_POSTS':
        return this.getPostsForProfilePage()
      case 'GET_COMMENTS':
        return this.getCommentsForProfilePage()
      case 'GET_DRAFTS':
        return this.getDraftsForProfilePage()
      default: return
    }
  }
  togglePublicDataList = (e, value) => {
    if (!this.publicDataListArray.includes(value)) return
    if (e) e.preventDefault();
    if (e) globals.createRipple(e)
    const currentListDataValue = this.state.listDataType
    if (value === currentListDataValue) return
    sessionStorage.setItem('dataListForProfilePage', value)
    this.setPublicDataListState(value)
    switch (value) {
      case 'GET_POSTS':
        return this.getPostsForProfilePage()
      case 'GET_COMMENTS':
        return this.getCommentsForProfilePage()
      default: return
    }
  }
  setPublicDataListState = (value) => {
    switch (value) {
      case 'GET_PROFILE':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: 'highlight-border',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_POSTS':
        return (
          this.setState({
            pubSelect: {
              profile: '',
              stats: '',
              posts: 'highlight-border',
              comments: '',
              tips: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          })
        )
      case 'GET_COMMENTS':
        return (
          this.setState({
            pubSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: 'highlight-border',
              tips: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          })
        )
      case 'GET_STATS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: 'highlight-border',
              posts: '',
              comments: '',
              tips: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_TIPS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: 'highlight-border',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_FOLLOWERS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              followers: 'highlight-border',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_FOLLOWING':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              followers: '',
              following: 'highlight-border',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_PAYWALLS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              paywalls: 'highlight-border',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_SHARES':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              followers: '',
              following: '',
              shares: 'highlight-border',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_VOTES':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              followers: '',
              following: '',
              shares: '',
              votes: 'highlight-border',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_STAMPSHOTS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: 'highlight-border',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      default:
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            pubSelect: {
              profile: '',
              stats: 'highlight-border',
              posts: '',
              comments: '',
              tips: '',
              followers: '',
              following: '',
              shares: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
    }
  }
  setPrivateDataListState = (value) => {
    switch (value) {
      case 'GET_PROFILE':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: 'highlight-border',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_POSTS':
        return (
          this.setState({
            privSelect: {
              profile: '',
              stats: '',
              posts: 'highlight-border',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          })
        )
      case 'GET_COMMENTS':
        return (
          this.setState({
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: 'highlight-border',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          })
        )
      case 'GET_DRAFTS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: 'highlight-border',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          })
        )
      case 'GET_STATS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: 'highlight-border',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_TIPS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: 'highlight-border',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_THUMBS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: 'highlight-border',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_PAYWALLS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: 'highlight-border',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_FOLLOWERS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: 'highlight-border',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_FOLLOWING':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: 'highlight-border',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_SHARES':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: 'highlight-border',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_VOTES':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: 'highlight-border',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_STAMPSHOTS':
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: '',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: 'highlight-border',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      default:
        return (
          this.setState({
            dataListOpacity: 'opacity-0',
            privSelect: {
              profile: 'highlight-border',
              stats: '',
              posts: '',
              comments: '',
              tips: '',
              purchases: '',
              thumbs: '',
              paywalls: '',
              drafts: '',
              followers: '',
              following: '',
              shares: '',
              votes: '',
              stampshots: '',
            }
          }),
          setTimeout(() => {
            this.setState({ listDataType: value, dataListOpacity: 'opacity-100' })
          }, 400)
        )
    }
  }
  ////////////
  // DRAFTS //
  ////////////
  getDraftsForProfilePage = () => {
    // Confirm that they are looking at their own drafts
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    if (!localStorageAuth || !this.props.userDatabaseData || !this.props.auth) return (
      this.setState({ listDataType: 'GET_STATS' }),
      setTimeout(() => {
        this.setState({ dataListOpacity: 'opacity-100', draftsOpacity: 'opacity-100' })
      }, 400),
      globals.toastError(toast, `Login to view drafts`)
    )
    if (this.props.userDatabaseData && localStorageAuth && this.props.auth && this.props.auth.handle !== this.props.match.params.handCashHandle) {
      return (
        this.setState({ listDataType: 'GET_STATS' }),
        setTimeout(() => {
          this.setState({ dataListOpacity: 'opacity-100' })
        }, 400),
        globals.toastError(toast, `Cannot view other's drafts!`)
      )
    }
    // If drafts are already loaded and they toggled away from drafts, and toggled back into them.
    if (this.state.drafts.length > 0) {
      return (
        this.setState({ dataListOpacity: 'opacity-0' }),
        setTimeout(() => {
          this.setState({ listDataType: 'GET_DRAFTS', dataListOpacity: 'opacity-100', draftsOpacity: 'opacity-100' })
        }, 400)
      )
    }
    this.setState({ dataListOpacity: 'opacity-0' })
    setTimeout(() => {
      this.setState({ dataListOpacity: 'opacity-100', listDataType: 'GET_DRAFTS' })
    }, 400)
    // If drafts are not loaded, grab them from database
    API.getDraftsForProfilePage(this.props.match.params.handCashHandle, this.cancelToken.token)
      .then(res => {
        if (res.data.length === 0) return (
          this.setState({ draftsAreLoaded: true, draftsOpacity: 'opacity-100' })
        )
        const drafts = res.data;
        this.setState({ drafts: drafts, draftsAreLoaded: true, draftsOpacity: 'opacity-100' })
      })
      .catch(error => {
        console.error(error);
        this.setState({ listDataType: 'GET_PROFILE', dataListOpacity: 'opacity-100' });
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, error.response.data.message);
      })
  }
  // Delete a draft
  deleteDraft = (e, draftId, indexInDraftArray) => {
    e.preventDefault();
    // Confirm that they are deleting their own stuff
    if (this.props.auth && this.props.auth.handle !== this.props.match.params.handCashHandle) {
      return (
        globals.toastError(toast, `Cannot delete other's drafts!`)
      )
    }
    // If it is indeed their draft
    API.deleteDraft(draftId, this.props.match.params.handCashHandle)
      .then(res => {
        let updatingDrafts = this.state.drafts;
        // Splice removes 1 element at specified index
        updatingDrafts.splice(indexInDraftArray, 1)
        globals.toastSuccess(toast, `Draft deleted!`);
        this.setState({ drafts: updatingDrafts, showDeleteDraftModal: false })
      })
      .catch(err => {
        console.error(err);
        globals.toastError(toast, err.response.data.message);
        this.setState({ showDeleteDraftModal: false })
      })
  }
  goToDraftEditingPage = (e, draft) => {
    e.preventDefault()
    // If it is not their draft
    if (this.props.auth && this.props.auth.handle !== this.props.match.params.handCashHandle) {
      return globals.toastError(toast, `Unable to go to draft editing page!`)
    }
    this.props.opacityChange('removeProfilePageOpacity')
    setTimeout(() => {
      this.props.history.push({
        pathname: `/draft-update/${draft.draftSlug}`,
        state: draft
      })
    }, 300)
  }
  // Capture posts for either public or private. This is the default option when someone wishes to view posts
  getPostsForProfilePage = () => {
    // If the profilePosts already exists. Publicly or Privately.
    if ((this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle &&
      this.props.userDatabaseData.profilePosts > 0)
      || this.state.publicUserNewestPosts.length > 0) {
      return (
        this.setState({ dataListOpacity: 'opacity-0', privatePostsLoaded: true, postsAreLoaded: true }),
        setTimeout(() => {
          this.setState({ listDataType: 'GET_POSTS', dataListOpacity: 'opacity-100', postsOpacity: 'opacity-100' })
        }, 400)
      )
    }
    // possibleHandle is to determine if paywall data is needed or not, for editing purposes
    let possibleHandle = 'noHandle'
    let pageNumber = this.state.pageNumberPublicNewestPosts
    // If user is logged in, and in own profile page
    if (this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle) {
      possibleHandle = this.props.auth.handle;
      pageNumber = this.state.pageNumberPrivateNewestPosts
    }
    // State change before API call
    this.setState({ dataListOpacity: 'opacity-0' })
    setTimeout(() => {
      this.setState({ dataListOpacity: 'opacity-100', listDataType: 'GET_POSTS' })
    }, 400)
    // API call
    API.getPostsForProfilePage(this.props.match.params.handCashHandle, possibleHandle, pageNumber, this.cancelToken.token)
      .then(res => {
        let posts = res.data;
        // If they're in their own profile page
        if (this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle) {
          let noMorePrivateNewestPostResults = false
          if (posts.length < globals.fetchFifty) noMorePrivateNewestPostResults = true
          if (posts.length === 0) return (
            this.setState({ privatePostsLoaded: true, postsAreLoaded: true, postsOpacity: 'opacity-100' })
          )
          const updatedArray = this.firstLoadUpdateArray(posts, globals.fetchFifty)
          this.props.storeUserDatabaseData({ type: 'addProfilePosts', updatedArray, noMorePrivateNewestPostResults })
          this.setState({ privatePostsLoaded: true, postsAreLoaded: true, postsOpacity: 'opacity-100' })
        }
        // If they are NOT in their own profile page
        else {
          if (posts.length === 0) return (
            this.setState({ privatePostsLoaded: true, postsAreLoaded: true, postsOpacity: 'opacity-100' })
          )
          if (posts.length < globals.fetchFifty) this.setState({ noMorePublicNewestPostResults: true })
          const updatedArray = this.firstLoadUpdateArray(posts, globals.fetchFifty)
          this.setState({ publicUserNewestPosts: updatedArray }, () => {
            this.setState({ postsAreLoaded: true, postsOpacity: 'opacity-100' })
          })
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({ listDataType: 'GET_PROFILE', postsOpacity: 'opacity-100' })
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, error.response.data.message);
      })
  }
  getCommentsForProfilePage = () => {
    // If user is logged in and the param handles match, and there ARE profileComments already.
    // Or if user is not logged in and the publicUserComments exist already
    if ((this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle &&
      this.props.userDatabaseData.profileComments.length > 0)
      || this.state.publicUserComments.length > 0) {
      return (
        this.setState({ dataListOpacity: 'opacity-0', privateCommentsLoaded: true, commentsAreLoaded: true }),
        setTimeout(() => {
          this.setState({ listDataType: 'GET_COMMENTS', dataListOpacity: 'opacity-100', commentsOpacity: 'opacity-100' })
        }, 400)
      )
    }
    //////////////////////////////////////////
    // If user is viewing their own account //
    //////////////////////////////////////////
    if (this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle) {
      // Check to see if there are comments already loaded. Return if so.
      if (this.props.userDatabaseData.profileComments && this.props.userDatabaseData.profileComments.length > 0) {
        return (
          this.setState({ dataListOpacity: 'opacity-0' }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100', listDataType: 'GET_COMMENTS', commentsOpacity: 'opacity-100' })
          }, 400)
        )
      }
      this.setState({ dataListOpacity: 'opacity-0' })
      setTimeout(() => {
        this.setState({ dataListOpacity: 'opacity-100', listDataType: 'GET_COMMENTS' })
      }, 400)
      API.getCommentsForProfilePage(this.props.match.params.handCashHandle, this.state.pageNumberPrivateComments, this.cancelToken.token)
        .then(res => {
          const comments = res.data
          let noMoreCommentResults = false
          if (comments.length < globals.fetchTwenty) noMoreCommentResults = true
          // If no data is retreived, return out of the function
          if (comments.length === 0) return setTimeout(() => {
            this.setState({ commentsAreLoaded: true, commentsOpacity: 'opacity-100' })
          }, 300)
          // Case if the search has some results, but not all.
          const updatedArray = this.firstLoadUpdateArray(comments, globals.fetchTwenty)
          this.props.storeUserDatabaseData({ type: 'addPrivateCommentsForProfilePage', updatedArray, noMoreCommentResults })
          setTimeout(() => {
            this.setState({ commentsAreLoaded: true, privateCommentsLoaded: true, commentsOpacity: 'opacity-100' })
          }, 300)
        })
        .catch(error => {
          console.error(error);
          this.setState({ listDataType: 'GET_PROFILE', dataListOpacity: 'opacity-100' })
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, error.response.data.message);
        })
    }
    ///////////////////////////////////////////////
    // If user is viewing someone else's account //
    ///////////////////////////////////////////////
    else {
      // If comments are already loaded
      if (this.state.publicUserComments.length > 0) {
        return (
          this.setState({ dataListOpacity: 'opacity-0' }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100', listDataType: 'GET_COMMENTS', commentsOpacity: 'opacity-100' })
          }, 400)
        )
      }
      this.setState({ dataListOpacity: 'opacity-0' })
      setTimeout(() => {
        this.setState({ dataListOpacity: 'opacity-100', listDataType: 'GET_COMMENTS' })
      }, 400)
      API.getCommentsForProfilePage(this.props.match.params.handCashHandle, this.state.pageNumberPublicComments, this.cancelToken.token)
        .then(res => {
          const comments = res.data
          // If there are no comments
          if (comments.length === 0) {
            return (
              this.setState({ commentsAreLoaded: true, noMoreCommentResults: true, commentsOpacity: 'opacity-100' })
            )
          }
          // Case if the search has some results, but not all.
          else if (comments.length < globals.fetchTwenty) {
            const updatedArray = this.firstLoadUpdateArray(comments, globals.fetchTwenty)
            this.setState({ publicUserComments: updatedArray }, () => {
              this.setState({ commentsAreLoaded: true, noMoreCommentResults: true, commentsOpacity: 'opacity-100' })
            })
          }
          // Case if max items retrieved
          else {
            const updatedArray = this.firstLoadUpdateArray(comments, globals.fetchTwenty)
            this.setState({ publicUserComments: updatedArray }, () => {
              this.setState({ commentsAreLoaded: true, commentsOpacity: 'opacity-100' })
            })
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({ listDataType: 'GET_PROFILE' })
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100' })
          }, 400)
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, error.response.data.message);
        })
    }
  }
  goToUserProfilePage = (e, userName) => {
    e.preventDefault();
    if (userName === this.state.paramHandle) return
    // Do an opacityChange
    this.props.opacityChange('removeProfilePageOpacity')
    setTimeout(() => {
      this.setState({ listDataType: 'GET_STATS' })
      this.props.history.push(`/profile/${userName}`)
    }, 300)
  }
  goToPost = (e, postSlug) => {
    e.preventDefault()
    this.props.opacityChange('removeProfilePageOpacity')
    setTimeout(() => {
      this.props.history.push(`/posts/${postSlug}`)
    }, 300)
  }
  // purchaseMembership = (e) => {
  //   e.preventDefault();
  //   let localStorageAuth = localStorage.getItem('handCashAuthData');
  //   // Frontend measure to protect against duplicate purchase or not logged in user.
  //   if (!localStorageAuth || !this.props.userDatabaseData || this.props.userDatabaseData?.membership) {
  //     return (
  //       this.setState({ showPaymentModal: false }, () => {
  //         globals.toastError(toast, 'Unable to purchase Membership');
  //         setTimeout(() => {
  //           this.setState({ transactionType: '' })
  //         }, 400)
  //       })
  //     )
  //   };
  //   if (this.props.userDatabaseData && !this.props.userDatabaseData?.membership) {
  //     this.toggleOverlay()
  //     API.purchaseMembership(globals.membershipPrice, this.props.userDatabaseData._id)
  //       .then(res => {
  //         this.props.storeUserDatabaseData(res.data.userUpdate)
  //         globals.toastSuccess(toast, `You have successfully purchased Membership on ${globals.platformName}!`);
  //         setTimeout(() => {
  //           this.setState({ pageOverlayActive: false })
  //         }, 400)
  //       })
  //       .catch(err => {
  //         console.error(err);
  //         globals.toastError(toast, err.response.data.message);
  //         setTimeout(() => {
  //           this.setState({ pageOverlayActive: false })
  //         }, 400)
  //       })
  //   }
  // }

  toggleOverlay = () => {
    this.setState({ showPaymentModal: false }, () => {
      setTimeout(() => {
        this.setState({ transactionType: '', pageOverlayActive: true })
      }, 400)
    })
  }
  // Edit Post
  clickToEditPost = (e, slug, post) => {
    e.preventDefault();
    if (this.props.auth.id !== post.postCreatorHandCashId) {
      return globals.toastError(toast, `Unable to edit other's posts!`);
    }
    this.props.opacityChange('removeProfilePageOpacity')
    setTimeout(() => {
      this.props.history.push({
        pathname: `/edit/${slug}`,
        state: post
      })
    }, 400)
  }
  unfollowUser = (e) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // Check to see if user is logged in and they are actually following the other user
    if (!this.props.auth?.id || !localStorageAuth?.authToken || !this.state.userAlreadyFollows
      || !this.state.publicUserData.followerHandCashIds.includes(this.props.auth.id)) {
      return (
        globals.toastError(toast, 'Unable to unfollow user'),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400);
        })
      )
    };
    const publicUserHandCashId = { unfollowedHandCashId: this.state.publicUserData.handCashId }
    this.toggleOverlay()
    API.unfollowUser(publicUserHandCashId)
      .then(res => {
        let loggedInUserHandCashId = res.data.loggedInUserUpdate.handCashId
        // Updating public user data
        let publicUserData = this.state.publicUserData
        publicUserData.totalFollowers = publicUserData.totalFollowers - 1
        // Updating followers Array in publicUserData
        const newFollowers = publicUserData.followers.filter(arrayItem => {
          return (arrayItem.followerHandCashId !== loggedInUserHandCashId)
        })
        publicUserData.followers = newFollowers
        // Updating the followerHandCashIds array
        const newfollowerHandCashIdsArray = publicUserData.followerHandCashIds.filter(id => {
          return (id !== loggedInUserHandCashId)
        })
        publicUserData.followerHandCashIds = newfollowerHandCashIdsArray
        // Updating logged-in user data
        const unfollowedHandCashId = this.state.publicUserData.handCashId
        this.props.storeUserDatabaseData({ type: 'updateForUnfollow', unfollowedHandCashId })
        // Concluding the function
        globals.toastSuccess(toast, `${this.state.publicUserData.handCashHandle} has been unfollowed`);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, userAlreadyFollows: false, publicUserData: publicUserData })
        }, 400)
      })
      .catch(err => {
        console.error(err);
        globals.toastError(toast, err.response.data.message);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false })
        }, 400)
      })
  }
  togglePostType = (e, value) => {
    e.preventDefault();
    globals.createRipple(e)
    // Guard clauses
    if (value === "NEWEST_POSTS" || value === "MOST_EARNED") {
      // If they were on mostEarned posts and are switching to newestPosts
      if (value === "NEWEST_POSTS" && this.state.newestViewForPosts === false) {
        this.setState({ postsOpacity: 'opacity-0', postsSelection: { newest: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ newestViewForPosts: true, postsOpacity: 'opacity-100' })
        }, 300)
      }
      if (value === "MOST_EARNED" && this.state.newestViewForPosts === true) {
        // Set state for the opacity and the selection
        this.setState({ postsOpacity: 'opacity-0', postsSelection: { mostEarned: 'highlight-border' } }, () => {
          setTimeout(() => {
            this.getMostEarnedPosts()
          }, 300)
        })
      }
    }
    else return
  }
  getMostEarnedPosts = () => {
    // So, we use this for both the public and private.
    const handCashHandle = this.props.match.params.handCashHandle
    const userData = this.props.userDatabaseData
    // If user is logged in, viewing their own account, and the mostEarned array has data within it, we return out
    if (this.props.auth && userData && this.props.auth.handle === handCashHandle && userData.profileMostEarnedPosts && userData.profileMostEarnedPosts.length > 0) {
      return this.setState({ newestViewForPosts: false, postsOpacity: 'opacity-100' })
    }
    // If they are not logged in (public view), and the publicUserMostEarnedPosts is not empty, we return out
    if (!this.props.auth || (this.props.auth && this.props.auth.handle !== this.props.match.params.handCashHandle)) {
      if (this.state.publicUserMostEarnedPosts.length > 0) {
        return this.setState({ newestViewForPosts: false, postsOpacity: 'opacity-100' })
      }
    }
    // possibleHandle is to determine if paywall data is needed or not, for editing purposes
    let possibleHandle = 'noHandle'
    let pageNumber = this.state.pageNumberPrivateMostEarnedPosts
    // If user is logged in, and in own profile page
    if (this.props.auth && this.props.auth.handle === handCashHandle) {
      possibleHandle = this.props.auth.handle;
      pageNumber = this.state.pageNumberPrivateMostEarnedPosts
    }
    this.setState({ postsAreLoaded: false, newestViewForPosts: false })
    API.getMostEarnedPosts(handCashHandle, possibleHandle, pageNumber, this.cancelToken.token)
      .then(res => {
        let posts = res.data;
        // If they're in their own profile page
        if (this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle) {
          let noMorePrivateMostEarnedPostResults = false
          if (posts.length < globals.fetchFifty) noMorePrivateMostEarnedPostResults = true
          if (posts.length === 0) return setTimeout(() => {
            this.setState({
              privatePostsLoaded: true,
              postsAreLoaded: true, postsOpacity: 'opacity-100'
            })
          }, 300)
          const updatedArray = this.firstLoadUpdateArray(posts, globals.fetchFifty)
          this.props.storeUserDatabaseData({ type: 'addProfileMostEarnedPosts', updatedArray, noMorePrivateMostEarnedPostResults })
          setTimeout(() => {
            this.setState({ postsAreLoaded: true, postsOpacity: 'opacity-100', privatePostsLoaded: true })
          }, 300)
        }
        // If they are NOT in their own profile page
        else {
          if (posts.length === 0) return setTimeout(() => {
            this.setState({ postsAreLoaded: true, postsOpacity: 'opacity-100' })
          }, 300)
          if (posts.length < globals.fetchFifty) this.setState({ noMorePublicMostEarnedPostResults: true })
          const updatedArray = this.firstLoadUpdateArray(posts, globals.fetchFifty)
          this.setState({ publicUserMostEarnedPosts: updatedArray }, () => {
            setTimeout(() => {
              this.setState({ postsOpacity: 'opacity-100', postsAreLoaded: true })
            }, 300)
          })
        }
      })
      .catch(error => {
        console.error(error);
        // If the posts can't be found, we'll just change the state back to newestPosts.
        setTimeout(() => {
          this.setState({ privatePostsLoaded: true, postsAreLoaded: true, postsOpacity: 'opacity-100', postsSelection: { newest: 'highlight-border' } })
        }, 300)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to find posts for user');
      })
  }
  getTransactionsForExcel = (e, searchType) => {
    e.preventDefault()
    // Guard clause
    if (!['All', '2022', '2023'].includes(searchType)) {
      return (
        globals.toastError(toast, 'Unable to gather data'),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400);
        })
      )
    }
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // If user is not logged in
    if (!this.props.auth || !localStorageAuth || this.props.auth.id !== localStorageAuth.id) {
      return (
        globals.toastError(toast, 'Login to download document'),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400);
        })
      )
    }
    this.toggleOverlay()
    this.setState({ nonPaymentOverlay: true })
    API.getTransactionsForExcel(searchType)
      .then(res => {
        if (res.data.sortedIncomeArray.length === 0 && res.data.sortedSpendsArray.length === 0) {
          return (
            globals.toastError(toast, 'No data to retrieve.'),
            setTimeout(() => {
              this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
            }, 400)
          )
        }
        this.createExcelWorkbook(res.data.sortedIncomeArray, res.data.sortedSpendsArray)
      })
      .catch(err => {
        console.error(err);
        globals.toastError(toast, 'Unable to gather data');
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        }, 400)
      })
  }
  onExcelSearchChange = (e) => {
    if (this.state.excelSelectionType === e.target.value) return
    this.setState({ excelSelectionType: e.target.value })
  }
  createExcelWorkbook = async (incomeArray, spendsArray) => {
    if (!incomeArray || !spendsArray) return
    const momentFormat = "YYYY-MM-DD HH:mm:ss";
    // Creating and setting up Excel Workbook
    const workbook = new ExcelJS.Workbook();
    let date = new Date()
    workbook.creator = globals.platformName;
    workbook.created = date
    workbook.modified = date
    workbook.views = [
      {
        x: 0, y: 0, width: 10000, height: 20000,
        firstSheet: 0, activeTab: 0, visibility: 'visible'
      }
    ]
    // Creating two worksheets. One for Income, one for Spends
    const incomeSheet = workbook.addWorksheet('Income (+)', {
      properties: { tabColor: { argb: '00B050' } }, views: [{ state: 'frozen', ySplit: 1 }]
    });
    const spendsSheet = workbook.addWorksheet('Spends (-)', {
      properties: { tabColor: { argb: 'FF0000' } }, views: [{ state: 'frozen', ySplit: 1 }]
    });
    // Creating initial columns for worksheet
    incomeSheet.columns = [
      { header: 'Date', key: 'date', width: 19, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Value', key: 'value', width: 10, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Type', key: 'type', width: 22, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Recipient', key: 'recipient', width: 27, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Sender', key: 'sender', width: 27, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Exchange Rate', key: 'rate', width: 19, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Currency', key: 'currency', width: 12, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Tx Link', key: 'link', width: 10, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
    ]
    spendsSheet.columns = [
      { header: 'Date', key: 'date', width: 19, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Value', key: 'value', width: 10, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Type', key: 'type', width: 22, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Sender', key: 'sender', width: 27, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Recipient', key: 'recipient', width: 27, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Exchange Rate', key: 'rate', width: 19, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Currency', key: 'currency', width: 12, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
      { header: 'Tx Link', key: 'link', width: 10, style: { font: { name: 'Cambria', bold: true, underline: true, size: 12 } } },
    ]
    // Setting row alignment
    incomeSheet.getRow(1).alignment = { horizontal: 'center', vertical: 'center' }
    spendsSheet.getRow(1).alignment = { horizontal: 'center', vertical: 'center' }
    // Checking array.length, so we can skip the logic if nothing is in array.
    if (incomeArray.length > 0) {
      // Just in case a tx doesn't have blockchainPaymentData (errors out otherwise)
      if (incomeArray[0].blockchainPaymentData) {
        // Creating and styling first row, so following rows can adopt the same style without modifying columns
        incomeSheet.addRow({
          date: moment(incomeArray[0].createdAt).format(momentFormat),
          value: parseFloat(incomeArray[0].value),
          type: incomeArray[0].txType,
          recipient: incomeArray[0].recipientHandle,
          sender: incomeArray[0].giverHandle,
          rate: incomeArray[0].blockchainPaymentData.fiatExchangeRate,
          currency: incomeArray[0].blockchainPaymentData.fiatCurrencyCode,
          link: {
            text: `BSV Tx`,
            hyperlink: `https://whatsonchain.com/tx/${incomeArray[0].blockchainPaymentData.transactionId}`
          }
        })
        // Setting styles and aligntment
        incomeSheet.getRow(2).font = { name: 'Calibri', bold: false, underline: false, size: 11 }
        incomeSheet.getRow(2).alignment = { horizontal: 'center' }
        incomeSheet.getCell('B2').numFmt = '$#,##0.00;($#,##0.00)'
        incomeSheet.getCell('B2').font = { name: 'Calibri', bold: false, underline: false, size: 11, color: { argb: '00B050' } }
        incomeSheet.getCell('F2').numFmt = '$#,##0.00;($#,##0.00)'
        incomeSheet.getCell('F2').font = { name: 'Calibri', bold: false, underline: false, size: 11, color: { argb: '00B050' } }
        incomeSheet.getCell('H2').font = { name: 'Calibri', bold: false, underline: true, size: 11 }
      }
      // Looping through the rest of the array. Index starts at 1, since we used 0 already
      for (let i = 1; i < incomeArray.length; i++) {
        let item = incomeArray[i]
        if (item.blockchainPaymentData) {
          incomeSheet.addRow({
            date: moment(item.createdAt).format(momentFormat),
            value: parseFloat(item.value),
            type: item.txType,
            recipient: item.recipientHandle,
            sender: item.giverHandle,
            rate: item.blockchainPaymentData.fiatExchangeRate,
            currency: item.blockchainPaymentData.fiatCurrencyCode,
            link: {
              text: `BSV Tx`,
              hyperlink: `https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`
            }
            // the 'i' means it follows the styles from the previous row
          }, 'i')
        }
      }
    }
    if (spendsArray.length > 0) {
      // Creating and styling first row, so following rows can adopt the same style without modifying columns
      if (spendsArray[0].blockchainPaymentData) {
        spendsSheet.addRow({
          date: moment(spendsArray[0].createdAt).format(momentFormat),
          value: parseFloat(spendsArray[0].value),
          type: spendsArray[0].txType,
          recipient: spendsArray[0].recipientHandle,
          sender: spendsArray[0].giverHandle,
          rate: spendsArray[0].blockchainPaymentData.fiatExchangeRate,
          currency: spendsArray[0].blockchainPaymentData.fiatCurrencyCode,
          link: {
            text: `BSV Tx`,
            hyperlink: `https://whatsonchain.com/tx/${spendsArray[0].blockchainPaymentData.transactionId}`
          }
        })
        // Setting styles and aligntment
        spendsSheet.getRow(2).font = { name: 'Calibri', bold: false, underline: false, size: 11 }
        spendsSheet.getRow(2).alignment = { horizontal: 'center' }
        spendsSheet.getCell('B2').numFmt = '($#,##0.00);($#,##0.00)'
        spendsSheet.getCell('B2').font = { name: 'Calibri', bold: false, underline: false, size: 11, color: { argb: 'FF0000' } }
        spendsSheet.getCell('F2').numFmt = '$#,##0.00;($#,##0.00)'
        spendsSheet.getCell('F2').font = { name: 'Calibri', bold: false, underline: false, size: 11, color: { argb: '00B050' } }
        spendsSheet.getCell('H2').font = { name: 'Calibri', bold: false, underline: true, size: 11 }
      }
      for (let i = 1; i < spendsArray.length; i++) {
        let item = spendsArray[i]
        if (item.blockchainPaymentData) {
          spendsSheet.addRow({
            date: moment(item.createdAt).format(momentFormat),
            value: parseFloat(item.value),
            type: item.txType,
            sender: item.giverHandle,
            recipient: item.recipientHandle,
            rate: item.blockchainPaymentData.fiatExchangeRate,
            currency: item.blockchainPaymentData.fiatCurrencyCode,
            link: {
              text: `BSV Tx`,
              hyperlink: `https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`
            }
          }, 'i')
        }
      }
    }
    // Write "buffer" and create "blob" for file download with file-saver NPM package
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const blob = new Blob([buffer], { type: fileType });
      // Make file download in user's browser
      if (navigator.msSaveBlog) {
        navigator.msSaveBlog(blob, `${globals.platformName} Excel Spreadsheet` + EXCEL_EXTENSION)
      }
      else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", `${globals.platformName} Excel Spreadsheet` + EXCEL_EXTENSION);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      // Conclude function
      globals.toastSuccess(toast, `Your file has been downloaded successfully!`);
      setTimeout(() => {
        this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
      }, 400)
    } catch (err) {
      console.error(err)
      globals.toastError(toast, `Unable to generate file at this time. Please try again later.`);
      setTimeout(() => {
        this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
      }, 400)
    }
  }
  render() {
    return (
      <>
        {/* If logged in and handle equals param handle the user is logged in */}
        {this.props.auth && this.props.auth.handle === this.props.match.params.handCashHandle
          ?
          <PrivateProfilePage
            {...this.state}
            auth={this.props.auth}
            userData={this.props.userDatabaseData}
            userDatabaseData={this.props.userDatabaseData}
            profilePageOpacity={this.props.profilePageOpacity}
            toggleDataList={this.togglePrivateDataList}
            togglePaymentModal={this.togglePaymentModal}
            toggleDeleteDraftModal={this.toggleDeleteDraftModal}
            goToUserProfilePage={this.goToUserProfilePage}
            goToPost={this.goToPost}
            goToDraftEditingPage={this.goToDraftEditingPage}
            deleteDraft={this.deleteDraft}
            clickToEditPost={this.clickToEditPost}
            globals={globals}
            API={API}
            newestPosts={
              this.state.privatePostsLoaded && this.props.userDatabaseData && this.props.userDatabaseData.profilePosts
                ? this.props.userDatabaseData.profilePosts // These are the Newest Posts
                : []
            }
            mostEarnedPosts={
              this.state.privatePostsLoaded && this.props.userDatabaseData && this.props.userDatabaseData.profileMostEarnedPosts
                ? this.props.userDatabaseData.profileMostEarnedPosts // These are the Most Earned Posts
                : []
            }
            comments={
              this.state.privateCommentsLoaded && this.props.userDatabaseData && this.props.userDatabaseData.profileComments
                ? this.props.userDatabaseData.profileComments
                : []
            }
            togglePostType={this.togglePostType}
            toast={toast}
            // Pagination stuff
            refCallbackForArray={this.refCallbackForArray}
            noMoreComments={this.props.userDatabaseData?.noMoreCommentResults
              ? this.props.userDatabaseData.noMoreCommentResults
              : false
            }
            noMorePostResults={this.state.newestViewForPosts
              ? this.props.userDatabaseData?.noMorePrivateNewestPostResults
                ? this.props.userDatabaseData.noMorePrivateNewestPostResults
                : false
              : this.props.userDatabaseData?.noMorePrivateMostEarnedPostResults
                ? this.props.userDatabaseData.noMorePrivateMostEarnedPostResults
                : false
            }
            noMoreFollowerResults={this.props.userDatabaseData?.noMorePrivateFollowerResults
              ? this.props.userDatabaseData.noMorePrivateFollowerResults
              : false
            }
            noMoreFollowingResults={this.props.userDatabaseData?.noMorePrivateFollowingResults
              ? this.props.userDatabaseData.noMorePrivateFollowingResults
              : false
            }
          />
          :
          <ProfilePageForPublic
            {...this.state}
            auth={this.props.auth}
            userData={this.state.publicUserData}
            userDatabaseData={this.props.userDatabaseData}
            profilePageOpacity={this.props.profilePageOpacity}
            toggleTipModal={this.toggleTipModal}
            togglePaymentModal={this.togglePaymentModal}
            toggleDataList={this.togglePublicDataList}
            goToUserProfilePage={this.goToUserProfilePage}
            goToPost={this.goToPost}
            publicUserNewestPosts={this.state.publicUserNewestPosts}
            comments={this.state.publicUserComments}
            unfollowUser={this.unfollowUser}
            togglePostType={this.togglePostType}
            globals={globals}
            toast={toast}
            refCallbackForArray={this.refCallbackForArray}
            noMoreComments={this.state.noMoreCommentResults}
            noMoreFollowerResults={this.state.noMorePublicFollowerResults}
            noMoreFollowingResults={this.state.noMorePublicFollowingResults}
            noMorePostResults={this.state.newestViewForPosts
              ? this.state.noMorePublicNewestPostResults
              : this.state.noMorePublicMostEarnedPostResults
            }
          />
        }
        {this.state.pageOverlayActive === true
          ? <OverlayLoadingIcon nonPaymentOverlay={this.state.nonPaymentOverlay} />
          : <></>
        }
        {this.props.auth?.id
          ?
          <>
            <PaymentModal
              showPaymentModal={this.state.showPaymentModal}
              toggleModal={this.togglePaymentModal}
              triggerPaymentFunction={
                this.state.transactionType === 'FOLLOW_USER'
                  ? this.followUser
                  : this.state.transactionType === 'TIP_USER'
                    ? this.tipUser
                    : this.state.transactionType === 'PURCHASE_MEMBERSHIP'
                      ? this.purchaseMembership
                      : this.state.transactionType === 'UPDATE_COMMENT'
                        ? this.updateComment
                        : this.state.transactionType === 'UNFOLLOW_USER'
                          ? this.unfollowUser
                          : this.state.transactionType === 'OPEN_EXCEL_MODAL'
                            ? this.getTransactionsForExcel
                            : null
              }
              auth={this.props.auth}
              transactionType={this.state.transactionType}
              userToPay={this.state.paramHandle}
              tipAmount={this.state.tipAmount}
              excelSelectionType={this.state.excelSelectionType}
              onExcelSearchChange={this.onExcelSearchChange}
            />
            <TipModal
              recipientHandCashHandle={this.state.publicUserData.handCashHandle}
              showModal={this.state.showTipModal}
              toggleModal={this.toggleTipModal}
              closeModal={this.closeTipModal}
              confirmPaymentModal={this.confirmPaymentModal}
              handleChange={this.handleTipAmountChange}
              amount={this.state.tipAmount}
              tipMessage={this.state.tipMessage}
              handleTipMessageChange={this.handleTipMessageChange}
              charCounterClass={this.state.charCounterClass}
              errorMessage={this.state.tipMessageError}
              transactionType={`TIP_USER`}
              min={`0.01`}
              max={`9999.99`}
              step={`0.01`}
              isThumbsModal={false}
            />
            <DeleteModal
              showDeleteDraftModal={this.state.showDeleteDraftModal}
              toggleDeleteDraftModal={this.toggleDeleteDraftModal}
              deleteDraft={this.deleteDraft}
              draftId={this.state.draftId}
              draftIndex={this.state.draftIndex}
            />
          </>
          : <></>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.handCashLogInData,
  userDatabaseData: state.auth.userDatabaseData,
  profilePageOpacity: state.opacity.profilePageOpacity
})

const mapDispatchToProps = (dispatch) => {
  return {
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData)),
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePageContainer)
