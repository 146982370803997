import React, { Component } from 'react'
import ReactQuill, { Quill } from 'react-quill';
// import smoothscroll from 'smoothscroll-polyfill';
import { globals, getVideoUrl, getClassesForIFrameWithUrlInEditor } from '../../config/globals';

import { toast } from 'react-toastify';
import Compressor from 'compressorjs';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import API from '../../utils/API';
import OverlayLoadingIcon from './../Widgets/OverlayLoadingIcon';

const Link = Quill.import('formats/link')
Link.PROTOCOL_WHITELIST = ['http', 'https']
const BlockEmbed = Quill.import("blots/block/embed");

// This code makes it so one one single click, the tooltip is displayed. Not sure what's better.
// const BubbleTheme = Quill.import("themes/bubble");
// class ExtendBubbleTheme extends BubbleTheme {
//   constructor(quill, options) {
//     super(quill, options);

//     quill.on("selection-change", (range) => {
//       if (range) {
//         quill.theme.tooltip.show();
//         quill.theme.tooltip.position(quill.getBounds(range));
//       }
//     });
//   }
// }

// Quill.register("themes/bubble", ExtendBubbleTheme);

class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url)
    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol) {
      return sanitizedUrl.startsWith(protocol)
    })

    if (hasWhitelistedProtocol) return sanitizedUrl

    // if not, then append only 'http' to not to be a relative URL
    return `https://${sanitizedUrl}`
  }
}

class VideoBlot extends BlockEmbed {
  static blotName = 'video';
  static tagName = 'div';
  static create(url) {
    // Fixes a bug for tiktok/fb/insta if you delete the post and try to repaste, it crashes. This prevents that.
    if (url == null || url === true) {
      const failedNode = super.create('')
      return failedNode
    }
    let videoURL = getVideoUrl(url)
    if (videoURL === null) {
      const failedNode = super.create('')
      globals.toastError(toast, globals.wrongEmbedFormatError);
      return failedNode
    }
    let node = super.create(videoURL);
    let iframe = document.createElement('iframe');
    const classesForIframe = getClassesForIFrameWithUrlInEditor(videoURL)
    // Set styles for wrapper
    node.setAttribute('class', classesForIframe.iframeContainer);
    // This is necessary because otherwise people can add text within the iframe for some reason
    // Set styles for iframe
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', true);
    iframe.setAttribute('src', videoURL);
    iframe.setAttribute('class', classesForIframe.iframe);
    // Append iframe as child to wrapper
    node.appendChild(iframe);
    return node;
  }

  static value(domNode) {
    let returnedValue = null
    if (domNode.firstChild?.getAttribute) returnedValue = domNode.firstChild.getAttribute('src');
    return returnedValue
  }
}

Quill.register(VideoBlot, true);
Quill.register(CustomLinkSanitizer, true)

const fileCompress = (file) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      file: 'file',
      quality: 0.8,
      maxWidth: 640,
      maxHeight: 640,
      success(file) {
        return resolve({
          success: true,
          file: file
        })
      },
      error(err) {
        return resolve({
          success: false,
          message: err.message
        })
      }

    })
  })
}
class ImageBlot extends BlockEmbed {
  static blotName = 'image';
  static tagName = 'img';

  static create(value) {
    let node = super.create();
    // This is for the Editor, so the image actually gets loaded. When it's string, it's creation of new. It's object, otherwise.
    if (typeof value === 'string') {
      node.setAttribute('alt', 'user-image');
      node.setAttribute('src', value);
      node.setAttribute('class', "mx-auto my-2 rounded-lg media-shadow");
      return node;
    }
    node.setAttribute('alt', 'user-image');
    node.setAttribute('src', value.src);
    node.setAttribute('class', "mx-auto my-2 rounded-lg media-shadow");
    return node;
  }
  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
      src: node.getAttribute('src')
    };
  }
  // static formats(node1) {
  //   let format = {}
  //   if (node1.hasAttribute('src')) {
  //     format.src = node1.getAttribute('src')
  //   }
  //   if (node1.hasAttribute('alt')) {
  //     format.alt = node1.getAttribute('alt')
  //   }
  //   console.log(format)

  //   return format
  // }
  //  format(name, value) {
  //   console.log(name, value)
  // }
}
Quill.register(ImageBlot);

class QuillEditorForMembers extends Component {
  state = { pageOverlayActive: false }
  // Changing hyperlink tooltip because default one is https://quilljs.com/
  htmlLink = `${globals.platformName}`;
  componentDidMount() {
    const input = document.querySelector("input[data-link]");
    input.dataset.link = this.htmlLink;
    input.placeholder = this.htmlLink;

  }
  componentDidUpdate() {
    const input = document.querySelector("input[data-link]");
    input.dataset.link = this.htmlLink;
    input.placeholder = this.htmlLink;
  }
  cancelToken = API.CancelToken.source();
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled');
  };
  // scrollCursorIntoView = () => {
  //   // Get current selection
  //   const selection = window.getSelection();
  //   // Check if there are selection ranges
  //   if (!selection.rangeCount) {
  //     return;
  //   }
  //   // Get the first selection range. There's almost never can be more (instead of firefox)
  //   const firstRange = selection.getRangeAt(0);
  //   // Sometimes if the editable element is getting removed from the dom you may get a HierarchyRequest error in safari
  //   if (firstRange.commonAncestorContainer === document) {
  //     return;
  //   }
  //   // Create an empty br that will be used as an anchor for scroll, because it's imposible to do it with just text nodes
  //   let tempAnchorEl = document.createElement('div');
  //   const br1 = document.createElement('br');
  //   const br2 = document.createElement('br');
  //   const br3 = document.createElement('br');
  //   tempAnchorEl.appendChild(br1)
  //   tempAnchorEl.appendChild(br2)
  //   tempAnchorEl.appendChild(br3)
  //   // Put the br right after the caret position
  //   firstRange.insertNode(tempAnchorEl);
  //   smoothscroll.polyfill();
  //   // Scroll to the br. I personally prefer to add the block end option, but if you want to use 'start' instead just replace br to span
  //   tempAnchorEl.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'end',
  //   });
  //   // Remove the anchor because it's not needed anymore
  //   tempAnchorEl.remove();
  // }
  imageHandler(cancelToken) {
    const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('type', 'file');
    input.setAttribute('name', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('class', `opacity-0 absolute`)
    input.click();
    input.onchange = async (e) => {
      const file = input.files[0]
      // Check file type for only images. return toastError if not.
      if (!/^image\//.test(file.type)) return globals.toastError(toast, `Only able to upload images`);
      if (!allowedFileTypes.includes(file.type)) return globals.toastError(toast, `Only .jpeg, jpg, or .png file types are allowed.`);
      // Compress file
      const fileCompression = await fileCompress(file)
      // If image compression doesn't work or file size is > 5mb
      if (!fileCompression.success) return globals.toastError(toast, `Unable to upload. Please try again.`);
      let compressedFile = fileCompression.file;
      if (compressedFile.size > 5000000) return globals.toastError(toast, `Please submit image under 5mb`);
      // This renameds the file and ensures the compressedFile is actually turned into a File type, rather than Blob. Which happens sometimes.
      compressedFile = new File([compressedFile], "image.jpeg", { type: compressedFile.type });
      // Create FormData to send to backend
      var formData = new FormData();
      formData.append("file", compressedFile);
      // Save current cursor state
      let quill = this.quill.getEditor()
      const range = quill.getSelection(true)
      // Loading
      this.setState({ pageOverlayActive: true })
      API.saveImageToCloud(formData, cancelToken.token)
        .then(res => {
          // Insert uploaded image (res.data is the URL)
          quill.insertEmbed(range.index, 'image', res.data);
          // Don't totally understand this, but it always keeps the cursor to the right, and that's what we want
          quill.setSelection(range.index + range.index + 1);
          this.setState({ pageOverlayActive: false })
          setTimeout(() => {
            document.body.removeChild(input)
            // this.scrollCursorIntoView()
          }, 200)
        })
        .catch(err => {
          console.error(err)
          this.setState({ pageOverlayActive: false })
          return globals.toastError(toast, `Unable to upload image.`);
        })
    }
  }
  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "indent",
    'align',
    "link",
    "image",
    "video",
    "code",
    `clean`
  ];
  modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "blockquote", "code"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          { 'align': null }, { 'align': 'center' },
        ],
        ["link", "image", "video", "clean"]
      ],
      handlers: {
        image: () => this.imageHandler({ cancelToken: this.cancelToken })
      }
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };

  render() {
    const { handleEditorChange, content, quillTheme } = this.props;
    return (
      <>
        <ReactQuill
          ref={(el) => this.quill = el}// must pass ref here
          theme={quillTheme}
          placeholder={"Tap here to begin"}
          value={content}
          onChange={handleEditorChange}
          formats={this.formats}
          modules={this.modules}
          scrollingContainer='body'
        />
        {this.state.pageOverlayActive
          ? <OverlayLoadingIcon nonPaymentOverlay={true} />
          : <></>
        }
      </>
    )
  }
}

export default QuillEditorForMembers;