import React from 'react'
import Modal from "react-modal";
import { Icon } from '@iconify/react';
import closeCircleO from '@iconify/icons-vaadin/close-circle-o';

const MassTipModal = props => {
  const {
    showMassTipModal,
    confirmPaymentModal,
    handleMassTipAmountChange,
    massTipAmount,
    charCounterClass,
    massTipMessage,
    handleMassTipMessageChange,
    errorMessage,
    closeModal,
    numberOfEligibleTippers
  } = props;
  const styles = {
    base: `modal-content first-text gradient-background-four shadow-effect-five
    mt-32 w-full px-1 sm:w-9/12 xl:w-2/5 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showMassTipModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        className={styles}
        overlayClassName="modal-overlay mr-4 sm:mr-0"
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        contentLabel={'HandCash Login Modal'}
      >
        <div className={`text-center`}>
          <button onClick={closeModal} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
              <Icon icon={closeCircleO} height={18} width={18} />
            </button>
          <div className={`teko-font text-3xl mb-2 logo-style ml-6`}>
            Mass Tip: <span className={`ninth-text`}>{numberOfEligibleTippers} Stampers</span>
          </div>
          <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
          {/* Input */}
          <div className={`inline-flex items-center mt-3 mb-1`}>
            <div className={`mr-1`}>Amount per Account: </div>
            <span className={`text-brand-green paywall-dollar-sign`}>
              <input className={`gradient-background-seven shadow-effect-ten border 
                  outline-none w-24 rounded-md pl-4 text-gray-900 transition updatingMassTippingValue
                `}
                type="number" min='.001' max='9999.99' step="0.001" pattern="^\d+(?:\.\d{1,2})?$"
                onChange={(e) => handleMassTipAmountChange(e)}
                value={massTipAmount}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                aria-label="Amount to Mass Tip users of the platform"
              />
            </span>
          </div>
          {/* Conditional render if tip message should exist or not */}
          <div className='mx-2 mb-3'>
            <div className={`flex`}>
              <div className={`flex flex-1 justify-end self-end mr-1 pb-1`}>
                <div className={`text-xs ${charCounterClass}`}>Count: {massTipMessage.length}</div>
              </div>
            </div>
            <div className={`mt-2 transition text-gray-900 first-text custom-input-container`} data-name="Message">
              <div className={`rounded-md shadow-effect-six`}>
                <textarea
                  id='massTipMessage'
                  placeholder='*Optional* with 280 character limit'
                  className={`gradient-background-seven shadow-effect-ten transition text-gray-900 w-full rounded-md px-2 pt-4 pb-2 -mb-7px
                    outline-none border resize-none`
                  }
                  rows='5'
                  value={massTipMessage}
                  onChange={handleMassTipMessageChange}>
                </textarea>
              </div>
            </div>
            {errorMessage
              ?
              <div className={`text-center font-header mt-1 text-xs text-red-600`}>
                {errorMessage}
              </div>
              : <></>
            }
          </div>
          {/* Confirmation */}
          <div className={`flex justify-between mt-5 mb-1`}>
            <button className={`text-sm font-header connectButtonNoImage ml-2`}
              onClick={e => confirmPaymentModal(e, 'MASS_TIP')}
            >
              Confirm
            </button>
            <button className={`cursor-pointer bg-transparent text-white gradient-background-red
              text-sm rounded-xl border-2 font-header border-red-700 p-2 mr-2`}
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MassTipModal;
