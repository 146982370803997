import React from 'react'

const SkeletonLoaderProfile = (props) => {
  return (
    <>
      <div className={`skeleton-card rounded-xl transition fifth-background shadow-effect-one py-3 -mx-1`}>
        <div className={`flex rounded-lg pb-1`}>
          {/* Left */}
          <div className={`flex-1 justify-start pl-3 pr-1`}>
            {/* Joined Date */}
            <div className={`skeleton skeleton-text-date mx-1 mb-2`}></div>
            <div className={`skeleton skeleton-text-date mx-1 mb-2`}></div>
            <div className={`skeleton skeleton-text-date mx-1`}></div>
          </div>
          {/* Center */}
          <div className={`flex-1 justify-center text-center w-auto mt-1`}>
            {/* Avatar */}
            <div className={`skeleton skeleton-fake-profile-image rounded-full mx-auto`}></div>
            {/* Handle */}
            <div className={`mt-2 mb-1`}>
              <div className={`skeleton skeleton-text-date mx-1 mx-auto mb-2`}></div>
              <div className={`skeleton skeleton-text-date mx-1 mx-auto mb-2`}></div>
            </div>
          </div>
          {/* Right */}
          <div className={`flex-1 pr-3 pl-1 text-right`}>
            <div className={`float-right`}>
              <div className={`skeleton skeleton-text-date mx-1 mb-2`}></div>
              <div className={`skeleton skeleton-text-date mx-1 mb-2`}></div>
              <div className={`skeleton skeleton-text-date mx-1 mb-2`}></div>
              <div className={`skeleton skeleton-text-date mx-1`}></div>
            </div>
          </div>
        </div>
        <div className={`flex justify-center pb-2`}>
          {/* Following */}
          <div className={`skeleton skeleton-text-date mx-1`}></div>
          <div className={`skeleton skeleton-text-date mx-1`}></div>
        </div>
        {/* Sorts */}
        <div className={`flex justify-center flex-wrap mb-1`}>
          <div className={`skeleton skeleton-text-button mx-1`}></div>
          <div className={`skeleton skeleton-text-button mx-1`}></div>
          <div className={`skeleton skeleton-text-button mx-1`}></div>
          <div className={`skeleton skeleton-text-button mx-1`}></div>
          <div className={`skeleton skeleton-text-button mx-1`}></div>
          <div className={`skeleton skeleton-text-button mx-1`}></div>
          <div className={`skeleton skeleton-text-button mx-1`}></div>
          <div className={`skeleton skeleton-text-button mx-1`}></div>
        </div>
        <hr className={`sixth-border outsetBorder border my-3 opacity-50 mx-2`}></hr>
        <div className={`mb-1 px-3 w-full`}>
          <div className={`flex justify-center`}>
            <div className={`skeleton skeleton-text-large mx-1 mb-2`}></div>
            </div>
            <div className={`flex justify-center mx-2 mb-2`}>
              <div className={`skeleton skeleton-text-button mx-1 `}></div>
              <div className={`skeleton skeleton-text-button mx-1`}></div>
            </div>
        </div>
        <hr className={`sixth-border outsetBorder border my-3 opacity-50 mx-2`}></hr>
        <div className={`mb-1 px-3 w-full`}>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
        </div>
      </div>
    </>
  )
}

export default SkeletonLoaderProfile;