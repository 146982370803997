import React, { Component } from 'react'
import API from '../utils/API';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { parse } from 'query-string';
import OverlayLoadingIcon from '../components/Widgets/OverlayLoadingIcon';
import { globals } from '../config/globals';
import { authenticateUser, storeUserDatabaseData } from '../store/reducers/authSlice';

class AuthenticatePage extends Component {
  cancelToken = API.CancelToken.source();
  componentDidMount() {
    this.authenticateUser();
  };
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled');
  };
  authenticateUser = () => {
    // This check prevents unnecessary calls to the database if a user tries using this route for something invalid.
    if (window.location.search.includes('?authToken=')) {
      // Breaking up the window.location.search object into authToken and the Redirection Link
      // const urlParams = new URLSearchParams(window.location.search)
      // const urlRedirectLink = urlParams.redirectLink
      // const urlAuthToken = urlParams.authToken
      const redirectObject = parse(window.location.search)
      const redirectLink = redirectObject.redirectLink
      // Auth Token
      const authToken = redirectObject.authToken
      // This is needed for the middleware
      let localStorageAuthToken = { authToken: authToken }
      localStorage.setItem('handCashAuthData', JSON.stringify(localStorageAuthToken))
      // Authenticating user (authToken is pulled with middleware via localStorage)
      API.authenticateHandCashUser(this.cancelToken.token)
      .then(res => {
        const handCashData = res.data.publicProfile;
        let userDatabaseData = {}
        // If this is a new user, update userDatabaseData properties
        if (!res.data.databaseUser || res.data.databaseUser === undefined) {
          userDatabaseData = res.data.updateNewUser;
          userDatabaseData.followers.length = 0
          userDatabaseData.following.length = 0
          const followKelsola = res.data.followKelsola
          const followTipStampers = res.data.followTipStampers
          const platformFollowsNewUser = res.data.platformFollowsNewUser
          const kelsolaFollowsNewUser = res.data.kelsolaFollowsNewUser
          userDatabaseData.followers.push(platformFollowsNewUser)
          userDatabaseData.followers.push(kelsolaFollowsNewUser)
          userDatabaseData.following.push(followTipStampers)
          userDatabaseData.following.push(followKelsola)
          userDatabaseData.tipsReceived.push(globals.initialTipDocument)
        }
        // This means it's an existing user from the database
        if (res.data.databaseUser && res.data.databaseUser !== undefined) {
          userDatabaseData = res.data.databaseUser
        }
        // Authenticating the user in Redux. Saves to localStorage.
        this.props.authenticateUser(handCashData);
        // Storing the user's database data in Redux.
        this.props.storeUserDatabaseData({ type: 'initialFetch', userDatabaseData: userDatabaseData });
        // Pushing user to the page they were original on.
        setTimeout(() => {
          this.props.history.push(`${redirectLink}`)
        }, 1000 )
      })
      .catch(err => {
        console.error(err)
        if (err.response.data?.message !== 'Banned User') {
          globals.toastError(toast, err.response.data.message);
          this.props.history.push(`/`) 
        }
      })
    }
    else {
      this.props.history.push(`/`)
    }
  };
  render() {
    return (
      <>
        {/* This disables clicking anything on this page. Z-index 1000 and covers entire screen */}
        <div className={`unclickable-overlay`} />
        <OverlayLoadingIcon authPage={true} nonPaymentOverlay={true}  />
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    categoryOpacity: state.opacity.categoryOpacity,
    postsOpacity: state.opacity.postsOpacity,
    auth: state.auth.handCashLogInData
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (credentials) => dispatch(authenticateUser(credentials)),
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatePage);
