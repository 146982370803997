import React from 'react';
import QuillEditorForComments from '../Editors/QuillEditorForComments';
import QuillEditorForMemberComments from '../Editors/QuillEditorForMemberComments';

const AddComment = props => {
  const {
    handleCommentChange,
    commentBody,
    togglePaymentModal,
    label,
    toggleConfirmCancelModal,
    quillTheme,
    userDatabaseData
  } = props;
  return (
    <>
      <div className={`w-full rounded-xl text-sm transition mb-2`}>
        <form>
          <div className={`logo-style eleventh-text mb-2 teko-font text-2xl sm:text-3xl text-center text-lg`}>
            {label}
          </div>
          <span className={`text-gray-900`}>
            {userDatabaseData?.membership
              ?
              <QuillEditorForMemberComments
              handleEditorChange={handleCommentChange}
              quillTheme={quillTheme}
              content={commentBody}
              selectedPlaceholder={`Begin Entry`}
            />
              :
              <QuillEditorForComments
              handleEditorChange={handleCommentChange}
              quillTheme={quillTheme}
              content={commentBody}
              selectedPlaceholder={`Begin Entry`}
            />
            }
          </span>
          {props.commentBodyError
            ?
            <div className={`text-center font-header text-xs mt-1 text-red-600`}>
              {props.commentBodyError}
            </div>
            : null
          }
          <span className='flex'>
            {props.updateNotCreateNew === true
              ?
              <button className={`ml-1 sm:ml-0 py-1 px-2 gradient-background-green mt-4
              text-white border-brand-green
                text-sm rounded-xl border-2 font-header`}
                onClick={(e) => togglePaymentModal(e, 'UPDATE_COMMENT')}
              >
                Update
              </button>
              :
              <button className={`ml-1 sm:ml-0 py-1 px-2 gradient-background-green mt-4 text-sm rounded-xl border-2 font-header
              text-white border-brand-green`}
                onClick={(e) => togglePaymentModal(e, 'CREATE_COMMENT')}
              >
                Submit
              </button>
            }
            <button onClick={(e) => toggleConfirmCancelModal(e)}
              className={`py-1 px-2 cursor-pointer self-center ml-2 mt-4 gradient-background-red 
              text-white hover:bg-red-700
              text-sm rounded-xl border-2 font-header border-red-700`}
            >
              Cancel
            </button>
          </span>
          {props.overallError
            ?
            <div className={`text-xs font-header mt-1 text-red-600`}>
              {props.overallError}
            </div>
            : null
          }
        </form>
      </div>
    </>
  );
};

export default AddComment;