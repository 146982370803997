import React from 'react'

const SkeletonLoaderTwoLines = (props) => {
  return (
    <>
      <div className={`skeleton-card rounded-xl transition fifth-background shadow-effect-one mb-2 py-1`}>
        {/* Row 1 */}
        <div className={`flex justify-between items-center mt-1`}>
          <div className={`pl-3 flex items-center w-full`}>
            {!props.removeAvatar ? <div className={`skeleton skeleton-fake-image rounded-xl mr-1`}></div> : <></>}
            <div className={`skeleton skeleton-text-date mr-2`}></div>
            <div className={`skeleton skeleton-text-longer`}></div>
          </div>
          <div className={`pr-3`}>
            <div className={`skeleton skeleton-text-date`}></div>
          </div>
        </div>
        <hr className={`sixth-border outsetBorder border mb-2 mt-2 opacity-50 mx-2`}></hr>
        {/* Row 2 */}
        {!props.removeAvatar ?
          <div className={`mb-1 px-3`}>
            <div className={`skeleton skeleton-text`}></div>
            <div className={`skeleton skeleton-text`}></div>
          </div>
          :
          <div className={`flex justify-between items-center mb-1 px-3 w-full`}>
            <div className='w-full'>
              <div className={`skeleton skeleton-split`}></div>
            </div>
            <div className='w-full'>
              <div className={`flex`}>
                <div className={`width-20-percent`}></div>
                <div className={`skeleton skeleton-split`}></div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default SkeletonLoaderTwoLines;
