import React from 'react'

const LoadingIcon = () => {
  return (
    <>
      <div className="multi-spinner-container">
        <div className="multi-spinner">
          <div className="multi-spinner-two">
            <div className="multi-spinner">
              <div className="multi-spinner-two">
                <div className="multi-spinner">
                  <div className="multi-spinner-two">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoadingIcon;
