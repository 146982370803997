import React from 'react'

const OverlayLoadingIcon = (props) => {
  const { nonPaymentOverlay, authPage } = props
  return (
    <>
      <div className={`unclickable-overlay`}>
        <div className={`mt-32 flex justify-center mx-auto px-6px`}>
          <div className={`p-3 rounded-xl text-center first-text gradient-background-two shadow-effect-five`}>
            {/* If nonPaymentLoading === true */}
            {nonPaymentOverlay
              ? <div className={`mb-2 text-xl`}>Computer thinking...</div>
              : <div className={`mb-2 text-xl`}>Payment Processing...</div>
            }
            <hr className={`sixth-border outsetBorder border my-1`}></hr>
            {!nonPaymentOverlay
              ? <div className='text-base'>Just a moment. Enjoy the spinner until it's complete.</div> 
              : authPage
                ? <div className='text-base'>It'll be just a moment. Stay put while we log you in.</div>
                : <div className='text-base'>Just a moment. Enjoy the spinner until it's finished.</div>
            }
            <div className="multi-spinner-container ">
              <div className="multi-spinner">
                <div className="multi-spinner-two">
                  <div className="multi-spinner">
                    <div className="multi-spinner-two">
                      <div className="multi-spinner">
                        <div className="multi-spinner-two">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OverlayLoadingIcon;
