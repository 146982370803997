import React from 'react';
import { Icon } from '@iconify/react';
import stampIcon from '@iconify/icons-la/stamp';
import handshakeIcon from '@iconify/icons-fa-regular/handshake';
import postageStamp from '@iconify/icons-mdi/postage-stamp';
import vote20Regular from '@iconify/icons-fluent/vote-24-filled';
import bulletListLine from '@iconify/icons-clarity/bullet-list-line';
import codeLine from '@iconify/icons-clarity/code-line';
import textDocumentEdit from '@iconify/icons-fluent-mdl2/text-document-edit';
import musicInCollection from '@iconify/icons-fluent-mdl2/music-in-collection';
import openBook from '@iconify/icons-vaadin/open-book';
import food16Filled from '@iconify/icons-fluent/food-16-filled';
import educationIcon from '@iconify/icons-fluent-mdl2/education';
import laughSquint from '@iconify/icons-fa-solid/laugh-squint';
import flowerIcon from '@iconify/icons-fluent-mdl2/flower';
import twitchIcon from '@iconify/icons-mdi/twitch';
import catIcon from '@iconify/icons-mdi/cat';
import myMoviesTV from '@iconify/icons-fluent-mdl2/my-movies-t-v';
import brandTiktok from '@iconify/icons-tabler/brand-tiktok';
import briefcaseIcon from '@iconify/icons-vaadin/briefcase';
import colorPaletteSolid from '@iconify/icons-clarity/color-palette-solid';
import barbellIcon from '@iconify/icons-tabler/barbell';
import basketballIcon from '@iconify/icons-mdi/basketball';
import heartbeatIcon from '@iconify/icons-fa-solid/heartbeat';
import bookSolid from '@iconify/icons-clarity/book-solid';
import newspaperIcon from '@iconify/icons-vaadin/newspaper';
import microphoneSolid from '@iconify/icons-clarity/microphone-solid';
import personQuestionMark20Filled from '@iconify/icons-fluent/person-question-mark-20-filled';
import knowledgeArticle from '@iconify/icons-fluent-mdl2/knowledge-article';
import talkBubblesLine from '@iconify/icons-clarity/talk-bubbles-line';
import lightbulbIcon from '@iconify/icons-vaadin/lightbulb';
import smileyO from '@iconify/icons-vaadin/smiley-o';
import helpInfoSolid from '@iconify/icons-clarity/help-info-solid';
import applicationBraces from '@iconify/icons-mdi/application-braces';
import adIcon from '@iconify/icons-fa-solid/ad';
import currencyUsd from '@iconify/icons-mdi/currency-usd';
import podcastIcon from '@iconify/icons-fa-solid/podcast';
import clipboardCheck from '@iconify/icons-la/clipboard-check';
import caretDown20Filled from '@iconify/icons-fluent/caret-down-20-filled';
import brainCircuit20Filled from '@iconify/icons-fluent/brain-circuit-20-filled';
import instagramIcon from '@iconify/icons-la/instagram';
import diplomaScroll from '@iconify/icons-vaadin/diploma-scroll';
import atomSolid from '@iconify/icons-clarity/atom-solid';

const NavAccordion = (props) => {
  const { selectedAccordionItem, toggleAccordion, accordionSelectedCategory, categoryHighlights } = props;
  return (
    <>
      <nav className={`h-auto text-center w-full px-1`}>
        {navCategoriesForAccordion.map((item, i) => {
          return (
            item.isMultipleCategory ?
              // Multiple selection category
              <React.Fragment key={i}>
                {/* Title */}
                <div className={`first-text gradient-background-two-hover rounded-xl text-xl transition w-full 
                    ${categoryHighlights[item.indexLevel].selectedClass ? `${categoryHighlights[item.indexLevel].selectedClass}` : `button-shadow`}`}>
                  <button className='w-full py-2 flex justify-center' onClick={(e) => toggleAccordion(e, i)}>
                    {item.title}
                    <div className={`ml-2px mt-6px pt-px arrow-color pointer-events-none`}>
                      <Icon icon={caretDown20Filled} height={18} width={18} />
                    </div>
                  </button>
                </div>
                {/* Dropdown content */}
                <div className={`${selectedAccordionItem === i ? `accordion-content show` : `accordion-content`} mb-2`}>
                  {item.categories.map((category, index) => {
                    return (
                      <div key={index} className={`px-2 rounded-xl flex justify-center items-center ${index === 0 ? `mt-2` : `mt-2px`}`}>
                        <button onClick={(e) => accordionSelectedCategory(e, category.indexLevel, category.href)}
                          className={`first-text flex justify-center font-header relative rounded-xl w-full category-hover border transition py-px 
                          ${categoryHighlights[category.indexLevel].selectedClass ? `${categoryHighlights[category.indexLevel].selectedClass}` : `inner-shadow`}
                          ${index % 2 === 0 ? `gradient-background-two-hover`: `gradient-background-eleven`} eighth-border`}
                        >
                          {category.categoryName}
                          {/* Entertainment + */}
                          {category.categoryName === 'Twitch Gaming' ? <div className='ml-1 mt-1'><Icon icon={twitchIcon} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Spotify Soundcloud' ? <div className='ml-1  mt-1'><Icon icon={musicInCollection} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Podcasts' ? <div className='ml-1 mt-3px'><Icon icon={podcastIcon} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Teach & Learn' ? <div className='ml-1 mt-2px'><Icon icon={educationIcon} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Comedy Corner' ? <div className='ml-1 mt-1'><Icon icon={laughSquint} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'YouTube Sports' ? <div className='ml-1  mt-3px'><Icon icon={basketballIcon} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Insta Fitness' ? <div className='ml-1 mt-3px'><Icon icon={barbellIcon} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Cooking' ? <div className='ml-px mt-5px'><Icon icon={food16Filled} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Talent Showcase' ? <div className='ml-1 mt-3px'><Icon icon={myMoviesTV} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Animal Love' ? <div className='ml-1 mt-3px'><Icon icon={catIcon} height={20} width={20} /></div> : <></>}
                          {category.categoryName === 'Art' ? <div className='ml-1 mt-2px'><Icon icon={colorPaletteSolid} height={20} width={20} /></div> : <></>}
                          {category.categoryName === 'Beautiful Nature' ? <div className='ml-1  mt-1'><Icon icon={flowerIcon} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'TikTok Playlist' ? <div className='ml-px mt-px'><Icon icon={brandTiktok} height={20} width={20} /></div> : <></>}
                          {category.categoryName === 'Panoramic Reels' ? <div className='ml-2px mt-2px'><Icon icon={instagramIcon} height={20} width={20} /></div> : <></>}
                          {/* Professional */}
                          {category.categoryName === 'Entrepreneurship' ? <div className='ml-1  mt-3px'><Icon icon={lightbulbIcon} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'AMA' ? <div className='ml-1 mt-2px'><Icon icon={talkBubblesLine} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Business & Career' ? <div className='ml-1 mt-1'><Icon icon={briefcaseIcon} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Interviews' ? <div className='ml-1  mt-3px'><Icon icon={microphoneSolid} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'In The News' ? <div className='ml-1 mt-1'><Icon icon={newspaperIcon} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Bitcoin Businesses' ? <div className='ml-1 mt-3px'><Icon icon={applicationBraces} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Branded Promotions' ? <div className='ml-1 mt-3px'><Icon icon={adIcon} height={18} width={18} /></div> : <></>}
                          {/* Knowledge */}
                          {category.categoryName === 'How To Tutorials' ? <div className='ml-px mt-3px'><Icon icon={helpInfoSolid} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Articles' ? <div className='ml-1 mt-3px'><Icon icon={knowledgeArticle} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'ChatGPT & AI' ? <div className='ml-1 mt-2px'><Icon icon={brainCircuit20Filled} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Science' ? <div className='ml-1 mt-2px'><Icon icon={atomSolid} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Reviews' ? <div className='ml-1 mt-2px'><Icon icon={clipboardCheck} height={20} width={20} /></div> : <></>}
                          {category.categoryName === 'Life' ? <div className='ml-1 mt-3px'><Icon icon={smileyO} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Health & Diet' ? <div className='ml-1 mt-3px'><Icon icon={heartbeatIcon} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Code Share' ? <div className='ml-1 mt-2px'><Icon icon={codeLine} height={20} width={20} /></div> : <></>}
                          {category.categoryName === 'Books' ? <div className='ml-1 mt-3px'><Icon icon={bookSolid} height={18} width={18} /></div> : <></>}
                          {/* Bonus */}
                          {category.categoryName === 'Polls' ? <div className='ml-1 mt-3px'><Icon icon={vote20Regular} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Lists' ? <div className='ml-1 mt-3px'><Icon icon={bulletListLine} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Questions' ? <div className='ml-1 mt-3px'><Icon icon={personQuestionMark20Filled} height={18} width={18} /></div> : <></>}
                          {category.categoryName === 'Stories' ? <div className='ml-1 mt-3px'><Icon icon={openBook} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Letter Forever' ? <div className='ml-1 mt-1'><Icon icon={textDocumentEdit} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Poetry' ? <div className='ml-1 mt-1'><Icon icon={diplomaScroll} height={16} width={16} /></div> : <></>}
                          {category.categoryName === 'Bounty Challenges' ? <div className='mt-3px'><Icon icon={currencyUsd} height={18} width={18} /></div> : <></>}
                        </button>
                      </div>
                    )
                  })}
                </div>
              </React.Fragment>
              :
              // Single category
              <React.Fragment key={i}>
                <div className={`first-text items-center rounded-xl text-xl transition w-full gradient-background-eleven mb-2
                ${categoryHighlights[item.indexLevel].selectedClass ? `${categoryHighlights[item.indexLevel].selectedClass}` : `button-shadow`}`}
                >
                  <button className='w-full py-2 flex justify-center' onClick={(e) => accordionSelectedCategory(e, item.indexLevel, item.href)}>
                    {item.title}
                    {item.title === 'Stamp You' ? <div className={`ml-2px mt-5px`}><Icon icon={stampIcon} height={19} width={19} /></div> : <></>}
                    {item.title === 'Meet the Stampers' ? <div className={`ml-1 mt-6px`}><Icon icon={handshakeIcon} height={19} width={19} /></div> : <></>}
                    {item.title === 'All Stamps' ? <div className={`ml-1 mt-1 mt-5px`}><Icon icon={postageStamp} height={19} width={19} /></div> : <></>}
                  </button>
                </div>
              </React.Fragment>
          )
        })}
      </nav>
    </>
  );
};
// 36 total unique categories
const navCategoriesForAccordion = [
  {
    key: 1,
    title: `Stamp You`,
    isMultipleCategory: false,
    href: 'stamp-you',
    indexLevel: 0,
  },
  {
    key: 2,
    title: `Meet the Stampers`,
    isMultipleCategory: false,
    href: 'meet-the-stampers',
    indexLevel: 1,
  },
  {
    key: 3,
    title: `Entertainment +`,
    isMultipleCategory: true,
    href: '',
    indexLevel: 2,
    categories: [
      { categoryName: `Twitch Gaming`, href: 'gaming', indexLevel: 3 },
      { categoryName: 'Spotify Soundcloud', href: 'music', indexLevel: 4 },
      { categoryName: 'Podcasts', href: 'podcasts', indexLevel: 5 },
      { categoryName: 'Teach & Learn', href: 'teach', indexLevel: 6 },
      { categoryName: 'Comedy Corner', href: 'comedy', indexLevel: 7 },
      { categoryName: `YouTube Sports`, href: 'sports', indexLevel: 8 },
      { categoryName: 'Insta Fitness', href: 'fitness', indexLevel: 9 },
      { categoryName: 'Cooking', href: 'cooking', indexLevel: 10 },
      { categoryName: 'Talent Showcase', href: 'talent-showcase', indexLevel: 11 },
      { categoryName: `Animal Love`, href: 'animals', indexLevel: 12 },
      { categoryName: `Art`, href: 'art', indexLevel: 13 },
      { categoryName: 'Beautiful Nature', href: 'beautiful-nature', indexLevel: 14 },
      { categoryName: 'TikTok Playlist', href: 'tiktok-playlist', indexLevel: 15 },
      { categoryName: 'Panoramic Reels', href: 'panoramic-reels', indexLevel: 16 },
    ],
  },
  {
    key: 4,
    title: `Professional`,
    isMultipleCategory: true,
    href: '',
    indexLevel: 17,
    categories: [
      { categoryName: 'Entrepreneurship', href: 'entrepreneurship', indexLevel: 18 },
      { categoryName: `AMA`, href: 'ama', indexLevel: 19 },
      { categoryName: 'Business & Career', href: 'career', indexLevel: 20 },
      { categoryName: `Interviews`, href: 'interviews', indexLevel: 21 },
      { categoryName: `In The News`, href: 'in-the-news', indexLevel: 22 },
      { categoryName: `Bitcoin Businesses`, href: 'bitcoin-businesses', indexLevel: 23 },
      { categoryName: 'Branded Promotions', href: 'promotions', indexLevel: 24 },

    ],
  },
  {
    key: 5,
    title: `Knowledge`,
    isMultipleCategory: true,
    href: '',
    indexLevel: 25,
    categories: [
      { categoryName: 'How To Tutorials', href: 'tutorials', indexLevel: 26 },
      { categoryName: `Articles`, href: 'articles', indexLevel: 27 },
      { categoryName: `ChatGPT & AI`, href: 'chatgpt', indexLevel: 28 },
      { categoryName: `Science`, href: 'science', indexLevel: 29 },
      { categoryName: `Reviews`, href: 'reviews', indexLevel: 30 },
      { categoryName: 'Life', href: 'life', indexLevel: 31 },
      { categoryName: `Health & Diet`, href: 'health', indexLevel: 32 },
      { categoryName: 'Code Share', href: 'code-share', indexLevel: 33 },
      { categoryName: `Books`, href: 'books', indexLevel: 34 },
    ],
  },
  {
    key: 6,
    title: `Bonus`,
    isMultipleCategory: true,
    href: '',
    indexLevel: 35,
    categories: [
      { categoryName: 'Polls', href: 'polls', indexLevel: 36 },
      { categoryName: 'Lists', href: 'lists', indexLevel: 37 },
      { categoryName: `Questions`, href: 'questions', indexLevel: 38 },
      { categoryName: 'Stories', href: 'stories', indexLevel: 39 },
      { categoryName: 'Letter Forever', href: 'letter-forever', indexLevel: 40 },
      { categoryName: 'Poetry', href: 'poetry', indexLevel: 41 },
      { categoryName: 'Bounty Challenges', href: 'bounties', indexLevel: 42 },
    ],
  },
  {
    key: 7,
    title: `All Stamps`,
    isMultipleCategory: false,
    href: 'all',
    indexLevel: 43,
  },

]
export default NavAccordion;