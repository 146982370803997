import React from 'react'

const SkeletonLoaderSingleLine = (props) => {
  return (
    <>
      <div className={`skeleton-card rounded-xl transition fifth-background shadow-effect-one mb-2 py-1`}>
        {/* Row 1 */}
        <div className={`flex justify-between items-center`}>
          <div className={`pl-2 flex items-center w-full`}>
            {!props.removeAvatar ? <div className={`skeleton skeleton-fake-image rounded-xl mr-1`}></div> : <></>}
            <div className={`skeleton skeleton-text-longer`}></div>
          </div>
          <div className={`flex`}>
          <div className={`skeleton skeleton-text-button mr-1`}></div>
          <div className={`skeleton skeleton-text-button mr-2`}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SkeletonLoaderSingleLine;
