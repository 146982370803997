import React from 'react'
import { Controlled as ControlledEditor } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';

import 'codemirror/theme/elegant.css';
import 'codemirror/theme/material-darker.css';
import 'codemirror/theme/tomorrow-night-bright.css';
import 'codemirror/theme/mdn-like.css';

import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/python/python';

const StaticCodeEditor = props => {
  let { themeforCodeEditor, codeEditorContent, codeEditorLanguage } = props
  // I need to take the theme state and then use it to determin which menu item to use. That's how I want that selected.
  // I think I might be doing this in the container. That probably exists there already. Then I can just pass in the value with that function.
  return (
      <>
        <div className={`shadow-effect-five my-2`}>
        <ControlledEditor
          value={codeEditorContent}
          className={``}
          options={{
            lineWrapping: true,
            lint: true,
            mode: codeEditorLanguage,
            theme: themeforCodeEditor,
            lineNumbers: true
          }}
        />
        </div>
      </>
    )
  }

export default StaticCodeEditor;