import React, { Component } from 'react'
import CommentListItem from './CommentListItem';
import { connect } from 'react-redux';
import API from '../../utils/API';
import smoothscroll from 'smoothscroll-polyfill';
import { globals, iframeUpdate } from '../../config/globals';
import { toast } from 'react-toastify'
import PaymentModal from '../Modals/PaymentModal';
import OverlayLoadingIcon from '../Widgets/OverlayLoadingIcon';
import { storeUserDatabaseData } from '../../store/reducers/authSlice';
import ArrayModal from '../../components/Modals/ArrayModal';
import ConfirmCancelModal from '../Modals/ConfirmCancelModal';
import TipModal from '../Modals/TipModal';

class CommentListItemContainer extends Component {
  state = {
    addExpandedClass: '',
    editorVisible: false,
    tierTwoVisible: false,
    overallError: '',
    tierTwoComment: {
      tierTwoCommentBody: '',
    },
    tierTwoComments: [],
    isLoaded: false,
    tierTwoCommentOpacity: 'opacity-0',
    commentBodyError: '',
    levelTwoCommentOpacity: 'opacity-0',
    showPaymentModal: false,
    showConfirmCancelModal: false,
    transactionType: '',
    pageOverlayActive: false,
    nonPaymentOverlay: false,
    commentIndexNumber: 0,
    costToCommentIsFree: false,
    // State for editing comments
    showCommentEditor: false,
    editedCommentBody: '',
    editedCommentBodyError: '',
    editedOverallError: '',
    updateCommentOpacity: 'opacity-100',
    // Array Modal
    showArrayModal: false,
    commentThumbsArray: [],
    arrayModalOpacity: 'opacity-0',
    arrayModalIsLoading: true,
    commentThumbsArrayIsLoading: false,
    pageNumberCommentThumbs: 1,
    noMoreResultsCommentThumbs: false,
    // thumbs/tipping
    showTipModal: false,
    thumbsAmount: globals.thumbsCostMin.toFixed(2),
  }
  cancelToken = API.CancelToken.source()
  modalObserver = React.createRef()

  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled');
  }
  // For ckeditor
  setEditorVisible = () => {
    if (this.state.editorVisible) {
      this.setState({ levelTwoCommentOpacity: 'opacity-0', showConfirmCancelModal: false, }, () => {
        setTimeout(() => {
          this.setState({
            editorVisible: false,
            addExpandedClass: '',

            tierTwoComment: {
              tierTwoCommentBody: '',
            },
          })
        }, 300)
      })
    } else if (!this.state.editorVisible) {
      this.setState({ editorVisible: true, addExpandedClass: 'expanded', showConfirmCancelModal: false, }, () => {
        setTimeout(() => {
          this.setState({ levelTwoCommentOpacity: 'opacity-100' })
        }, 300)
      })
    }
  }
  // For tier two comments
  setTierTwoVisible = () => {
    if (this.state.tierTwoVisible === true) {
      // So here is where I am going to set the tierTwoVisible to false. So i need to setTimeout that, and let opacity finish changing.
      this.setState({ tierTwoCommentOpacity: 'opacity-0' }, () => {
        setTimeout(() => {
          this.setState({ tierTwoVisible: false })
        }, 500)
      })
    }
    else {
      this.setState({ tierTwoVisible: true }, () => {
        setTimeout(() => {
          this.setState({ tierTwoCommentOpacity: 'opacity-100' })
        }, 100)
      })
    }
  }
  toggleEditor = (e, body, handle, date) => {
    if (e) e.preventDefault();
    // If the user "quotes" a comment
    if (body && handle && date) {
      let bodyBlockquoteRemove = body.replaceAll('<blockquote>', '').replaceAll('</blockquote>', '');
      let updatingDate = new Date(date);
      let updatedDate = updatingDate.toLocaleDateString();
      let creatorhandle = `<p><i>On ${updatedDate}, ${handle} replied: </i></p>`;
      let commentBody = `${bodyBlockquoteRemove}`;
      let mergedQuote = creatorhandle.concat(commentBody);
      mergedQuote = `<blockquote>${mergedQuote}</blockquote><p> </p><p></p>`
      if (!this.state.editorVisible) {
        return (
          this.setState({
            editorVisible: true,
            addExpandedClass: 'expanded',
            tierTwoComment: {
              tierTwoCommentBody: mergedQuote,
            }
          }),
          setTimeout(() => {
            this.setState({ levelTwoCommentOpacity: 'opacity-100' })
          }, 200)
        )
      }
      return (
        this.setState({
          tierTwoComment: {
            tierTwoCommentBody: mergedQuote,
          }
        })
      )
    }
    // If the user selects "reply" without the quotes.
    this.setEditorVisible()
  }
  checkCommentsForUserThumbs = (commentsArray) => {
    let updatedCommentsArray = commentsArray;
    for (let i = 0; i < updatedCommentsArray.length; i++) {
      if (updatedCommentsArray[i].thumberHandCashIds) {
        updatedCommentsArray[i].thumberHandCashIds.forEach(id => {
          if (id === this.props.auth.id) {
            updatedCommentsArray[i].userAlreadyThumbsComment = true;
          }
        })
      }
    }
    return updatedCommentsArray;
  }
  displayTierTwoComments = (parentCommentId) => {
    // If the array is empty, we want to call this function. If it is not, just display. No extra calls.

    // So here is where we grab the comments and will need to set the # of comments to fetch in the backend.
    // Luckily, when we append to the array, we have a predefined number to scroll. And then if it's less than that
    // and there aren't any more to scroll, it'll just say something. We'll have the same deal when the exact amount
    // is the array amount though. But that's especially ok with comments because you don't go "back" with them.
    if (this.state.tierTwoComments.length === 0) {
      this.setState({ tierTwoVisible: true })
      API.fetchTierTwoComments(parentCommentId, this.cancelToken.token)
        .then(res => {
          let commentsArray = res.data
          if (this.props.auth && this.props.auth.id) commentsArray = this.checkCommentsForUserThumbs(commentsArray);
          this.setNewTierTwoComments(commentsArray)
          setTimeout(() => {
            iframeUpdate();
          }, 750)
        })
        .catch(error => {
          if (error.message === 'Operation canceled') return
          console.error(error);
          globals.toastError(toast, 'Unable to locate comments');
        });
    }
    else {
      this.setTierTwoVisible()
    }
  }
  setNewTierTwoComments = (comments) => {
    this.setState({ tierTwoComments: comments }, () => {
      this.setState({ isLoaded: true, }, () => {
        setTimeout(() => {
          this.setState({ tierTwoCommentOpacity: 'opacity-100' })
        }, 100)
      })
    })
  }
  // This is going to need some rework and will probably just move into the previous function.
  setTierTwoComments = (comments) => {
    // This is probably where I'm going to need to do something with the data. 
    // Take the array, grab the last element, put something on it
    this.setState({ tierTwoComments: comments }, () => {
      this.setState({ isLoaded: true }, () => {
        this.setTierTwoVisible()
      })
    })
  }
  handleTierTwoCommentChange = (data) => {
    this.setState({
      tierTwoComment: {
        tierTwoCommentBody: data,
      }
    })
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.tierTwoComment.tierTwoCommentBody.length >= 1) {
        this.setState({ commentBodyError: '', overallError: '' })
      }
    }, 100)
  }
  validationChecks = () => {
    let allChecksValid;
    // commentBody Validation
    let commentBodyIsValid;
    if (this.state.tierTwoComment.tierTwoCommentBody.length <= 1) {
      this.setState({
        commentBodyError: '*Your comment could use some material*'
      })
      commentBodyIsValid = false
    } else commentBodyIsValid = true;

    // This allChecksValid will return true or false to initiate the function in handleSubmitPost
    if (!commentBodyIsValid) {
      allChecksValid = false
      this.setState({ overallError: `*Psst, fix any errors above and try again*` })
    }
    else allChecksValid = true;
    return allChecksValid;
  }
  // Submit tier 2 comment
  handleTierTwoCommentSubmit = (e) => {
    const t1CommentIndex = this.props.index
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // LocalStorage check. If it doesn't exist, we return out.
    if (!this.props.auth?.id || !localStorageAuth?.authToken) {
      globals.toastError(toast, 'Login with HandCash to post');
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
      // May want to redirect since they're not logged in. Will update App component and remove login
      return this.props.history.push(`/posts/${this.props.post.slug}`);
    }
    // The postID is necessary for stat keeping
    const validationReturnsTrue = this.validationChecks()
    if (!validationReturnsTrue) {
      return (
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        }),
        globals.toastError(toast, 'Fix error and try again')
      )
    }
    // Creating the Promise
    const postId = this.props.postId;
    const parentCommentId = this.props.comment._id;
    let commentBody = this.state.tierTwoComment.tierTwoCommentBody;
    commentBody.trim()
    // Check commentBody if there are any @tags.
    let taggedHandlesArray = globals.getTaggedHandles(commentBody, this.props.auth.handle)
    let hasIframeInComment = false
    if (commentBody.includes('<iframe')) hasIframeInComment = true
    // Need to remove empty paragraphs from paywall
    const checkIfEmptyParagraph = commentBody.endsWith(`<p><br></p>`)
    if (checkIfEmptyParagraph) {
      do commentBody = commentBody.slice(0, -11)
      while (commentBody.endsWith(`<p><br></p>`))
    }
    const tierOneCommentCreatorHandCashHandle = this.props.comment.commentCreatorHandle;
    const tierOneCommentCreatorHandCashId = this.props.comment.commentCreatorHandCashId;
    const postCreatorHandle = this.props.postCreatorHandle;
    commentBody = commentBody.replaceAll(new RegExp('iframe', 'g'), 'bdi');
    commentBody = commentBody.replaceAll(`contenteditable="false"`, '')
    commentBody = globals.checkForLinksToAdd(commentBody)

    // Comment cost
    let commentCost = globals.commentCost
    if (this.props.userDatabaseData?.membership) commentCost = globals.memberCommentCost
    const newCommentData = {
      taggedHandlesArray: taggedHandlesArray,
      commentBody: commentBody,
      postSlug: this.props.post.slug,
      postTitle: this.props.post.title,
      categoryDisplayName: this.props.post.categoryDisplayName,
      postId: postId,
      postCreatorHandle: postCreatorHandle,
      postCreatorHandCashId: this.props.post.postCreatorHandCashId,
      tierOneCommentCreatorHandCashHandle: tierOneCommentCreatorHandCashHandle,
      tierOneCommentCreatorHandCashId: tierOneCommentCreatorHandCashId,
      commentCost: commentCost,
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      hasIframeInComment: hasIframeInComment
    }
    this.toggleOverlay()
    if (this.props.auth && this.props.auth.handle === tierOneCommentCreatorHandCashHandle) this.setState({ nonPaymentOverlay: true })
    let promiseArray = []
    const createTierTwo = API.createTierTwoComment(parentCommentId, newCommentData)
    promiseArray.push(createTierTwo)
    if (this.props.numChildComments > 0 && this.state.tierTwoComments.length === 0) {
      const pullTierTwoCommentsIfExisting = API.fetchTierTwoComments(this.props.comment._id, this.cancelToken.token)
      promiseArray.push(pullTierTwoCommentsIfExisting)
    }
    return Promise.all(promiseArray)
      .then(res => {
        globals.toastSuccess(toast, 'Comment succesfully created!');
        const incrementTierTwoComments = res[0].data.incrementTierTwoComments;
        const commentDoc = res[0].data.tierTwoComment;
        const incrementComments = res[0].data.incrementComments;
        const updateTierTwoCommentCreator = res[0].data.updateTierTwoCommentCreator;
        if (res[1]) {
          let commentsArray = res[1].data;
          if (this.props.auth && this.props.auth.id) commentsArray = this.checkCommentsForUserThumbs(commentsArray);
          this.setTierTwoComments(commentsArray)
        }
        this.setState({
          tierTwoComment: {
            tierTwoCommentBody: '',
          },
          tierTwoVisible: true
        })
        // Updating database user
        const totalCommentValuePaid = updateTierTwoCommentCreator.totalCommentValuePaid
        const totalValueSent = updateTierTwoCommentCreator.totalValueSent
        const totalCommentsPosted = updateTierTwoCommentCreator.totalCommentsPosted
        this.props.storeUserDatabaseData({ type: 'updateForCommentCreation', totalCommentValuePaid, totalValueSent, totalCommentsPosted, commentDoc })
        // Here is where I need to set tierTwoVisible to true. I need to add the data to the array
        this.toggleEditor();
        this.props.updatePostAndCommentDataAfterT2Comment(incrementComments, commentDoc, t1CommentIndex);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
          this.addAndFindComment(incrementTierTwoComments, commentDoc);
        }, 400)
        setTimeout(() => {
          iframeUpdate();
        }, 750)
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        }, 400)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, error.response.data.message);
      });
  }
  // To bring to the id of the newly posted comment
  smoothScroll = (tierTwoCommentId) => {
    smoothscroll.polyfill();
    let scrollToComment = document.getElementById(`${tierTwoCommentId}`);
    scrollToComment.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    });
  }
  addAndFindComment = (incrementTierTwoComments, tierTwoComment) => {
    // Probably will need to do something with the statistics in this function.
    // data retrievals: data.tierTwoComment, data.incrementComments, data.incrementTierTwoComments
    this.props.setTierTwoCommentNumber(this.props.index, incrementTierTwoComments)
    // Timeout is needed, otherwise the opacity change doesn't work.
    this.setState({ tierTwoVisible: true, isLoaded: true }, () => {
      setTimeout(() => {
        this.setState({ tierTwoCommentOpacity: 'opacity-100' })
      }, 100)
    })
    // Adding the tierTwoComment to the array.
    let concatArray = this.state.tierTwoComments.concat(tierTwoComment)
    this.setState({ tierTwoComments: concatArray }, () => {
      this.smoothScroll(tierTwoComment._id)
    })
  }
  validateThumbsCost = () => {
    let thumbsValidated = { valueReturned: true, errorMessage: '' }
    if ((this.state.thumbsAmount < globals.thumbsCostMin) || (this.state.thumbsAmount > globals.thumbsCostMax)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Thumbs value must be between $${globals.thumbsCostMin.toFixed(2)} and $${globals.thumbsCostMax.toFixed(2)}.`;
      return thumbsValidated;
    };
    return thumbsValidated;
  }
  commentThumbsValidation = (index) => {
    let thumbsValidated = { valueReturned: true, errorMessage: '', }
    if (this.props.auth.id === this.state.tierTwoComments[index].commentCreatorHandCashId) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Cannot Thumbs yourself!!`;
      return thumbsValidated
    }
    if (this.state.tierTwoComments[index].thumberHandCashIds.includes(this.props.auth.id)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Already Thumbs'ed comment!`;
      return thumbsValidated;
    };
    if ((this.state.thumbsAmount < globals.thumbsCostMin) || (this.state.thumbsAmount > globals.thumbsCostMax)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Thumbs value must be between $${globals.thumbsCostMin.toFixed(2)} and $${globals.thumbsCostMax.toFixed(2)}.`;
      return thumbsValidated;
    };
    return thumbsValidated;
  }
  updateCommentThumbsData = (index, updatedCommentData) => {
    let updatedArray = this.state.tierTwoComments;
    updatedArray[index] = updatedCommentData
    updatedArray[index].userAlreadyThumbsComment = true;
    this.setState({ tierTwoComments: updatedArray })
  }
  addThumbsToTierTwoComment = (e, index) => {
    // Will need to add checks here eventually. With MoneyButton and whatnot.
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (this.props.auth?.id && localStorageAuth) {
      let thumbsValidated = this.commentThumbsValidation(index);
      if (!thumbsValidated.valueReturned) {
        return (
          globals.toastError(toast, thumbsValidated.errorMessage),
          this.setState({ showPaymentModal: false }, () => {
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400);
          })
        )
      }
      localStorageAuth = JSON.parse(localStorageAuth);
    };
    const commentId = this.state.tierTwoComments[index]._id;
    if (this.props.auth?.id && localStorageAuth?.authToken) {
      const thumbsData = {
        thumbType: 'comment',
        valueOfThumbs: this.state.thumbsAmount,
        postTitle: this.props.post.title,
        postSlug: this.props.post.slug,
        postId: this.props.post.id,
        postCreatorHandle: this.props.post.postCreatorHandle,
        postCreatorPaymail: this.props.post.postCreatorPaymail,
        postCreatorAvatarURL: this.props.post.postCreatorAvatarURL,
        postCreatorHandCashId: this.props.post.postCreatorHandCashId,
        commentCreatorHandle: this.state.tierTwoComments[index].commentCreatorHandle,
        commentCreatorHandCashId: this.state.tierTwoComments[index].commentCreatorHandCashId,
        commentCreatorAvatarURL: this.state.tierTwoComments[index].commentCreatorAvatarURL,
        thumbsCommentId: commentId,
        actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      }
      this.toggleOverlay();
      API.addThumbsToTierTwoComment(commentId, thumbsData)
        .then(res => {
          // Database response objects
          const commentUpdate = res.data.commentUpdate
          const thumbGiverUpdate = res.data.thumbGiverUpdate;
          const thumbsDoc = res.data.createThumbs
          // Updating comment data
          this.updateCommentThumbsData(index, commentUpdate)
          // Updating logged-in user data
          const totalThumbsGiven = thumbGiverUpdate.totalThumbsGiven
          const totalThumbValueGiven = thumbGiverUpdate.totalThumbValueGiven
          const totalValueSent = thumbGiverUpdate.totalValueSent
          this.props.storeUserDatabaseData({ type: 'updateForCommentThumbs', totalThumbsGiven, totalThumbValueGiven, totalValueSent, thumbsDoc })
          // Conclusion of function
          globals.toastSuccess(toast, 'Thumbs up success!');
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
        })
        .catch(error => {
          console.error(error);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
          globals.toastError(toast, error.response.data.message);
        });
    }
    // If not authorized for some reason
    else {
      globals.toastError(toast, 'Unable to add thumbs');
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
    }
  }
  togglePaymentModal = (e, typeOfTransaction, commentIndex) => {
    e.preventDefault();
    if (typeOfTransaction === 'UPDATE_COMMENT') {
      let validationReturnsTrue = this.editedCommentValidationChecks();
      if (!validationReturnsTrue) return globals.toastError(toast, 'Correct the error(s) and try again')
    }
    if (typeOfTransaction === 'CREATE_COMMENT') {
      if (this.props.auth && this.props.auth.handle === this.props.comment.commentCreatorHandle) this.setState({ costToCommentIsFree: true })
      let validationReturnsTrue = this.validationChecks();
      if (!validationReturnsTrue) return globals.toastError(toast, 'Correct the error(s) and try again')
    }
    if (typeOfTransaction === 'THUMBS_FOR_COMMENT') {
      const thumbsValidated = this.validateThumbsCost();
      if (!thumbsValidated.valueReturned) return globals.toastError(toast, thumbsValidated.errorMessage)
    }
    if (commentIndex) this.setState({ commentIndexNumber: commentIndex })
    // Okay, I can't do prevState, because I need to slow down the state change when it closes. Otherwise, no smooth transition.
    if (this.state.showPaymentModal === false) {
      this.setState({ showPaymentModal: true, transactionType: typeOfTransaction, showTipModal: false })
    }
    else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: typeOfTransaction, costToCommentIsFree: false })
        }, 400)
      })
    }
  };
  // Updating comments array to include new edits
  updateT2CommentsArray = (commentBody, index) => {
    if (!index || !commentBody) return
    let updatingTierTwoComments = this.state.tierTwoComments;
    updatingTierTwoComments[index].body = commentBody;
    this.setState({ tierTwoComments: updatingTierTwoComments })
  }
  ////////////////////////////////
  ///Updating T1 comment section//
  ////////////////////////////////
  toggleCommentEditor = (e) => {
    if (e) e.preventDefault();
    const comment = this.props.comment
    // Confirm this is their own comment
    if (comment && this.props.auth.id !== comment.commentCreatorHandCashId) {
      return globals.toastError(toast, `Unable to edit other's comments!`);
    }
    // So I'll need to toggle a state so that the Editor appears and this particular comment spec disappears. View Comment, View Editor.
    if (this.state.showCommentEditor) {
      this.setState({ updateCommentOpacity: 'opacity-0', showConfirmCancelModal: false })
      setTimeout(() => {
        this.setState({ showCommentEditor: false, updateCommentOpacity: 'opacity-100' })
      }, 400)
    }
    else {
      this.setState({ updateCommentOpacity: 'opacity-0', showConfirmCancelModal: false })
      setTimeout(() => {
        this.setState({
          editedOverallError: '',
          editedCommentBodyError: '',
          showCommentEditor: true,
          updateCommentOpacity: 'opacity-100',
          editedCommentBody: comment.body,
        })
      }, 400)
    }
  }
  handleEditCommentChange = (data) => {
    this.setState({
      editedCommentBody: data
    })
    if (this.state.editedOverallError === '') return
    setTimeout(() => {
      if (this.state.editedCommentBody.length >= 1) {
        this.setState({ editedCommentBodyError: '', editedOverallError: '' })
      }
    }, 100)
  }
  submitCommentUpdate = (e, comment) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    if (!comment || !localStorageAuth?.authToken || !this.props.auth?.id) {
      return (
        globals.toastError(toast, `Missing information to update comment.`),
        this.setState({ showPaymentModal: false }),
        this.props.history.push(`/`)
      )
    }
    // If no changes have been made
    if (this.state.editedCommentBody === comment.body) {
      return (
        globals.toastError(toast, `No changes have been made.`),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        })
      )
    }
    // If it is not the user
    if (this.props.auth.id !== comment.commentCreatorHandCashId) {
      return (
        globals.toastError(toast, `Unable to edit other's comments!`),
        this.setState({ showPaymentModal: false }),
        this.props.history.push(`/`)
      )
    }
    const validationReturnsTrue = this.editedCommentValidationChecks()
    if (!validationReturnsTrue) {
      return (
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        }),
        globals.toastError(toast, 'Fix error and try again')
      )
    }
    this.setState({ nonPaymentOverlay: true })
    this.toggleOverlay()
    // Replacing comment with new comment body
    delete comment.body
    comment.body = this.state.editedCommentBody
    let hasIframeInComment = false
    if (comment.body.includes('<iframe')) hasIframeInComment = true
    comment.hasIframeInComment = hasIframeInComment
    // Need to remove empty paragraphs
    const checkIfEmptyParagraph = comment.body.endsWith(`<p><br></p>`)
    if (checkIfEmptyParagraph) {
      do comment.body = comment.body.slice(0, -11)
      while (comment.body.endsWith(`<p><br></p>`))
    }
    comment.body = globals.checkForLinksToAdd(comment.body)
    // updating comment
    API.submitCommentUpdate(comment)
      .then(res => {
        let updatedComment = res.data
        let index = this.props.index;
        // Updating the comments array
        this.props.updateT1CommentsArray(updatedComment.body, index)
        // This ugly setTimeout sequence is needed because the pageOverlay doesn't close otherwise
        setTimeout(() => {
          this.setState({
            pageOverlayActive: false,
            updateCommentOpacity: 'opacity-0',
            nonPaymentOverlay: false
          },
            // 2nd setTimeout function
            () => {
              setTimeout(() => {
                this.setState({
                  editedCommentBody: '',
                  showCommentEditor: false,
                  updateCommentOpacity: 'opacity-100',
                })
              }, 400)
            })
          iframeUpdate()
        }, 400)
        globals.toastSuccess(toast, `You have successfully updated your comment!`);
      })
      .catch(err => {
        console.error(err)
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        }, 400)
        globals.toastError(toast, err.response.data.message)
      })
  }
  editedCommentValidationChecks = () => {
    let allChecksValid;
    // commentBody Validation
    let commentBodyIsValid;
    if (this.state.editedCommentBody.length <= 1) {
      this.setState({
        editedCommentBodyError: '*Your comment could use some material*'
      })
      commentBodyIsValid = false
    } else commentBodyIsValid = true;

    // This allChecksValid will return true or false to initiate the function in handleSubmitPost
    if (!commentBodyIsValid) {
      allChecksValid = false
      this.setState({ editedOverallError: `*Psst, fix any errors above and try again*` })
    }
    else allChecksValid = true;
    return allChecksValid;
  }
  toggleOverlay = () => {
    this.setState({ showPaymentModal: false }, () => {
      setTimeout(() => {
        this.setState({ transactionType: '', pageOverlayActive: true })
      }, 400)
    })
  }
  updateTier2CommentsData = (updateTier2CommentsData, tierTwoCommentIndex) => {
    let updatedCommentsArray = [...this.state.tierTwoComments];
    // Updating the # of t2 comments
    updatedCommentsArray[tierTwoCommentIndex].numChildComments = updatedCommentsArray[tierTwoCommentIndex].numChildComments + 1;
    // If t2 comment author is replying to their own comment
    if (updateTier2CommentsData.commentCreatorHandle === this.props.auth.handle) {
      return this.setState({ tierTwoComments: updatedCommentsArray })
    }
    // If user is replying to someone else's comment
    updatedCommentsArray[tierTwoCommentIndex].totalValueReceived = (
      parseFloat(updateTier2CommentsData.totalValueReceived)
    ).toFixed(2)
    return this.setState({ tierTwoComments: updatedCommentsArray })
  }
  // Comment Thumbs Array Modal
  toggleCommentThumbsArrayModal = (e, arrayType) => {
    e.preventDefault();
    if (arrayType !== 'COMMENT_THUMBS_ARRAY') return
    // Here we need to check if there is existing already, or not. As well as if noMoreResults
    if (this.state.noMoreResultsCommentThumbs || this.state.commentThumbsArray.length > 0) {
      return (
        this.setState({
          arrayModalOpacity: 'opacity-0',
          showArrayModal: true,
          modalArrayType: 'COMMENT_THUMBS_ARRAY',
          arrayModalIsLoading: false
        }),
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      )
    }
    this.setState({
      modalArrayType: 'COMMENT_THUMBS_ARRAY',
      showArrayModal: true,
      commentThumbsArrayIsLoading: false,
      arrayModalOpacity: 'opacity-0',
      arrayModalIsLoading: true
    })
    API.getCommentThumbers(this.props.comment._id, this.state.pageNumberCommentThumbs, this.cancelToken.token)
      .then(res => {
        let thumbsArray = res.data.thumbers
        // Check length
        if (thumbsArray.length === 0) {
          this.setState({ noMoreResultsCommentThumbs: true, commentThumbsArrayIsLoading: false, arrayModalIsLoading: false })
        }
        // If it's less than 20
        else if (thumbsArray.length < globals.fetchTwenty) {
          const updatedArray = this.firstLoadUpdateArray(thumbsArray)
          this.setState({ commentThumbsArray: updatedArray, arrayModalIsLoading: false }, () => {
            this.setState({ noMoreResultsCommentThumbs: true, commentThumbsArrayIsLoading: false })
          })
        }
        else {
          const updatedArray = this.firstLoadUpdateArray(thumbsArray)
          this.setState({ commentThumbsArray: updatedArray, arrayModalIsLoading: false }, () => {
            this.setState({ noMoreResultsCommentThumbs: false, commentThumbsArrayIsLoading: false })
          })
        }
        // Conclusion of function is to add opacity
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          this.setState({
            showArrayModal: false,
            commentThumbsArrayIsLoading: false,
            arrayModalOpacity: 'opacity-0'
          })
        }, 300)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve data');
      });
  }
  // This update adds the ref and the lastItem properties
  firstLoadUpdateArray = (dataArray) => {
    let addingToArray = dataArray;
    if (dataArray.length === globals.fetchTwenty) {
      let itemFive = addingToArray[4];
      itemFive.ref = true;
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    return addingToArray;
  }
  updateExistingArray = (newResults, arrayType) => {
    // arrayType is this.state.TYPEOFARRAY
    // newResults gets added to that array and assigned properties, when applicable.
    let addingToArray = arrayType
    addingToArray.forEach(arrayItem => {
      arrayItem.ref = false
      arrayItem.lastItem = false
    })
    newResults.map(item => {
      return addingToArray.push(item)
    })
    if (newResults.length === globals.fetchTwenty) {
      let itemFive = addingToArray[addingToArray.length - 15]
      itemFive.ref = true
      addingToArray.splice(addingToArray.length - 15, 1, itemFive)
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    // So this takes the index of the addingToArray, grabs one item, and replaces it with lastItem.
    addingToArray.splice(addingToArray.length - 1, 1, lastItem)
    return addingToArray;
  }
  closeArrayModal = () => {
    this.setState({ arrayModalOpacity: 'opacity-0' })
    setTimeout(() => {
      this.setState({ showArrayModal: false })
    }, 300)
  }
  arrayListPaginationLogic = (node) => {
    // Disconnects the current ref observer when it hits.
    if (this.modalObserver.current) this.modalObserver.current.disconnect()
    // Creates a new observer and in the grabMoreComments function, it updates the div where the refCallback lands on
    this.modalObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !this.state.noMoreResultsCommentThumbs) {
        this.paginateCommentThumbsArray()
      }
    })
    // Observe the current node.
    if (node) this.modalObserver.current.observe(node)
  }
  refCallbackForArrayList = this.arrayListPaginationLogic.bind(this); // <- this is essentially `useCallback`
  paginateCommentThumbsArray = () => {
    this.setState({ commentThumbsArrayIsLoading: true, pageNumberCommentThumbs: this.state.pageNumberCommentThumbs + 1 }, () => {
      API.getCommentThumbers(this.props.comment._id, this.state.pageNumberCommentThumbs, this.cancelToken.token)
        .then(res => {
          let thumbsArray = res.data.thumbers
          if (thumbsArray.length === 0) {
            // Update existing array so there aren't any refs.
            let updatedArray = this.state.commentThumbsArray
            updatedArray.forEach(item => { item.ref = false })
            this.setState({
              commentThumbsArrayIsLoading: false,
              noMoreResultsCommentThumbs: true,
              commentThumbsArray: updatedArray
            })
          }
          else if (thumbsArray.length < globals.fetchTwenty) {
            // Case if the search has some results, but not all.
            const updatedArray = this.updateExistingArray(thumbsArray, this.state.commentThumbsArray)
            this.setState({ commentThumbsArray: updatedArray }, () => {
              this.setState({ commentThumbsArrayIsLoading: false, noMoreResultsCommentThumbs: true })
            })
          }
          // Case if max items retrieved
          else {
            const updatedArray = this.updateExistingArray(thumbsArray, this.state.commentThumbsArray)
            this.setState({ commentThumbsArray: updatedArray }, () => {
              this.setState({ commentThumbsArrayIsLoading: false })
            })
          }
        })
        .catch(error => {
          console.error(error);
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to find content');
        });
    })
  }
  toggleConfirmCancelModal = (e) => {
    e.preventDefault();
    if (this.state.showConfirmCancelModal === false) {
      this.setState({ showConfirmCancelModal: true })
    }
    else this.setState({ showConfirmCancelModal: false })
  }
  closeConfirmCancelModal = (e) => {
    e.preventDefault();
    this.setState({ showConfirmCancelModal: false })
  }
  handleThumbsAmountChange = (e) => {
    // This ensures 2 decimal places
    this.setState({ thumbsAmount: e.target.value });
    let source = document.querySelector('.updatingTipValue')
    source.addEventListener('change', () => {
      source.value = parseFloat(source.value).toFixed(2)
    });
  }
  toggleTipModal = (e, transactionType, index) => {
    e.preventDefault();
    if (this.state.showTipModal === false) {
      this.setState({ showTipModal: true, transactionType: transactionType, commentIndexNumber: index })
    }
    else {
      this.setState({ showTipModal: false, transactionType: '' })
    }
  }
  closeTipModal = (e) => {
    e.preventDefault();
    this.setState({ showTipModal: false, transactionType: '' })
  }
  render() {
    return (
      <>
        <CommentListItem
          {...this.state}
          id={this.props.comment._id}
          history={this.props.history}
          comment={this.props.comment}
          post={this.props.post}
          globals={globals}
          moreCommentsLoading={this.props.moreCommentsLoading}
          noMoreComments={this.props.noMoreComments}
          refCallback={this.props.refCallback}
          index={this.props.index}
          postId={this.props.postId}
          updatePostAndCommentDataAfterT2Comment={this.props.updatePostAndCommentDataAfterT2Comment}
          numChildComments={this.props.numChildComments}
          setEditorVisible={this.setEditorVisible}
          toggleEditor={this.toggleEditor}
          handleTierTwoCommentChange={this.handleTierTwoCommentChange}
          handleTierTwoCommentSubmit={this.handleTierTwoCommentSubmit}
          displayTierTwoComments={this.displayTierTwoComments}
          addAndFindComment={this.addAndFindComment}
          addThumbsToTierTwoComment={this.addThumbsToTierTwoComment}
          togglePaymentModal={this.togglePaymentModal}
          title={this.props.title}
          auth={this.props.auth}
          userDatabaseData={this.props.userDatabaseData}
          postCreatorHandCashId={this.props.postCreatorHandCashId}
          postCreatorHandle={this.props.postCreatorHandle}
          // toggleTipModal is for T1 Comment Thumbs. Rest of logic is in PostDetailPageContainer
          toggleTipModalForT1Thumbs={this.props.toggleTipModal}
          toggleTipModalForT2Thumbs={this.toggleTipModal}
          storeUserDatabaseData={this.props.storeUserDatabaseData}
          goToUserProfilePage={this.props.goToUserProfilePage}
          updateT2CommentsArray={this.updateT2CommentsArray}
          toggleConfirmCancelModal={this.toggleConfirmCancelModal}
          // Editing T1 Comments
          toggleCommentEditor={this.toggleCommentEditor}
          handleEditCommentChange={this.handleEditCommentChange}
          updateTier1CommentsAndPostNumbersAfterT3Creation={this.props.updateTier1CommentsAndPostNumbersAfterT3Creation}
          updateTier2CommentsData={this.updateTier2CommentsData}
          toggleCommentThumbsArrayModal={this.toggleCommentThumbsArrayModal}
          listOrCommentView={this.props.listOrCommentView}
        />
        {this.state.pageOverlayActive
          ? <OverlayLoadingIcon nonPaymentOverlay={this.state.nonPaymentOverlay} />
          : <></>
        }
        {this.props.auth?.id
          ? <>
            <PaymentModal
              showPaymentModal={this.state.showPaymentModal}
              toggleModal={this.togglePaymentModal}
              triggerPaymentFunction={
                this.state.transactionType === 'CREATE_COMMENT'
                  ? this.handleTierTwoCommentSubmit
                  : this.state.transactionType === 'UPDATE_COMMENT'
                    ? this.submitCommentUpdate
                    : this.state.transactionType === 'THUMBS_FOR_COMMENT'
                      ? this.addThumbsToTierTwoComment
                      : null
              }
              auth={this.props.auth}
              transactionType={this.state.transactionType}
              postTitle={this.props.title}
              handCashPostCreator={this.props.postCreatorHandle}
              thumbsCost={this.state.thumbsAmount}
              commentsArray={this.state.tierTwoComments}
              commentIndex={this.state.commentIndexNumber}
              comment={this.props.comment}
              costToCommentIsFree={this.state.costToCommentIsFree}
              category={this.props.post.category}
              userDatabaseData={this.props.userDatabaseData}
            />
            {/* For T2 Comments */}
            <TipModal
              showModal={this.state.showTipModal}
              closeModal={this.closeTipModal}
              confirmPaymentModal={this.togglePaymentModal}
              handleChange={this.handleThumbsAmountChange}
              amount={this.state.thumbsAmount}
              transactionType={this.state.transactionType}
              min={globals.thumbsCostMin}
              max={globals.thumbsCostMax}
              step={`0.01`}
              isThumbsModal={true}
            />
            <ConfirmCancelModal
              showConfirmCancelModal={this.state.showConfirmCancelModal}
              closeConfirmCancelModal={this.closeConfirmCancelModal}
              confirmCancel={this.state.showCommentEditor === true ? this.toggleCommentEditor : this.toggleEditor}
            />
          </>
          : null
        }
        {this.state.commentThumbsArray.length > 0
          ? <ArrayModal
            closeArrayModal={this.closeArrayModal}
            showModal={this.state.showArrayModal}
            modalArrayType={'COMMENT_THUMBS_ARRAY'}
            refCallbackForArrayList={this.refCallbackForArrayList}
            goToUserProfilePage={this.props.goToUserProfilePage}
            modalDataArray={this.state.commentThumbsArray}
            noMoreResults={this.state.noMoreResultsCommentThumbs}
            arrayIsLoading={this.state.commentThumbsArrayIsLoading}
            arrayModalIsLoading={this.state.arrayModalIsLoading}
            arrayModalOpacity={this.state.arrayModalOpacity}
          />
          : null
        }
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    userDatabaseData: state.auth.userDatabaseData,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentListItemContainer);
