import { createSlice } from '@reduxjs/toolkit'

let initState = {}
const localStorageTheme = localStorage.getItem('theme')

const getPreferredColorScheme = () => {
  // If window.matchMedia exists for the browser
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      initState = { themeModeChecked: true }
      document.documentElement.setAttribute('data-theme', 'LIGHT_THEME')
    }
    else {
      document.documentElement.setAttribute('data-theme', 'DARK_THEME')
      initState = { themeModeChecked: false }
    }
  }
  // If user does not have preference
  else {
    document.documentElement.setAttribute('data-theme', 'DARK_THEME')
    initState = { themeModeChecked: false }
  }
}

if (localStorageTheme === 'LIGHT_THEME') {
  initState = { themeModeChecked: true }
  document.documentElement.setAttribute('data-theme', localStorageTheme)
}
else if (localStorageTheme === 'DARK_THEME') {
  document.documentElement.setAttribute('data-theme', 'DARK_THEME')
  initState = { themeModeChecked: false }
}
else {
  getPreferredColorScheme()
}

const themeSlice = createSlice({
  name: 'theme',
  initialState: initState,
  reducers: {
    handleThemeChange(state, action) {
      switch (action.payload) {
        case 'DARK_THEME':
          localStorage.setItem('theme', 'DARK_THEME')
          state.themeModeChecked = false
          return
        case 'LIGHT_THEME':
          localStorage.setItem('theme', 'LIGHT_THEME')
          state.themeModeChecked = true
          return
        default:
          state = state;
          return
      }
    }
  }
})

export const { handleThemeChange } = themeSlice.actions;
export default themeSlice.reducer;