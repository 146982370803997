import React from 'react';

const TitleInput = props => {
  const { categoryNameDisplay, title, handleChange, titleError, noDescription, editorPageForPosts } = props
  return (
    <>
      <h2 className={'-mt-2 md:mt-0 -mb-1 sm:-mb-2 text-center logo-style teko-font text-4xl relative'}>
        {categoryNameDisplay}
        {editorPageForPosts
          ?
          <>
            <span className={`ml-2px absolute mt-4`}>
              <div className="bottom-tooltip text-shadow-none text-base font-normal 
              cursor-pointer flex justify-center items-center text-center ml-2 rounded-xl shadow-effect-seven
              gradient-background-four h-5 w-5"
                data-tooltip={`Select "Media" in the Editor to add TikToks, Reels, and more. Visit FAQ page for specifics.`}
              >
                ?
              </div>
            </span>
          </>
          : <></>
        }
      </h2>
      <div className={`sm:mx-2 ${noDescription ? `my-6` : `mt-6 mb-2`} `}>
        {/* Title Input */}
        <div className={`transition first-text custom-input-container`} data-name="Title">
          <div className={`rounded-xl shadow-effect-six`}>
            <input
              type='text'
              onChange={handleChange}
              className={`shadow-effect-ten border outline-none w-full rounded-xl px-2 h-50px text-gray-900 transition`}
              aria-label="Enter Title of Post"
              id='title' value={title}
              // Different placeholders for different categories
              placeholder={
                categoryNameDisplay === `Polls` ? `Poll Title...` :
                  categoryNameDisplay === `Lists` ? `List Title...` : 'Tap here to add title'
              }
            >
            </input>
          </div>
        </div>
        {titleError
          ?
          <div className={`text-center font-header text-xs mt-1 text-red-600`}>
            {titleError}
          </div>
          : null
        }
      </div>
    </>
  )
}

export default TitleInput