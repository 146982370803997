import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { globals } from '../config/globals';

class DeclinePage extends Component {
  componentDidMount() {
    globals.toastError(toast, 'HandCash Authentication declined. Redirecting to Home Page.');
    this.props.history.push(`/`)
  };
  render() {
    return (
      <>
        <div className={`unclickable-overlay`} />
      </>
    )
  }
}

export default DeclinePage;
