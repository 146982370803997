import React, { Component } from 'react';
import TierTwoComments from './TierTwoComments';
import API from '../../utils/API';
import smoothscroll from 'smoothscroll-polyfill';
import { globals, iframeUpdate } from '../../config/globals';
import { toast } from 'react-toastify'
import PaymentModal from '../Modals/PaymentModal';
import OverlayLoadingIcon from '../Widgets/OverlayLoadingIcon';
import ArrayModal from '../../components/Modals/ArrayModal';
import ConfirmCancelModal from '../Modals/ConfirmCancelModal';
import TipModal from '../Modals/TipModal';

class TierTwoCommentsContainer extends Component {
  state = {
    addExpandedClass: '',
    editorVisible: false,
    showPaymentModal: false,
    transactionType: '',
    pageOverlayActive: false,
    nonPaymentOverlay: false,
    costToCommentIsFree: false,
    showConfirmCancelModal: false,
    // State for editing comments
    showCommentEditor: false,
    editedCommentBody: '',
    editedCommentBodyError: '',
    editedOverallError: '',
    overallError: '',
    updateCommentOpacity: 'opacity-100',
    tierThreeOpacity: 'opacity-0',
    // Tier 3 state
    tierThreeIsLoaded: false,
    tierThreeComment: {
      tierThreeCommentBody: ''
    },
    levelThreeCommentOpacity: 'opacity-0',
    commentBodyError: '',
    tierThreeVisible: false,
    tierThreeComments: [],
    commentIndexNumber: 0,
    // Array Modal
    showArrayModal: false,
    commentThumbsArray: [],
    arrayModalOpacity: 'opacity-0',
    arrayModalIsLoading: true,
    commentThumbsArrayIsLoading: false,
    pageNumberCommentThumbs: 1,
    noMoreResultsCommentThumbs: false,
    // thumbs/tipping
    showTipModal: false,
    thumbsAmount: globals.thumbsCostMin.toFixed(2),
  }
  cancelToken = API.CancelToken.source()
  modalObserver = React.createRef()

  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled');
  }
  setEditorVisible = () => {
    if (this.state.editorVisible) {
      this.setState({ levelThreeCommentOpacity: 'opacity-0', showConfirmCancelModal: false }, () => {
        setTimeout(() => {
          this.setState({
            editorVisible: false,
            addExpandedClass: '',
            tierThreeComment: {
              tierThreeCommentBody: '',
            },
          })
        }, 500)
      })
    } else if (!this.state.editorVisible) {
      // What it will be.
      // Have to change it to true, because expanded class won't exist.
      this.setState({ editorVisible: true, addExpandedClass: 'expanded', showConfirmCancelModal: false }, () => {
        setTimeout(() => {
          this.setState({ levelThreeCommentOpacity: 'opacity-100' })
        }, 200)
      })
    }
  }
  toggleEditor = (e, body, handle, date) => {
    if (e) e.preventDefault();
    if (body && handle && date) {
      let bodyBlockquoteRemove = body.replaceAll('<blockquote>', '').replaceAll('</blockquote>', '');
      let updatingDate = new Date(date);
      let updatedDate = updatingDate.toLocaleDateString();
      let creatorhandle = `<p><i>On ${updatedDate}, ${handle} replied: </i></p>`;
      let commentBody = `${bodyBlockquoteRemove}`;
      let mergedQuote = creatorhandle.concat(commentBody);
      mergedQuote = `<blockquote>${mergedQuote}</blockquote><p> </p><p></p>`
      if (!this.state.editorVisible) {
        return (
          this.setState({
            editorVisible: true,
            addExpandedClass: 'expanded',
            tierThreeComment: {
              tierThreeCommentBody: mergedQuote,
            }
          }),
          setTimeout(() => {
            this.setState({ levelThreeCommentOpacity: 'opacity-100' })
          }, 200)
        )
      }
      return (
        this.setState({
          tierThreeComment: {
            tierThreeCommentBody: mergedQuote,
          }
        })
      )
    }
    // If the user selects "reply" without the quotes.
    this.setEditorVisible()
  }
  handleTierThreeCommentChange = (data) => {
    this.setState({
      tierThreeComment: {
        tierThreeCommentBody: data,
      }
    })
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.tierThreeComment.tierThreeCommentBody.length >= 1) {
        this.setState({ commentBodyError: '', overallError: '' })
      }
    }, 100)
  }
  validateThumbsCost = () => {
    let thumbsValidated = { valueReturned: true, errorMessage: '' }
    if ((this.state.thumbsAmount < globals.thumbsCostMin) || (this.state.thumbsAmount > globals.thumbsCostMax)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Thumbs value must be between $${globals.thumbsCostMin.toFixed(2)} and $${globals.thumbsCostMax.toFixed(2)}.`;
      return thumbsValidated;
    };
    return thumbsValidated;
  }
  validationChecks = () => {
    let allChecksValid;
    // commentBody Validation
    let commentBodyIsValid;
    if (this.state.tierThreeComment.tierThreeCommentBody.length <= 1) {
      this.setState({
        commentBodyError: '*Your comment could use some material*'
      })
      commentBodyIsValid = false
    } else commentBodyIsValid = true;

    // This allChecksValid will return true or false to initiate the function in handleSubmitPost
    if (!commentBodyIsValid) {
      allChecksValid = false
      this.setState({ overallError: `*Psst, fix any errors above and try again*` })
    }
    else allChecksValid = true;
    return allChecksValid;
  }
  handleTierThreeCommentSubmit = (e) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // LocalStorage check. If it doesn't exist, we return out.
    if (!this.props.auth?.id || !localStorageAuth?.authToken) {
      globals.toastError(toast, 'Login with HandCash to post');
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
      // May want to redirect since they're not logged in. Will update App component and remove login
      return this.props.history.push(`/posts/${this.props.post.slug}`);
    }
    let validationReturnsTrue = this.validationChecks()
    if (validationReturnsTrue) {
      // The postID is necessary for stat keeping
      const postId = this.props.postId;
      // This is the parent comment Id, which we use to put into the t2 comment's "childComments" array
      const parentCommentId = this.props.comment._id;
      let commentBody = this.state.tierThreeComment.tierThreeCommentBody;
      commentBody.trim()
      // Check commentBody if there are any @tags.
      let taggedHandlesArray = globals.getTaggedHandles(commentBody, this.props.auth.handle)
      let hasIframeInComment = false
      if (commentBody.includes('<iframe')) hasIframeInComment = true
      // Need to remove empty paragraphs from paywall
      const checkIfEmptyParagraph = commentBody.endsWith(`<p><br></p>`)
      if (checkIfEmptyParagraph) {
        do commentBody = commentBody.slice(0, -11)
        while (commentBody.endsWith(`<p><br></p>`))
      }
      const tierTwoCommentCreatorHandCashHandle = this.props.comment.commentCreatorHandle;
      const tierTwoCommentCreatorHandCashId = this.props.comment.commentCreatorHandCashId;
      const postCreatorHandle = this.props.postCreatorHandle;
      commentBody = commentBody.replaceAll(new RegExp('iframe', 'g'), 'bdi');
      commentBody = commentBody.replaceAll(`contenteditable="false"`, '')
      commentBody = globals.checkForLinksToAdd(commentBody)
      // Comment cost
      let commentCost = globals.commentCost
      if (this.props.userDatabaseData?.membership) commentCost = globals.memberCommentCost
      let newCommentData = {
        taggedHandlesArray: taggedHandlesArray,
        commentBody: commentBody,
        commentCost: commentCost,
        hasIframeInComment: hasIframeInComment,
        // Tier 1 comment ID
        t1CommentId: this.props.tierOneCommentId,
        // Post Info
        postSlug: this.props.post.slug,
        postTitle: this.props.post.title,
        categoryDisplayName: this.props.post.categoryDisplayName,
        postId: postId,
        postCreatorHandle: postCreatorHandle,
        postCreatorHandCashId: this.props.post.postCreatorHandCashId,
        // tier 2 comment creator that user is replying to
        tierTwoCommentCreatorHandCashHandle: tierTwoCommentCreatorHandCashHandle,
        tierTwoCommentCreatorHandCashId: tierTwoCommentCreatorHandCashId,
        actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      }
      if (this.props.auth && this.props.auth.handle === tierTwoCommentCreatorHandCashHandle) this.setState({ nonPaymentOverlay: true })
      this.toggleOverlay()
      // Creating a Promise that creates the t3 comment, and retrieves the existing t3 comments if there are any
      let promiseArray = []
      const createTierThree = API.createTierThreeComment(parentCommentId, newCommentData)
      promiseArray.push(createTierThree)
      // If there are child comments to be grabbed from this particular t2 comment, we fetch them
      if (this.props.numChildComments > 0 && this.state.tierThreeComments.length === 0) {
        const pullTierThreeCommentsIfExisting = API.fetchTierThreeComments(this.props.comment._id, this.cancelToken.token)
        promiseArray.push(pullTierThreeCommentsIfExisting)
      }
      // Returning the promise
      return Promise.all(promiseArray)
        .then(res => {
          this.setState({ tierThreeComment: { tierThreeCommentBody: '' } })
          const commentDoc = res[0].data.tierThreeComment;
          const updateTierThreeCommentCreator = res[0].data.updateTierThreeCommentCreator;
          const updateTierTwoCommentData = res[0].data.updateTierTwoCommentData;
          const tierTwoCommentIndex = this.props.index
          // Grabbing any t3 comments
          if (res[1]) {
            let commentsArray = res[1].data;
            if (this.props.auth && this.props.auth.id) commentsArray = this.checkCommentsForUserThumbs(commentsArray);
            this.setTierThreeComments(commentsArray)
          }
          // Updating database user
          const totalCommentValuePaid = updateTierThreeCommentCreator.totalCommentValuePaid
          const totalValueSent = updateTierThreeCommentCreator.totalValueSent
          const totalCommentsPosted = updateTierThreeCommentCreator.totalCommentsPosted
          this.props.storeUserDatabaseData({ type: 'updateForCommentCreation', totalCommentValuePaid, totalValueSent, totalCommentsPosted, commentDoc })
          // Conclusion of function
          this.toggleEditor()
          // post and t1 should update now
          this.props.updateTier1CommentsAndPostNumbersAfterT3Creation(this.props.tierOneCommentIndex)
          this.props.updateTier2CommentsData(updateTierTwoCommentData, tierTwoCommentIndex)
          globals.toastSuccess(toast, 'Comment successfully created!');
          setTimeout(() => {
            this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
            this.addAndFindT3Comment(commentDoc)
          }, 400)
          setTimeout(() => {
            iframeUpdate();
          }, 750)
        })
        .catch(error => {
          console.error(error);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
          }, 400)
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, error.response.data.message);

        });
      // If validation fails
    } else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
      globals.toastError(toast, 'Fix error and try again');
    }
  }
  addAndFindT3Comment = (t3Comment) => {
    this.setState({ tierThreeVisible: true, tierThreeIsLoaded: true }, () => {
      setTimeout(() => {
        this.setState({ tierThreeOpacity: 'opacity-100' })
      }, 100)
    })
    let concatArray = this.state.tierThreeComments.concat(t3Comment)
    this.setState({ tierThreeComments: concatArray }, () => {
      this.smoothScroll(t3Comment._id)
    })
  }
  // To bring to the id of the newly posted comment
  smoothScroll = (t3CommentId) => {
    smoothscroll.polyfill();
    let scrollToComment = document.getElementById(`${t3CommentId}`);
    scrollToComment.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    });
  }
  togglePaymentModal = (e, typeOfTransaction, commentIndex) => {
    e.preventDefault();
    if (typeOfTransaction === 'UPDATE_COMMENT') {
      let validationReturnsTrue = this.editedCommentValidationChecks();
      if (!validationReturnsTrue) return globals.toastError(toast, 'Correct the error(s) and try again')
    }
    if (typeOfTransaction === 'CREATE_COMMENT') {
      if (this.props.auth && this.props.auth.handle === this.props.comment.commentCreatorHandle) this.setState({ costToCommentIsFree: true })
      let validationReturnsTrue = this.validationChecks();
      if (!validationReturnsTrue) return globals.toastError(toast, 'Correct the error(s) and try again')
    }
    if (typeOfTransaction === 'THUMBS_FOR_COMMENT') {
      const thumbsValidated = this.validateThumbsCost();
      if (!thumbsValidated.valueReturned) return globals.toastError(toast, thumbsValidated.errorMessage)
    }
    if (commentIndex) this.setState({ commentIndexNumber: commentIndex })
    // Okay, I can't do prevState, because I need to slow down the state change when it closes. Otherwise, no smooth transition.
    if (this.state.showPaymentModal === false) {
      this.setState({ showPaymentModal: true, transactionType: typeOfTransaction, showTipModal: false })
    }
    else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: typeOfTransaction, costToCommentIsFree: false })
        }, 400)
      })
    }
  };

  /////////////////////////////
  ///Updating comment section//
  /////////////////////////////

  toggleCommentEditor = (e) => {
    if (e) e.preventDefault();
    const comment = this.props.comment
    // Confirm this is their own comment
    if (comment && this.props.auth.id !== comment.commentCreatorHandCashId) {
      return globals.toastError(toast, `Unable to edit other's comments!`);
    }
    // So I'll need to toggle a state so that the Editor appears and this particular comment spec disappears. View Comment, View Editor.
    if (this.state.showCommentEditor) {
      this.setState({ updateCommentOpacity: 'opacity-0', showConfirmCancelModal: false })
      setTimeout(() => {
        this.setState({ showCommentEditor: false, updateCommentOpacity: 'opacity-100' })
      }, 400)
    }
    else {
      this.setState({ updateCommentOpacity: 'opacity-0', showConfirmCancelModal: false })
      setTimeout(() => {
        this.setState({
          editedOverallError: '',
          editedCommentBodyError: '',
          showCommentEditor: true,
          updateCommentOpacity: 'opacity-100',
          editedCommentBody: comment.body,
        })
      }, 400)
    }
  }
  handleEditCommentChange = (data) => {
    this.setState({
      editedCommentBody: data
    })
    if (this.state.editedOverallError === '') return
    setTimeout(() => {
      if (this.state.editedCommentBody.length >= 1) {
        this.setState({ editedCommentBodyError: '', editedOverallError: '' })
      }
    }, 100)
  }
  toggleOverlay = () => {
    this.setState({ showPaymentModal: false }, () => {
      setTimeout(() => {
        this.setState({ transactionType: '', pageOverlayActive: true })
      }, 400)
    })
  }
  submitCommentUpdate = (e, comment) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (!this.props.userDatabaseData || !comment || !localStorageAuth || !this.props.auth) {
      return (
        globals.toastError(toast, `Missing information to update comment.`),
        this.setState({ showPaymentModal: false }),
        this.props.history.push(`/`)
      )
    }
    // If no changes have been made
    if (this.state.editedCommentBody === comment.body) {
      return (
        globals.toastError(toast, `No changes have been made.`),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        })
      )
    }
    // If it is not the user
    if (this.props.auth.id !== comment.commentCreatorHandCashId) {
      return (
        globals.toastError(toast, `Unable to edit other's comments!`),
        this.setState({ showPaymentModal: false }),
        this.props.history.push(`/`)
      )
    }
    const validationReturnsTrue = this.editedCommentValidationChecks()
    if (!validationReturnsTrue) {
      return (
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        }),
        globals.toastError(toast, 'Fix error and try again')
      )
    }
    this.toggleOverlay()
    this.setState({ nonPaymentOverlay: true })
    // Replacing comment with new comment body
    delete comment.body
    comment.body = this.state.editedCommentBody
    let hasIframeInComment = false
    if (comment.body.includes('<iframe')) hasIframeInComment = true
    comment.hasIframeInComment = hasIframeInComment
    // Need to remove empty paragraphs
    const checkIfEmptyParagraph = comment.body.endsWith(`<p><br></p>`)
    if (checkIfEmptyParagraph) {
      do comment.body = comment.body.slice(0, -11)
      while (comment.body.endsWith(`<p><br></p>`))
    }
    comment.body = globals.checkForLinksToAdd(comment.body)
    // updating comment
    API.submitCommentUpdate(comment)
      .then(res => {
        const updatedComment = res.data
        const index = this.props.index;
        // Updating the comments array
        // So where does my t2 comments array exist?
        this.props.updateT2CommentsArray(updatedComment.body, index)
        // This ugly setTimeout sequence is needed because the pageOverlay doesn't close otherwise
        setTimeout(() => {
          this.setState({
            pageOverlayActive: false,
            updateCommentOpacity: 'opacity-0',
            nonPaymentOverlay: false
          },
            // 2nd setTimeout function
            () => {
              setTimeout(() => {
                this.setState({
                  editedCommentBody: '',
                  updateCommentOpacity: 'opacity-100',
                  showCommentEditor: false,
                })
              }, 400)
            })
          iframeUpdate()
        }, 400)
        globals.toastSuccess(toast, `You have successfully updated your comment!`);
      })
      .catch(err => {
        console.error(err)
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        }, 400)
        globals.toastError(toast, err.response.data.message)
      })
  }
  editedCommentValidationChecks = () => {
    let allChecksValid;
    // commentBody Validation
    let commentBodyIsValid;
    if (this.state.editedCommentBody.length <= 1) {
      this.setState({
        editedCommentBodyError: '*Your comment could use some material*'
      })
      commentBodyIsValid = false
    } else commentBodyIsValid = true;

    // This allChecksValid will return true or false to initiate the function in handleSubmitPost
    if (!commentBodyIsValid) {
      allChecksValid = false
      this.setState({ editedOverallError: `*Psst, fix any errors above and try again*` })
    }
    else allChecksValid = true;
    return allChecksValid;
  }
  displayTierThreeComments = (parentCommentId) => {
    // If the array is empty, we want to call this function. If it is not, just display. No extra calls.

    // So here is where we grab the comments and will need to set the # of comments to fetch in the backend.
    // Luckily, when we append to the array, we have a predefined number to scroll. And then if it's less than that
    // and there aren't any more to scroll, it'll just say something. We'll have the same deal when the exact amount
    // is the array amount though. But that's especially ok with comments because you don't go "back" with them.
    if (this.state.tierThreeComments.length === 0) {
      this.setState({ tierThreeVisible: true })
      API.fetchTierThreeComments(parentCommentId, this.cancelToken.token)
        .then(res => {
          let commentsArray = res.data
          if (this.props.auth && this.props.auth.id) commentsArray = this.checkCommentsForUserThumbs(commentsArray);
          this.setNewTierThreeComments(commentsArray)
          setTimeout(() => {
            iframeUpdate();
          }, 750)
        })
        .catch(error => {
          console.error(error);
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to locate comments');
        });
    }
    else {
      this.setTierThreeVisible()
    }
  }
  checkCommentsForUserThumbs = (commentsArray) => {
    let updatedCommentsArray = commentsArray;
    for (let i = 0; i < updatedCommentsArray.length; i++) {
      if (updatedCommentsArray[i].thumberHandCashIds) {
        updatedCommentsArray[i].thumberHandCashIds.forEach(id => {
          if (id === this.props.auth.id) {
            updatedCommentsArray[i].userAlreadyThumbsComment = true;
          }
        })
      }
    }
    return updatedCommentsArray;
  }
  setNewTierThreeComments = (comments) => {
    this.setState({ tierThreeComments: comments }, () => {
      this.setState({ tierThreeIsLoaded: true, }, () => {
        setTimeout(() => {
          this.setState({ tierThreeOpacity: 'opacity-100' })
        }, 100)
      })
    })
  }
  setTierThreeComments = (comments) => {
    this.setState({ tierThreeComments: comments }, () => {
      this.setState({ tierThreeIsLoaded: true }, () => {
        this.setTierThreeVisible()
      })
    })
  }
  // For tier two comments
  setTierThreeVisible = () => {
    if (this.state.tierThreeVisible === true) {
      this.setState({ tierThreeOpacity: 'opacity-0' }, () => {
        setTimeout(() => {
          this.setState({ tierThreeVisible: false })
        }, 500)
      })
    }
    else {
      this.setState({ tierThreeVisible: true }, () => {
        setTimeout(() => {
          this.setState({ tierThreeOpacity: 'opacity-100' })
        }, 100)
      })
    }
  }
  // Tier 3 Thumbs
  commentThumbsValidation = (index) => {
    let thumbsValidated = { valueReturned: true, errorMessage: '', }
    if (this.props.auth.id === this.state.tierThreeComments[index].commentCreatorHandCashId) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Cannot Thumbs yourself!!`;
      return thumbsValidated
    }
    if (this.state.tierThreeComments[index].thumberHandCashIds.includes(this.props.auth.id)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Already Thumbs'ed comment!`;
      return thumbsValidated;
    };
    if ((this.state.thumbsAmount < globals.thumbsCostMin) || (this.state.thumbsAmount > globals.thumbsCostMax)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Thumbs value must be between $${globals.thumbsCostMin.toFixed(2)} and $${globals.thumbsCostMax.toFixed(2)}.`;
      return thumbsValidated;
    };
    return thumbsValidated;
  }
  addThumbsToTierThreeComment = (e, index) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (this.props.auth?.id && localStorageAuth) {
      let thumbsValidated = this.commentThumbsValidation(index);
      if (!thumbsValidated.valueReturned) {
        return (
          globals.toastError(toast, thumbsValidated.errorMessage),
          this.setState({ showPaymentModal: false }, () => {
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400);
          })
        )
      }
      localStorageAuth = JSON.parse(localStorageAuth);
    };
    const commentId = this.state.tierThreeComments[index]._id;
    if (this.props.auth?.id && localStorageAuth?.authToken) {
      const thumbsData = {
        thumbType: 'comment',
        valueOfThumbs: this.state.thumbsAmount,
        postTitle: this.props.post.title,
        postSlug: this.props.post.slug,
        postId: this.props.post.id,
        postCreatorHandle: this.props.post.postCreatorHandle,
        postCreatorPaymail: this.props.post.postCreatorPaymail,
        postCreatorAvatarURL: this.props.post.postCreatorAvatarURL,
        postCreatorHandCashId: this.props.post.postCreatorHandCashId,
        commentCreatorHandle: this.state.tierThreeComments[index].commentCreatorHandle,
        commentCreatorHandCashId: this.state.tierThreeComments[index].commentCreatorHandCashId,
        commentCreatorAvatarURL: this.state.tierThreeComments[index].commentCreatorAvatarURL,
        thumbsCommentId: commentId,
        actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      }
      this.toggleOverlay();
      API.addThumbsToTierThreeComment(commentId, thumbsData)
        .then(res => {
          // Database response objects
          const commentUpdate = res.data.commentUpdate
          const thumbGiverUpdate = res.data.thumbGiverUpdate;
          const thumbsDoc = res.data.createThumbs
          // Updating comment data
          this.updateCommentThumbsData(index, commentUpdate)
          // Updating logged-in user data
          const totalThumbsGiven = thumbGiverUpdate.totalThumbsGiven
          const totalThumbValueGiven = thumbGiverUpdate.totalThumbValueGiven
          const totalValueSent = thumbGiverUpdate.totalValueSent
          this.props.storeUserDatabaseData({ type: 'updateForCommentThumbs', totalThumbsGiven, totalThumbValueGiven, totalValueSent, thumbsDoc })
          // Conclusion of function
          globals.toastSuccess(toast, 'Thumbs up success!');
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
        })
        .catch(error => {
          console.error(error);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
          globals.toastError(toast, error.response.data.message);
        });
    }
    // If not authorized for some reason
    else {
      globals.toastError(toast, 'Unable to add thumbs');
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
    }
  }
  updateCommentThumbsData = (index, updatedCommentData) => {
    let updatedArray = this.state.tierThreeComments;
    updatedArray[index] = updatedCommentData
    updatedArray[index].userAlreadyThumbsComment = true;
    this.setState({ tierThreeComments: updatedArray })
  }
  updateT3CommentsArray = (commentBody, index) => {
    if (!index || !commentBody) return
    let updatingTierThreeComments = this.state.tierThreeComments;
    updatingTierThreeComments[index].body = commentBody;
    this.setState({ tierThreeComments: updatingTierThreeComments })
  }
  // Comment Thumbs Array Modal
  toggleCommentThumbsArrayModal = (e, arrayType) => {
    e.preventDefault();
    if (arrayType !== 'COMMENT_THUMBS_ARRAY') return
    // Here we need to check if there is existing already, or not. As well as if noMoreResults
    if (this.state.noMoreResultsCommentThumbs || this.state.commentThumbsArray.length > 0) {
      return (
        this.setState({
          arrayModalOpacity: 'opacity-0',
          showArrayModal: true,
          modalArrayType: 'COMMENT_THUMBS_ARRAY',
          arrayModalIsLoading: false
        }),
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      )
    }
    this.setState({
      modalArrayType: 'COMMENT_THUMBS_ARRAY',
      showArrayModal: true,
      commentThumbsArrayIsLoading: false,
      arrayModalOpacity: 'opacity-0',
      arrayModalIsLoading: true
    })
    API.getCommentThumbers(this.props.comment._id, this.state.pageNumberCommentThumbs, this.cancelToken.token)
      .then(res => {
        let thumbsArray = res.data.thumbers
        // Check length
        if (thumbsArray.length === 0) {
          this.setState({ noMoreResultsCommentThumbs: true, commentThumbsArrayIsLoading: false, arrayModalIsLoading: false })
        }
        // If it's less than 20
        else if (thumbsArray.length < globals.fetchTwenty) {
          const updatedArray = this.firstLoadUpdateArray(thumbsArray)
          this.setState({ commentThumbsArray: updatedArray, arrayModalIsLoading: false }, () => {
            this.setState({ noMoreResultsCommentThumbs: true, commentThumbsArrayIsLoading: false })
          })
        }
        else {
          const updatedArray = this.firstLoadUpdateArray(thumbsArray)
          this.setState({ commentThumbsArray: updatedArray, arrayModalIsLoading: false }, () => {
            this.setState({ noMoreResultsCommentThumbs: false, commentThumbsArrayIsLoading: false })
          })
        }
        // Conclusion of function is to add opacity
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          this.setState({
            showArrayModal: false,
            commentThumbsArrayIsLoading: false,
            arrayModalOpacity: 'opacity-0'
          })
        }, 300)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve data');
      });
  }
  // This update adds the ref and the lastItem properties
  firstLoadUpdateArray = (dataArray) => {
    let addingToArray = dataArray;
    if (dataArray.length === globals.fetchTwenty) {
      let itemFive = addingToArray[4];
      itemFive.ref = true;
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    return addingToArray;
  }
  updateExistingArray = (newResults, arrayType) => {
    // arrayType is this.state.TYPEOFARRAY
    // newResults gets added to that array and assigned properties, when applicable.
    let addingToArray = arrayType
    addingToArray.forEach(arrayItem => {
      arrayItem.ref = false
      arrayItem.lastItem = false
    })
    newResults.map(item => {
      return addingToArray.push(item)
    })
    if (newResults.length === globals.fetchTwenty) {
      let itemFive = addingToArray[addingToArray.length - 15]
      itemFive.ref = true
      addingToArray.splice(addingToArray.length - 15, 1, itemFive)
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    // So this takes the index of the addingToArray, grabs one item, and replaces it with lastItem.
    addingToArray.splice(addingToArray.length - 1, 1, lastItem)
    return addingToArray;
  }
  closeArrayModal = () => {
    this.setState({ arrayModalOpacity: 'opacity-0' })
    setTimeout(() => {
      this.setState({ showArrayModal: false })
    }, 300)
  }
  arrayListPaginationLogic = (node) => {
    // Disconnects the current ref observer when it hits.
    if (this.modalObserver.current) this.modalObserver.current.disconnect()
    // Creates a new observer and in the grabMoreComments function, it updates the div where the refCallback lands on
    this.modalObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !this.state.noMoreResultsCommentThumbs) {
        this.paginateCommentThumbsArray()
      }
    })
    // Observe the current node.
    if (node) this.modalObserver.current.observe(node)
  }
  refCallbackForArrayList = this.arrayListPaginationLogic.bind(this); // <- this is essentially `useCallback`
  paginateCommentThumbsArray = () => {
    this.setState({ commentThumbsArrayIsLoading: true, pageNumberCommentThumbs: this.state.pageNumberCommentThumbs + 1 }, () => {
      API.getCommentThumbers(this.props.comment._id, this.state.pageNumberCommentThumbs, this.cancelToken.token)
        .then(res => {
          let thumbsArray = res.data.thumbers
          if (thumbsArray.length === 0) {
            // Update existing array so there aren't any refs.
            let updatedArray = this.state.commentThumbsArray
            updatedArray.forEach(item => { item.ref = false })
            this.setState({
              commentThumbsArrayIsLoading: false,
              noMoreResultsCommentThumbs: true,
              commentThumbsArray: updatedArray
            })
          }
          else if (thumbsArray.length < globals.fetchTwenty) {
            // Case if the search has some results, but not all.
            const updatedArray = this.updateExistingArray(thumbsArray, this.state.commentThumbsArray)
            this.setState({ commentThumbsArray: updatedArray }, () => {
              this.setState({ commentThumbsArrayIsLoading: false, noMoreResultsCommentThumbs: true })
            })
          }
          // Case if max items retrieved
          else {
            const updatedArray = this.updateExistingArray(thumbsArray, this.state.commentThumbsArray)
            this.setState({ commentThumbsArray: updatedArray }, () => {
              this.setState({ commentThumbsArrayIsLoading: false })
            })
          }
        })
        .catch(error => {
          console.error(error);
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to find content');
        });
    })
  }
  toggleConfirmCancelModal = (e) => {
    e.preventDefault();
    if (this.state.showConfirmCancelModal === false) {
      this.setState({ showConfirmCancelModal: true })
    }
    else this.setState({ showConfirmCancelModal: false })
  }
  handleThumbsAmountChange = (e) => {
    // This ensures 2 decimal places
    this.setState({ thumbsAmount: e.target.value });
    let source = document.querySelector('.updatingTipValue')
    source.addEventListener('change', () => {
      source.value = parseFloat(source.value).toFixed(2)
    });
  }
  toggleTipModal = (e, transactionType, index) => {
    e.preventDefault();
    if (this.state.showTipModal === false) {
      this.setState({ showTipModal: true, transactionType: transactionType, commentIndexNumber: index })
    }
    else {
      this.setState({ showTipModal: false, transactionType: '' })
    }
  }
  closeTipModal = (e) => {
    e.preventDefault();
    this.setState({ showTipModal: false, transactionType: '' })
  }
  closeConfirmCancelModal = (e) => {
    e.preventDefault();
    this.setState({ showConfirmCancelModal: false })
  }
  render() {
    return (
      <>
        <TierTwoComments
          {...this.state}
          index={this.props.index}
          tierOneCommentId={this.props.tierOneCommentId}
          tierOneCommentIndex={this.props.tierOneCommentIndex}
          comment={this.props.comment}
          tierTwoCommentOpacity={this.props.tierTwoCommentOpacity}
          toggleEditor={this.toggleEditor}
          handleTierThreeCommentChange={this.handleTierThreeCommentChange}
          togglePaymentModal={this.togglePaymentModal}
          auth={this.props.auth}
          userDatabaseData={this.props.userDatabaseData}
          goToUserProfilePage={this.props.goToUserProfilePage}
          toggleCommentEditor={this.toggleCommentEditor}
          handleEditCommentChange={this.handleEditCommentChange}
          // T3
          displayTierThreeComments={this.displayTierThreeComments}
          handleTierThreeCommentSubmit={this.handleTierThreeCommentSubmit}
          addAndFindT3Comment={this.addAndFindT3Comment}
          addThumbsToTierThreeComment={this.addThumbsToTierThreeComment}
          post={this.props.post}
          postCreatorHandle={this.props.postCreatorHandle}
          storeUserDatabaseData={this.props.storeUserDatabaseData}
          history={this.props.history}
          updateT3CommentsArray={this.updateT3CommentsArray}
          setTierThreeComments={this.setTierThreeComments}
          updateTier1CommentsAndPostNumbersAfterT3Creation={this.props.updateTier1CommentsAndPostNumbersAfterT3Creation}
          updateTier2CommentsData={this.props.updateTier2CommentsData}
          toggleCommentThumbsArrayModal={this.toggleCommentThumbsArrayModal}
          toggleConfirmCancelModal={this.toggleConfirmCancelModal}
          toggleTipModalForT3Thumbs={this.toggleTipModal}
          toggleTipModalForT2Thumbs={this.props.toggleTipModalForT2Thumbs}
        />
        {this.state.pageOverlayActive
          ? <OverlayLoadingIcon nonPaymentOverlay={this.state.nonPaymentOverlay} />
          : <></>
        }
        {this.props.auth?.id
          ?
          <>
            <PaymentModal
              showPaymentModal={this.state.showPaymentModal}
              toggleModal={this.togglePaymentModal}
              triggerPaymentFunction={
                this.state.transactionType === 'CREATE_COMMENT'
                  ? this.handleTierThreeCommentSubmit
                  : this.state.transactionType === 'UPDATE_COMMENT'
                    ? this.submitCommentUpdate
                    : this.state.transactionType === 'THUMBS_FOR_COMMENT'
                      ? this.addThumbsToTierThreeComment
                      : null
              }
              auth={this.props.auth}
              transactionType={this.state.transactionType}
              postTitle={this.props.title}
              handCashPostCreator={this.props.postCreatorHandle}
              thumbsCost={this.state.thumbsAmount}
              comment={this.props.comment}
              commentsArray={this.state.tierThreeComments}
              commentIndex={this.state.commentIndexNumber}
              costToCommentIsFree={this.state.costToCommentIsFree}
              category={this.props.post.category}
              userDatabaseData={this.props.userDatabaseData}
            />
            <TipModal
              recipientHandCashHandle={this.props.comment.commentCreatorHandle}
              showModal={this.state.showTipModal}
              closeModal={this.closeTipModal}
              confirmPaymentModal={this.togglePaymentModal}
              handleChange={this.handleThumbsAmountChange}
              amount={this.state.thumbsAmount}
              transactionType={this.state.transactionType}
              min={globals.thumbsCostMin}
              max={globals.thumbsCostMax}
              step={`0.01`}
              isThumbsModal={true}
            />
            <ConfirmCancelModal
              showConfirmCancelModal={this.state.showConfirmCancelModal}
              closeConfirmCancelModal={this.closeConfirmCancelModal}
              confirmCancel={this.state.showCommentEditor === true ? this.toggleCommentEditor : this.toggleEditor}
            />
          </>
          : <></>
        }
        {this.state.commentThumbsArray.length > 0
          ? <ArrayModal
            closeArrayModal={this.closeArrayModal}
            showModal={this.state.showArrayModal}
            modalArrayType={'COMMENT_THUMBS_ARRAY'}
            refCallbackForArrayList={this.refCallbackForArrayList}
            goToUserProfilePage={this.props.goToUserProfilePage}
            modalDataArray={this.state.commentThumbsArray}
            noMoreResults={this.state.noMoreResultsCommentThumbs}
            arrayIsLoading={this.state.commentThumbsArrayIsLoading}
            arrayModalIsLoading={this.state.arrayModalIsLoading}
            arrayModalOpacity={this.state.arrayModalOpacity}
          />
          : null
        }
      </>
    )
  }
}

export default TierTwoCommentsContainer;