import React from 'react';
import CodeEditor from './CodeEditor';
import DescriptionInput from './DescriptionInput';
import TagsInput from './TagsInput'
import EditorButtons from './EditorButtons'
import TitleInput from './TitleInput'
import QuillEditorForNonMembers from '../../components/Editors/QuillEditorForNonMembers'
import QuillEditorForMembers from '../../components/Editors/QuillEditorForMembers'

const EditorPageForCode = props => {
  const {
    handleEditorChange,
    handleChange,
    freeContent,
    categoryNameDisplay,
    isNewPost,
    editorOpacity,
    editorIsLoaded,
    goBackToCategoryPostList,
    toggleCodeEditor,
    codeEditorToggleText,
    handleCodeEditorChange,
    codeEditorContent,
    onSelectLanguage,
    onSelectCodeTheme,
    languageDropdownValue,
    themeDropdownValue,
    themeforCodeEditor,
    codeEditorLanguage,
    enableCodeEditor,
    togglePaymentModal,
    saveDraft,
    userDatabaseData,
    // Tag stuff
    tagInput,
    onTagChange,
    onKeyUp,
    onKeyDown,
    tags,
    removeTag,
    tagInputClass,
    addTagOnClick,
    changeQuillTheme,
    quillTheme
  } = props;
  return (
    <>
      {!editorIsLoaded
        ?
        <> </>
        :
        <main id='main-content' className={`flex -mt-2 rounded-lg md:inline-flex mb-4 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition `}>
          <div className={`w-full rounded px-1 ${editorOpacity} transition-to-opacity`}>
            <div className={`pb-3 mb-2 rounded-lg transition first-text`}>
              <form>
                {/* Title Input */}
                <TitleInput
                  categoryNameDisplay={categoryNameDisplay}
                  handleChange={handleChange}
                  title={props.title}
                  titleError={props.titleError}
                  noDescription={false}
                />
                {/* Description Input */}
                <DescriptionInput
                  charCounterClass={props.charCounterClass}
                  description={props.description}
                  handleChange={handleChange}
                  descriptionError={props.descriptionError}
                />
                {/* Body/Content Input */}
                <div className={`sm:mx-2 mb-2 ${quillTheme === 'snow' ? `mt-6`: ''}`}>
                  <div className={`rounded-lg text-gray-900 mt-3px`}>
                    <button onClick={(e) => changeQuillTheme(e)} 
                      className={`font-header gradient-background-twelve shadow-effect-seven ${quillTheme === 'snow' ?`float-right mr-3 mt-3`: 'mb-3'}
                      eighth-text button-ripple transition rounded cursor-pointer px-1 category-hover text-xs mx-1 `}>
                      Change Editors
                    </button>
                    {userDatabaseData?.membership
                      ? <QuillEditorForMembers  quillTheme={quillTheme} handleEditorChange={handleEditorChange} content={freeContent} selectedPlaceholder={"Tap here to begin"} />
                      : <QuillEditorForNonMembers  quillTheme={quillTheme} handleEditorChange={handleEditorChange} content={freeContent} selectedPlaceholder={"Tap here to begin"} />
                    }
                  </div>
                  {props.freeContentError
                    ?
                    <div className={`text-center font-header text-xs mt-1 text-red-600`}>
                      {props.freeContentError}
                    </div>
                    : null
                  }
                </div>
                {/* CodeEditor Button */}
                <button className={`font-header gradient-background-twelve shadow-effect-seven transition
                  eighth-text button-ripple  rounded cursor-pointer py-px px-2 category-hover text-sm mx-2 
                  ${enableCodeEditor ? `my-3` : `mt-3`}
                  `}
                  onClick={(e) => toggleCodeEditor(e)}>
                  {codeEditorToggleText}
                </button>
                {/* CodeEditor Input */}
                {props.enableCodeEditor === true
                  ?
                  <>
                    <CodeEditor
                      handleCodeEditorChange={handleCodeEditorChange}
                      codeEditorContent={codeEditorContent}
                      onSelectLanguage={onSelectLanguage}
                      onSelectCodeTheme={onSelectCodeTheme}
                      languageDropdownValue={languageDropdownValue}
                      themeDropdownValue={themeDropdownValue}
                      themeforCodeEditor={themeforCodeEditor}
                      codeEditorLanguage={codeEditorLanguage}
                    />
                    {props.codeEditorError
                      ?
                      <div className={`text-center font-header text-xs mt-1 text-red-600`}>
                        {props.codeEditorError}
                      </div>
                      : null
                    }
                  </>
                  : <></>
                }
                {/* Tags Input */}
                <TagsInput
                  tagInputClass={tagInputClass}
                  tagInput={tagInput}
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  onTagChange={onTagChange}
                  addTagOnClick={addTagOnClick}
                  tags={tags}
                  removeTag={removeTag}
                />
                <hr className={`sixth-border outsetBorder border mb-2 mx-2`}></hr>
                {/* Buttons */}
                <EditorButtons
                  isNewPost={isNewPost}
                  togglePaymentModal={togglePaymentModal}
                  savingDraft={props.savingDraft}
                  saveDraft={saveDraft}
                  goBackToCategoryPostList={goBackToCategoryPostList}
                  overallError={props.overallError}
                  toggleConfirmCancelModal={props.toggleConfirmCancelModal}
                />
              </form>
            </div>
          </div>
        </main>
      }
    </>
  )
}

export default EditorPageForCode;