import { createSlice } from '@reduxjs/toolkit'

const initState = {
  postsOpacity: 'opacity-0',
  categoryOpacity: 'opacity-0',
  postDetailOpacity: 'opacity-0',
  faqOpacity: 'opacity-0',
  editorOpacity: 'opacity-0',
  profilePageOpacity: 'opacity-0',
  rankingPageOpacity: 'opacity-0',
  massTippingOpacity: 'opacity-0',
  membershipOpacity: 'opacity-0',
  notificationOpacity: 'opacity-0',
  privacyOpacity: 'opacity-0',
  searchPageOpacity: 'opacity-0'
};

const opacitySlice = createSlice({
  name: 'opacity',
  initialState: initState,
  reducers: {
    opacityChange(state, action) {
      switch (action.payload) {
        // MainPageContainer PostList
        case 'addPostListOpacity':
          state.postsOpacity = 'opacity-100'
          return
        case 'removePostListOpacity':
          state.postsOpacity = 'opacity-0'
          return
        // MainPageContainer Category
        case 'addCategoryOpacity':
          state.categoryOpacity = 'opacity-100'
          return
        case 'removeCategoryOpacity':
          state.categoryOpacity = 'opacity-0'
          return
        // PostDetailPageContainer
        case 'addPostDetailOpacity':
          state.postDetailOpacity = 'opacity-100'
          return
        case 'removePostDetailOpacity':
          state.postDetailOpacity = 'opacity-0'
          return
        // FAQPage
        case 'addFaqOpacity':
          state.faqOpacity = 'opacity-100'
          return
        case 'removeFaqOpacity':
          state.faqOpacity = 'opacity-0'
          return
        // EditorPageContainer
        case 'addEditorOpacity':
          state.editorOpacity = 'opacity-100'
          return
        case 'removeEditorOpacity':
          state.editorOpacity = 'opacity-0'
          return
        // ProfilePageContainer
        case 'addProfilePageOpacity':
          state.profilePageOpacity = 'opacity-100'
          return
        case 'removeProfilePageOpacity':
          state.profilePageOpacity = 'opacity-0'
          return
        // RankingContainer
        case 'addRankingPageOpacity':
          state.rankingPageOpacity = 'opacity-100'
          return
        case 'removeRankingPageOpacity':
          state.rankingPageOpacity = 'opacity-0'
          return
        // DonationContainer
        case 'addMassTippingOpacity':
          state.massTippingOpacity = 'opacity-100'
          return
        case 'removeMassTippingOpacity':
          state.massTippingOpacity = 'opacity-0'
          return
        // Membership
        case 'addMembershipOpacity':
          state.membershipOpacity = 'opacity-100'
          return
        case 'removeMembershipOpacity':
          state.membershipOpacity = 'opacity-0'
          return
        // Notification
        case 'addNotificationOpacity':
          state.notificationOpacity = 'opacity-100'
          return
        case 'removeNotificationOpacity':
          state.notificationOpacity = 'opacity-0'
          return
        // Privacy Page
        case 'addPrivacyOpacity':
          state.privacyOpacity = 'opacity-100'
          return
        case 'removePrivacyOpacity':
          state.privacyOpacity = 'opacity-0'
          return
        // Search Page
        case 'addSearchPageOpacity':
          state.searchPageOpacity = 'opacity-100'
          return
        case 'removeSearchPageOpacity':
          state.searchPageOpacity = 'opacity-0'
          return
        default:
          state = state
          return
      }
    }
  }
})

export const { opacityChange } = opacitySlice.actions;
export default opacitySlice.reducer;