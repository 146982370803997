import React from 'react'
import Modal from "react-modal";
import { Icon } from '@iconify/react';
import closeCircleO from '@iconify/icons-vaadin/close-circle-o';
import { IntroText } from '../../config/globals';

const IntroModal = props => {
  const { showIntroModal, closeIntroModal } = props;
  const styles = {
    base: `array-modal-content first-text gradient-background-four shadow-effect-five
    mt-6  w-full sm:w-9/12 xl:w-1/2 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showIntroModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        className={styles}
        overlayClassName="array-modal-overlay"
        onRequestClose={closeIntroModal}
        closeTimeoutMS={300}
        contentLabel={'HandCash Login Modal'}
      >
        <div className={`text-center pb-3`}>
            <div className={`flex justify-end`}>
            <button onClick={closeIntroModal} className={`float-right transition rounded-xl 
            shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
            <Icon icon={closeCircleO} height={18} width={18} />
          </button>
</div>
          <div className={`teko-font logo-style second-text text-5xl sm:text-6xl -mt-6 `}>
            Tip<span className={`eleventh-text`}>Stampers</span>.com
          </div>
          <div className={`text-sm sm:text-lg -mt-3 logo-style mb-6`}>
            Digital Content Publishing, with 100% creator cut
          </div>
          <div className='i-container mb-1 mx-1'>
            <iframe className='standard-iframe rounded-lg media-shadow' allowFullScreen={true} title="https://www.youtube.com/embed/Fgp4Qf73SF0?showinfo=0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              src="https://www.youtube.com/embed/0bFMKYpc-w8?showinfo=0" />
          </div> 
          {/* HandCash login if they're not logged in already */}
          {/* {auth?.id
            ? <></>
            :
            <div className={`mt-8`}>
              <button
                className={`font-header connectButtonNoImage`}
                onClick={(e) => authenticateWithHandCash(e)}
              >
                <span className={`text-sm sm:text-base`}>
                  Get started with HandCash
                </span>
              </button>
            </div>
          } */}
          <div className={`text-left`}>
            <IntroText />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default IntroModal;
