import React, { Component } from 'react'

class NoMatch extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push(`/`)
    }, 3000)
  }
  render() {
    return (
      <>
        <main className={`flex md:inline-flex mt-2 mx-auto w-full sm:max-w-3xl md:max-w-4xl`} >
          <div className={`w-full mb-3 rounded px-1 text-center text-light-gray bg-darkest-gray`}>
            <h2 className={`my-2 text-center`}>Page Not Found</h2>
            <hr className={`hidden md:block border-medium-gray border-2 outsetBorder mx-1 mb-3`} />
            <div className='text-sm pb-3'>
              Redirecting to Home Page momentarily...
            </div>
          </div>
        </main>
      </>
    )
  }
}

export default NoMatch;
