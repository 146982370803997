import React from 'react'
import { Icon } from '@iconify/react';
import thumbsUp from '@iconify/icons-fa-solid/thumbs-up';

const CommentThumbsComponent = (props) => {
  const { auth, toggleCommentModal, comment, index, numThumbs } = props;
  return (
    <>
      {auth?.id
        ?
        // If it is their own comment
        auth.id === comment.commentCreatorHandCashId
          ?
          <>
            <div className={`flex items-center cursor-pointer thumb-tooltip`} data-tooltip="Thumbing yourself?">
              <div className={`mr-2px`}>
                <Icon icon={thumbsUp} height={12} width={12} />
              </div>
              <div className={`ml-px`}>{numThumbs}</div>
            </div>
          </>
          :
          // If they've already thumbsed
          comment.userAlreadyThumbsComment
            ?
            <>
              <div className={`flex items-center cursor-pointer thumb-tooltip`} data-tooltip="Already Thumbsed" >
                <div className={`mr-2px`}>
                  <Icon icon={thumbsUp} height={12} width={12} />
                </div>
                <div className={`ml-px`}>{numThumbs}</div>
              </div>
            </>
            :
            // If they're logged in and everything checks out
            <>
              <button onClick={(e) => toggleCommentModal(e, 'THUMBS_FOR_COMMENT', index)} className={`fifth-hover flex items-center thumb-tooltip`} data-tooltip="Click to Thumbs" >
                <div className={`mr-2px`}>
                  <Icon icon={thumbsUp} height={12} width={12} />
                </div>
                <div className={`ml-px`}>{numThumbs}</div>
              </button>
            </>
        :
        <>
          <div className={`cursor-pointer items-center thumb-tooltip flex`} data-tooltip="Login Required">
            <div className={`mr-2px`}>
              <Icon icon={thumbsUp} height={12} width={12} />
            </div>
            <div className={`ml-px`}>{numThumbs}</div>
          </div>
        </>
      }
    </>
  )
}

export default CommentThumbsComponent;
