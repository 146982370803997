import React from 'react';

const DescriptionInput = props => {
  const { charCounterClass, description, handleChange, descriptionError } = props
  // const tx = document.getElementsByClassName("description-autosize");
  // for (let i = 0; i < tx.length; i++) {
  //   tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
  //   tx[i].addEventListener("input", OnInput, false);
  // }

  // function OnInput() {
  //   this.style.height = 0;
  //   this.style.height = (this.scrollHeight) + "px";
  // }
  return (
    <>
      <div className='sm:mx-2 mt-2 mb-2'>
        <div className={`flex`}>
          <div className={`flex flex-1 justify-end self-end mr-1 pb-2`}>
            <div className={`text-xs ${charCounterClass}`}>Count: {description.length}</div>
          </div>
        </div>
        <div className={`mt-2 transition text-gray-900 first-text custom-input-container`} data-name="Description">
          <div className={`rounded-xl shadow-effect-six`}>
            <textarea
              id='description'
              aria-label="Enter Description (Optional)"
              placeholder='*Optional* with 280 character limit'
              className={`shadow-effect-ten transition text-gray-900 w-full rounded-xl px-2 pt-4 pb-2 -mb-7px
                outline-none border resize-none text-sm description-autosize`
              }
              rows='3'
              value={description}
              onChange={handleChange}>
            </textarea>
          </div>
        </div>
        {descriptionError
          ?
          <div className={`text-center font-header mt-1 text-xs text-red-600`}>
            {descriptionError}
          </div>
          : null
        }
      </div>
    </>
  )
}

export default DescriptionInput