import React, { Component } from 'react';
import Moment from 'react-moment';
import parse from 'html-react-parser';
import AddComment from './AddComment';
import TierTwoCommentsContainer from './TierTwoCommentsContainer';
import CommentThumbsComponent from './CommentThumbsComponent';
import SkeletonLoaderForPosts from '../Widgets/SkeletonLoaderForPosts';
import { Icon } from '@iconify/react';
import commentsIcon from '@iconify/icons-fa-regular/comments';
import createOutline from '@iconify-icons/ion/create-outline';
import thumbsUp from '@iconify/icons-fa-solid/thumbs-up';
import caretDown20Filled from '@iconify/icons-fluent/caret-down-20-filled';

class CommentListItem extends Component {

  state = {
    showMoreOfListComment: false,
    fading: '',
    lineClamp: 'line-clamp',
  }
  componentDidMount() {
    if (this.props.post.category.includes(this.props.globals.listCategoryArray)) {
      this.checkForOverflow()
    }
  }
  checkForOverflow() {
    const expandableElement = document.getElementById(`item-list-${this.props.index}`)
    if (!expandableElement || expandableElement === null) return
    if (expandableElement.classList.contains('expanded')) return
    const expandableText = expandableElement.querySelector('[data-expand-list-comment]')
    const overflowing = expandableText.scrollHeight > expandableText.clientHeight
    expandableElement.dataset.overflow = overflowing
    if (overflowing === false) {
      this.setState({ showMoreOfListComment: false })
    }
    if (overflowing) this.setState({ fading: 'fading' })
  }
  viewListCommentDetails = (e) => {
    const expandableElement = document.getElementById(`item-list-${this.props.index}`)
    if (!this.state.showMoreOfListComment) {
      expandableElement.classList.toggle('expanded')
      this.setState({ showMoreOfListComment: true, fading: '' })
    }
    else {
      expandableElement.classList.remove('expanded')
      this.setState({ showMoreOfListComment: false })
    }
  }
  addOrRemoveLineClamp = (e) => {
    e.preventDefault()
    if (this.state.lineClamp === 'line-clamp') this.setState({ lineClamp: '' })
    if (this.state.lineClamp === '') this.setState({ lineClamp: 'line-clamp' })
  }
  render() {
    const {
      comment,
      id,
      index,
      postId,
      editorVisible,
      addExpandedClass,
      updatePostAndCommentDataAfterT2Comment,
      toggleEditor,
      handleTierTwoCommentChange,
      handleTierTwoCommentSubmit,
      tierTwoComment,
      tierTwoVisible,
      tierTwoComments,
      displayTierTwoComments,
      addAndFindComment,
      numChildComments,
      isLoaded,
      tierTwoCommentOpacity,
      refCallback,
      moreCommentsLoading,
      noMoreComments,
      levelTwoCommentOpacity,
      togglePaymentModal,
      title,
      postCreatorHandle,
      auth,
      userDatabaseData,
      goToUserProfilePage,
      // Editing Comments
      showCommentEditor,
      handleEditCommentChange,
      editedCommentBody,
      toggleCommentEditor,
      editedCommentBodyError,
      editedOverallError,
      updateCommentOpacity,
      toggleCommentThumbsArrayModal,
      globals,
      toggleConfirmCancelModal,
      toggleTipModalForT1Thumbs,
      listOrCommentView
    } = this.props;
    return (
      <>
        <div id={id} className={`rounded-xl first-text shadow-effect-one post-detail-background
          ${(this.props.post.category.includes(globals.listCategoryArray) && listOrCommentView === 'listView')
            ? `p-1 mb-1`
            : `${showCommentEditor ? `p-0` : `px-2 pt-2`} mb-2 `
          }
        `}>
          <div className={`${updateCommentOpacity} transition-to-opacity`}>
            {!showCommentEditor
              // If category is NOT a list category, we display standard comments. Otherwise, we do list mode.
              ? !this.props.post.category.includes(globals.listCategoryArray) || (this.props.post.category.includes(globals.listCategoryArray) && listOrCommentView !== 'listView')
                // NON-LIST CATEGORY
                ?
                <>
                  {/* Top left  */}
                  <div className={`text-sm inline-flex justify-between w-full`}>
                    <div className={`inline-flex items-center`}>
                      <span className={`${comment.actionTakerIsMember && auth?.handle !== comment.commentCreatorHandle ? `member-tooltip ` : ``}`} data-member='standard' data-tooltip='Member'>
                        <div className={`p-2 -m-2 ${comment.actionTakerIsMember ? `flip-hover` : ``}`}>
                          <img width='22' height='22'
                            alt="Avatar"
                            src={comment.commentCreatorAvatarURL}
                            className={`rounded-xl ${comment.actionTakerIsMember ? `shadow-effect-six` : ``}`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = '/images/round-handcash-logo.png'
                            }}
                          >
                          </img>
                        </div>
                      </span>
                      <div className={`-mt-px ml-1 text-sm`}>
                        <button className={`fifth-hover text-sm hover:underline`} onClick={(e) => goToUserProfilePage(e, comment.commentCreatorHandle)}>
                          {comment.commentCreatorHandle}
                        </button> earned <span className={`fifth-text font-bold`}>${comment.totalValueReceived}</span>
                      </div>
                    </div>
                    {/* Top right */}
                    <div className={`text-medium-gray inline-flex items-center`}>
                      <div className={`mx-1 -mt-px text-right`}>
                        {/* Date */}
                        <Moment fromNow>
                          {new Date(comment.createdAt)}
                        </Moment>
                      </div>
                      {/* Edit */}
                      <div className={``}>
                        {auth && comment.commentCreatorHandCashId === auth.id
                          ?
                          <>
                            <button onClick={(e) => toggleCommentEditor(e)}
                              className={`sixth-hover cursor-pointer align-middle h-5 -mt-2px`}>
                              <span className={`mr-1`}>
                                <Icon icon={createOutline} height={18} width={18} />
                              </span>
                            </button>
                          </>
                          : <></>
                        }
                      </div>
                    </div>
                  </div>
                  {/* Dividers & body */}
                  <hr className={`sixth-border outsetBorder border opacity-50`}></hr>
                  <div className={`first-text text-base my-2 clearfix parsed-editor-data`}>{parse(comment.body)}</div>
                  <hr className={`sixth-border outsetBorder border opacity-50`}></hr>
                  {/* Bottom */}
                  <div className={`flex justify-between items-center flex-wrap text-sm mt-5px`}>
                    {/* Bottom Left */}
                    <div className={`flex flex-wrap pb-2`}>
                      <div className={`mr-1`}>
                        <CommentThumbsComponent
                          toggleCommentModal={toggleTipModalForT1Thumbs}
                          auth={auth}
                          comment={comment}
                          userDatabaseData={userDatabaseData}
                          index={index}
                          numThumbs={comment.numThumbs}
                        />
                      </div>
                      {auth?.id
                        ?
                        <>
                          <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                          <div className={`fifth-hover flex`}>
                            <div className={`mt-3px mr-px fifth-hover`}>
                              <Icon icon={commentsIcon} height={15} width={15} />
                            </div>
                            <button onClick={(e) => toggleEditor(e)} className={`ml-px mr-1 fifth-hover`}>
                              {!editorVisible
                                ? <span className={`fifth-hover`}>Reply</span>
                                : <span className={`fifth-hover`}>Close</span>
                              }
                            </button>
                          </div>
                          {comment.hasIframeInComment
                            ? <></>
                            :
                            <>
                              <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                              <button className={`fifth-hover mr-1`}
                                onClick={(e) => toggleEditor(e, comment.body, comment.commentCreatorHandle, comment.createdAt)} >
                                Quote
                              </button>
                            </>
                          }
                        </>
                        :
                        <></>
                      }
                      {/* Conditional render based upon the numChildComments, 
                        then whether tierTwoVisible is true/false */}
                      {numChildComments === 0
                        ? <></>
                        : tierTwoVisible
                          ?
                          <>
                            <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                            <button className={`mr-1 fifth-hover`}
                              onClick={() => displayTierTwoComments(id)}>Hide ({`${comment.numChildComments}`})
                            </button>
                          </>
                          :
                          <>
                            <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                            <button className={`mr-1 description-color font-bold fifth-hover flex items-center`}
                              onClick={() => displayTierTwoComments(id)}>View More ({`${comment.numChildComments}`})
                              <span className="ml-px mt-1 -mr-1 pointer-events-none"><Icon icon={caretDown20Filled} height={17} width={17} /></span>
                            </button>
                          </>
                      }
                    </div>
                    {/* Bottom Right */}
                    <div className={`pb-6px`}>
                      {comment.numThumbs > 0
                        ? <div className={``}>
                          <button onClick={(e) => toggleCommentThumbsArrayModal(e, "COMMENT_THUMBS_ARRAY")}
                            className={`font-header shadow-effect-seven
                            eighth-text gradient-background-twelve
                            items-center flex  button-ripple transition rounded-xl cursor-pointer py-px px-2 category-hover`}>
                            <div className={`-mt-px`}>
                              <Icon icon={thumbsUp} height={12} width={12} />
                            </div><span className={`ml-px`}>Thumbers</span>
                          </button>
                        </div>
                        : <></>
                      }
                    </div>
                  </div>
                </>
                // LIST CATEGORY
                // If this is a list category and 'listView', we display something different
                :
                <>
                  <div className={`text-sm flex justify-between py-1 pl-1`}>
                    <div className={`inline-block float-left items-center w-full`}>
                      <div className={`float-left flex mr-2 
                          ${this.state.lineClamp === '' ? 'mt-2px' : 'my-2px'}`}
                      >
                        {/* Total Value Received */}
                        <div className={`fifth-text font-bold ml-1 mr-2`}>${comment.totalValueReceived}</div>
                        <div className={`${comment.actionTakerIsMember && auth?.handle !== comment.commentCreatorHandle ? `member-tooltip ` : ``}`} data-member='standard' data-tooltip='Member'>
                          <div className={`${comment.actionTakerIsMember ? `flip-hover` : ``}`}>
                            <img width='22' height='22'
                              alt="Avatar"
                              src={comment.commentCreatorAvatarURL}
                              className={`rounded-xl ${comment.actionTakerIsMember ? `shadow-effect-six` : ``}`}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = '/images/round-handcash-logo.png'
                              }}
                            >
                            </img>
                          </div>
                        </div>
                      </div>
                      {/* Actual Body */}
                      <div className={`first-text mt-px mr-2 ${this.state.lineClamp} list-body`}>{parse(comment.body)}</div>
                    </div>
                    {/* Right side */}
                    <div className={`flex items-center ${this.state.lineClamp === '' ? 'ml-1' : ''}`}>
                      <div className={`flex justify-end`}>
                        <button onClick={(e) => this.addOrRemoveLineClamp(e)}
                          className={`items-center flex mr-1 justify-center shadow-effect-five 
                            rounded-xl button-ripple eighth-text gradient-background-twelve h-5 w-5`}
                        >
                          {this.state.lineClamp === ''
                            ?
                            <div className={`-mt-2px`}>-</div>
                            :
                            <div className={`-mt-px`}>+</div>
                          }
                        </button>
                        {/* Edit */}
                        <div className={``}>
                          {auth && comment.commentCreatorHandCashId === auth.id
                            ?
                            <>
                              <button onClick={(e) => toggleCommentEditor(e)}
                                className={`sixth-hover flex items-center text-medium-gray -mt-2px`}>
                                <div className={`mr-1`}>
                                  <Icon icon={createOutline} height={18} width={18} />
                                </div>
                              </button>
                            </>
                            : <></>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.lineClamp !== ''
                    ? <></>
                    :
                    <>
                      <hr className={`sixth-border outsetBorder border mx-1 my-2 opacity-50`}></hr>
                      {/* Bottom */}
                      <div className={`flex justify-between items-center flex-wrap text-sm`}>
                        {/* Bottom Left */}
                        <div className={`flex flex-wrap pb-2`}>
                        <div className={`mx-1`}>
                            By:
                          </div>
                            <button className={`fifth-hover text-underline-under hover:underline mr-1`} onClick={(e) => goToUserProfilePage(e, comment.commentCreatorHandle)}>
                              {comment.commentCreatorHandle}
                            </button>
                            <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                          <div className={`mr-1`}>
                            <CommentThumbsComponent
                              toggleCommentModal={toggleTipModalForT1Thumbs}
                              auth={auth}
                              comment={comment}
                              userDatabaseData={userDatabaseData}
                              index={index}
                              numThumbs={comment.numThumbs}
                            />
                          </div>
                          {auth?.id
                            ?
                            <>
                              <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                              <div className={`fifth-hover flex`}>
                                <div className={`mt-3px mr-px fifth-hover`}>
                                  <Icon icon={commentsIcon} height={15} width={15} />
                                </div>
                                <button onClick={(e) => toggleEditor(e)} className={`ml-px mr-1 fifth-hover`}>
                                  {!editorVisible
                                    ? <span className={`fifth-hover`}>Reply</span>
                                    : <span className={`fifth-hover`}>Close</span>
                                  }
                                </button>
                              </div>
                              {comment.hasIframeInComment
                                ? <></>
                                :
                                <>
                                  <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                                  <button className={`fifth-hover mr-1`}
                                    onClick={(e) => toggleEditor(e, comment.body, comment.commentCreatorHandle, comment.createdAt)} >
                                    Quote
                                  </button>
                                </>
                              }
                            </>
                            :
                            <></>
                          }
                          {/* Conditional render based upon the numChildComments, 
                        then whether tierTwoVisible is true/false */}
                          {numChildComments === 0
                            ? <></>
                            : tierTwoVisible
                              ?
                              <>
                                <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                                <button className={`mr-1 fifth-hover`}
                                  onClick={() => displayTierTwoComments(id)}>Hide ({`${comment.numChildComments}`})
                                </button>
                              </>
                              :
                              <>
                                <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                                <button className={`mr-1 description-color font-bold fifth-hover flex items-center`}
                                  onClick={() => displayTierTwoComments(id)}>View More ({`${comment.numChildComments}`})
                                  <span className="ml-px mt-1 -mr-1 pointer-events-none"><Icon icon={caretDown20Filled} height={17} width={17} /></span>
                                </button>
                              </>
                          }
                        </div>
                        {/* Bottom Right */}
                        <div className={`pb-6px`}>
                          {comment.numThumbs > 0
                            ? <div className={`mr-1`}>
                              <button onClick={(e) => toggleCommentThumbsArrayModal(e, "COMMENT_THUMBS_ARRAY")}
                                className={`font-header shadow-effect-seven
                            eighth-text gradient-background-twelve
                            items-center flex  button-ripple transition rounded-xl cursor-pointer py-px px-2 category-hover`}>
                                <div className={`-mt-px`}>
                                  <Icon icon={thumbsUp} height={12} width={12} />
                                </div><span className={`ml-px`}>Thumbers</span>
                              </button>
                            </div>
                            : <></>
                          }
                        </div>
                      </div>
                    </>
                  }
                </>
              // If showCommentEditor is true
              :
              <>
                <div className={`rounded-xl transition p-1 sm:px-4 sixth-text`}>
                  <AddComment
                    handleCommentChange={handleEditCommentChange}
                    togglePaymentModal={togglePaymentModal}
                    commentBody={editedCommentBody}
                    toggleConfirmCancelModal={toggleConfirmCancelModal}
                    commentBodyError={editedCommentBodyError}
                    overallError={editedOverallError}
                    updateNotCreateNew={true}
                    label={this.props.post.category.includes(globals.listCategoryArray) ? `List Entry` : `Comment Creation Station`}
                    userDatabaseData={userDatabaseData}
                  />
                </div>
              </>
            }
          </div>
        </div>
        {/* Needs to be a different ID for each CommentListItem, so the index was added to the id */}
        {auth && auth.id
          ?
          editorVisible
            ?
            <div className={`ml-0 sm:ml-4 pl-4 rounded-xl border-l-2 second-border`}>
              <div id={`add-comment-index-${index}`} className={`adding-comment rounded-xl ${this.props.post.category.includes(globals.listCategoryArray) ? `mt-2` : `mt-2`} transition px-1 
                sixth-text ${addExpandedClass}`}
              >
                <div className={`${levelTwoCommentOpacity} transition-to-opacity`}>
                  <AddComment
                    handleCommentChange={handleTierTwoCommentChange}
                    togglePaymentModal={togglePaymentModal}
                    commentBody={tierTwoComment.tierTwoCommentBody}
                    toggleConfirmCancelModal={toggleConfirmCancelModal}
                    commentBodyError={this.props.commentBodyError}
                    overallError={this.props.overallError}
                    auth={auth}
                    label={`Comment Creation Station`}
                    userDatabaseData={userDatabaseData}
                  />
                </div>
              </div>
            </div>
            :
            <div className={`ml-4 pl-0 sm:pl-4 rounded-xl border-l-2 second-border`}>
              <div id={`add-comment-index-${index}`} className={`adding-comment rounded-xl ${this.props.post.category.includes(globals.listCategoryArray) ? `mt-2` : `mt-2`} transition px-1 
            sixth-text gradient-background-four 
          `}>
              </div>
            </div>
          : <></>
        }
        {!tierTwoVisible
          ?
          <></>
          : !isLoaded
            ?
            <>
              <SkeletonLoaderForPosts />
            </>
            :
            <div className={`rounded-xl mb-4 transition py-px ml-0 sm:ml-4 pl-4 border-l-2 second-border sixth-text`}>
              {tierTwoComments.map((tierTwoComment, i) => {
                return (
                  <TierTwoCommentsContainer
                    key={i}
                    index={i}
                    tierOneCommentId={id}
                    tierOneCommentIndex={index}
                    postId={postId}
                    comment={tierTwoComment}
                    numChildComments={comment.numChildComments}
                    updatePostAndCommentDataAfterT2Comment={updatePostAndCommentDataAfterT2Comment}
                    handleCommentChange={handleTierTwoCommentChange}
                    handleSubmitComment={handleTierTwoCommentSubmit}
                    addAndFindComment={addAndFindComment}
                    tierTwoCommentOpacity={tierTwoCommentOpacity}
                    title={title}
                    postCreatorHandle={postCreatorHandle}
                    auth={auth}
                    userDatabaseData={userDatabaseData}
                    togglePaymentModal={togglePaymentModal}
                    post={this.props.post}
                    storeUserDatabaseData={this.props.storeUserDatabaseData}
                    goToUserProfilePage={goToUserProfilePage}
                    history={this.props.history}
                    updateT2CommentsArray={this.props.updateT2CommentsArray}
                    updateTier1CommentsAndPostNumbersAfterT3Creation={this.props.updateTier1CommentsAndPostNumbersAfterT3Creation}
                    updateTier2CommentsData={this.props.updateTier2CommentsData}
                    toggleTipModalForT2Thumbs={this.props.toggleTipModalForT2Thumbs}
                  />
                )
              })
              }
            </div>
        }
        {/* </div> */}
        {comment.ref
          ? <div ref={refCallback}></div>
          : <></>
        }
        {/* This can't exist here. I would need to create a new end ref if I wanted to do this. */}
        {comment.lastItem
          ? noMoreComments
            ? <></>
            : moreCommentsLoading
              ?
              <>
                <SkeletonLoaderForPosts />
              </>
              : <></>
          : <></>
        }
      </>
    );
  }
};


export default CommentListItem;

