import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'

import ScrollToTop from './components/ScrollToTop';
import './css/tailwind.css';
import registerServiceWorker from './registerServiceWorker';

// Redux
import App from './App';
import authReducer from './store/reducers/authSlice'
import themeReducer from './store/reducers/themeSlice'
import opacityReducer from './store/reducers/opacitySlice'
import quillThemeReducer from './store/reducers/quillThemeSlice'
import './index.css';

const store = configureStore({
  reducer: {
    theme: themeReducer,
    opacity: opacityReducer,
    auth: authReducer,
    quillTheme: quillThemeReducer,
  }
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById('root')
);
registerServiceWorker();