import axios from 'axios'
import authAxios from './authAxios';

export default {
  CancelToken: axios.CancelToken,
  // Individual actions regarding posts
  createPost: (data) => {
    return authAxios.post('/api/add/', data)
  },
  saveDraft: (data) => {
    return authAxios.put('/api/add/', data)
  },
  findPostBySlug: (slug, cancelToken) => {
    let localStorageAuth = localStorage.getItem('handCashAuthData')
    if (localStorageAuth) {
      localStorageAuth = JSON.parse(localStorageAuth)
      const headers = { Authorization: `Bearer ${localStorageAuth.authToken}` }
      return axios.get(`/api/posts/${slug}`, {headers: headers, cancelToken: cancelToken})
    }
    else {
      return axios.get(`/api/posts/${slug}`, {cancelToken: cancelToken})
    }
  },
  getDraft: (draftSlug, cancelToken) => {
    return authAxios.get(`/api/getDraft/${draftSlug}`, {cancelToken: cancelToken})
  },
  updatePost: (handle, updatedData) => {
    return authAxios.put(`/api/posts/update/${handle}`, updatedData)
  },
  // deletePost: (slug) => {
  //   return axios.delete('/api/posts/' + slug);
  // },
  deleteDraft: (draftId, handle) => {
    return authAxios.delete(`/api/posts/${draftId}/${handle}`);
  },
  // Getting Arrays within a post for the Modals
  getPaywallPurchasers: (postId, pageNumber, cancelToken) => {
    const headers = { postId: `${postId}` }
    return axios.get(`/api/getPaywallPurchasers/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  getPostSharers: (postId, pageNumber, cancelToken) => {
    const headers = { postId: `${postId}` }
    return axios.get(`/api/getPostSharers/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  getPostThumbers: (postId, pageNumber, cancelToken) => {
    const headers = { postId: `${postId}` }
    return axios.get(`/api/getPostThumbers/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  getCommentThumbers: (commentId, pageNumber, cancelToken) => {
    const headers = { commentId: `${commentId}` }
    return axios.get(`/api/getCommentThumbers/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  getPollVoters: (postId, pageNumber, cancelToken) => {
    const headers = { postId: `${postId}` }
    return axios.get(`/api/getPollVoters/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  getStampshots: (postId, pageNumber, cancelToken) => {
    const headers = { postId: `${postId}` }
    return axios.get(`/api/getStampshots/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  // Get posts
  getTrendingPostsByCategory: (categoryName, pageNumber, cancelToken) => {
    return axios.get(`/api/categoryPostsByTrending/${categoryName}/${pageNumber}`, {cancelToken: cancelToken})
  },
  getAllTimePosts: (categoryName, pageNumber, cancelToken) => {
    return axios.get(`/api/categoryPostsByAllTime/${categoryName}/${pageNumber}`, {cancelToken: cancelToken})
  },
  getNewestPosts: (categoryName, pageNumber, cancelToken) => {
    return axios.get(`/api/categoryPostsByNewest/${categoryName}/${pageNumber}`, {cancelToken: cancelToken})
  },
  getPostsForHomePage: (pageNumber, cancelToken) => {
    return axios.get(`/api/postsForHomePage/${pageNumber}`, {cancelToken: cancelToken})
  },
  // getWeekPosts: (categoryName, pageNumber, cancelToken) => {
  //   return axios.get(`/api/categoryPostsByWeek/${categoryName}/${pageNumber}`, {cancelToken: cancelToken})
  // },
  getFollowingPosts: (pageNumber, cancelToken) => {
    return authAxios.get(`/api/followingPosts/${pageNumber}`, {cancelToken: cancelToken})
  },
  // Comments
  createTierOneComment: (postId, commentData) => {
    return authAxios.post(`/api/addComment/${postId}`, commentData)
  },
  createTierTwoComment: (commentId, commentData) => {
    return authAxios.post(`/api/tierTwoComments/${commentId}`, commentData)
  },
  createTierThreeComment: (commentId, commentData) => {
    return authAxios.post(`/api/tierThreeComments/${commentId}`, commentData)
  },
  submitCommentUpdate: (comment) => {
    return authAxios.put(`/api/comments/update`, comment)
  },
  fetchPostComments: (postSlug, pageNumber, cancelToken) => {
    return axios.get(`/api/posts/${postSlug}/comments/${pageNumber}`, {cancelToken: cancelToken})
  },
  fetchTierTwoComments: (commentId, cancelToken) => {
    return axios.get(`/api/tierTwoComments/${commentId}`, {cancelToken: cancelToken})
  },
  fetchTierThreeComments: (commentId, cancelToken) => {
    return axios.get(`/api/tierThreeComments/${commentId}`, {cancelToken: cancelToken})
  },
  // Thumbs
  addThumbsToPost: (postId, userData) => {
    return authAxios.put(`/api/posts/${postId}/incrementThumbs`, userData)
  },
  addThumbsToTierOneComment: (tierOneCommentId, thumbsData) => {
    return authAxios.put(`/api/tierOneComments/${tierOneCommentId}/incrementThumbs`, thumbsData)
  },
  addThumbsToTierTwoComment: (tierTwoCommentId, thumbsData) => {
    return authAxios.put(`/api/tierTwoComments/${tierTwoCommentId}/incrementThumbs`, thumbsData)
  },
  addThumbsToTierThreeComment: (tierThreeCommentId, thumbsData) => {
    return authAxios.put(`/api/tierThreeComments/${tierThreeCommentId}/incrementThumbs`, thumbsData)
  },

  // Views
  incrementViewsOfPost: (slug) => {
    return axios.put(`/api/posts/${slug}/incrementViews`)
  },
  // Shares
  addShareToPost: (postId, shareData) => {
    return authAxios.put(`/api/posts/${postId}/addShare`, shareData)
  },
  // User Stampshot
  submitUserStampshot: (stampshotData) => {
    return authAxios.put(`/api/posts/submitUserStampshot`, stampshotData)
  },
  // HandCash
  authenticateWithHandCash: (cancelToken) => {
    return axios.get(`/api/authenticateWithHandCash`, {cancelToken: cancelToken})
  },
  authenticateHandCashUser: (cancelToken) => {
    return authAxios.get(`/api/authenticateHandCashUser/`, {cancelToken: cancelToken})
  },
  // Database user
  verifyTokenAndGetDatabaseUserData: (cancelToken) => {
    return authAxios.get(`/api/verifyTokenAndGetDatabaseUserData`, {cancelToken: cancelToken})
  },
  getPublicDataForUser: (handCashHandle, cancelToken) => {
    return axios.get(`/api/getPublicDataForUser/${handCashHandle}`, {cancelToken: cancelToken})
  },
  // Paywall
  purchasePaywall: (paywallData) => {
    return authAxios.put(`/api/purchasePaywall`, paywallData)
  },
  ratePaywall: (data) => {
    return authAxios.put(`/api/ratePaywall`, data)
  },
  // Not currently used
  // getFollowersArray: (handCashHandle, pageNumber, databaseId) => {
  //   return axios.get(`/api/getFollowers/${handCashHandle}/${pageNumber}`, databaseId)
  // },
  // Not currently used
  // getFollowingArray: (handCashHandle, pageNumber, databaseId) => {
  //   const headers = { databaseId: `${databaseId}` }
  //   return axios.get(`/api/getFollowing/${handCashHandle}/${pageNumber}`, {headers: headers})
  // },
  // Profile page
  followUser: (data) => {
    return authAxios.put(`/api/followUser`, data)
  },
  unfollowUser: (unfollowedHandCashId) => {
    return authAxios.put(`/api/unfollowUser`, unfollowedHandCashId)
  },
  tipUser: (data) => {
    return authAxios.put(`/api/tipUser`, data)
  },
  // ProfilePage
  getPostsForProfilePage: (handle, possibleUserHandle, pageNumber, cancelToken) => {
    const headers = { handle: `${handle}`, possibleUserHandle: `${possibleUserHandle}` }
    return axios.get(`/api/getPostsForProfilePage/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  getDraftsForProfilePage: (handle, cancelToken) => {
    return authAxios.get(`/api/getDraftsForProfilePage/${handle}`, {cancelToken: cancelToken})
  },
  getCommentsForProfilePage: (handle, pageNumber, cancelToken) => {
    const headers = { handle: `${handle}` }
    return axios.get(`/api/getCommentsForProfilePage/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  getMostEarnedPosts: (handCashHandle, possibleUserHandle, pageNumber, cancelToken) => {
    const headers = { handle: `${handCashHandle}`, possibleUserHandle: `${possibleUserHandle}` }
    return axios.get(`/api/getMostEarnedPosts/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  }, 
  updateProfileField: (data) => {
    return authAxios.put(`/api/updateProfileField`, data)
  },
  getFollowData: (handCashHandle, pageNumber, type, cancelToken) => {
    const headers = { handle: `${handCashHandle}`, type: `${type}` }
    return axios.get(`/api/getFollowData/${pageNumber}`, {headers: headers, cancelToken: cancelToken})
  },
  // Membership & Donations
  purchaseMembership: (price, membershipType) => {
    const data = { price: price, membershipType: membershipType }
    return authAxios.put(`/api/purchaseMembership/`, data)
  },
  // sendDonation: (amount, databaseId, donationMessage, actionTakerIsMember) => {
  //   const data = { amount: amount, databaseId: databaseId, donationMessage: donationMessage, actionTakerIsMember: actionTakerIsMember }
  //   return authAxios.put(`/api/sendDonation/`, data)
  // },
  getNumberOfMembersAndRecents: (cancelToken) => {
    return axios.get(`/api/getNumberOfMembers`, {cancelToken})
  },
  checkForMembership: (cancelToken) => {
    return authAxios.get(`/api/checkForMembership`, {cancelToken: cancelToken})
  },
  // Ranking Page
  getRankingData: (sortType, cancelToken) => { 
    const headers = { sortType: `${sortType}` }
    return axios.get(`/api/getRankingData`, {cancelToken: cancelToken, headers: headers})
  },
  // Donation Page
  getDonorLists: (listType, cancelToken) => {
    const headers = { listType: `${listType}` }
    return axios.get(`/api/donor/getDonorLists`, {cancelToken: cancelToken, headers: headers})
  },
  // Notifications
  getNotifications: (pageNumber, cancelToken) => {
    return authAxios.get(`/api/getNotifications/${pageNumber}`, cancelToken)
  },
  updateNumNotifications: () => {
    return authAxios.put(`/api/updateNumNotifications`)
  },
  updateNotificationViewedProperty: (notificationsToUpdateArray) => {
    return authAxios.put(`/api/updateNotificationViewedProperty`, notificationsToUpdateArray)
  },
  // Search
  getSearchResults: (searchData, cancelToken) => {
    const headers = { searchinputvalue: `${searchData.searchInputValue}`, searchtype: `${searchData.searchType}` }
    return axios.get(`/api/getSearchResults`, {cancelToken: cancelToken, headers: headers})
  },
  getTransactionsForExcel: (searchType, cancelToken) => {
    return authAxios.get(`/api/getTransactionsForExcel/${searchType}`, {cancelToken: cancelToken})
  },
  // Image
  saveImageToCloud: (image, cancelToken) => {
    return authAxios.post(`/api/saveImageToCloud`, image, cancelToken)
  },
  // Unique user login
  addLoginAndReferrerLocation: (referrerLink, cancelToken) => {
    return authAxios.post(`/api/addLoginAndReferrerLocation`, referrerLink, cancelToken)
  },
  // Votes
  addHandCashIdToVoteViewersArray: (slug) => {
    return authAxios.put(`/api/addHandCashIdToVoteViewersArray`, slug)
  },
  submitVote: (voteData) => {
    return authAxios.put(`/api/submitVote`, voteData)
  },
  // Mass Tipping
  getTipListLengthAndTotalMassTipValue: (cancelToken) => {
    return axios.get(`/api/getTipListLengthAndTotalMassTipValue`, { cancelToken: cancelToken })
  },
  triggerMassTipping: (massTipAmount) => {
    return authAxios.post(`/api/triggerMassTipping`, massTipAmount)
  },
  getMassTippings: (listType, cancelToken) => {
    const headers = { listType: `${listType}` }
    return axios.get(`/api/getMassTippings`, { cancelToken: cancelToken, headers: headers })
  },
};