import React from 'react'
import Modal from "react-modal";
import { Icon } from '@iconify/react';
import closeCircleO from '@iconify/icons-vaadin/close-circle-o';

const TipModal = props => {
  const {
    showModal,
    recipientHandCashHandle,
    confirmPaymentModal,
    handleChange,
    amount,
    charCounterClass,
    tipMessage,
    handleTipMessageChange,
    errorMessage,
    isThumbsModal,
    transactionType,
    step,
    min,
    max,
    closeModal
  } = props;
  const styles = {
    base: `modal-content first-text gradient-background-four shadow-effect-five
    mt-32 w-full px-1 sm:w-9/12 xl:w-2/5 transition-to-opacity rounded-lg`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        className={styles}
        overlayClassName="modal-overlay mr-4 sm:mr-0"
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        contentLabel={'HandCash Login Modal'}
      >
        <div className={`text-center`}>
          <button onClick={closeModal} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
              <Icon icon={closeCircleO} height={18} width={18} />
            </button>
          <div className={`teko-font text-3xl sm:text-3xl mb-2 logo-style ml-6`}>
            {isThumbsModal
              ? <div>Thumbs Amount</div>
              : <div>Tipping {recipientHandCashHandle}...</div>
            }
          </div>
          <hr className={`sixth-border outsetBorder border my-1`}></hr>
          {/* Input */}
          <div className={`inline-flex items-center mt-3 mb-1`}>
            <div className={`mr-1`}>
              {isThumbsModal
                ? `Amount: `
                : `Tip Amount: `
              }
            </div>
            <span className={`text-brand-green paywall-dollar-sign`}>
              <input
                className={`gradient-background-seven shadow-effect-ten border 
                  outline-none w-24 rounded-md pl-4 text-gray-900 transition updatingTipValue`
                }
                aria-label="Amount to tip or thumbs other user"
                type="number" min={min} max={max} step={step} pattern="^\d+(?:\.\d{1,2})?$"
                onChange={(e) => handleChange(e)}
                value={amount}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              />
            </span>
          </div>
          {/* Conditional render if tip message should exist or not */}
          {isThumbsModal
            ? <></>
            :
            <>
              <div className='mx-2 mb-3'>
                <div className={`flex`}>
                  <div className={`flex flex-1 justify-end self-end mr-1 pb-1`}>
                    <div className={`text-xs ${charCounterClass}`}>Count: {tipMessage?.length}</div>
                  </div>
                </div>
                <div className={`mt-2 transition text-gray-900 first-text custom-input-container`} data-name="Tip Message">
                  <div className={`rounded-md shadow-effect-six`}>
                    <textarea
                      id='tipMessage'
                      placeholder='*Optional* with 280 character limit'
                      className={`gradient-background-seven shadow-effect-ten transition text-gray-900 w-full rounded-md px-2 pt-4 pb-2 -mb-7px
                    outline-none border  resize-none `
                      }
                      rows='5'
                      value={tipMessage}
                      onChange={handleTipMessageChange}>
                    </textarea>
                  </div>
                </div>
                {errorMessage
                  ?
                  <div className={`text-center font-header mt-1 text-xs text-red-600`}>
                    {errorMessage}
                  </div>
                  : null
                }
              </div>
            </>
          }
          {/* Confirmation */}
          <div className={`flex justify-between mt-5 mb-1`}>
            <button
              className={`text-sm py-1 px-2 shadow-effect-seven font-header gradient-background-green rounded-xl border-2 font-header text-white border-brand-green ml-2`}
              onClick={e => confirmPaymentModal(e, transactionType)}
            >
              Confirm
            </button>
            <button
              className={`text-white gradient-background-red shadow-effect-seven
              text-sm rounded-xl border-2 font-header border-red-700 p-2 mr-2`}
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default TipModal;
