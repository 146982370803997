import React, { Component } from 'react'
import { connect } from 'react-redux';
import { opacityChange } from '../../store/reducers/opacitySlice';
import { storeUserDatabaseData } from '../../store/reducers/authSlice';

import API from '../../utils/API';
import MassTippingPage from './MassTippingPage';
import { toast } from 'react-toastify'
import { globals } from '../../config/globals';
import PaymentModal from '../../components/Modals/PaymentModal';
import OverlayLoadingIcon from '../../components/Widgets/OverlayLoadingIcon';
import MassTipModal from '../../components/Modals/MassTipModal';
import SkeletonLoaderRanking from '../../components/Widgets/SkeletonLoaderRanking';
import ArrayModal from '../../components/Modals/ArrayModal';

class MassTippingContainer extends Component {
  state = {
    listDataType: 'GET_LATEST',
    dataListOpacity: 'opacity-0',
    mostRecentArray: [],
    totalAllTimeArray: [],
    showPaymentModal: false,
    showMassTipModal: false,
    massTipMessage: '',
    massTipMessageError: '',
    massTipAmount: '.010',
    donationCharCounterClass: `fifth-text`,
    pageOverlayActive: false,
    transactionType: '',
    donorSelect: {
      recent: 'highlight-border',
      allTimeTotal: 'highlight-border',
    },
    massTipSelectionIsLoaded: false,
    numberOfEligibleTippers: 0,
    totalMassTipValue: '0.00',
    amountPerPerson: '',
    // Data array modal
    modalDataArray: [],
    showArrayModal: false,
    arrayModalOpacity: 'opacity-0',
    arrayModalIsLoading: false,
  }
  cancelToken = API.CancelToken.source();
  massTipDataListArray = ['GET_LATEST', 'GET_HIGHEST']

  // Component Mount
  componentDidMount() {
    let dataListType = localStorage.getItem('massTipListSelection')
    this.getTipListLengthAndTotalMassTipValue()
    if (dataListType && this.massTipDataListArray.includes(dataListType)) this.toggleTippingList('', dataListType)
    else this.toggleTippingList('', 'GET_LATEST')
    setTimeout(() => {
      this.props.opacityChange('addMassTippingOpacity')
    }, 100)
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled')
  };
  getTipListLengthAndTotalMassTipValue = () => {
    API.getTipListLengthAndTotalMassTipValue(this.cancelToken.token)
      .then(res => {
        const totalMassTipValue = res.data.totalMassTipValue
        const numberOfEligibleTippers = res.data.tipListLength;
        this.setState({
          numberOfEligibleTippers: numberOfEligibleTippers,
          totalMassTipValue: parseFloat(totalMassTipValue).toFixed(2)
        })
      })
      .catch(error => {
        console.error(error);
      })
  }
  toggleTippingList = (e, dataType) => {
    // GET_LATEST GET_HIGHEST
    if (e) e.preventDefault();
    if (e) globals.createRipple(e)
    const currentListDataValue = this.state.listDataType;
    // Needed this extra logic for the mounting (because the values are the same), but returns out if user clicks on current selection
    if (e !== '' && dataType === currentListDataValue) return;
    this.setListSelectionState(dataType)
    localStorage.setItem('massTipListSelection', dataType)
    switch (dataType) {
      case 'GET_LATEST':
        if (this.state.mostRecentArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        break;
      case 'GET_HIGHEST':
        if (this.state.totalAllTimeArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        break;
      default:
        return (
          globals.toastError(toast, 'Unable to retrieve list. Redirecting to home page.'),
          this.props.opacityChange('removeMassTippingOpacity'),
          setTimeout(() => {
            this.props.history.push(`/`);
          }, 300)
        )
    }
    setTimeout(() => {
      this.setState({ massTipSelectionIsLoaded: false })
    }, 300)
    API.getMassTippings(dataType, this.cancelToken.token)
      .then(res => {
        const queryData = res.data.query;
        // Conditionally render which array the data belongs to. Then change to that sort type and add opacity
        return setTimeout(() => {
          this.setArrayAndListDataType(dataType, queryData)
        }, 400)
      })
      .catch(error => {
        console.error(error);
        this.props.opacityChange('removeMassTippingOpacity')
        setTimeout(() => {
          this.props.history.push(`/`);
        }, 300);
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve list. Redirecting to home page.');
      })
  }
  viewRecipientsArray = (e, recipientsArray, massTipAmount) => {
    e.preventDefault()
    this.setState({
      modalDataArray: recipientsArray,
      amountPerPerson: massTipAmount,
      arrayModalOpacity: 'opacity-0',
      showArrayModal: true,
      arrayModalIsLoading: false
    })
    setTimeout(() => {
      this.setState({ arrayModalOpacity: 'opacity-100' })
    }, 300)
  }
  // toggleDonorDataList = (e, dataType) => {
  //   if (e) e.preventDefault();
  //   if (e) globals.createRipple(e)
  //   const currentListDataValue = this.state.listDataType;
  //   // Needed this extra logic for the mounting (because the values are the same), but returns out if user clicks on current selection
  //   if (e !== '' && dataType === currentListDataValue) return;
  //   // This switch statement tells our API database function which data to pull.
  //   // It also checks the length of the array to determine if it should query or not. If there's a length, it returns the existing data.
  //   this.setListSelectionState(dataType)
  //   localStorage.setItem('donationListSelection', dataType)
  //   switch (dataType) {
  //     case 'GET_RECENT':
  //       if (this.state.mostRecentArray.length > 0) {
  //         return (
  //           setTimeout(() => {
  //             this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
  //           }, 400)
  //         )
  //       }
  //       break;
  //     case 'GET_TOTAL_ALLTIME':
  //       if (this.state.totalAllTimeArray.length > 0) {
  //         return (
  //           setTimeout(() => {
  //             this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
  //           }, 400)
  //         )
  //       }
  //       break;
  //     case 'GET_SINGLE_ALLTIME':
  //       if (this.state.singleAllTimeArray.length > 0) {
  //         return (
  //           setTimeout(() => {
  //             this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
  //           }, 400)
  //         )
  //       }
  //       break;
  //     default:
  //       return (
  //         globals.toastError(toast, 'Unable to retrieve list. Redirecting to home page.'),
  //         this.props.opacityChange('removeMassTippingOpacity'),
  //         setTimeout(() => {
  //           this.props.history.push(`/`);
  //         }, 300)
  //       )
  //   }
  //   setTimeout(() => {
  //     this.setState({ massTipSelectionIsLoaded: false })
  //   }, 300)
  //   // Calling the API function
  //   API.getDonorLists(dataType, this.cancelToken.token)
  //     .then(res => {
  //       const queryData = res.data.donorQuery;
  //       // Conditionally render which array the data belongs to. Then change to that sort type and add opacity
  //       return setTimeout(() => {
  //         this.setArrayAndListDataType(dataType, queryData)
  //       }, 400)
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       this.props.opacityChange('removeMassTippingOpacity')
  //       setTimeout(() => {
  //         this.props.history.push(`/`);
  //       }, 300);
  //       if (error.message === 'Operation canceled') return
  //       globals.toastError(toast, 'Unable to retrieve list. Redirecting to home page.');
  //     })
  // }
  setArrayAndListDataType = (dataType, queryData) => {
    switch (dataType) {
      case 'GET_LATEST':
        return (
          this.setState({
            mostRecentArray: queryData,
            massTipSelectionIsLoaded: true,
            listDataType: dataType
          }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_HIGHEST':
        return (
          this.setState({
            totalAllTimeArray: queryData,
            massTipSelectionIsLoaded: true,
            listDataType: dataType
          }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100' })
          }, 400)
        )
      // It can't get to this point, because of the original switch statement. dataType parameter doesn't change. So we just return.
      default: return
    }
  }
  setListSelectionState = (value) => {
    switch (value) {
      case 'GET_LATEST':
        this.setState({
          dataListOpacity: 'opacity-0',
          donorSelect: {
            recent: 'highlight-border',
            allTimeTotal: '',
          }
        })
        break
      case 'GET_HIGHEST':
        this.setState({
          dataListOpacity: 'opacity-0',
          donorSelect: {
            recent: '',
            allTimeTotal: 'highlight-border',
          }
        })
        break
      default:
        this.setState({
          dataListOpacity: 'opacity-0',
          donorSelect: {
            recent: 'highlight-border',
            allTimeTotal: '',
          }
        })
        break
    }
  }
  goToUserProfilePage = (e, userName) => {
    e.preventDefault();
    // Do an opacityChange
    this.props.opacityChange('removeMassTippingOpacity')
    setTimeout(() => {
      this.props.history.push(`/profile/${userName}`)
    }, 300)
  }
  massTipValidationCheck = () => {
    let allChecksValid = true
    if (this.state.massTipMessage.length > 280) {
      this.setState({
        massTipMessageError: `*Message must be less than 280 characters*`
      })
      allChecksValid = false
    }
    const massTipAmount = parseFloat(this.state.massTipAmount)
    if (isNaN(massTipAmount) || massTipAmount === 0 || !massTipAmount >= .001) {
      this.setState({
        massTipMessageError: `*Please update the amount.*`
      })
      allChecksValid = false
    }
    return allChecksValid
  }
  triggerMassTipping = (e) => {
    e.preventDefault()
    // Validation
    const validationReturnsTrue = this.massTipValidationCheck()
    if (!validationReturnsTrue) return globals.toastError(toast, 'Fix any errors and try again')
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    if (!localStorageAuth?.authToken || !this.props.auth?.id) {
      return (
        this.setState({ showPaymentModal: false }, () => {
          globals.toastError(toast, 'Unable to Mass Tip');
          setTimeout(() => {
            this.setState({ transactionType: '', massTipAmount: '.010', massTipMessage: '' })
          }, 400)
        })
      )
    };
    this.setState({ showPaymentModal: false }, () => {
      setTimeout(() => {
        this.setState({ transactionType: '', pageOverlayActive: true })
      }, 400)
    })
    const massTipData = {
      massTipAmount: parseFloat(this.state.massTipAmount),
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      massTipMessage: this.state.massTipMessage
    }
    API.triggerMassTipping(massTipData)
      .then(res => {
        const massTippingDocForUpdate = res.data.createMassTippingDoc
        const valueSentUpdate = res.data.incrementForStatsSchema.totalMassTipValueSent
        // Updating user info
        const userUpdate = res.data.userUpdate
        const totalMassTipValueSent = userUpdate.totalMassTipValueSent
        const totalMassTipsConducted = userUpdate.totalMassTipsConducted
        const totalValueSent = userUpdate.totalValueSent
        this.props.storeUserDatabaseData({ type: 'updateMassTippingInfo', totalMassTipValueSent, totalMassTipsConducted, totalValueSent })
        // Ending function. Update newest array
        let mostRecentArray = this.state.mostRecentArray;
        if (mostRecentArray.length > 0) mostRecentArray.unshift(massTippingDocForUpdate)
        if (mostRecentArray.length === 0) mostRecentArray = []
        globals.toastSuccess(toast, `Thank you for your Mass Tipping contribution!`);
        setTimeout(() => {
          this.setState({
            pageOverlayActive: false,
            massTipAmount: '.010',
            massTipMessage: '',
            mostRecentArray: mostRecentArray,
            totalMassTipValue: valueSentUpdate
          })
        }, 400)
      })
      .catch(err => {
        console.error(err);
        globals.toastError(toast, err.response.data.message);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, massTipAmount: '.010', massTipMessage: '' })
        }, 400)
      })
  }
  // sendDonation = (e) => {
  //   e.preventDefault();
  //   const validationReturnsTrue = this.donationValidationCheck()
  //   if (!validationReturnsTrue) return globals.toastError(toast, 'Fix any errors and try again')
  //   let localStorageAuth = localStorage.getItem('handCashAuthData');
  //   if (!localStorageAuth || !this.props.userDatabaseData?._id || !this.props.auth) {
  //     return (
  //       this.setState({ showPaymentModal: false }, () => {
  //         globals.toastError(toast, 'Unable to donate');
  //         setTimeout(() => {
  //           this.setState({ transactionType: '', massTipAmount: '.010', massTipMessage: '' })
  //         }, 400)
  //       })
  //     )
  //   };
  //   this.setState({ showPaymentModal: false }, () => {
  //     setTimeout(() => {
  //       this.setState({ transactionType: '', pageOverlayActive: true })
  //     }, 400)
  //   })
  //   let actionTakerIsMember = this.props.userDatabaseData.membership ? true : false
  //   API.sendDonation(parseFloat(this.state.massTipAmount), this.props.userDatabaseData._id, this.state.massTipMessage, actionTakerIsMember)
  //     .then(res => {
  //       const userUpdate = res.data.userUpdate
  //       let userDatabaseData = this.props.userDatabaseData
  //       Object.assign(userDatabaseData, {
  //         totalValueOfDonations: userUpdate.totalValueOfDonations,
  //         totalDonationsGiven: userUpdate.totalDonationsGiven,
  //         totalValueSent: userUpdate.totalValueSent
  //       })
  //       this.props.storeUserDatabaseData(userDatabaseData)
  //       globals.toastSuccess(toast, `Thank you for your generous donation and support!!`);
  //       // Update the state so the person can view their donation instantly.
  //       let mostRecentArray = this.state.mostRecentArray;
  //       const donationObject = res.data.createDonation
  //       mostRecentArray.unshift(donationObject)
  //       setTimeout(() => {
  //         this.setState({ pageOverlayActive: false, massTipAmount: '.010', massTipMessage: '', mostRecentArray: mostRecentArray })
  //       }, 400)
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       globals.toastError(toast, err.response.data.message);
  //       setTimeout(() => {
  //         this.setState({ pageOverlayActive: false, massTipAmount: '.010', massTipMessage: '' })
  //       }, 400)
  //     })
  // }
  toggleMassTipModal = (e) => {
    e.preventDefault();
    if (this.state.showMassTipModal === false) {
      this.setState({ showMassTipModal: true })
    }
    else {
      this.setState({ showMassTipModal: false })
    }
  }
  closeModal = (e) => {
    e.preventDefault();
    this.setState({ showMassTipModal: false })
  }
  togglePaymentModal = (e, typeOfTransaction) => {
    e.preventDefault();
    // Okay, I can't do prevState, because I need to slow down the state change when it closes. Otherwise, no smooth transition.
    if (this.state.showPaymentModal === false) {
      this.setState({ showPaymentModal: true, transactionType: typeOfTransaction })
    }
    else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: typeOfTransaction })
        }, 400)
      })
    }
  };
  confirmPaymentModal = (e, typeOfTransaction) => {
    e.preventDefault();
    const validationReturnsTrue = this.massTipValidationCheck()
    if (!validationReturnsTrue) return globals.toastError(toast, 'Fix any errors and try again')
    this.setState({ showMassTipModal: false }, () => {
      setTimeout(() => {
        this.setState({ showPaymentModal: true, transactionType: typeOfTransaction })
      }, 400)
    })
  }
  handleMassTipAmountChange = (e) => {
    this.setState({ massTipAmount: e.target.value, massTipMessageError: `` });
    let source = document.querySelector('.updatingMassTippingValue')
    source.addEventListener('change', () => {
      source.value = parseFloat(source.value).toFixed(3)
    });
  }
  handleMassTipMessageChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
    setTimeout(() => {
      // Determines character count
      this.checkLengthOnDescription('MASS_TIP');
      // If there are errors
      if (!this.state.massTipMessageError === '') {
        setTimeout(() => {
          if (this.state.massTipMessage.length > 1 && this.state.massTipMessage.length <= 280) {
            this.setState({ massTipMessageError: '' })
          }
        }, 400)
      }
    }, 100)
  }
  // This checks the length and changes color of the character # count to notify how many characters a person has.
  checkLengthOnDescription = (type) => {
    if (type === 'MASS_TIP') {
      const massTipMessageLength = this.state.massTipMessage.length;
      if (massTipMessageLength <= 200) {
        return this.setState({ donationCharCounterClass: `fifth-text` })
      } else if (massTipMessageLength >= 200 && massTipMessageLength <= 280) {
        return this.setState({ donationCharCounterClass: 'text-orange-500' })
      } else {
        return this.setState({ donationCharCounterClass: 'text-red-600' })
      }
    }
    return
  }
  closeArrayModal = () => {
    this.setState({ arrayModalOpacity: 'opacity-0' })
    setTimeout(() => {
      this.setState({ showArrayModal: false })
    }, 300)
  }
  render() {
    const { massTippingOpacity } = this.props;
    return (
      <>
        <main className={`pb-3 mt-2  mx-auto w-full sm:max-w-3xl md:max-w-4xl`} id='main-content'>
          <div className={`w-full rounded-xl px-1 mb-3 first-text shadow-effect-eight transition`}>
            <div className={`${massTippingOpacity} transition-to-opacity`}>
              <div className={`gradient-background-four -mx-1 rounded-t-xl`}>
                {/* Part 1: Wrapping div for top */}
                <div className={`flex justify-center text-center w-auto pt-3 -mb-1`}>
                  <h2 className={`teko-font logo-style eleventh-text text-4xl`}>Mass Tipping</h2>
                </div>
                <hr className={`mx-3 sixth-border outsetBorder border my-2 opacity-50`}></hr>
                {/* Part 2 */}
                <div className={`flex justify-between flex-wrap items-center`}>
                  <div className={`flex-1 text-center rounded-xl transition px-1 mx-1 text-sm sm:text-base`}>
                    <div className={`underline text-underline-under logo-style`}>
                      Qualified Stampers
                    </div>
                    <div className={``}>
                      {this.state.numberOfEligibleTippers}
                    </div>
                  </div>
                  <div className={`flex-1 text-center rounded-xl transition px-1 mx-1`}>
                    {this.props.auth
                      ?
                      <div className={`text-center mt-2`}>
                        <button className={`text-sm sm:text-base py-1 px-2 rounded-xl border-2 font-header border-brand-green
                            text-white gradient-background-green shadow-effect-seven`}
                          onClick={(e) => this.toggleMassTipModal(e)}
                        >
                          Conduct Mass Tipping
                        </button>
                      </div>
                      : <div className={`text-center mt-2`}>
                        <button className={`text-sm sm:text-base py-1 px-2 rounded-xl border-2 font-header border-brand-green
                            text-white gradient-background-green shadow-effect-seven`}
                        >
                          Login to Mass Tip!
                        </button>
                      </div>
                    }
                  </div>
                  <div className={`flex-1 text-center rounded-xl transition px-1 mx-1 text-sm sm:text-base`}>
                    <div className={`underline text-underline-under logo-style`}>
                      Total Amount Sent
                    </div>
                    <div className={``}>
                      ${this.state.totalMassTipValue}
                    </div>
                  </div>
                </div>
                {/* Part 3 */}
                <div className={`px-3 mt-3`}>
                  <p className={`my-3 text-sm sm:text-base text-center`}>
                    A TipStampers.com "Mass Tipping" sends <span className={`fifth-text`}>$</span> to every actual user of the platform at once, via <a href='https://market.handcash.io/'
                      aria-label={`Go to HandCash website`}
                      className={`fifth-hover hover:underline`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >HandCash</a>.
                  </p>
                  <p className={`mb-2 text-sm sm:text-base text-center`}>
                    To qualify, simply create 1 post in *any* category.
                  </p>
                  {/* Part 4 */}
                  <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
                  <p className={`mb-3 text-sm sm:text-base mt-1`}>
                    This feature was built for the Bitcoin community of givers:
                    The generous individuals who also wish to bring electronic cash to the masses - to the average, everyday person.
                  </p>
                  <p className={`mb-3 text-sm sm:text-base`}>
                    With mainstream appeal and over 30 options to earn and spend,
                    TipStampers.com is the ideal location to onboard newcomers with free giveaways.
                    Unlike giveaways on Twitter, people can actually do things with their money here,
                    giving us the opportunity to grow a circulating economy, and create a lasting community.
                  </p>
                  <p className={`mb-3 text-sm sm:text-base`}>
                    If this appeals to you, we encourage you to join us on our onboarding mission by using and promoting this feature.
                    Let's bring Bitcoin to the masses together, one Mass Tipping at a time.
                  </p>
                </div>
                <hr className={`sixth-border outsetBorder border my-3 mx-3 opacity-50`}></hr>
                {/* Part 5: Div for the sorts */}
                <div className={`flex justify-center items-center text-sm sm:text-base font-header`}>
                  <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                    ${this.state.donorSelect.recent ? `${this.state.donorSelect.recent}` : `shadow-effect-five`} eighth-text gradient-background-twelve`}
                    onClick={(e) => this.toggleTippingList(e, "GET_LATEST")}
                  >
                    Latest Tippings
                  </button>
                  <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                    ${this.state.donorSelect.allTimeTotal} shadow-effect-five eighth-text gradient-background-twelve`}
                    onClick={(e) => this.toggleTippingList(e, "GET_HIGHEST")}
                  >
                    Greatest Value
                  </button>
                </div>
                {/* Divider */}
                <hr className={`sixth-border outsetBorder border mt-3 mx-3 opacity-50`}></hr>
              </div>
              {/* This is the wrapper div that will apply to all data displayed. */}
              <div className={``}>
                {!this.state.massTipSelectionIsLoaded
                  ? <SkeletonLoaderRanking />
                  :
                  <div className={`${this.state.dataListOpacity} transition-to-opacity`}>
                    <div className={`gradient-background-five -mx-1 pb-3 rounded-b-xl`}>
                      <ListConductor
                        {...this.state}
                        goToUserProfilePage={this.goToUserProfilePage}
                        auth={this.props.auth}
                        viewRecipientsArray={this.viewRecipientsArray}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </main>
        {/* Modals */}
        {this.state.pageOverlayActive === true
          ? <OverlayLoadingIcon />
          : <></>
        }
        {this.props.auth?.id
          ?
          <>
            <PaymentModal
              showPaymentModal={this.state.showPaymentModal}
              toggleModal={this.togglePaymentModal}
              triggerPaymentFunction={this.triggerMassTipping}
              auth={this.props.auth}
              transactionType={this.state.transactionType}
              massTipAmount={this.state.massTipAmount}
              numberOfEligibleTippers={this.state.numberOfEligibleTippers}
            />
            <MassTipModal
              showMassTipModal={this.state.showMassTipModal}
              closeModal={this.closeModal}
              confirmPaymentModal={this.confirmPaymentModal}
              handleMassTipAmountChange={this.handleMassTipAmountChange}
              massTipAmount={this.state.massTipAmount}
              massTipMessage={this.state.massTipMessage}
              handleMassTipMessageChange={this.handleMassTipMessageChange}
              charCounterClass={this.state.donationCharCounterClass}
              errorMessage={this.state.massTipMessageError}
              numberOfEligibleTippers={this.state.numberOfEligibleTippers}
            />
          </>
          : <></>
        }
        {this.state.modalDataArray.length > 0
          ? <ArrayModal
            closeArrayModal={this.closeArrayModal}
            showModal={this.state.showArrayModal}
            modalArrayType={'MASS_TIPPERS_ARRAY'}
            goToUserProfilePage={this.goToUserProfilePage}
            modalDataArray={this.state.modalDataArray}
            arrayIsLoading={false}
            arrayModalIsLoading={this.state.arrayModalIsLoading}
            arrayModalOpacity={this.state.arrayModalOpacity}
            massTipAmount={this.state.amountPerPerson}
          />
          : <></>
        }
      </>
    )
  }
}
class ListConductor extends Component {
  // Rendering Switch Statement
  render() {
    switch (this.props.listDataType) {
      case 'GET_LATEST':
        return <MassTippingPage
          listDataType={this.props.listDataType}
          array={this.props.mostRecentArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          title={'Latest Mass Tippings'}
          auth={this.props.auth}
          viewRecipientsArray={this.props.viewRecipientsArray}
        />;
      case 'GET_HIGHEST':
        return <MassTippingPage
          listDataType={this.props.listDataType}
          array={this.props.totalAllTimeArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          title={'Greatest Value Mass Tippings'}
          auth={this.props.auth}
          viewRecipientsArray={this.props.viewRecipientsArray}
        />;
      // Default will be the most earned, although it will never reach this point.
      default:
        return <MassTippingPage
          listDataType={this.props.listDataType}
          array={this.props.mostRecentArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          title={'Latest Mass Tippings'}
          auth={this.props.auth}
          viewRecipientsArray={this.props.viewRecipientsArray}
        />;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.handCashLogInData,
    userDatabaseData: state.auth.userDatabaseData,
    massTippingOpacity: state.opacity.massTippingOpacity
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue)),
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MassTippingContainer);