import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import EditorPageConductor from './EditorPageConductor';
import API from '../../utils/API';
import { connect } from 'react-redux';
import { storeUserDatabaseData } from '../../store/reducers/authSlice';
import { handleQuillThemeChange } from '../../store/reducers/quillThemeSlice';
import { opacityChange } from '../../store/reducers/opacitySlice';
import { globals } from '../../config/globals';
import { toast } from 'react-toastify';
import PaymentModal from '../../components/Modals/PaymentModal';
import ConfirmCancelModal from '../../components/Modals/ConfirmCancelModal';
import OverlayLoadingIcon from '../../components/Widgets/OverlayLoadingIcon';

class EditorPageContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      slug: '',
      postId: '',
      title: '',
      freeContent: '',
      paywallContent: '',
      tags: [],
      tagInput: '',
      tagInputClass: '',
      isKeyReleased: false,
      paywallCost: .01,
      description: '',
      codeEditorContent: '',
      preEditedState: {
        title: '',
        freeContent: '',
        description: '',
        tags: [],
        paywallContent: '',
        codeEditorContent: '',
        codeEditorLanguage: '',
        pollData: []
      },
      categoryName: this.props.match.params.categoryName || '',
      tipValue: '',
      isNewPost: true,
      editorIsLoaded: false,
      charCounterClass: `fifth-text`,
      titleError: '',
      freeContentError: '',
      descriptionError: '',
      codeEditorError: '',
      paywallContentError: '',
      paywallCostError: '',
      overallError: '',
      enableCodeEditor: false,
      codeEditorToggleText: 'Add Code Snippet',
      themeDropdownValue: 'Select Theme',
      languageDropdownValue: 'Select Language',
      codeEditorLanguage: 'javascript',
      themeforCodeEditor: 'material-darker',
      showPaymentModal: false,
      showConfirmCancelModal: false,
      typeOfTransaction: '',
      pageOverlayActive: false,
      nonPaymentOverlay: false,
      paywallAdded: false,
      savingDraft: false,
      // Polls
      pollData: [
        { pollOption: '', numOptionVotes: 0 },
        { pollOption: '', numOptionVotes: 0 }
      ],
      pollTextColor: 'text-gray-900',
      // Stampshot
      stampshotAddIsChecked: false,
    }
  }
  getQuillTheme = () => {
    const localStorageTheme = localStorage.getItem('quillTheme')
    if (localStorageTheme === 'bubble') {
      this.props.handleQuillThemeChange('bubble');
      document.documentElement.setAttribute('data-quill', 'BUBBLE')
    }
  }
  cancelToken = API.CancelToken.source()
  componentDidMount() {
    this.loadInitialData()
    this.checkForMeetTheStamperPost()
    this.getQuillTheme()
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled');
    this.props.handleQuillThemeChange('snow');
    document.documentElement.setAttribute('data-quill', 'SNOW')
  }
  removeHistoryState = () => {
    this.props.history.replace(this.props.location.state, null);
  }
  checkForMeetTheStamperPost = () => {
    if (this.props.match.params.categoryName === 'meet-the-stampers'
      && this.props.userDatabaseData.postedInMeetTheStampers
    ) {
      return (
        this.props.history.push('/'),
        globals.toastError(toast, 'Only able to post once in Meet the Stampers')
      )
    }
  }
  loadInitialData = () => {
    // Check localStorage for auth or Redirect
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    if (!localStorageAuth || !localStorageAuth.authToken) {
      return (
        this.props.history.push('/'),
        globals.toastError(toast, 'Login to edit posts')
      )
    }
    // If the path includes /edit/, the person is going to make edits to an existing post
    // and we need to retrieve that particular post. Otherwise, the path includes the categoryName
    // and we just need to capture the categoryID to attach to a post.
    if (window.location.pathname.includes("/edit/")) {
      // I need to do a check here to ensure that this is the particular person's post.
      this.setState({ isNewPost: false })
      // Check to see if this is someone clicking the edit button and passing in state
      if (this.props.location.state) {
        let post = this.props.location.state;
        // Long Story Short: Calling function because if the state is saved, browser is refreshed, the images from quill editor get removed. They don't when going through interface.
        this.removeHistoryState()
        // Grabbing handle from the post, and matching it with logged-in user.
        if (post.postCreatorHandle !== localStorageAuth.handle) {
          return (
            setTimeout(() => {
              this.props.history.push(`/`)
            }, 300),
            globals.toastError(toast, 'Unable to edit post')
          )
        }
        // If everything checks outs... Set State.
        let enableCodeEditor = false
        if (post.codeEditorContent.length > 0) enableCodeEditor = true;
        let codeEditorLanguage = 'javascript'
        if (post.codeEditorLanguage) codeEditorLanguage = post.codeEditorLanguage;
        this.onSelectLanguage("", codeEditorLanguage)
        const localStorageTheme = localStorage.getItem('theme')
        let themeforCodeEditor = this.state.themeforCodeEditor;
        let themeDropdownValue = this.state.themeDropdownValue;
        if (localStorageTheme === 'LIGHT_THEME') {
          themeforCodeEditor = 'elegant'
          themeDropdownValue = 'Bright'
        }
        if (localStorageTheme === 'DARK_THEME') {
          themeforCodeEditor = 'tomorrow-night-bright'
          themeDropdownValue = 'Night'
        }
        let paywallCost = this.state.paywallCost;
        if (post.paywallCost !== '0.00') paywallCost = parseFloat(post.paywallCost).toFixed(2);
        if (post.tags.length > 2) this.setState({ tagInputClass: 'hidden' })
        return this.setState({
          slug: post.slug,
          postId: post._id,
          title: post.title,
          tags: post.tags,
          category: post.category,
          freeContent: post.freeContent,
          description: post.description,
          categoryName: post.category,
          paywallContent: post.paywallContent,
          codeEditorContent: post.codeEditorContent,
          paywallAdded: post.postHasPaywall,
          paywallCost: paywallCost,
          enableCodeEditor: enableCodeEditor,
          themeforCodeEditor: themeforCodeEditor,
          themeDropdownValue: themeDropdownValue,
          actionTakerIsMember: post.actionTakerIsMember,
          pollData: post.pollData
        }, () =>
          this.setState({ editorIsLoaded: true }, () => {
            setTimeout(() => {
              // preEdited needs to be updated after state is set, as changes are made from initial load
              // ... such as iframes and the added <p><br></p> from Quill
              this.setState({
                preEditedState: {
                  title: post.title,
                  description: post.description,
                  codeEditorLanguage: post.codeEditorLanguage,
                  tags: post.tags,
                  freeContent: this.state.freeContent,
                  codeEditorContent: this.state.codeEditorContent,
                  paywallContent: this.state.paywallContent,
                  pollData: post.pollData
                }
              })
              this.props.opacityChange('addEditorOpacity')
            }, 100)
          }))
      }
      // If post is loaded externally, by user input into browser
      // Because of undesired image behavior, we redirect.
      return (
        setTimeout(() => {
          this.props.history.push(`/`)
        }, 300),
        globals.toastError(toast, `Unable to access Editor via direct link in browser`)
      )
      // API.findPostBySlug(this.props.match.params.slug, this.cancelToken.token)
      //   .then(res => {
      //     // If the logged in user is not the poster
      //     if (res.data[0].postCreatorHandle !== localStorageAuth.handle) {
      //       return (
      //         setTimeout(() => {
      //           this.props.history.push(`/`)
      //         }, 300),
      //         globals.toastError(toast, `Unable to edit other's posts!`)
      //       )
      //     }
      //     // Creating and setting the proper state
      //     let enableCodeEditor = false
      //     if (res.data[0].codeEditorContent.length > 0) enableCodeEditor = true;
      //     let codeEditorLanguage = 'javascript'
      //     if (res.data[0].codeEditorLanguage) codeEditorLanguage = res.data[0].codeEditorLanguage;
      //     this.onSelectLanguage("", codeEditorLanguage)
      //     const localStorageTheme = localStorage.getItem('theme')
      //     let themeforCodeEditor = this.state.themeforCodeEditor;
      //     let themeDropdownValue = this.state.themeDropdownValue;
      //     if (localStorageTheme === 'LIGHT_THEME') {
      //       themeforCodeEditor = 'elegant'
      //       themeDropdownValue = 'Bright'
      //     }
      //     if (localStorageTheme === 'DARK_THEME') {
      //       themeforCodeEditor = 'tomorrow-night-bright'
      //       themeDropdownValue = 'Night'
      //     }
      //     let paywallCost = this.state.paywallCost;
      //     if (res.data[0].paywallCost !== '0.00') paywallCost = parseFloat(res.data[0].paywallCost).toFixed(2)
      //     if (res.data[0].tags.length > 2) this.setState({ tagInputClass: 'hidden' })
      //     // Actually setting the state
      //     return this.setState({
      //       slug: res.data[0].slug,
      //       postId: res.data[0]._id,
      //       title: res.data[0].title,
      //       tags: res.data[0].tags,
      //       freeContent: res.data[0].freeContent,
      //       description: res.data[0].description,
      //       category: res.data[0].category,
      //       categoryName: res.data[0].category,
      //       paywallContent: res.data[0].paywallContent,
      //       codeEditorContent: res.data[0].codeEditorContent,
      //       codeEditorLanguage: codeEditorLanguage,
      //       paywallAdded: res.data[0].postHasPaywall,
      //       paywallCost: paywallCost,
      //       enableCodeEditor: enableCodeEditor,
      //       themeforCodeEditor: themeforCodeEditor,
      //       themeDropdownValue: themeDropdownValue,
      //       actionTakerIsMember: res.data[0].actionTakerIsMember,
      // pollData: res.data[0].pollData
      //       preEditedState: {
      //         title: res.data[0].title,
      //         tags: res.data[0].tags,
      //         freeContent: res.data[0].freeContent,
      //         description: res.data[0].description,
      //         codeEditorContent: res.data[0].codeEditorContent,
      //         codeEditorLanguage: res.data[0].codeEditorLanguage,
      //         paywallContent: res.data[0].paywallContent,
      // pollData: res.data[0].pollData
      //       }
      //     }, () =>
      //       this.setState({ editorIsLoaded: true }, () => {
      //         setTimeout(() => {
      //           this.props.opacityChange('addEditorOpacity')
      //         }, 100)
      //       }))
      //   })
      //   .catch(error => {
      //     console.error('Getting post error: ', error)
      //     if (error.message === 'Operation canceled') return
      //     globals.toastError(toast, error.response.data.message);
      //   })
    }
    // If this is leading to a draft
    else if (window.location.pathname.includes("/draft-update/")) {
      // If it is clicked on from the profile page with state passed in
      if (this.props.location.state) {
        // Grabbing handle from the post, and matching it with logged-in user.
        let postCreatorHandle = this.props.location.state.postCreatorHandle
        // Should also check - just in case - that user is logged in && matches with postCreatorHandle. Otherwise, redirect.
        if (postCreatorHandle !== localStorageAuth.handle) {
          return (
            setTimeout(() => {
              this.props.history.push(`/`)
            }, 300),
            globals.toastError(toast, 'Login to edit posts')
          )
        }
        let draft = this.props.location.state;
        // If everything checks outs... Set State.
        let enableCodeEditor = false;
        if (draft.codeEditorContent.length > 0) enableCodeEditor = true;
        let codeEditorLanguage = 'javascript';
        if (draft.codeEditorLanguage) codeEditorLanguage = draft.codeEditorLanguage;
        this.onSelectLanguage("", codeEditorLanguage);
        const localStorageTheme = localStorage.getItem('theme')
        let themeforCodeEditor = this.state.themeforCodeEditor;
        let themeDropdownValue = this.state.themeDropdownValue;
        if (localStorageTheme === 'LIGHT_THEME') {
          themeforCodeEditor = 'elegant';
          themeDropdownValue = 'Bright';
        }
        if (localStorageTheme === 'DARK_THEME') {
          themeforCodeEditor = 'tomorrow-night-bright';
          themeDropdownValue = 'Night';
        }
        let paywallCost = this.state.paywallCost;
        if (draft.paywallCost !== '0.00') paywallCost = parseFloat(draft.paywallCost).toFixed(2);
        if (draft.tags.length > 2) this.setState({ tagInputClass: 'hidden' })
        return this.setState({
          slug: draft.slug,
          postId: draft._id,
          title: draft.title,
          tags: draft.tags,
          category: draft.category,
          freeContent: draft.freeContent,
          description: draft.description,
          categoryName: draft.category,
          paywallContent: draft.paywallContent,
          codeEditorContent: draft.codeEditorContent,
          paywallAdded: draft.postHasPaywall,
          paywallCost: paywallCost,
          enableCodeEditor: enableCodeEditor,
          themeforCodeEditor: themeforCodeEditor,
          themeDropdownValue: themeDropdownValue,
          actionTakerIsMember: draft.actionTakerIsMember,
          preEditedState: {
            title: draft.title,
            freeContent: draft.freeContent,
            tags: draft.tags,
            description: draft.description,
            codeEditorContent: draft.codeEditorContent,
            codeEditorLanguage: draft.codeEditorLanguage,
            paywallContent: draft.paywallContent,
            pollData: draft.pollData
          },
          pollData: draft.pollData
        }, () =>
          this.setState({ editorIsLoaded: true }, () => {
            setTimeout(() => {
              this.props.opacityChange('addEditorOpacity')
            }, 100)
          }))
      }
      // If it is loaded externally, by user input into browser
      API.getDraft(this.props.match.params.draftSlug, this.cancelToken.token)
        .then(res => {
          let draft = res.data[0];
          // If it is NOT a draft, we redirect to the actual post
          if (!draft.isDraft) {
            return (
              globals.toastSuccess(toast, 'Redirecting to post'),
              setTimeout(() => {
                this.props.history.push(`/posts/${draft.slug}`)
              }, 300)
            )
          }
          // If this is not the user's post.
          if (draft.postCreatorHandle !== localStorageAuth.handle) {
            return (
              setTimeout(() => {
                this.props.history.push(`/`)
              }, 300),
              globals.toastError(toast, 'Login to edit your posts')
            )
          }
          // Set state for draft editing capabilities.
          let enableCodeEditor = false;
          if (draft.codeEditorContent.length > 0) enableCodeEditor = true;
          let codeEditorLanguage = 'javascript'
          if (draft.codeEditorLanguage) codeEditorLanguage = draft.codeEditorLanguage;
          this.onSelectLanguage("", codeEditorLanguage)
          const localStorageTheme = localStorage.getItem('theme')
          let themeforCodeEditor = this.state.themeforCodeEditor;
          let themeDropdownValue = this.state.themeDropdownValue;
          if (localStorageTheme === 'LIGHT_THEME') {
            themeforCodeEditor = 'elegant'
            themeDropdownValue = 'Bright'
          }
          if (localStorageTheme === 'DARK_THEME') {
            themeforCodeEditor = 'tomorrow-night-bright'
            themeDropdownValue = 'Night'
          }
          let paywallCost = this.state.paywallCost;
          if (draft.paywallCost !== '0.00') paywallCost = parseFloat(draft.paywallCost).toFixed(2);
          if (draft.tags.length > 2) this.setState({ tagInputClass: 'hidden' })
          return this.setState({
            slug: draft.slug,
            postId: draft._id,
            title: draft.title,
            tags: draft.tags,
            category: draft.category,
            freeContent: draft.freeContent,
            description: draft.description,
            categoryName: draft.category,
            paywallContent: draft.paywallContent,
            codeEditorContent: draft.codeEditorContent,
            paywallAdded: draft.postHasPaywall,
            paywallCost: paywallCost,
            enableCodeEditor: enableCodeEditor,
            themeforCodeEditor: themeforCodeEditor,
            themeDropdownValue: themeDropdownValue,
            actionTakerIsMember: draft.actionTakerIsMember,
            pollData: draft.pollData,
            preEditedState: {
              title: draft.title,
              freeContent: draft.freeContent,
              description: draft.description,
              tags: draft.tags,
              codeEditorContent: draft.codeEditorContent,
              codeEditorLanguage: draft.codeEditorLanguage,
              paywallContent: draft.paywallContent,
              pollData: draft.pollData
            }
          }, () =>
            this.setState({ editorIsLoaded: true }, () => {
              setTimeout(() => {
                this.props.opacityChange('addEditorOpacity')
              }, 100)
            }))
        })
        .catch(error => {
          console.error('API.getDraft error: ', error)
          setTimeout(() => {
            this.props.history.push(`/`)
          }, 300)
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to get draft');
        })
    }
    // If EditorPageContainer is loaded via the route: /categories/:categoryName/posts/new
    else {
      // If it's beautiful-nature or water-cooler (member posts only)
      if ((this.props.match && this.props.match.params.categoryName === 'water-cooler') ||
        (this.props.match && this.props.match.params.categoryName === 'beautiful-nature')) {
        this.checkForMembership()
      }
      if ((!globals.categoryHrefs.includes(this.props.match.params.categoryName)) || (this.props.match.params.categoryName === 'all')) {
        return (
          globals.toastError(toast, `Category doesn't exist. Redirecting to the home page.`),
          this.props.history.push(`/`)
        )
      }
      return this.setState({ editorIsLoaded: true }, () => {
        setTimeout(() => {
          this.props.opacityChange('addEditorOpacity')
        }, 100)
      })
    }
  }
  checkForMembership = () => {
    // Confirm they are in water-cooler or beautiful-nature category. If they are, continue, if not, return.
    if ((this.props.match && this.props.match.params.categoryName === 'water-cooler') ||
      (this.props.match && this.props.match.params.categoryName === 'beautiful-nature')) {
      // If they are not logged in
      if (!this.props.auth?.id) return (
        globals.toastError(toast, `Must be logged in. Redirecting to the home page.`),
        this.props.history.push(`/`)
      )
      // If the userDatabase data exists in Redux already, and they are NOT a member.
      if (this.props.userDatabaseData && !this.props.userDatabaseData?.membership) return (
        globals.toastError(toast, `Members only. Redirecting to the home page.`),
        this.props.history.push(`/`)
      )
      // If userDatabaseData does exist and they are a member
      if (this.props.userDatabaseData && this.props.userDatabaseData?.membership) return
      // If userDatabaseData doesn't exist yet, but they are logged in, and params are correct
      API.checkForMembership(this.cancelToken.token)
        .then(res => {
          if (res.data === true) return
          else return (
            globals.toastError(toast, `Members only. Redirecting to the home page.`),
            this.props.history.push(`/`)
          )
        })
        .catch(error => {
          console.error(error);
          this.props.history.push(`/`)
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Error retrieving data. Redirecting to home page');
        });
    }
    else return
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
    // This check is added so it doesn't update if it's a list category
    if (this.state.categoryName !== 'letter-to-satoshi' || this.state.categoryName !== 'lists') {
      setTimeout(() => {
        this.checkLengthOnDescription();
      }, 100)
    }
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.title.length > 2 && this.state.title.length <= 280) {
        this.setState({ titleError: '' })
      }
      if (this.state.description.length <= 280) {
        this.setState({ descriptionError: '' })
      }
      if (this.state.freeContentError === '' && this.state.paywallCostError === '' && this.state.paywallContentError === '' && this.state.titleError === '' && this.state.descriptionError === '' && this.state.codeEditorError === '') {
        this.setState({ overallError: '' })
      }
    }, 100)
  }
  checkLengthOnDescription = () => {
    const descriptionLength = this.state.description.length;
    if (descriptionLength <= 200) {
      this.setState({ charCounterClass: `fifth-text` })
    } else if (descriptionLength >= 200 && descriptionLength <= 280) {
      this.setState({ charCounterClass: 'text-orange-500' })
    } else {
      this.setState({ charCounterClass: 'text-red-600' })
    }
  }
  handleEditorChange = (data) => {
    this.setState({ freeContent: data })
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.freeContent.length > 0) {
        this.setState({ freeContentError: '' })
      }
      if (this.state.freeContentError === '' && this.state.paywallCostError === '' && this.state.paywallContentError === '' && this.state.titleError === '' && this.state.descriptionError === '' && this.state.codeEditorError === '') {
        this.setState({ overallError: '' })
      }
    }, 100)
  }
  handlePaywallEditorChange = (data) => {
    this.setState({ paywallContent: data })
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.paywallContent.length > 0) {
        this.setState({
          paywallContentError: ''
        })
      }
      if (this.state.freeContentError === '' && this.state.paywallCostError === '' && this.state.paywallContentError === '' && this.state.paywallContentError === '' && this.state.titleError === '' && this.state.descriptionError === '' && this.state.codeEditorError === '') {
        this.setState({ overallError: '' })
      }
    }, 100)
  }
  togglePaymentModal = (e, typeOfTransaction) => {
    e.preventDefault();
    // If a poll category
    if (this.state.categoryName === 'polls') {
      const validatePollOptions = this.validatePoll()
      if (!validatePollOptions.isValid) return globals.toastError(toast, validatePollOptions.message)
    }
    // Validate everything else
    const validationReturnsTrue = this.validationChecks();
    if (!validationReturnsTrue) return globals.toastError(toast, 'Correct the error(s) and try again')

    // Okay, I can't do prevState, because I need to slow down the state change when it closes. Otherwise, no smooth transition.
    if (this.state.showPaymentModal === false) {
      this.setState({ showPaymentModal: true, transactionType: typeOfTransaction, overallError: '' })
    }
    else {
      this.setState({ showPaymentModal: false, overallError: '' }, () => {
        setTimeout(() => {
          this.setState({ transactionType: typeOfTransaction })
        }, 400)
      })
    }
  };
  determineCostToPost = () => {
    const categoryName = this.props.match.params.categoryName;
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    let postCost = .10
    // If the user is logged in and has membership
    if (localStorageAuth.authToken && this.props.userDatabaseData?.membership) {
      postCost = 0
      this.setState({ nonPaymentOverlay: true })
      return postCost
    }
    // If user is not a member
    if (globals.freeCategoryCostArray.includes(categoryName)) postCost = 0
    if (globals.fiveCentCategoryCostArray.includes(categoryName)) postCost = .05
    if (globals.twentyCentCategoryCostArray.includes(categoryName)) postCost = .20
    if (globals.twentyFiveCentCategoryCostArray.includes(categoryName)) postCost = .25
    if (globals.fiftyCentCategoryCostArray.includes(categoryName)) postCost = .50
    if (categoryName === 'promotions') postCost = globals.promotionsPrice
    if (categoryName === 'bitcoin-businesses') postCost = globals.bitcoinBusinessesPrice
    return postCost;
  }

  // Function is called when a user submits a post from the PaymentModal
  handleSubmitPost = (e) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // Gaurd clause
    if (!this.props.auth?.id || !localStorageAuth?.authToken) {
      return (
        globals.toastError(toast, 'Login with HandCash to post'),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        })
      );
    }
    // Validation for polls
    if (this.state.categoryName === 'polls') {
      const pollValidation = this.validatePoll()
      if (!pollValidation.isValid) return (
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        }),
        globals.toastError(toast, pollValidation.message)
      );
    }
    // Validation Measures
    let validationReturnsTrue = this.validationChecks();
    if (!validationReturnsTrue) {
      return (
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        }),
        globals.toastError(toast, 'Correct the error(s) and try again')
      );
    }
    // Variables for the post
    let updatedFreeContent = this.state.freeContent || ''
    const postCost = this.determineCostToPost();
    const newTitle = this.state.title;
    const newDescription = this.state.description;
    const tags = this.state.tags
    let readTime = ''
    // freeContent manipulation if not polls category
    if (!['polls'].includes(this.state.categoryName)) {
      readTime = this.getPostTimeToRead(updatedFreeContent)
      // Check if ending is an empty paragraph. If so, remove.
      const checkIfEmptyParagraphAtEnd = updatedFreeContent.endsWith(`<p><br></p>`)
      if (checkIfEmptyParagraphAtEnd) {
        do updatedFreeContent = updatedFreeContent.slice(0, -11)
        while (updatedFreeContent.endsWith(`<p><br></p>`))
      }
      updatedFreeContent = updatedFreeContent.replaceAll(new RegExp('iframe', 'g'), 'bdi');
      updatedFreeContent = updatedFreeContent.replaceAll(`contenteditable="false"`, '')
    }
    // Adding links, if applicable. Starting with Regex
    if (updatedFreeContent.length > 0) updatedFreeContent = globals.checkForLinksToAdd(updatedFreeContent)
    // PAYWALL STUFF
    let paywallContent = this.state.paywallContent;
    let paywallCost = this.state.paywallCost;
    if (paywallCost) paywallCost = parseFloat(paywallCost).toFixed(2)
    let postHasPaywall = this.state.paywallAdded;
    if (postHasPaywall) {
      paywallContent = paywallContent.replaceAll(new RegExp('iframe', 'g'), 'bdi');
      paywallContent = paywallContent.replaceAll(`contenteditable="false"`, '')
      // Need to remove empty paragraphs from paywall
      const checkIfEmptyParagraph = paywallContent.endsWith(`<p><br></p>`)
      if (checkIfEmptyParagraph) {
        do paywallContent = paywallContent.slice(0, -11)
        while (paywallContent.endsWith(`<p><br></p>`))
      }
      paywallContent = globals.checkForLinksToAdd(paywallContent)
    }
    // Paywall Validation Measures
    // If somehow someone enters a value lower than 0.
    if (!paywallCost < 0) { this.setState({ paywallAdded: false, paywallContent: '', paywallCost: 0 }); postHasPaywall = false }
    if (!this.state.paywallAdded) { paywallContent = ''; paywallCost = 0; }
    let newOrEditedPost = {
      category: this.state.categoryName,
      title: newTitle.trim(),
      freeContent: updatedFreeContent,
      description: newDescription.trim(),
      tags: tags,
      readTime: readTime,
      postCost: postCost,
      postCreatorHandle: localStorageAuth.handle,
      postCreatorPaymail: localStorageAuth.paymail,
      postCreatorAvatarURL: localStorageAuth.avatarUrl,
      postCreatorHandCashId: localStorageAuth.id,
      paywallCost: paywallCost,
      paywallContent: paywallContent,
      postHasPaywall: postHasPaywall,
      isDraft: false,
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      followerHandCashIds: this.props.userDatabaseData?.followerHandCashIds
    };
    // If this should be a StampShot
    if (this.state.isNewPost === true && this.state.stampshotAddIsChecked === true
      && globals.nonStandardCategoriesArray.includes(this.state.categoryName)
      && this.props.userDatabaseData?.membership) {
      newOrEditedPost.isStampshot = 'isStampshot'
    }
    // Updating post if it has polls
    if (this.state.categoryName === 'polls') {
      let trimmedPollArray = []
      this.state.pollData.forEach(object => {
        // This removes any whitespace and then only pushes the array if it has length.
        let newPollObject = object
        newPollObject.pollOption.trim()
        if (newPollObject.pollOption.length > 0) {
          trimmedPollArray.push(newPollObject)
        }
      })
      newOrEditedPost.pollData = trimmedPollArray
    }
    // CodeEditor stuff
    if (this.state.enableCodeEditor && this.state.codeEditorContent.length > 0) {
      newOrEditedPost.codeEditorLanguage = this.state.codeEditorLanguage;
      newOrEditedPost.codeEditorContent = this.state.codeEditorContent;
      newOrEditedPost.themeforCodeEditor = this.state.themeforCodeEditor;
    }
    else {
      newOrEditedPost.codeEditorLanguage = '';
      newOrEditedPost.codeEditorContent = '';
      newOrEditedPost.themeforCodeEditor = '';
    }
    // Determines if this is an update to a post, or a new one to be created.
    if (window.location.pathname.includes("/posts/new")) {
      this.createPost(newOrEditedPost);
    }
    // If it is an UPDATE to a post:
    else {
      // [1] In order to prevent the database from triggering an update when nothing has changed,
      // [2] we compare the state with the data we already received. 
      // [3] If there are no changes, we would then launch the function.
      // [4] This is the reason we capture the same data and store it in seperate states.
      if (this.props.match.params.slug && this.state.title === this.state.preEditedState.title &&
        this.state.freeContent === this.state.preEditedState.freeContent &&
        this.state.paywallContent === this.state.preEditedState.paywallContent &&
        this.state.description === this.state.preEditedState.description &&
        this.state.codeEditorContent === this.state.preEditedState.codeEditorContent &&
        // this.state.codeEditorLanguage === this.state.preEditedState.codeEditorLanguage &&
        this.state.tags === this.state.preEditedState.tags && this.state.pollData === this.state.preEditedState.pollData
      ) {
        // If no edits are made, setState and go back to the post.
        this.setState({
          slug: '',
          title: '',
          tags: '',
          freeContent: '',
          paywallContent: '',
          description: '',
          codeEditorContent: '',
          pollData: [],
          preEditedState: {
            title: '',
            freeContent: '',
            description: '',
            tags: '',
            paywallContent: '',
            codeEditorContent: '',
            codeEditorLanguage: '',
            pollData: [],
          },
        })
        this.props.opacityChange('removeEditorOpacity')
        // Don't want it to be perceived as a negative, so we're using the green, toastSuccess here.
        globals.toastSuccess(toast, 'No changes have been made. Redirecting to post.');
        setTimeout(() => {
          this.props.history.push(`/posts/${this.props.match.params.slug}`)
        }, 300);
      }
      // If all checks pass and user is updating the post...
      else {
        newOrEditedPost.id = this.state.postId;
        newOrEditedPost.preEditedTags = this.state.preEditedState.tags
        this.toggleOverlay()
        API.updatePost(localStorageAuth.handle, newOrEditedPost)
          .then(res => {
            this.setState({
              slug: '',
              title: '',
              freeContent: '',
              description: '',
              tags: '',
              paywallContent: '',
              codeEditorContent: '',
              pollData: [],
              preEditedState: {
                title: '',
                freeContent: '',
                description: '',
                tags: '',
                paywallContent: '',
                codeEditorContent: '',
                codeEditorLanguage: '',
                pollData: []
              },
            })
            this.props.opacityChange('removeEditorOpacity')
            globals.toastSuccess(toast, 'Update successful! Redirecting to post.');
            setTimeout(() => {
              this.props.history.push(`/posts/${res.data.slug}`)
            }, 300);
          })
          .catch(err => {
            console.error('updated post error: ', err)
            globals.toastError(toast, err.response.data.message);
            setTimeout(() => {
              this.setState({ showPaymentModal: false, pageOverlayActive: false })
            }, 400)
          })
      }
    };
  };
  validatePoll = () => {
    let pollObject = { isValid: true, message: '' }
    const pollsArray = this.state.pollData
    if (pollsArray.length > 6) {
      pollObject = { isValid: false, message: `Only six poll options please` }
      return pollObject
    }
    // Validation pollData Array
    for (let i = 0; i < pollsArray.length; i++) {
      let pollItem = pollsArray[i].pollOption.trim()
      if (pollItem.length === 0) {
        pollObject = { isValid: false, message: `Poll option(s) need more material` }
        return pollObject
      }
      if (pollItem.length > 60) {
        pollObject = { isValid: false, message: `Poll option(s) need trimming` }
        return pollObject
      }
    }
    return pollObject
  }
  validationChecks = () => {
    let allChecksValid;

    // Title Validation
    let titleIsValid = true;
    if (this.state.title.length <= 2) {
      this.setState({
        titleError: '*Your post could use a longer title*'
      })
      titleIsValid = false
    } else if (this.state.title.length > 280) {
      this.setState({
        titleError: `*Your title could use a trimming*`
      })
      titleIsValid = false
    }

    // Description Validation (if applicable)
    let descriptionIsValid = true;
    // Post the things that are false
    if (this.state.description.length > 280) {
      this.setState({
        descriptionError: '*Description must be less than 280 characters*'
      })
      descriptionIsValid = false
    }

    // TAGS validation (Doesn't need an error cause UI doesn't allow more than 3. Would be nefariously forced)
    let tagLengthIsValid = true
    if (this.state.tags.length > 3) tagLengthIsValid = false

    // Validation for Polls and Lists returns out before freeContent or Code Content or paywall
    if (['polls', 'lists', 'questions'].includes(this.state.categoryName)) {
      // Overall for Polls and lists
      if (!descriptionIsValid || !titleIsValid || !tagLengthIsValid) {
        allChecksValid = false
        this.setState({ overallError: `*Psst, fix errors and try again*` })
        return allChecksValid
      }
      allChecksValid = true;
      return allChecksValid;
    }
    // FreeContent Validation
    let freeContentIsValid;
    if (!['lists'].includes(this.state.categoryName) && this.state.freeContent.length < 3) {
      this.setState({
        freeContentError: '*Your post could use some material*',
      })
      freeContentIsValid = false
    }
    else freeContentIsValid = true;

    // CodeEditorContent Validation (if applicable)
    let codeEditorContentIsValid;
    // This checks to see if there is actually an active codeEditor
    if (this.state.enableCodeEditor === false) {
      codeEditorContentIsValid = true
    }
    else {
      if (this.state.codeEditorContent.length > 1 && this.state.enableCodeEditor === true) {
        codeEditorContentIsValid = true
      } else {
        // Need to add in an error message
        this.setState({
          codeEditorError: '*Your snippet could use some material*'
        })
        codeEditorContentIsValid = false
      }
    }
    // Paywall
    let paywallContentIsValid = true;
    if (this.state.paywallAdded === true && this.state.paywallContent.length < 3) {
      this.setState({
        paywallContentError: '*Your paywall could use some material*'
      })
      paywallContentIsValid = false
    }

    let paywallCostIsValid = true;
    if (this.state.paywallAdded === true) {
      let paywallCost = parseFloat(this.state.paywallCost)
      if (isNaN(paywallCost) || paywallCost === 0) {
        this.setState({
          paywallCostError: '*Please enter a valid number*'
        })
        paywallCostIsValid = false
      }
    }

    // This allChecksValid will return true or false to initiate the function in handleSubmitPost
    if (!descriptionIsValid || !titleIsValid || !tagLengthIsValid || !freeContentIsValid || !codeEditorContentIsValid || !paywallCostIsValid || !paywallContentIsValid) {
      allChecksValid = false
      this.setState({ overallError: `*Psst, fix errors and try again*` })
    }
    else allChecksValid = true;
    return allChecksValid;
  }
  // This prevents the database from getting spammed by people saving drafts without making changes.
  verifyChangesMade = () => {
    let changesHaveBeenMade = true
    if (this.state.title === this.state.preEditedState.title &&
      this.state.freeContent === this.state.preEditedState.freeContent &&
      this.state.paywallContent === this.state.preEditedState.paywallContent &&
      this.state.paywallCost === this.state.preEditedState.paywallCost &&
      this.state.description === this.state.preEditedState.description &&
      this.state.codeEditorContent === this.state.preEditedState.codeEditorContent &&
      this.state.codeEditorLanguage === this.state.preEditedState.codeEditorLanguage &&
      this.state.tags === this.state.preEditedState.tags &&
      this.state.pollData === this.state.preEditedState.pollData
    ) {
      changesHaveBeenMade = false
    }
    return changesHaveBeenMade;
  }
  // This is Medium.com's formula. Starts with 12 seconds per image, then drops by 1, and ends with 3 seconds per image. Which is the 10th image added
  convertImagesToSeconds = (number) => {
    if (number === 0) return 0
    // This adds 3 seconds to all
    const returnedValue = number * 3
    let seconds = 9
    let variableSeconds = 0
    // This loops through images 1-9
    for (let index = 1; index < 10; index++) {
      variableSeconds = variableSeconds + seconds
      // Subtract seconds
      seconds -= 1
      if (index === number) break
    }
    const totalSeconds = returnedValue + variableSeconds
    return totalSeconds;
  }
  // 
  getPostTimeToRead = (string) => {
    let countImages = 0
    // Check for images. If they exist, we do the calculation
    if (string.toString().match(/<img/)) countImages = string.toString().match(/<img/g).length;
    const secondsFromImages = this.convertImagesToSeconds(countImages)
    // Remove tags & attributes, including images and iframes
    const newString = string.replace(/<img[^>]+>(<\/img>)?|<iframe.+?<\/iframe>|&nbsp;|<[^>]*>?/gm, ' ')
    // // Find length of words, store in word-length variable
    const updatingStringToArray = newString.split(' ');
    const numberOfWords = updatingStringToArray.filter(word => word !== '').length
    // This gives me how many minutes it takes. 265 average words a human reads per minute.
    const percentageInMinutes = (numberOfWords / 265)
    const secondsFromStrings = percentageInMinutes * 60
    const totalSeconds = secondsFromStrings + secondsFromImages
    const totalMinutes = totalSeconds / 60;
    // Return a string we can display on the frontend
    let returnedValue = '<1 min read'
    if (totalMinutes >= 1) {
      const roundingTotalMinutes = Math.round(totalMinutes)
      returnedValue = `~${roundingTotalMinutes} min read`
    }
    return returnedValue
  }
  // Saving a draft
  saveDraft = (e) => {
    e.preventDefault();
    if (this.state.categoryName === 'polls') {
      const validatePollOptions = this.validatePoll()
      if (!validatePollOptions.isValid) return globals.toastError(toast, validatePollOptions.message)
    }
    const validationReturnsTrue = this.validationChecks();
    if (!validationReturnsTrue) return globals.toastError(toast, 'Additional content needed before saving')
    const verifyChanges = this.verifyChangesMade()
    if (!verifyChanges) return globals.toastError(toast, 'No changes have been made')
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // Gaurd clause
    if (!this.props.auth?.id || !localStorageAuth?.authToken) {
      return globals.toastError(toast, 'Login with HandCash to submit draft')
    }
    // Setting up the variables
    let updatedFreeContent = ''
    if (this.state.categoryName !== 'polls') {
      let freeContent = this.state.freeContent;
      updatedFreeContent = freeContent.replaceAll(new RegExp('iframe', 'g'), 'bdi');
    }
    let postHasPaywall = this.state.paywallAdded;
    // Creating the object submitted to the saveDraft API call
    let draftData = {
      title: this.state.title,
      tags: this.state.tags,
      category: this.props.match.params.categoryName,
      freeContent: updatedFreeContent,
      description: this.state.description,
      postCreatorHandle: localStorageAuth.handle,
      postCreatorPaymail: localStorageAuth.paymail,
      postCreatorAvatarURL: localStorageAuth.avatarUrl,
      postCreatorHandCashId: localStorageAuth.id,
      codeEditorContent: this.state.codeEditorContent,
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      isDraft: true,
    }
    if (this.state.categoryName === 'polls') {
      let trimmedPollArray = []
      this.state.pollData.forEach(object => {
        // This removes any whitespace and then only pushes the array if it has length.
        let newPollObject = object
        newPollObject.pollOption.trim()
        if (newPollObject.pollOption.length > 0) {
          trimmedPollArray.push(newPollObject)
        }
      })
      draftData.pollData = trimmedPollArray
    }
    // Conditionally adding properties of paywall / codeEditor
    if (postHasPaywall) {
      let paywallContent = this.state.paywallContent
      let updatedPaywallContent = paywallContent.replace(new RegExp('iframe', 'g'), 'bdi');
      draftData.paywallContent = updatedPaywallContent;
      draftData.postHasPaywall = postHasPaywall;
      draftData.paywallCost = parseFloat(this.state.paywallCost).toFixed(2)
    }
    if (this.state.enableCodeEditor) {
      draftData.codeEditorLanguage = this.state.codeEditorLanguage;
      draftData.codeEditorContent = this.state.codeEditorContent;
      draftData.themeforCodeEditor = this.state.themeforCodeEditor;
    }
    // Disabling buttons to create post and save draft
    this.setState({ savingDraft: true })
    // Create new post if it doesn't exist in database
    if (!this.state.postId) {
      API.createPost(draftData)
        .then(res => {
          // Update state
          this.setState({
            // Setting postId, so if the user saves another draft, it triggers API.saveDraft instead of creating a new post
            postId: res.data._id,
            // Setting preEdited state to ensure that people don't spam the database with unchanged data
            preEditedState: {
              title: this.state.title,
              tags: this.state.tags,
              freeContent: this.state.freeContent,
              description: this.state.description,
              paywallContent: this.state.paywallContent,
              paywallCost: this.state.paywallCost,
              codeEditorContent: this.state.codeEditorContent,
              codeEditorLanguage: this.state.codeEditorLanguage,
              themeforCodeEditor: this.state.themeforCodeEditor,
              pollData: this.state.pollData
            },
            savingDraft: false,
          })
          globals.toastSuccess(toast, 'Your draft has been saved!');
        })
        .catch(err => {
          console.error('updated post error: ', err)
          globals.toastError(toast, 'Please try again');
          this.setState({ savingDraft: false })
        })
    }
    // Save draft if it already exists in database
    else {
      draftData.postId = this.state.postId;
      API.saveDraft(draftData)
        .then(res => {
          this.setState({
            savingDraft: false,
            preEditedState: {
              title: this.state.title,
              freeContent: this.state.freeContent,
              description: this.state.description,
              tags: this.state.tags,
              paywallContent: this.state.paywallContent,
              paywallCost: this.state.paywallCost,
              codeEditorContent: this.state.codeEditorContent,
              codeEditorLanguage: this.state.codeEditorLanguage,
              themeforCodeEditor: this.state.themeforCodeEditor,
              pollData: this.state.pollData
            },
          })
          globals.toastSuccess(toast, 'Your draft has been saved!');
        })
        .catch(err => {
          console.error('updated post error: ', err)
          globals.toastError(toast, err.response.data.message);
          this.setState({ savingDraft: false })
        })
    }
  }
  toggleOverlay = () => {
    this.setState({ showPaymentModal: false }, () => {
      setTimeout(() => {
        this.setState({ transactionType: '', pageOverlayActive: true })
      }, 400)
    })
  }
  // Creates a Post.
  createPost = async (postContent) => {
    let categoryName = this.state.categoryName
    Object.assign(postContent, { category: categoryName })
    // Check this to see if it is a proper categoryName
    if ((!globals.categoryHrefs.includes(categoryName)) || (categoryName === 'all')) {
      return (
        console.error('Not a valid category.'),
        globals.toastError(toast, 'Not a valid category')
      )
    }
    try {
      this.toggleOverlay()
      if (this.props.match.params.categoryName === globals.meetTheStampers.href) this.setState({ nonPaymentOverlay: true })
      // Create Post
      const response = await API.createPost(postContent);
      // Grab data and create variables for updates
      const slug = response.data.createPost.slug;
      const totalPostsCreated = response.data.userUpdate.totalPostsCreated
      const totalPostsValuePaid = response.data.userUpdate.totalPostsValuePaid
      const totalValueSent = response.data.userUpdate.totalValueSent
      this.props.storeUserDatabaseData({ type: 'updateForPostCreation', totalPostsCreated, totalPostsValuePaid, totalValueSent })
      // 2nd variation is slug. For now let's test the ID
      this.setState({
        title: '',
        freeContent: '',
        description: '',
        tags: '',
        codeEditorContent: '',
        codeEditorLanguage: '',
        paywallContent: '',
      });
      globals.toastSuccess(toast, 'Post successfully created!')
      this.props.opacityChange('removeEditorOpacity')
      setTimeout(() => {
        this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        this.props.history.push(`/posts/${slug}`)
      }, 300);
    } catch (error) {
      console.error(error);
      globals.toastError(toast, error.response.data.message);
      setTimeout(() => {
        this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
      }, 400)
    }
  };
  goBackToPost = (e) => {
    e.preventDefault();
    this.setState({ showConfirmCancelModal: false })
    this.props.opacityChange('removeEditorOpacity');
    setTimeout(() => {
      this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
      this.props.history.push(`/posts/${this.state.slug}`)
    }, 300);
  }
  goBackToCategoryPostList = (e) => {
    e.preventDefault();
    this.setState({ showConfirmCancelModal: false })
    this.props.opacityChange('removeEditorOpacity');
    setTimeout(() => {
      this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
      if (this.props.location.state && this.props.location.state.category) {
        this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        return this.props.history.push(`/categories/${this.props.location.state.category}`)
      }
      return this.props.history.push(`/categories/${this.props.match.params.categoryName}`)
    }, 300);
  }
  toggleCodeEditor = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ enableCodeEditor: !prevState.enableCodeEditor }), () => {
      if (this.state.enableCodeEditor === true) {
        this.setState({ codeEditorToggleText: 'Remove Code Snippet' })
      } else {
        this.setState({ codeEditorToggleText: 'Add Code Snippet', codeEditorContent: '' })
      }
    })
  };
  handleCodeEditorChange = (editor, data, value) => {
    this.setState({ codeEditorContent: value })
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.enableCodeEditor === true) {
        if (this.state.codeEditorContent.length > 1) {
          this.setState({ codeEditorError: '' })
        }
      }
      if (this.state.freeContentError === '' && this.state.paywallContentError === '' && this.state.titleError === '' && this.state.descriptionError === '' && this.state.codeEditorError === '') {
        this.setState({ overallError: '' })
      }
    }, 100)
  };
  removeActiveClassOfDropdown = () => {
    document.querySelectorAll("[data-dropdown].active").forEach(dropdown => {
      dropdown.classList.remove('active')
    })
  }
  onSelectLanguage = (e, key) => {
    if (e) {
      e.preventDefault()
      this.removeActiveClassOfDropdown()
    }
    let codeEditorLanguage = key;
    this.setState({ languageDropdownValue: codeEditorLanguage })
    switch (codeEditorLanguage) {
      case 'JavaScript':
        this.setState({ codeEditorLanguage: 'javascript' })
        break
      case 'Python':
        this.setState({ codeEditorLanguage: 'python' })
        break
      case 'CSS':
        this.setState({ codeEditorLanguage: 'css' })
        break
      case 'HTML/XML':
        this.setState({ codeEditorLanguage: 'xml' })
        break
      case 'C, C++, C#':
        this.setState({ codeEditorLanguage: 'clike' })
        break
      case 'javascript':
        this.setState({ codeEditorLanguage: 'javascript', languageDropdownValue: 'JavaScript' })
        break
      case 'python':
        this.setState({ codeEditorLanguage: 'python', languageDropdownValue: 'Python' })
        break
      case 'css':
        this.setState({ codeEditorLanguage: 'css', languageDropdownValue: 'CSS' })
        break
      case 'xml':
        this.setState({ codeEditorLanguage: 'xml', languageDropdownValue: 'HTML/XML' })
        break
      case 'clike':
        this.setState({ codeEditorLanguage: 'clike', languageDropdownValue: 'C, C++, C#' })
        break
      default:
        this.setState({ codeEditorLanguage: 'javascript', languageDropdownValue: 'Select Language' })
    }
  }
  onSelectCodeTheme = (e, key) => {
    if (e) {
      e.preventDefault()
      this.removeActiveClassOfDropdown()
    }
    let themeforCodeEditor = key;
    switch (themeforCodeEditor) {
      case 'elegant':
        this.setState({ themeforCodeEditor: 'elegant', themeDropdownValue: 'Bright' })
        break
      case 'material-darker':
        this.setState({ themeforCodeEditor: 'material-darker', themeDropdownValue: 'Dark' })
        break
      case 'mdn-like':
        this.setState({ themeforCodeEditor: 'mdn-like', themeDropdownValue: 'Light' })
        break
      case 'tomorrow-night-bright':
        this.setState({ themeforCodeEditor: 'tomorrow-night-bright', themeDropdownValue: 'Night' })
        break
      default:
        this.setState({ themeforCodeEditor: 'material-darker', themeDropdownValue: 'Dark' })
    }
  }
  addPaywall = (e) => {
    e.preventDefault();
    if (this.state.paywallAdded === true) {
      // I'll need to check if this is false to decide on adding paywall data or not.
      // Because I don't want to wipe what someone put in. I want to keep that in case they misclick or
      // change their mind. So that means I need to do a check on Submit. If it's false, we don't add the paywall data or I change it to ''
      this.setState({ paywallAdded: false });
    }
    else {
      this.setState({ paywallAdded: true });
    }
  }
  handlePaywallCostChange = (e) => {
    this.setState({ paywallCost: e.target.value, paywallCostError: '' });
    let source = document.querySelector('.updatingPaywallValue')
    source.addEventListener('change', () => {
      source.value = parseFloat(source.value).toFixed(2)
    });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
    // This check is added so it doesn't update if it's a list category
    if (this.state.categoryName !== 'letter-to-satoshi' || this.state.categoryName !== 'lists') {
      setTimeout(() => {
        this.checkLengthOnDescription();
      }, 100)
    }
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.title.length > 2 && this.state.title.length <= 280) {
        this.setState({ titleError: '' })
      }
      if (this.state.description.length <= 280) {
        this.setState({ descriptionError: '' })
      }
      if (this.state.freeContentError === '' && this.state.paywallContentError === '' && this.state.paywallCostError === '' && this.state.titleError === '' && this.state.descriptionError === '' && this.state.codeEditorError === '') {
        this.setState({ overallError: '' })
      }
    }, 100)
  }
  ///////////////
  // TAG STUFF //
  ///////////////
  removeTag = (e, i) => {
    e.preventDefault()
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.setState({ tags: newTags, tagInputClass: '' });
  }
  onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = this.state.tagInput.trim();
    // Allows letters, numbers, and spaces
    const regexTest = new RegExp(`^[a-zA-Z0-9 ]+$`);
    // Comma
    if (key === ',') {
      // If they're the same or there is no length
      if (trimmedInput.length > 24) {
        e.preventDefault()
        return globals.toastError(toast, 'Tag must be under 25 characters');
      }
      if (this.state.tags.find(tag => tag.toLowerCase() === trimmedInput.toLowerCase()) || trimmedInput.length < 2) {
        e.preventDefault();
        return
      }
      e.preventDefault();
      this.setState({ tags: [...this.state.tags, trimmedInput], tagInput: '' });
      // The tags.length doesn't register immediately upon the change, so the length === 2 works without delay
      if (this.state.tags.length >= 2) {
        this.setState({ tagInputClass: 'hidden' })
      }
    }
    // Enter
    if (key === 'Enter') {
      if (trimmedInput.length > 24) {
        e.preventDefault()
        return globals.toastError(toast, 'Tag must be under 25 characters');
      }
      if (this.state.tags.find(tag => tag.toLowerCase() === trimmedInput.toLowerCase()) || trimmedInput.length < 2) {
        e.preventDefault();
        return
      }
      e.preventDefault();
      this.setState({ tags: [...this.state.tags, trimmedInput], tagInput: '' });
      if (this.state.tags.length >= 2) {
        this.setState({ tagInputClass: 'hidden' })
      }
    }
    // Backspace key
    if (key === "Backspace" && !this.state.tagInput.length && this.state.tags.length && this.state.isKeyReleased) {
      const tagsCopy = [...this.state.tags]
      const poppedTag = tagsCopy.pop()
      e.preventDefault()
      this.setState({ tags: tagsCopy, tagInput: poppedTag })
    }
    // This allows only letters and spacebar
    if (!regexTest.test(key)) {
      e.preventDefault()
      return
    }
    this.setState({ isKeyReleased: false })
  }
  addTagOnClick = (e) => {
    e.preventDefault()
    const trimmedInput = this.state.tagInput.trim();
    if (trimmedInput.length > 24) {
      return globals.toastError(toast, 'Tag must be under 25 characters');
    }
    // If tag already exists or there is no length
    if (this.state.tags.find(tag => tag.toLowerCase() === trimmedInput.toLowerCase()) || trimmedInput.length < 2) {
      return
    }
    this.setState({ tags: [...this.state.tags, trimmedInput], tagInput: '' });
    // The tags.length doesn't register immediately upon the change, so the length === 2 works without delay
    if (this.state.tags.length >= 2) {
      this.setState({ tagInputClass: 'hidden' })
    }
  }
  onTagChange = (e) => {
    const { value } = e.target;
    this.setState({ tagInput: value })
  };
  onKeyUp = () => {
    this.setState({ isKeyReleased: true });
  }
  toggleConfirmCancelModal = (e) => {
    e.preventDefault();
    if (this.state.showConfirmCancelModal === false) {
      this.setState({ showConfirmCancelModal: true })
    }
    else this.setState({ showConfirmCancelModal: false })
  }
  closeConfirmCancelModal = (e) => {
    e.preventDefault();
    this.setState({ showConfirmCancelModal: false })
  }
  changeQuillTheme = (e) => {
    e.preventDefault();
    if (this.props.quillTheme === 'bubble') {
      localStorage.setItem('quillTheme', 'snow')
      document.documentElement.setAttribute('data-quill', 'SNOW')
      this.props.handleQuillThemeChange('snow');
    }
    if (this.props.quillTheme === 'snow') {
      document.documentElement.setAttribute('data-quill', 'BUBBLE')
      localStorage.setItem('quillTheme', 'bubble')
      this.props.handleQuillThemeChange('bubble');
    }
  }
  handleStampshotCheckbox = () => {
    if (this.state.stampshotAddIsChecked) {
      this.setState({ stampshotAddIsChecked: false })
    }
    if (!this.state.stampshotAddIsChecked) {
      this.setState({ stampshotAddIsChecked: true })
    }
  }

  handlePollOptionChange = (e, index) => {
    const value = e.target.value
    // Change color to red as user approaches end limit, and switch back to gray when they delete
    if (this.state.pollTextColor !== 'text-gray-900' && value.length < 60) this.setState({ pollTextColor: 'text-gray-900' })
    if (value.length > 58) this.setState({ pollTextColor: 'text-red-600 font-bold' })
    if (value.length > 59) return
    let pollData = [...this.state.pollData]
    let item = { ...pollData[index] }
    item.pollOption = value
    pollData[index] = item
    this.setState({ pollData })
  }
  handleRemovePollOption = (e, index) => {
    if ([0, 1].includes(index)) return
    e.preventDefault()
    const newPollData = [...this.state.pollData]
    newPollData.splice(index, 1)
    this.setState({ pollData: newPollData })
  }
  handleAddPollOption = (e) => {
    e.preventDefault()
    let pollData = [...this.state.pollData]
    pollData.push({ pollOption: '', numOptionVotes: 0 })
    this.setState({ pollData: pollData })
  }
  render() {
    if (!this.props.auth) return <Redirect to='/' />
    return (
      <>
        <EditorPageConductor
          {...this.state}
          userDatabaseData={this.props.userDatabaseData}
          togglePaymentModal={this.togglePaymentModal}
          handleChange={this.handleChange}
          handleEditorChange={this.handleEditorChange}
          editorOpacity={this.props.editorOpacity}
          goBackToCategoryPostList={this.goBackToCategoryPostList}
          toggleCodeEditor={this.toggleCodeEditor}
          handleCodeEditorChange={this.handleCodeEditorChange}
          onSelectCodeTheme={this.onSelectCodeTheme}
          onSelectLanguage={this.onSelectLanguage}
          handlePaywallEditorChange={this.handlePaywallEditorChange}
          addPaywall={this.addPaywall}
          handlePaywallCostChange={this.handlePaywallCostChange}
          saveDraft={this.saveDraft}
          // Tag Stuff
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          removeTag={this.removeTag}
          onTagChange={this.onTagChange}
          addTagOnClick={this.addTagOnClick}
          toggleConfirmCancelModal={this.toggleConfirmCancelModal}
          changeQuillTheme={this.changeQuillTheme}
          quillTheme={this.props.quillTheme}
          handlePollOptionChange={this.handlePollOptionChange}
          handleAddPollOption={this.handleAddPollOption}
          handleRemovePollOption={this.handleRemovePollOption}
          handleStampshotCheckbox={this.handleStampshotCheckbox}
        />
        {this.state.pageOverlayActive
          ? <OverlayLoadingIcon nonPaymentOverlay={this.state.nonPaymentOverlay} />
          : <></>
        }
        {this.props.auth?.id
          ?
          <>
            <PaymentModal
              showPaymentModal={this.state.showPaymentModal}
              toggleModal={this.togglePaymentModal}
              triggerPaymentFunction={this.handleSubmitPost}
              auth={this.props.auth}
              transactionType={this.state.transactionType}
              categoryName={this.state.categoryName}
              userDatabaseData={this.props.userDatabaseData}
            />
            <ConfirmCancelModal
              showConfirmCancelModal={this.state.showConfirmCancelModal}
              closeConfirmCancelModal={this.closeConfirmCancelModal}
              confirmCancel={
                window.location.pathname.includes("/edit/")
                  ? this.goBackToPost
                  : this.goBackToCategoryPostList}
            />
          </>
          : <></>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    editorOpacity: state.opacity.editorOpacity,
    auth: state.auth.handCashLogInData,
    userDatabaseData: state.auth.userDatabaseData,
    quillTheme: state.quillTheme.quillTheme
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleQuillThemeChange: (toggleValue) => dispatch(handleQuillThemeChange(toggleValue)),
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue)),
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorPageContainer);
