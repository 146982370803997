import React from 'react';
import TagsInput from './TagsInput'
import DescriptionInput from './DescriptionInput'
import TitleInput from './TitleInput'
import EditorButtons from './EditorButtons'
import QuillEditorForNonMembers from '../../components/Editors/QuillEditorForNonMembers'
import QuillEditorForMembers from '../../components/Editors/QuillEditorForMembers'

// On this page, I'll need a conditional render based upon the categoryName param,
// which is dependent on being a "list type" post or a regular post.

// List posts will not include the freeContent or paywallContent. It will only need
// a title and body

const EditorPageForPosts = props => {
  const {
    handleEditorChange,
    handlePaywallEditorChange,
    handleChange,
    freeContent,
    categoryNameDisplay,
    isNewPost,
    editorOpacity,
    editorIsLoaded,
    goBackToCategoryPostList,
    togglePaymentModal,
    paywallContent,
    addPaywall,
    paywallAdded,
    paywallCost,
    handlePaywallCostChange,
    saveDraft,
    userDatabaseData,
    // Tag stuff
    tagInput,
    onTagChange,
    onKeyUp,
    onKeyDown,
    tags,
    removeTag,
    tagInputClass,
    addTagOnClick,
    changeQuillTheme,
    quillTheme
  } = props;
  return (
    <>
      {!editorIsLoaded
        ?
        <> </>
        :
        <main id='main-content' className={`flex -mt-2 rounded-xl md:inline-flex mb-4 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition `}>
          <div className={`w-full rounded-xl px-1 ${editorOpacity} transition-to-opacity`}>
            <div className={`pb-3 mb-2 rounded-xl transition first-text`}>
              <form>
                <TitleInput
                  categoryNameDisplay={categoryNameDisplay}
                  handleChange={handleChange}
                  title={props.title}
                  titleError={props.titleError}
                  noDescription={false}
                  editorPageForPosts={true}
                />
                {/* Description Input */}
                <DescriptionInput
                  charCounterClass={props.charCounterClass}
                  description={props.description}
                  handleChange={handleChange}
                  descriptionError={props.descriptionError}
                />
                {/* Body/Content Input */}
                <div className={`sm:mx-2 mb-2 ${quillTheme === 'snow' ? `mt-6` : ''}`}>
                  <div className={`rounded-xl text-gray-900 mt-3px`}>
                    <button onClick={(e) => changeQuillTheme(e)}
                      className={`font-header gradient-background-twelve shadow-effect-seven ${quillTheme === 'snow' ? `float-right mr-3 mt-3` : 'mb-3'}
                      eighth-text button-ripple transition rounded-xl cursor-pointer px-1 category-hover text-xs mx-1 `}>
                      Change Editors
                    </button>
                    {userDatabaseData?.membership
                      ? <QuillEditorForMembers quillTheme={quillTheme} handleEditorChange={handleEditorChange} content={freeContent} selectedPlaceholder={"Tap here to begin"} />
                      : <QuillEditorForNonMembers quillTheme={quillTheme} handleEditorChange={handleEditorChange} content={freeContent} selectedPlaceholder={"Tap here to begin"} />
                    }
                  </div>
                  {props.freeContentError
                    ?
                    <div className={`text-center font-header text-xs mt-1 text-red-600`}>
                      {props.freeContentError}
                    </div>
                    : null
                  }
                </div>
                {/* Add Paywall Button */}
                <button className={`font-header shadow-effect-five eighth-text gradient-background-twelve
                  button-ripple transition rounded-xl cursor-pointer py-px px-2 category-hover text-sm mx-3 mt-3`}
                  onClick={(e) => addPaywall(e)}>
                  {paywallAdded
                    ? 'Remove Paywall'
                    : 'Add Paywall'
                  }
                </button>
                {/* Paywall Editor */}
                {paywallAdded
                  ?
                  <>
                    <div className={`sm:mx-2 mt-2 sm:-mt-4 ${categoryNameDisplay === 'Water Cooler' ? `` : `mb-8`} `}>
                      <div className={`text-center text-lg mb-2`}>
                        Paywall Editor
                      </div>
                      <div className={`rounded-xl text-gray-900 mt-3px`}>
                        <button onClick={(e) => changeQuillTheme(e)}
                          className={`font-header gradient-background-twelve shadow-effect-seven ${quillTheme === 'snow' ? `float-right mr-3 mt-3` : 'mb-3'}
                          eighth-text button-ripple transition rounded-xl cursor-pointer px-1 category-hover text-xs mx-1 `}>
                          Change Editors
                        </button>
                        {userDatabaseData?.membership
                          ? <QuillEditorForMembers quillTheme={quillTheme} handleEditorChange={handlePaywallEditorChange} content={paywallContent} selectedPlaceholder={"Tap here to begin"} />
                          : <QuillEditorForNonMembers quillTheme={quillTheme} handleEditorChange={handlePaywallEditorChange} content={paywallContent} selectedPlaceholder={"Tap here to begin"} />
                        }
                      </div>
                      {/* If there's an error from paywallContent, such as .length */}
                      {props.paywallContentError
                        ?
                        <div className={`text-center font-header text-xs mt-1 text-red-600`}>
                          {props.paywallContentError}
                        </div>
                        : <></>
                      }
                      <div className={`text-lg mt-2 mb-1`}><span className={`border-b border-gray-500`}>Set Paywall Cost</span></div>
                      <div className={`text-brand-green paywall-dollar-sign`}>
                        <input className={`gradient-background-seven shadow-effect-ten border 
                          outline-none w-24 rounded-md pl-4 text-gray-900 transition updatingPaywallValue`
                        }
                          type="number" min='.01' max='999.99' step="0.01" pattern="^\d+(?:\.\d{1,2})?$"
                          onChange={(e) => handlePaywallCostChange(e)}
                          value={paywallCost}
                          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        />
                      </div>
                      {props.paywallCostError
                        ?
                        <div className={`font-header text-xs mt-1 text-red-600`}>
                          {props.paywallCostError}
                        </div>
                        : <></>
                      }
                    </div>
                  </>
                  : <></>
                }
                {/* No tags for Water Cooler category because it'll muddy up the search */}
                {categoryNameDisplay === 'Water Cooler'
                  ? <><div className='pb-2px'></div></>
                  // Tags Input
                  :
                  <>
                    <TagsInput
                      tagInputClass={tagInputClass}
                      tagInput={tagInput}
                      onKeyDown={onKeyDown}
                      onKeyUp={onKeyUp}
                      onTagChange={onTagChange}
                      addTagOnClick={addTagOnClick}
                      tags={tags}
                      removeTag={removeTag}
                    />
                    <hr className={`sixth-border outsetBorder border mb-2 mx-2`}></hr>
                  </>
                }
                {/* Buttons */}
                <EditorButtons
                  isNewPost={isNewPost}
                  togglePaymentModal={togglePaymentModal}
                  savingDraft={props.savingDraft}
                  saveDraft={saveDraft}
                  goBackToCategoryPostList={goBackToCategoryPostList}
                  overallError={props.overallError}
                  toggleConfirmCancelModal={props.toggleConfirmCancelModal}
                />
              </form>
            </div>
          </div>
        </main>
      }
    </>
  )
}

export default EditorPageForPosts;