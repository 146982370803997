import React from 'react';
import { Icon } from '@iconify/react';
import trashIcon from '@iconify-icons/ion/trash';

const TagsInput = props => {
  const { tagInputClass, tagInput, onKeyDown, onKeyUp, onTagChange, addTagOnClick, tags, removeTag } = props
  return (
    <>
      {/* Tags Input */}
      <div className='sm:mx-2 mt-6 mb-3'>
        <div className={`transition flex items-center flex-wrap w-full first-text custom-input-container`} data-name="Tags">
          <div className={`overflow-auto w-full max-w-full items-center rounded-xl mb-3 mr-2 shadow-effect-six`}>
            <input className={`w-full min-width-1/2 rounded-xl p-3 ${tagInputClass} shadow-effect-ten
              border outline-none px-2 text-gray-900 transition`}
              type='text'
              aria-label="Enter Tags to assign to your post (Optional)"
              value={tagInput}
              placeholder="Choose up to 3 tags. 'Enter' or 'Comma' adds a tag."
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={onTagChange}
            />
          </div>
          <div className={`-ml-12 mb-3 ${tagInputClass}`}>
            <div className={`rounded-xl shadow-effect-five`}>
              <button onClick={addTagOnClick}
                className={`items-center text-center transition rounded-xl button-ripple eighth-text gradient-background-twelve h-8 w-8`}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div className={`transition flex flex-wrap w-full`}>
          {tags && tags.map((tag, index) => (
            <div key={index} className={`text-sm flex items-center mr-2 my-2 pl-2 pr-1 py-1 
              whitespace-nowrap overflow-auto rounded-xl shadow-effect-five eighth-text gradient-background-three-no-hover`}>
              {tag}
              <button className={`p-1 -mt-2px flex border-none items-center `} onClick={(e) => removeTag(e, index)}>
                <span className={`hover:text-red-700`}><Icon icon={trashIcon} height={16} width={16} /></span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TagsInput