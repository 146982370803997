import { createSlice } from '@reduxjs/toolkit'
import { globals } from '../../config/globals'
let initState = {};

const addUserPropertiesNotInDatabase = (userData) => {
  let userDatabaseData = userData
  userDatabaseData.profilePosts = []
  userDatabaseData.profileMostEarnedPosts = []
  userDatabaseData.noMorePrivateMostEarnedPostResults = false
  userDatabaseData.noMorePrivateNewestPostResults = false
  userDatabaseData.profileComments = []
  userDatabaseData.noMoreCommentResults = false
  userDatabaseData.noMorePrivateFollowingResults = false
  userDatabaseData.noMorePrivateFollowerResults = false
  return userDatabaseData
}
const checkValidLocalStorage = () => {
  // Check for handCash token
  let localStorageAuth = JSON.parse(localStorage.getItem('handCashAuthData'));
  if (localStorageAuth) {
    initState.handCashLogInData = localStorageAuth
    // Check for userDatabaseData prior to loading, only if they're logged in with HandCash.
    let userDatabaseData = JSON.parse(localStorage.getItem('userDatabaseData'));
    if (!userDatabaseData) userDatabaseData = {}
    userDatabaseData = addUserPropertiesNotInDatabase(userDatabaseData)
    initState.userDatabaseData = userDatabaseData
  }
  return initState
}
// This removes sensitive data like _id's from localStorage. Makes Avatar + Profile + Con-rendering instant.
const updateLocalStorage = (userData) => {
  if (!userData) return
  // This removes the properties listed and creates a new object called newUserData without them.
  const { _id, __v, updatedAt, membershipPrice, isBanned, postedInMeetTheStampers,
    followerHandCashIds, followingDatabaseIds, followingHandCashIds, paymail, notifications,
    ...newUserData
  } = userData
  localStorage.setItem('userDatabaseData', JSON.stringify(newUserData))
}

// First function to fire to setup state
checkValidLocalStorage()

const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    authenticateUser(state, action) {
      if (action.payload) {
        localStorage.setItem('handCashAuthData', JSON.stringify(action.payload))
        let updatedHandCashData = action.payload
        delete updatedHandCashData.authToken;
        state.handCashLogInData = updatedHandCashData
        return
      }
      else {
        state.handCashLogInData = action.payload
        return
      }
    },
    logoutUser(state) {
      localStorage.removeItem('handCashAuthData')
      localStorage.removeItem('userDatabaseData')
      state.handCashLogInData = {}
      state.userDatabaseData = {}
      return
    },
    storeUserDatabaseData(state, action) {
      // Declaring reused action.payloads
      const { totalValueSent, totalThumbsGiven, totalThumbValueGiven, thumbsDoc } = action.payload;
      // Different functions for different kind of updates
      switch (action.payload.type) {
        // Initial login to update userDatabaseData state
        case 'initialFetch':
          updateLocalStorage(action.payload.userDatabaseData)
          const userDatabaseData = addUserPropertiesNotInDatabase(action.payload.userDatabaseData)
          state.userDatabaseData = userDatabaseData
          return
        // Updating notifications to 0
        case 'updateNumNotifications':
          state.userDatabaseData.numNewNotifications = 0
          return
        // Private Newest Posts in Profile Page
        case 'addProfilePosts':
          state.userDatabaseData.noMorePrivateNewestPostResults = action.payload.noMorePrivateNewestPostResults || false
          if (state.userDatabaseData.profilePosts.length === 0) {
            state.userDatabaseData.profilePosts = action.payload.updatedArray
          }
          else {
            const existingArray = state.userDatabaseData.profilePosts
            const newArray = globals.updateExistingArray(existingArray, action.payload.updatedArray, globals.fetchFifty)
            state.userDatabaseData.profilePosts = newArray
          }
          return
        // Updating Private Most Earned Posts in Profile Page
        case 'addProfileMostEarnedPosts':
          state.userDatabaseData.noMorePrivateMostEarnedPostResults = action.payload.noMorePrivateMostEarnedPostResults || false
          if (state.userDatabaseData.profileMostEarnedPosts.length === 0) {
            state.userDatabaseData.profileMostEarnedPosts = action.payload.updatedArray
          }
          else {
            const existingArray = state.userDatabaseData.profileMostEarnedPosts
            const newArray = globals.updateExistingArray(existingArray, action.payload.updatedArray, globals.fetchFifty)
            state.userDatabaseData.profileMostEarnedPosts = newArray
          }
          return
        // Updating Comments in Profile Page (only viewable by commentor)
        case 'addPrivateCommentsForProfilePage':
          state.userDatabaseData.noMoreCommentResults = action.payload.noMoreCommentResults || false
          if (state.userDatabaseData.profileComments.length === 0) {
            state.userDatabaseData.profileComments = action.payload.updatedArray
          }
          else {
            const existingArray = state.userDatabaseData.profileComments
            const newArray = globals.updateExistingArray(existingArray, action.payload.updatedArray, globals.fetchTwenty)
            state.userDatabaseData.profileComments = newArray
          }
          return
        // When a user is seeing who follow a user in their profile page
        case 'getPrivateFollowers':
          state.userDatabaseData.noMorePrivateFollowerResults = action.payload.noMorePrivateFollowerResults || false
          if (state.userDatabaseData.followers.length === 0) {
            state.userDatabaseData.followers = action.payload.updatedArray
          }
          else {
            const existingArray = state.userDatabaseData.followers
            const newArray = globals.updateExistingArray(existingArray, action.payload.updatedArray, globals.fetchFifty)
            state.userDatabaseData.followers = newArray
          }
          return
        // When a user is seeing who a user follows in their profile page
        case 'getPrivateFollowing':
          state.userDatabaseData.noMorePrivateFollowingResults = action.payload.noMorePrivateFollowingResults || false
          if (state.userDatabaseData.following.length === 0) {
            state.userDatabaseData.following = action.payload.updatedArray
          }
          else {
            const existingArray = state.userDatabaseData.following
            const newArray = globals.updateExistingArray(existingArray, action.payload.updatedArray, globals.fetchFifty)
            state.userDatabaseData.following = newArray
          }
          return
        // This is for when a comment gets edited, to display in the profile page within SingleCommentInProfilePage file
        case 'updateCommentsArray':
          const { commentIndexInArray, commentBody } = action.payload;
          const existingArray = state.userDatabaseData.profileComments
          const newArray = [
            // existingArray.slice(0, commentIndexInArray) creates a new array containing items from the beginning of existingArray up to (but not including) the specified index.
            ...existingArray.slice(0, commentIndexInArray),
            // { ...existingArray[commentIndexInArray], body: commentBody } creates a shallow copy of the item at commentIndexInArray with the updated body property.
            { ...existingArray[commentIndexInArray], body: commentBody },
            // existingArray.slice(commentIndexInArray + 1) creates a new array containing items after the specified index.
            ...existingArray.slice(commentIndexInArray + 1)
          ]
          state.userDatabaseData.profileComments = newArray
          return
        // When a user conducts a Stampshot
        case 'updateStampshotInfo':
          const { totalStampshotsConducted, totalStampshotValueSent, stampshotDoc } = action.payload;
          state.userDatabaseData.totalStampshotsConducted = totalStampshotsConducted
          state.userDatabaseData.totalStampshotValueSent = totalStampshotValueSent
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.stampshotsConducted.unshift(stampshotDoc)
          return
        // When a user sends a direct tip to another user
        case 'updateTipInfo':
          const { totalTipsGiven, totalTipValueGiven, tippingDoc } = action.payload;
          state.userDatabaseData.totalTipsGiven = totalTipsGiven
          state.userDatabaseData.totalTipValueGiven = totalTipValueGiven
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.tipsGiven.unshift(tippingDoc)
          return
        // When a user conducts a Mass Tipping
        case 'updateMassTippingInfo':
          const { totalMassTipValueSent, totalMassTipsConducted } = action.payload;
          state.userDatabaseData.totalMassTipValueSent = totalMassTipValueSent
          state.userDatabaseData.totalMassTipsConducted = totalMassTipsConducted
          state.userDatabaseData.totalValueSent = totalValueSent
          return
        // When a user creates a post
        case 'updateForPostCreation':
          const { totalPostsCreated, totalPostsValuePaid } = action.payload;
          state.userDatabaseData.totalPostsCreated = totalPostsCreated
          state.userDatabaseData.totalPostsValuePaid = totalPostsValuePaid
          state.userDatabaseData.totalValueSent = totalValueSent
          return
        // When a user creates a comment
        case 'updateForCommentCreation':
          const { totalCommentsPosted, totalCommentValuePaid, commentDoc } = action.payload;
          state.userDatabaseData.totalCommentsPosted = totalCommentsPosted
          state.userDatabaseData.totalCommentValuePaid = totalCommentValuePaid
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.profileComments.unshift(commentDoc)
          return
        // When a user shares a post
        case 'updateForPostShare':
          const { sharesGiven, totalShareValueGiven, shareDoc } = action.payload;
          state.userDatabaseData.sharesGiven = sharesGiven
          state.userDatabaseData.totalShareValueGiven = totalShareValueGiven
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.postSharesGiven.unshift(shareDoc)
          return
        // When a user thumbs a post
        case 'updateForPostThumbs':
          state.userDatabaseData.totalThumbsGiven = totalThumbsGiven
          state.userDatabaseData.totalThumbValueGiven = totalThumbValueGiven
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.postThumbsGiven.unshift(thumbsDoc)
          return
        // When a user thumbs a comment
        case 'updateForCommentThumbs':
          state.userDatabaseData.totalThumbsGiven = totalThumbsGiven
          state.userDatabaseData.totalThumbValueGiven = totalThumbValueGiven
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.commentThumbsGiven.unshift(thumbsDoc)
          return
        // When a user purchases a paywall
        case 'updateForPaywallPurchase':
          const { totalPaywallsPaid, totalPaywallValuePaid, paywallDoc } = action.payload;
          state.userDatabaseData.totalPaywallsPaid = totalPaywallsPaid
          state.userDatabaseData.totalPaywallValuePaid = totalPaywallValuePaid
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.paywallsPurchased.unshift(paywallDoc)
          return
        // When a user votes
        case 'updateForVoteGiven':
          const { totalVotesGiven, totalVoteValueGiven, voteDoc } = action.payload;
          state.userDatabaseData.totalVotesGiven = totalVotesGiven
          state.userDatabaseData.totalVoteValueGiven = totalVoteValueGiven
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.votesGiven.unshift(voteDoc)
          return
        // When a user follows another user
        case 'updateForFollowing':
          const { totalFollowing, totalFollowValuePaid, followingDoc } = action.payload;
          state.userDatabaseData.totalFollowing = totalFollowing
          state.userDatabaseData.totalFollowValuePaid = totalFollowValuePaid
          state.userDatabaseData.totalValueSent = totalValueSent
          state.userDatabaseData.following.unshift(followingDoc)
          return
        // When a user unfollows another user
        case 'updateForUnfollow':
          const { unfollowedHandCashId } = action.payload;
          state.userDatabaseData.totalFollowing = state.userDatabaseData.totalFollowing - 1
          const updatedArray = state.userDatabaseData.following.filter(arrayItem => {
            return (arrayItem.recipientHandCashId !== unfollowedHandCashId)
          })
          state.userDatabaseData.following = updatedArray
          return
        // When a user purchases membership
        case 'membershipUpdate':
          const { membership, membershipPrice } = action.payload;
          state.userDatabaseData.membership = membership
          state.userDatabaseData.membershipPrice = membershipPrice
          state.userDatabaseData.totalValueSent = totalValueSent
          return
        default: return

      }
    },
  }
})

export const {
  authenticateUser,
  logoutUser,
  storeUserDatabaseData,
} = authSlice.actions;

export default authSlice.reducer;
