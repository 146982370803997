import React, { Component } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import { globals, getVideoUrl, getClassesForIFrameWithUrlInEditor } from '../../config/globals';
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';

const Link = Quill.import('formats/link')
Link.PROTOCOL_WHITELIST = ['http', 'https']
const BlockEmbed = Quill.import("blots/block/embed");

class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url)
    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol) {
      return sanitizedUrl.startsWith(protocol)
    })

    if (hasWhitelistedProtocol) return sanitizedUrl

    // if not, then append only 'http' to not to be a relative URL
    return `https://${sanitizedUrl}`
  }
}

class VideoBlot extends BlockEmbed {
  static blotName = 'video';
  static tagName = 'div';
  static create(url) {
    // Fixes a bug for tiktok/fb/insta if you delete the post and try to repaste, it crashes. This prevents that.
    if (url == null || url === true) {
      let failedNode = super.create('')
      return failedNode
    }
    let videoURL = getVideoUrl(url)
    if (videoURL === null) {
      const failedNode = super.create('')
      globals.toastError(toast, globals.wrongEmbedFormatError);
      return failedNode
    }
    let node = super.create(videoURL);
    let iframe = document.createElement('iframe');
    const classesForIframe = getClassesForIFrameWithUrlInEditor(videoURL)
    // Set styles for wrapper
    node.setAttribute('class', classesForIframe.iframeContainer);
    // This is necessary because otherwise people can add text within the iframe for some reason
    node.setAttribute('contenteditable', false);
    // Set styles for iframe
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', true);
    iframe.setAttribute('src', videoURL);
    iframe.setAttribute('class', classesForIframe.iframe);
    // Append iframe as child to wrapper
    node.appendChild(iframe);
    return node;
  }

  static value(domNode) {
    let returnedValue = null
    if (domNode.firstChild?.getAttribute) returnedValue = domNode.firstChild.getAttribute('src');
    return returnedValue
  }
}

Quill.register(VideoBlot, true);
Quill.register(CustomLinkSanitizer, true)

class QuillEditorForNonMembers extends Component {
  // Changing hyperlink tooltip because default one is https://quilljs.com/
  htmlLink = `${globals.platformName}`;
  componentDidMount() {
    const input = document.querySelector("input[data-link]");
    input.dataset.link = this.htmlLink;
    input.placeholder = this.htmlLink;
  }
  componentDidUpdate() {
    const input = document.querySelector("input[data-link]");
    input.dataset.link = this.htmlLink;
    input.placeholder = this.htmlLink;
  }
  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "list",
    "bullet",
    "indent",
    'align',
    "link",
    "video",
    "code",
    `clean`
  ];
  modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "blockquote", "code"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          // align null = left for some odd reason
          { 'align': null }, { 'align': 'center' },
        ],
        ["link", "video", "clean"]
      ]
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };

  render() {
    const { handleEditorChange, content, quillTheme } = this.props;
    return (
      <>
        <ReactQuill
          ref={(el) => this.quill = el}// must pass ref here
          theme={quillTheme}
          placeholder={"Tap here to begin"}
          value={content}
          onChange={handleEditorChange}
          formats={this.formats}
          modules={this.modules}
          scrollingContainer='body'
        />
      </>
    )
  }
}

export default QuillEditorForNonMembers;