import React, { Component } from 'react';
import PostListItem from './PostListItem';
import { globals } from '../../config/globals';
import SkeletonLoaderForPosts from '../Widgets/SkeletonLoaderForPosts';

// Seems excessive, but an if/else statement based upon lumping categoryNames doesn't function properly
// The switch statement based upon each categoryName is required.
// Luckily, this will enable us to easily add different editor properties in the future, if so needed.
class PostListConductor extends Component {
  render() {
    const {
      categoryName,
      postsType,
      clickToPostDetailPage,
      listOrPostView,
      goToUserProfilePage,
      refCallbackForPostsList,
      auth,
      // Posts
      homePosts,
      trendingPosts,
      allTimePosts,
      newestPosts,
      weekPosts,
      followingPosts,
      // Loading
      homePostsIsLoaded,
      followingPostsIsLoaded,
      allTimePostsIsLoaded,
      trendingPostsIsLoaded,
      newestPostsIsLoaded,
      weekPostsIsLoaded,
      homeStartingLoaded,
      allTimeStartingLoaded,
      newestStartingLoaded,
      trendingStartingLoaded,
      weekStartingLoaded,
      followingStartingLoaded,
      // No more results
      noMoreResultsHomePosts,
      noMoreResultsAllTimePosts,
      noMoreResultsTrendingPosts,
      noMoreResultsNewestPosts,
      noMoreResultsWeekPosts,
      noMoreResultsFollowingPosts,
    } = this.props
    switch (postsType) {
      case globals.homePosts:
        return <PostListDisplay posts={homePosts} categoryName={categoryName} goToUserProfilePage={goToUserProfilePage}
          refCallbackForPostsList={refCallbackForPostsList} isLoaded={homePostsIsLoaded} auth={auth}
          noMoreResults={noMoreResultsHomePosts} startingPostsAreLoaded={homeStartingLoaded}
          clickToPostDetailPage={clickToPostDetailPage} listOrPostView={listOrPostView} />;
      case globals.allTime:
        return <PostListDisplay posts={allTimePosts} categoryName={categoryName} goToUserProfilePage={goToUserProfilePage}
          refCallbackForPostsList={refCallbackForPostsList} isLoaded={allTimePostsIsLoaded} auth={auth}
          noMoreResults={noMoreResultsAllTimePosts} startingPostsAreLoaded={allTimeStartingLoaded}
          clickToPostDetailPage={clickToPostDetailPage} listOrPostView={listOrPostView} />;
      case globals.newest:
        return <PostListDisplay posts={newestPosts} categoryName={categoryName} goToUserProfilePage={goToUserProfilePage}
          refCallbackForPostsList={refCallbackForPostsList} isLoaded={newestPostsIsLoaded} auth={auth}
          noMoreResults={noMoreResultsNewestPosts} startingPostsAreLoaded={newestStartingLoaded}
          clickToPostDetailPage={clickToPostDetailPage} listOrPostView={listOrPostView} />
      case globals.trending:
        return <PostListDisplay posts={trendingPosts} categoryName={categoryName} goToUserProfilePage={goToUserProfilePage}
          refCallbackForPostsList={refCallbackForPostsList} isLoaded={trendingPostsIsLoaded} auth={auth}
          noMoreResults={noMoreResultsTrendingPosts} startingPostsAreLoaded={trendingStartingLoaded}
          clickToPostDetailPage={clickToPostDetailPage} listOrPostView={listOrPostView} />
      case globals.week:
        return <PostListDisplay posts={weekPosts} categoryName={categoryName} goToUserProfilePage={goToUserProfilePage}
          refCallbackForPostsList={refCallbackForPostsList} isLoaded={weekPostsIsLoaded} auth={auth}
          noMoreResults={noMoreResultsWeekPosts} startingPostsAreLoaded={weekStartingLoaded}
          clickToPostDetailPage={clickToPostDetailPage} listOrPostView={listOrPostView} />
      case globals.followingPosts:
        return <PostListDisplay posts={followingPosts} categoryName={categoryName} goToUserProfilePage={goToUserProfilePage}
          refCallbackForPostsList={refCallbackForPostsList} isLoaded={followingPostsIsLoaded} auth={auth}
          noMoreResults={noMoreResultsFollowingPosts} startingPostsAreLoaded={followingStartingLoaded}
          clickToPostDetailPage={clickToPostDetailPage} listOrPostView={listOrPostView} />
      default:
        return null;
    }
  }
};

class PostListDisplay extends Component {

  render() {
    const {
      posts,
      categoryName,
      isLoaded,
      refCallbackForPostsList,
      noMoreResults,
      startingPostsAreLoaded,
      clickToPostDetailPage,
      listOrPostView,
      goToUserProfilePage,
      auth
    } = this.props
    return (
      <>
        {/* So I do want this here, but I don't want it for the individual ones. I want it once for an overall.
          because it is displaying each time a new post is added. Which we don't want anything changed. Crap, I think I'm going to need five
          of these too. Because each time it is grabbing new ones. */}
        {startingPostsAreLoaded
          ?
          posts.length === 0
            ?
            <div className={`height-148px`}>
              <div className={`rounded-xl text-sm text-center my-6 p-1 text-medium-gray-lighter gradient-background-two shadow-effect-one `}>
              {globals.noContentToDisplay}
              </div>
            </div>
            :
            // This is where my sorter should go. Because then I can wait for the startingPostsAreLoaded.
            posts.map((post, index) => {
              return (
                <PostListItem
                  key={index}
                  post={post}
                
                  index={index}
                  categoryName={categoryName}
                  refCallbackForPostsList={refCallbackForPostsList}
                  postsAreLoaded={isLoaded}
                  noMoreResults={noMoreResults}
                  clickToPostDetailPage={clickToPostDetailPage}
                  listOrPostView={listOrPostView}
                  goToUserProfilePage={goToUserProfilePage}
                  auth={auth}
                />
              )
            })
          :
          <>
            <SkeletonLoaderForPosts />
            <SkeletonLoaderForPosts />
            <SkeletonLoaderForPosts />
            <SkeletonLoaderForPosts />
            <SkeletonLoaderForPosts />
          </>
        }
      </>
    )
  }
}


export default PostListConductor;