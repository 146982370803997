import React, { Component } from 'react'
import DataListConductor from './DataListConductor';
import Moment from 'react-moment';
import SkeletonLoaderProfile from '../../components/Widgets/SkeletonLoaderProfile';

class ProfilePageForPublic extends Component {

  render() {
    const {
      userData,
      profilePageOpacity,
      auth,
      paramHandle,
      toggleTipModal,
      togglePaymentModal,
      userAlreadyFollows,
      toggleDataList,
      dataListOpacity,
      pubSelect,
      dataIsLoaded
    } = this.props;
    return (
      <>
        <main className={`pb-3 px-1 mt-2 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition`} id='main-content'>
          <div className={`w-full rounded-xl px-1 mb-3 first-text shadow-effect-seven`}>
            {/* Opacity & container div*/}
            {!dataIsLoaded
              ? <>
                <SkeletonLoaderProfile />
              </>
              :
              // This div is needed for the profilePageOpacity to work correctly for some reason. Redux related
              <div className={``}>
                <div className={`${profilePageOpacity} transition-to-opacity`}>
                  <div className={`gradient-background-four rounded-t-xl -mx-1`}>
                    <div className={`flex text-xs rounded-xl pb-1`}>
                      {/* Left */}
                      <div className={`flex-1 justify-start pt-3 pl-3 pr-1`}>
                        {/* Joined */}
                        <div className={`underline text-underline-under`}>Joined: <Moment format="MM-DD-YYYY">{userData.createdAt}</Moment></div>
                        {/* Member */}
                        {userData.membership
                          ?
                          <>
                            <div className={`text-brand-green text-sm font-bold my-1`}>*Member*</div>
                          </>
                          :
                          <></>
                        }
                        {/* Following */}
                        <div className={`sm:flex mt-1`}>
                          {/* If they're logged in and it's not themselves */}
                          {auth && auth.id && auth.handle !== paramHandle
                            ?
                            // If user already follows other user (unfollow route/functionality yet to be built)
                            userAlreadyFollows
                              ?
                              <>
                                <div className={`mt-px`}>
                                  <button className={`-ml-px text-sm h-10 px-6px py-px shadow-effect-seven gradient-background-eleven font-header rounded-xl category-hover
                                first-text `}
                                    onClick={e => togglePaymentModal(e, 'UNFOLLOW_USER')}
                                  >
                                    Unfollow
                                  </button>
                                </div>
                              </>
                              :
                              <>
                                <div className={`mt-px`}>
                                  <button className={`-ml-px h-10 text-sm px-6px py-px font-header rounded-xl category-hover 
                                  first-text gradient-background-eleven shadow-effect-seven`}
                                    onClick={e => togglePaymentModal(e, 'FOLLOW_USER')}
                                  >
                                    Follow
                                  </button>
                                </div>
                              </>
                            : <></>
                          }

                        </div>
                      </div>
                      {/* Center */}
                      <div className={`flex-1 justify-center text-center w-auto pt-3 mt-1`}>
                        <div className={``}>
                          {/* Avatar */}
                          <span className={`transition ${userData.membership ? `member-tooltip-large` : ``}`} data-member='profile' data-tooltip='Member'>
                            <div className={`p-2 -m-2 ${userData.membership ? `flip-hover` : ``}`}>
                              <img alt="Avatar" src={userData.handCashAvatarUrl} width='85' height='85'
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = '/images/round-handcash-logo.png'
                                }}
                                className={`rounded-full mx-auto ${userData.membership ? `profile-member-shadow` : `shadow-effect-five`}`}>
                              </img>
                            </div>
                          </span>
                          {/* Handle */}
                          <div className={`text-center my-2 text-lg thirteenth-text logo-style-two`}>
                            {userData.handCashHandle}
                          </div>
                          {userAlreadyFollows
                            ? <div className={`text-center font-bold text-brand-green mb-1`}>*Following User*</div>
                            : <></>
                          }
                        </div>
                      </div>
                      {/* Right */}
                      <div className={`flex-1 justify-end pt-3 pr-3 pl-1 text-right`}>
                        {/* Joined */}
                        <div className={``}>Earned: <span className={`fifth-text font-bold`}>${userData.totalValueReceived}</span></div>
                        <div className={``}>Sent: <span className={`fifth-text font-bold`}>${userData.totalValueSent}</span></div>
                        <div className={``}>Thumbs: <span className={`fifth-text font-bold`}>{userData.totalThumbsReceived}</span></div>
                        {auth && auth.id && auth.handle !== paramHandle
                          ?
                          <>
                            <button className={`h-10 mt-6px mb-1 px-6px py-px font-header rounded-xl category-hover 
                              first-text text-sm gradient-background-eleven shadow-effect-seven`}
                              onClick={(e) => toggleTipModal(e)}
                            >
                              Send Tip
                            </button>
                          </>
                          : <></>
                        }
                      </div>
                    </div>
                    <div className={`flex justify-center pb-2`}>
                      {/* Following */}
                      <div className={`text-sm`}>
                        <button className={`${pubSelect.followers} font-header py-px mr-1 px-3 rounded-xl button-ripple
                          cursor-pointer category-hover shadow-effect-seven eighth-text 
                          gradient-background-twelve`}
                          onClick={(e) => toggleDataList(e, "GET_FOLLOWERS")}
                        >
                          <span className={`font-bold grey-and-bold`}>[{userData.totalFollowers}]</span> Followers
                        </button>
                        <button className={`${pubSelect.following} font-header py-px ml-1 px-3 rounded-xl button-ripple
                          cursor-pointer category-hover shadow-effect-seven eighth-text 
                          gradient-background-twelve`}
                          onClick={(e) => toggleDataList(e, "GET_FOLLOWING")}
                        >
                          Following <span className={`font-bold grey-and-bold`}>[{userData.totalFollowing}]</span>
                        </button>
                      </div>
                    </div>
                    {/* Sorts */}
                    <div className={`flex justify-center flex-wrap font-header text-sm mb-1`}>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.profile} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_PROFILE")}
                      >
                        Profile
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.stats} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_STATS")}
                      >
                        Stats
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.posts} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_POSTS")}
                      >
                        Posts
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.comments} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_COMMENTS")}
                      >
                        Comments
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.tips} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_TIPS")}
                      >
                        Tips
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.paywalls} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_PAYWALLS")}
                      >
                        Purchases
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.shares} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_SHARES")}
                      >
                        Shares
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.stampshots} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_STAMPSHOTS")}
                      >
                        Stampshots
                      </button>
                      <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                        ${pubSelect.votes} shadow-effect-seven eighth-text gradient-background-twelve`}
                        onClick={(e) => toggleDataList(e, "GET_VOTES")}
                      >
                        Votes
                      </button>
                    </div>
                    {/* Sorts come above hr */}
                    <hr className={`sixth-border outsetBorder border mx-2 mt-3 mb-1 opacity-50`}></hr>
                  </div>
                  {/* This is the wrapper div that will apply to all data displayed. */}
                  <div className={`${dataListOpacity} transition-to-opacity`}>
                    <div className={`gradient-background-five -mt-2 -mx-1 pb-3 rounded-b-xl`}>
                      <DataListConductor
                        {...this.props}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        </main>
      </>
    )
  }
}

export default ProfilePageForPublic;