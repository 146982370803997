import React, { Component } from 'react'
import Moment from 'react-moment';
import parse from 'html-react-parser';
import SkeletonLoaderTwoLines from '../../components/Widgets/SkeletonLoaderTwoLines';

class NotificationPage extends Component {
  render() {
    const {
      notificationOpacity,
      refCallbackForPostsList,
      notificationsArray,
      startingNotificationsAreLoaded,
      newNotificationsAreLoaded,
      goToUserProfilePage,
      noMoreResults,
      notificationListOpacity
    } = this.props;
    return (
      <>
        <main className={`flex md:inline-flex -mt-3 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition`} id='main-content'>
          <div className={`w-full rounded-xl px-1 ${notificationOpacity} transition-to-opacity`}>
            <div className='mb-3 rounded-xl'>
              <div className={`px-3 mb-1 mt-0 sm:mt-4 md:mt-1 rounded-xl first-text ${!startingNotificationsAreLoaded ? `pb-3` : ``}`}>
                <h2 className={`teko-font text-center text-4xl logo-style eleventh-text`}>Notifications</h2>
              </div>
              {!startingNotificationsAreLoaded
                ?
                <>
                  <SkeletonLoaderTwoLines />
                  <SkeletonLoaderTwoLines />
                  <SkeletonLoaderTwoLines />
                  <SkeletonLoaderTwoLines />
                  <SkeletonLoaderTwoLines />
                  <SkeletonLoaderTwoLines />
                  <SkeletonLoaderTwoLines />
                  <SkeletonLoaderTwoLines />
                </>
                : notificationsArray.length === 0
                  ?
                  <>
                    <div className={`rounded text-base text-center my-3 p-1 eighth-text bg-transparent height-148px`}>
                      You do not have any notifications at this time!
                    </div>
                  </>
                  :
                  <>
                    <div className={`${notificationListOpacity} rounded-xl transition-to-opacity`}>
                      <div className={`py-3 px-1 sm:px-3 mb-1 transition first-text`}>
                        {notificationsArray.map((notification, index) => {
                          return (
                            <Notification
                              key={index}
                              index={index}
                              notification={notification}
                              goToUserProfilePage={goToUserProfilePage}
                              refCallbackForPostsList={refCallbackForPostsList}
                              noMoreResults={noMoreResults}
                              newNotificationsAreLoaded={newNotificationsAreLoaded}
                            />
                          )
                        })
                        }
                      </div>
                    </div>
                  </>
              }
            </div>
          </div>
        </main>
      </>
    )
  }
}

class Notification extends Component {
  state = {
    viewComment: true
  }
  toggleCommentView = (toggleValue) => {
    if (toggleValue === true && this.state.viewComment === true) return
    if (toggleValue === false && this.state.viewComment === false) return
    if (toggleValue === true) this.setState({ viewComment: true })
    if (toggleValue === false) this.setState({ viewComment: false })
    return
  }
  render() {
    const {
      notification,
      goToUserProfilePage,
      refCallbackForPostsList,
      noMoreResults,
      newNotificationsAreLoaded,
    } = this.props;
    switch (notification.notificationType) {
      case 'newFollower':
        return (
          <>
            <div className={`inline-flex justify-between containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}
              ${notification.alreadyViewed ? 'opacity-90' : ''}   
            `}>
              {/* Left Side */}
              <div className={`inline-flex items-center pr-1`}>
                {/* Avatar */}
                <div className={`mr-1`}>
                  <span className={``} data-member='standard'>
                    <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                      <img width='30' height='30'
                        alt="Avatar"
                        src={notification.giversArray[0].avatarURL}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/round-handcash-logo.png'
                        }}
                        // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                        className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                      </img>
                    </div>
                  </span>
                </div>
                {/* Handle & notification*/}
                <div className={`ml-px `}>
                  <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                    onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                    {notification.giversArray[0].handle}
                  </button>
                  <span className={``}> followed you for <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(2)}</span>.</span>
                  <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                    <span className={`px-1 date-border text-center`}>
                      <Moment fromNow>
                        {new Date(notification.createdAt)}
                      </Moment>
                    </span>
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div className={`hidden md:flex text-medium-gray date-border text-center px-1 text-xs`}>
                <Moment fromNow>
                  {new Date(notification.createdAt)}
                </Moment>
              </div>
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'massTipping':
        return (
          <>
            <div className={`containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
              ${notification.alreadyViewed ? 'opacity-90' : ''}  
            `}>
              <div className={`flex justify-between w-full items-center`}>
                {/* Left Side */}
                <div className={`inline-flex items-center pr-1`}>
                  {/* Avatar */}
                  <div className={`mr-1 `}>
                    <span className={``} data-member='standard'>
                      <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                        <img width='30' height='30'
                          alt="Avatar"
                          src={notification.giversArray[0].avatarURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                          className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                        </img>
                      </div>
                    </span>
                  </div>
                  {/* Handle & notification*/}
                  <div className={`ml-px `}>
                    <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                      onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                      {notification.giversArray[0].handle}
                    </button>
                    <span className={``}> conducted a Mass Tipping, which sent <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(3)}</span> to you.</span>
                    <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                      <span className={`px-1 date-border text-center`}>
                        <Moment fromNow>
                          {new Date(notification.createdAt)}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
                {/* Right Side */}
                <div className={`hidden md:flex text-medium-gray date-border text-center px-1 text-xs`}>
                  <Moment fromNow>
                    {new Date(notification.createdAt)}
                  </Moment>
                </div>
              </div>
              {/* Tip message if applicable */}
              {notification.tipMessage
                ?
                <>
                  <hr className={`sixth-border outsetBorder border my-6px opacity-50`}></hr>
                  <div className={`text-sm`}>
                    {notification.tipMessage}
                  </div>
                </>
                : <></>
              }
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'tipReceived':
        return (
          <>
            <div className={`containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}

              ${notification.alreadyViewed ? 'opacity-90' : ''}  
            `}>
              <div className={`flex justify-between w-full items-center`}>
                {/* Left Side */}
                <div className={`inline-flex items-center pr-1`}>
                  {/* Avatar */}
                  <div className={`mr-1 `}>
                    <span className={``} data-member='standard'>
                      <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                        <img width='30' height='30'
                          alt="Avatar"
                          src={notification.giversArray[0].avatarURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                          className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                        </img>
                      </div>
                    </span>
                  </div>
                  {/* Handle & notification*/}
                  <div className={`ml-px `}>
                    <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                      onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                      {notification.giversArray[0].handle}
                    </button>
                    <span className={``}> sent you a tip for <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(2)}</span>.</span>
                    <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                      <span className={`px-1 date-border text-center`}>
                        <Moment fromNow>
                          {new Date(notification.createdAt)}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
                {/* Right Side */}
                <div className={`hidden md:flex text-medium-gray date-border text-center px-1 text-xs`}>
                  <Moment fromNow>
                    {new Date(notification.createdAt)}
                  </Moment>
                </div>
              </div>
              {/* Tip message if applicable */}
              {notification.tipMessage
                ?
                <>
                  <hr className={`sixth-border outsetBorder border my-6px opacity-50`}></hr>
                  <div className={`text-sm`}>
                    {notification.tipMessage}
                  </div>
                </>
                : <></>
              }
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'thumbsOnPost':
        return (
          <>
            <div className={`inline-flex justify-between containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
              ${notification.alreadyViewed ? 'opacity-90' : ''}`}>
              {/* Left Side */}
              <div className={`inline-flex items-center pr-1`}>
                {/* Avatar */}
                <div className={`mr-1 `}>
                  <span className={``} data-member='standard'>
                    <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                      <img width='30' height='30'
                        alt="Avatar"
                        src={notification.giversArray[0].avatarURL}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/round-handcash-logo.png'
                        }}
                        // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                        className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                      </img>
                    </div>
                  </span>
                </div>
                {/* Handle & notification*/}
                <div className={`ml-px `}>
                  <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text  `}
                    onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                    {notification.giversArray[0].handle}
                  </button>
                  {/* Notification Text goes here */}
                  <span className={``}> gave a thumbs-up to your post, <a className={`text-left`}
                    aria-label={`Go to post`}
                    href={`https://tipstampers.com/posts/${notification.postSlug}`}>
                    <span className={`fifth-hover underline text-underline-under`}>{notification.postTitle}</span>
                  </a> for <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(2)}</span>.
                  </span>
                  <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                    <span className={`px-1 date-border text-center`}>
                      <Moment fromNow>
                        {new Date(notification.createdAt)}
                      </Moment>
                    </span>
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div className={`hidden md:flex text-medium-gray date-border text-center px-1 text-xs`}>
                <Moment fromNow>
                  {new Date(notification.createdAt)}
                </Moment>
              </div>
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'thumbsOnComment':
        return (
          <>
            <div className={`inline-flex justify-between containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
              ${notification.alreadyViewed ? 'opacity-90' : ''}`}>
              {/* Left Side */}
              <div className={`inline-flex items-center pr-1`}>
                {/* Avatar */}
                <div className={`mr-1 `}>
                  <span className={``} data-member='standard'>
                    <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                      <img width='30' height='30'
                        alt="Avatar"
                        src={notification.giversArray[0].avatarURL}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/round-handcash-logo.png'
                        }}
                        // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                        className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                      </img>
                    </div>
                  </span>
                </div>
                {/* Handle & notification*/}
                <div className={`ml-px `}>
                  <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                    onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                    {notification.giversArray[0].handle}
                  </button>
                  {/* Notification Text goes here */}
                  <span className={``}> gave a thumbs-up to your comment within the post, <a className={`text-left`}
                    aria-label={`Go to post`}
                    href={`https://tipstampers.com/posts/${notification.postSlug}`}>
                    <span className={`fifth-hover underline text-underline-under`}>{notification.postTitle}</span>
                  </a> for <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(2)}</span>.
                  </span>
                  <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                    <span className={`px-1 date-border text-center`}>
                      <Moment fromNow>
                        {new Date(notification.createdAt)}
                      </Moment>
                    </span>
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div className={`hidden md:flex text-medium-gray date-border text-center px-1 text-xs`}>
                <Moment fromNow>
                  {new Date(notification.createdAt)}
                </Moment>
              </div>
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'paywallPurchase':
        return (
          <>
            <div className={`inline-flex justify-between containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
              ${notification.alreadyViewed ? 'opacity-90' : ''}`}>
              {/* Left Side */}
              <div className={`inline-flex items-center pr-1`}>
                {/* Avatar */}
                <div className={`mr-1 `}>
                  <span className={``} data-member='standard'>
                    <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                      <img width='30' height='30'
                        alt="Avatar"
                        src={notification.giversArray[0].avatarURL}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/round-handcash-logo.png'
                        }}
                        // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                        className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                      </img>
                    </div>
                  </span>
                </div>
                {/* Handle & notification*/}
                <div className={`ml-px `}>
                  <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                    onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                    {notification.giversArray[0].handle}
                  </button>
                  {/* Notification Text goes here */}
                  <span className={``}> purchased hidden content from your post, <a className={`text-left`}
                    aria-label={`Go to post`}
                    href={`https://tipstampers.com/posts/${notification.postSlug}`}>
                    <span className={`fifth-hover underline text-underline-under`}>{notification.postTitle}</span>
                  </a> for <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(2)}</span>.
                  </span>
                  <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                    <span className={`px-1 date-border text-center`}>
                      <Moment fromNow>
                        {new Date(notification.createdAt)}
                      </Moment>
                    </span>
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div className={`hidden md:flex text-medium-gray date-border text-center px-1 text-xs`}>
                <Moment fromNow>
                  {new Date(notification.createdAt)}
                </Moment>
              </div>
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'commentOnPost':
        return (
          <>
            <div className={`containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
              ${notification.alreadyViewed ? 'opacity-90' : ''}`}
            >
              {/* Top level */}
              <div className={`flex justify-between w-full items-center`}>
                {/* Left Side */}
                <div className={`inline-flex items-center pr-1`}>
                  {/* Avatar */}
                  <div className={`mr-1 `}>
                    <span className={``} data-member='standard'>
                      <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                        <img width='30' height='30'
                          alt="Avatar"
                          src={notification.giversArray[0].avatarURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                          className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                        </img>
                      </div>
                    </span>
                  </div>
                  {/* Handle & notification*/}
                  <div className={`ml-px `}>
                    <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                      onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                      {notification.giversArray[0].handle}
                    </button>
                    {/* Notification Text goes here */}
                    <span className={``}> commented on your post, <a className={`text-left`}
                      aria-label={`Go to post`}
                      href={`https://tipstampers.com/posts/${notification.postSlug}`}>
                      <span className={`fifth-hover underline text-underline-under`}>{notification.postTitle}</span>
                    </a> for <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(2)}</span>.
                    </span>
                    <div className={`ml-px md:hidden items-center text-medium-gray text-xs`}>
                      {notification.textFromComment
                        ? this.state.viewComment
                          ? <button onClick={() => this.toggleCommentView(false)}
                            className={`fifth-hover px-1 date-border text-center mr-1`}>Hide</button>
                          : <button onClick={() => this.toggleCommentView(true)}
                            className={`fifth-hover px-1 date-border text-center mr-1`}>Show</button>
                        : <></>
                      }
                      <span className={`text-medium-gray px-1 date-border text-center`}>
                        <Moment fromNow>
                          {new Date(notification.createdAt)}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
                {/* Right Side */}
                <div className={`hidden md:flex items-center text-medium-gray text-xs`}>
                  {notification.textFromComment
                    ? this.state.viewComment
                      ? <button onClick={() => this.toggleCommentView(false)}
                        className={`fourth-text-hover px-1 date-border text-center mr-1`}>Hide</button>
                      : <button onClick={() => this.toggleCommentView(true)}
                        className={`fourth-text-hover px-1 date-border text-center mr-1`}>Show</button>
                    : <></>
                  }
                  <span className={`text-medium-gray date-border text-center px-1`}>
                    <Moment fromNow>
                      {new Date(notification.createdAt)}
                    </Moment>
                  </span>
                </div>
              </div>
              {/* Bottom Level */}
              {notification.textFromComment
                // If it exists and the person hasn't chosen to view the comment
                ?
                !this.state.viewComment
                  ? <></>
                  // If they have chosen to view the comment.
                  :
                  <>
                    <hr className={`sixth-border outsetBorder border my-6px opacity-50`}></hr>
                    <div className={`parsed-editor-data notification-comment`}>
                      {parse(notification.textFromComment)}
                    </div>
                  </>
                // If notification.textFromComment doesn't exist, we don't display anything.
                : <></>
              }
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'commentOnComment':
        return (
          <>
            <div className={`containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
              ${notification.alreadyViewed ? 'opacity-90' : ''}`}
            >
              {/* Top level */}
              <div className={`flex justify-between w-full items-center py-1`}>
                {/* Left Side */}
                <div className={`inline-flex items-center pr-1`}>
                  {/* Avatar */}
                  <div className={`mr-1 `}>
                    <span className={``} data-member='standard'>
                      <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                        <img width='30' height='30'
                          alt="Avatar"
                          src={notification.giversArray[0].avatarURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                          className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                        </img>
                      </div>
                    </span>
                  </div>
                  {/* Handle & notification*/}
                  <div className={`ml-px `}>
                    <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                      onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                      {notification.giversArray[0].handle}
                    </button>
                    {/* Notification Text goes here */}
                    <span className={``}> replied to your comment within the post, <a className={`text-left`}
                      aria-label={`Go to post`}
                      href={`https://tipstampers.com/posts/${notification.postSlug}`}>
                      <span className={`fifth-hover underline text-underline-under`}>{notification.postTitle}</span>
                    </a> for <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(2)}</span>.
                    </span>
                    <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                      <span className={`px-1 date-border text-center`}>
                        <Moment fromNow>
                          {new Date(notification.createdAt)}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
                {/* Right Side */}
                <div className={`hidden md:flex items-center text-medium-gray text-xs`}>
                  {notification.textFromComment
                    ? <>
                      {this.state.viewComment
                        ? <button onClick={() => this.toggleCommentView(false)}
                          className={`fourth-text-hover mr-1 date-border px-1 text-center`}>Hide</button>
                        : <button onClick={() => this.toggleCommentView(true)}
                          className={`fourth-text-hover mr-1 date-border px-1 text-center`}>Show</button>
                      }
                    </>
                    :
                    <></>
                  }
                  <span className={`date-border text-center px-1 text-xs`}>
                    <Moment fromNow>
                      {new Date(notification.createdAt)}
                    </Moment>
                  </span>
                </div>
              </div>
              {/* Bottom Level */}
              {notification.textFromComment
                // If it exists and the person hasn't chosen to view the comment
                ?
                !this.state.viewComment
                  ? <></>
                  // If they have chosen to view the comment.
                  :
                  <>
                    <hr className={`sixth-border outsetBorder border my-6px opacity-50`} />
                    <div className={`parsed-editor-data notification-comment`}>
                      {parse(notification.textFromComment)}
                    </div>
                  </>
                // If notification.textFromComment doesn't exist, we don't display anything.
                : <></>
              }
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'shareOnPost':
        return (
          <>
            <div className={`inline-flex justify-between containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
              ${notification.alreadyViewed ? 'opacity-90' : ''}`}>
              {/* Left Side */}
              <div className={`inline-flex items-center pr-1`}>
                {/* Avatar */}
                <div className={`mr-1 `}>
                  <span className={``} data-member='standard'>
                    <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                      <img width='30' height='30'
                        alt="Avatar"
                        src={notification.giversArray[0].avatarURL}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/round-handcash-logo.png'
                        }}
                        // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                        className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                      </img>
                    </div>
                  </span>
                </div>
                {/* Handle & notification*/}
                <div className={`ml-px `}>
                  <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                    onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                    {notification.giversArray[0].handle}
                  </button>
                  {/* Notification Text goes here */}
                  <span className={``}> shared your post, <a className={`text-left`}
                    aria-label={`Go to post`}
                    href={`https://tipstampers.com/posts/${notification.postSlug}`}>
                    <span className={`fifth-hover underline text-underline-under`}>{notification.postTitle}</span>
                  </a> to all their followers for <span className={`fifth-text font-bold`}>${parseFloat(notification.valueOfAction).toFixed(2)}</span>.
                  </span>
                  <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                    <span className={`px-1 date-border text-center`}>
                      <Moment fromNow>
                        {new Date(notification.createdAt)}
                      </Moment>
                    </span>
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div className={`hidden md:flex text-medium-gray date-border text-center px-1 text-xs`}>
                <Moment fromNow>
                  {new Date(notification.createdAt)}
                </Moment>
              </div>
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'welcomeNotification':
        return (
          <>
            <div className={`inline-flex justify-between containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
                ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
                ${notification.alreadyViewed ? 'opacity-90' : ''}`}>
              {/* Left Side */}
              <div className={`inline-flex items-center pr-1`}>
                {/* Avatar */}
                <div className={`mr-1 `}>
                  <span className={``} data-member='standard'>
                    <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                      <img width='30' height='30'
                        alt="Avatar"
                        src={notification.giversArray[0].avatarURL}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/round-handcash-logo.png'
                        }}
                        // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                        className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                      </img>
                    </div>
                  </span>
                </div>
                {/* Handle & notification*/}
                <div className={`ml-px `}>
                  <button className={`fifth-hover hover:underline text-underline-under logo-style-two eleventh-text `}
                    onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                    {notification.giversArray[0].handle}
                  </button>
                  {/* Notification Text goes here */}
                  <span className={``}> would like to welcome and thank you for joining our platform!
                    To start, we encourage you to create an introductory post within the “Meet the Stampers” category,
                    where you will be tipped by us and potentially others.
                    From there, you can use that seed money to start posting and earning *100% cut* from your content thereafter.
                    Paywalls included. Good luck, have fun, and keep it classy. Thanks for checking us out.
                  </span>
                  <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                    <span className={`px-1 date-border text-center`}>
                      <Moment fromNow>
                        {new Date(notification.createdAt)}
                      </Moment>
                    </span>
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div className={`hidden md:flex text-medium-gray date-border text-center px-1 text-xs`}>
                <Moment fromNow>
                  {new Date(notification.createdAt)}
                </Moment>
              </div>
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      case 'commentMention':
        return (
          <>
            <div className={`containerShadowSmaller items-center text-xs sm:text-sm word-wrap rounded-xl transition w-full px-2 mb-2 py-6px
              ${notification.alreadyViewed ? `background-fourteen` : `gradient-background-thirteen`}  
              ${notification.alreadyViewed ? 'opacity-90' : ''}`}>
              <div className={`flex justify-between w-full items-center`}>
                {/* Left Side */}
                <div className={`inline-flex items-center pr-1`}>
                  {/* Avatar */}
                  <div className={`mr-1 `}>
                    <span className={``} data-member='standard'>
                      <div className={`p-2 -m-2 ${notification.giversArray[0].actionTakerIsMember ? `flip-hover` : ``}`}>
                        <img width='30' height='30'
                          alt="Avatar"
                          src={notification.giversArray[0].avatarURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          // Needs max-w-none in case there are multiple lines of text (for some weird css reason)
                          className={`rounded-xl ${notification.giversArray[0].actionTakerIsMember ? `shadow-effect-six` : ``} max-w-none`}>
                        </img>
                      </div>
                    </span>
                  </div>
                  {/* Handle & notification*/}
                  <div className={`ml-px `}>
                    <button className={`fifth-hover hover:underline text-underline-under logo-style-two  eleventh-text `}
                      onClick={(e) => goToUserProfilePage(e, notification.giversArray[0].handle)}>
                      {notification.giversArray[0].handle}
                    </button>
                    {/* Notification Text goes here */}
                    <span className={``}> @tagged you in a {notification.commentTier} comment within the post, <a className={`text-left`}
                      aria-label={`Go to post`}
                      href={`https://tipstampers.com/posts/${notification.postSlug}`}>
                      <span className={`fifth-hover underline text-underline-under`}>{notification.postTitle}</span>
                    </a>.
                    </span>
                    <div className={`ml-px md:hidden text-medium-gray text-xs`}>
                      <span className={`px-1 date-border text-center`}>
                        <Moment fromNow>
                          {new Date(notification.createdAt)}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
                {/* Right Side */}
                <div className={`hidden md:flex items-center text-medium-gray text-xs`}>
                  {notification.textFromComment
                    ? <>
                      {this.state.viewComment
                        ? <button onClick={() => this.toggleCommentView(false)}
                          className={`fourth-text-hover mr-1 date-border px-1 text-center`}>Hide</button>
                        : <button onClick={() => this.toggleCommentView(true)}
                          className={`fourth-text-hover mr-1 date-border px-1 text-center`}>Show</button>
                      }
                    </>
                    :
                    <></>
                  }
                  <span className={`date-border text-center px-1 text-xs`}>
                    <Moment fromNow>
                      {new Date(notification.createdAt)}
                    </Moment>
                  </span>
                </div>
              </div>
              {/* Bottom Level */}
              {notification.textFromComment
                // If it exists and the person hasn't chosen to view the comment
                ?
                !this.state.viewComment
                  ? <></>
                  // If they have chosen to view the comment.
                  :
                  <>
                    <hr className={`sixth-border outsetBorder border my-6px opacity-50`} />
                    <div className={`parsed-editor-data notification-comment`}>
                      {parse(notification.textFromComment)}
                    </div>
                  </>
                // If notification.textFromComment doesn't exist, we don't display anything.
                : <></>
              }
            </div>
            {/* End Wrapper Div */}
            {notification.ref
              ? <div ref={refCallbackForPostsList}></div>
              : <></>
            }
            {notification.lastItem
              ? noMoreResults
                ?
                <div className={`rounded text-base text-center mt-6 p-1 eighth-text rounded-xl gradient-background-two shadow-effect-one`}>
                  Sorry, no more notifications to display!
                </div>
                : newNotificationsAreLoaded
                  ? <></>
                  : <>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </>
              : <></>
            }
          </>
        )
      default: return <></>
    }
  }
}

export default NotificationPage;