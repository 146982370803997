import React from 'react'
import { Icon } from '@iconify/react';
import cameraLock from '@iconify/icons-mdi/camera-lock';

const StampshotComponent = (props) => {
  const { auth, togglePaymentModal } = props;
  return (
    <>
      {/* If user is logged in */}
      {auth?.id
        ?
        <>
          <div className={`mx-2px mb-2`}>
            <button className={`gradient-background-green text-white border-brand-green
              items-center flex rounded-xl px-6px font-header button-ripple border-2 offsetBorder`}
              onClick={(e) => togglePaymentModal(e, 'STAMPSHOT_POST')}>
              <div className={`-mt-px`}><Icon icon={cameraLock} height={12} width={12} /></div><span className={`ml-2px`}>Stampshot</span>
            </button>
          </div>
        </>
        :
        // If user is not logged in
        <></>
      }
    </>
  )
}

export default StampshotComponent;