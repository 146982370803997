import React, { Component } from 'react'
import { connect } from 'react-redux';
import { opacityChange } from '../store/reducers/opacitySlice';
import API from '../utils/API';
import { globals } from '../../src/config/globals';
import { toast } from 'react-toastify';

class FAQPage extends Component {
  state = {
    selectedFAQAccordionItem: null,
  }
  componentDidMount() {
    setTimeout(() => {
      this.props.opacityChange('addFaqOpacity')
    }, 100)
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled');
  };
  cancelToken = API.CancelToken.source();
  toggleAccordion = (e, index) => {
    if (e) e.preventDefault()
    if (this.state.selectedFAQAccordionItem === index) return this.setState({ selectedFAQAccordionItem: null })
    this.setState({ selectedFAQAccordionItem: index })
  }
  authenticateWithHandCash = (e) => {
    e.preventDefault();
    API.authenticateWithHandCash(this.cancelToken.token)
      .then(res => {
        const pathNameString = window.location.pathname
        const redirectionLoginUrl = res.data.redirectionLoginUrl
        const redirectWithPath = `${redirectionLoginUrl}&redirectLink=${pathNameString}`
        window.location.assign(redirectWithPath)
      })
      .catch(error => {
        if (error.message === 'Operation canceled') return
        console.error(error.response.data.message);
        globals.toastError(toast, error.response.data.message);
      });
  }
  render() {
    const { faqOpacity } = this.props;
    const FAQInfoObject = [
      {
        title: `What is ${globals.platformName}?`,
        text: <>
        <div className={`mx-1`}>
          <div className='i-container my-3'>
            <iframe className='standard-iframe rounded-lg media-shadow' loading="lazy" allowFullScreen={true} title="https://www.youtube.com/embed/0bFMKYpc-w8?showinfo=0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              src="https://www.youtube.com/embed/0bFMKYpc-w8?showinfo=0" />
          </div>
          <p className={`mb-3`}>
            On TipStampers.com, you create, promote, build a brand, and earn.
            You earn money from YouTube videos, Twitch streams, TikToks, Instagram Reels, Apple Podcasts, Spotify tracks,
            and traditional Blog posts.
          </p>
          <p className={`mb-3`}>
            Here, digital content creators are given a blank canvas to create posts that include all of these things,
            along with the option to add a paywall and hide content behind it.
            Including a slick deal where they get to keep 100% of everything they earn.
            Our main differentiating factor, competitive advantage, and oath to our users.
          </p>
          <p className={`mb-3`}>
            The platform itself is very Social Media like. You have your profile page; other people can follow you;
            you can give thumbs on posts and comments, and create your own too.
            We’re like a crossbreed between Reddit and LinkedIn, wired with instant and direct, person-to-person payments;
            to make this Social Media platform, and experience, the best on the net. Annoying ads, not found here.
          </p>
          <p className={`mb-3`}>
            After creating an account on TipStampers, introduce yourself within the free “Meet the Stampers”
            category to receive a complimentary tip from us and potentially others.
            Hiding “tipworthy” content behind a paywall here and enticing others into purchasing it,
            is something we definitely encourage.
          </p>
          <p className={`mb-3`}>
            Now while we do offer a paywall, we expect content on the platform to be appropriate and professional. We are not an OnlyFans here.
            Our vibe is useful, intelligent, and fun, and our categories have been devised with this in mind. So keep it classy, that’s all we ask.
          </p>
          <p className={`mb-3`}>
            Hope you like what you find here. If you deem us worthwhile, consider purchasing membership to unlock perks such as free posts,
            cheaper costs, image uploading, and automatic entry to our weekly mass tipping giveaways.
          </p>
          <p className={`mb-3`}>
            Thanks for checking us out. <a href='https://market.handcash.io/'
              aria-label={`Go to HandCash website`}
              className={`fifth-hover hover:underline underline text-underline-under is-link`}
              rel="noopener noreferrer"
              target="_blank"
            >Sign up with HandCash
            </a> to start earing 100% cut from your digital content today. Happy Stamping.
          </p>
          </div>
        </>
      },
      {
        title: `How do I earn money here? What is a troll-toll?`,
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            If you’ve been on Twitter or Reddit before, you’ve probably encountered spam and trolling.
            Unfortunately, they exist because there’s really no good way to stop them. Until now.
          </p>
          <p className={`mx-1 mb-4`}>
            By requiring a small cost to post or comment (the troll-toll),
            automated bots can’t spam the website for free, and trolls have to pay YOU in order to troll.
            Which means they’re much less likely to do so, and you at least get paid if they do.
          </p>
          <p className={`mx-1 mb-4`}>
            The toll also enables you, the content creator,
            to collect 100% of every cent you ever earn here.
            By paying the platform a toll to post the content (varies based upon category),
            you receive 100% of every comment (<span className={`fifth-text`}>${globals.commentCost.toFixed(2)}</span>),
            share (<span className={`fifth-text`}>${globals.shareCost.toFixed(2)}</span>),
            thumbs-up (<span className={`fifth-text`}>${globals.thumbsCostMin.toFixed(2)} - {globals.thumbsCostMax.toFixed(2)}</span>),
            and paywall purchase (set by you), thereafter. Not to mention Follows (
            <span className={`fifth-text`}>${globals.followingCost.toFixed(2)}</span>) and direct tips too.
          </p>
          <p className={`mx-1 mb-1`}>
            With a streamlined payment process and superior user experience,
            the troll-toll unlocks a new and improved content creation business model for everyone.
            As long as you're not a troll or spammer, of course...
          </p>
        </>
      },
      {
        title: 'How do I get started?',
        text: <>
          <p className={`underline text-underline-under text-lg mx-1 mt-3 mb-4`}>
            Step #1: Get a HandCash account
          </p>
          <p className={`mx-1 mt-1 mb-4`}>
            You can skim through the platform without a HandCash account, but to interact and earn, you will need it.
            You're unable to receive your money without one.
          </p>
          <p className={`mx-1 mt-1 mb-4`}>
            Luckily for you, HandCash makes it pretty easy to setup. It is very similar to the typical account creation experience you’ve already encountered.
          </p>
          <p className={`mx-1 mt-1 mb-4`}>
            To sign up, you’ll need to enter your phone number and e-mail address,
            and go through the usual verification routine with those.
            Once you’ve done that, you just need to login from our platform and enable permissions.
            Then, “game on” from that point forward.</p>
          <p className={`mx-1 mb-1`}>
            <button
              className={`font-header connectButtonNoImage`}
              onClick={(e) => this.authenticateWithHandCash(e)}
            >
              <span className={`text-sm sm:text-base pr-1`}>Tap here to create HandCash account</span>
            </button>
          </p>
        </>
      },
      {
        title: `The golden rules and vibe of ${globals.platformName}`,
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            Rules are annoying, but so is rudeness.
            And since we’re about benefitting people’s lives rather than bringing them down,
            the golden rule applies here: Treat others how you would like to be treated. With respect.
          </p>
          <p className={`mx-1 mt-1 mb-4`}>
            Fortunately, we filter most negative behaviors with our payment system,
            but if someone starts acting childish,
            there is a ban hammer we can use to restore respectfulness.
            Behaviors that may classify as childish and could warrant a hammer slam are:
          </p>
          <div className={`mx-1 mb-4 colored-dots`}>
            <ul>
              <li className={``}>Excessive foul language</li>
              <li className={``}>Treating others disrespectfully (insults, name-calling, etc)</li>
              <li className={``}>Spamming (especially in free categories)</li>
              <li className={``}>Posting copyrighted content of others without giving credit</li>
              <li className={``}>Posting explicit content</li>
              <li className={``}>And potentially other negative behaviors we just haven’t thought of yet</li>
            </ul>
          </div>
          <p className={`mx-1 mb-1`}>
            Debate is always fair game as long as insults aren’t used.
            Don’t let the pseudo-anonymity get the best of you.
            <span className={`underline text-underline-under ninth-text ml-1`}>
              Treat others how you would like to be treated.
            </span>
          </p>
        </>
      },
      {
        title: 'Why HandCash?',
        text: <>
          <p className={`mx-1 mt-3 mb-4 underline text-underline-under`}>
            We utilize HandCash because they’re the best wallet in existence. Here are a few reasons why:
          </p>
          <div className={`mx-1 mt-1 mb-4 colored-dots`}>
            <ul>
              <li className={``}>
                Password recovery is familiar to everyone. It is the standard process on the internet. Crypto seed words not included.
              </li>
              <li className={``}>
                Non-custodial wallet. They, nor our platform, are in control of your funds. Just you.
              </li>
              <li className={``}>
                Can purchase BSV directly through them. A seamless, one-stop-shop experience.
              </li>
              <li className={``}>
                User handles. Payments can be routed to a Username Handle, rather than a long string of numbers and letters.
              </li>
              <li className={``}>
                Easy integration within your app. If you code, their Blockchain API documentation is excellent.
              </li>
              <li className={``}>
                Top notch UI/UX and customer support. They truly care about the user experience.
              </li>
            </ul>
          </div>
          <p className={`mx-1 my-1`}>
            HandCash makes interacting with the Blockchain easy,
            and the experience from start-to-finish is of the highest quality for everyone involved.
            They are without a doubt the gold standard of Bitcoin wallets.
            Kudos to the team behind this fantastic product.
          </p>
        </>
      },

      {
        title: 'How do I get Bitcoin-SV (BSV)?',
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            <span className={`underline text-underline-under ninth-text`}>Method #1:</span> Purchase BSV within your HandCash account.
          </p>
          <p className={`mx-1 my-1`}>
            <span className={`underline text-underline-under ninth-text`}>Method #2:</span> Introduce yourself and post something noteworthy
            in the *FREE* "Meet the Stampers" category. Maybe even hide content behind a paywall.
            There's no guarantee, but the community is known to help new users out. Good luck.
          </p>
        </>
      },
      {
        title: `Your data and what we store`,
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            We are not Facebook. We don’t keep track of everything you click on and sell it to advertisers.
            <span className={`ml-1`}>We keep track of your stats + interactions + content for record-keeping and displaying purposes,
              and that’s the extent of how we use your data, and what we use it for.</span>
          </p>
          <p className={`mx-1 mb-4`}>
            We’re moving in the direction of Web 3.0, rather than Web 2.0.
            We don’t ask for your name, age or gender, nor do we keep track of what topics you clicked on,
            and so forth. Privacy is respected here.
          </p>
          <p className={`mx-1 mb-4`}>
            In regards to web “Cookies,”
            we do store data in your web browser’s Local Storage (which is like a cookie).
            We do that so you don’t have to re-login to HandCash every time you enter the website,
            and so certain preferences (such as light/dark mode) can be saved for the next time.
          </p>
          <p className={`mx-1 mb-1`}>
            If you would like to clear this information from your browser,
            do a Google search for “how to clear local storage on *THE WEB BROWSER YOU’RE USING* web browser” and it should be easy to do.
            But trust us, we’re not doing anything nefarious with it.
            Some websites use these tools for data-harvesting, but we do not. We are {globals.platformName}, not Facebook.com.
          </p>
        </>
      },
      {
        title: 'What happens if I earn a bunch of money? (Taxes)',
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            If you earn a bunch of money on the platform and you need to provide your accountant with records,
            you're in luck.<span className={`ninth-text ml-1`}>We have an Excel spreadsheet download for you.</span>
          </p>
          <p className={`mx-1 mt-1 mb-4`}>
            To download the Excel spreadsheet, simply go to your profile page and click the Excel button. Pretty easy.
          </p>
          <p className={`mx-1 mb-4`}>
            The spreadsheet is sorted by income and spends, in case some of the spends qualify as deductions.
            You'll need to ask your accountant which may qualify.
          </p>
          <p className={`mx-1 mb-4`}>
            If you have capital gains from sales of Bitcoin-SV you earned on our platform, your
            accountant can use the 'Exchange Rate' included in the spreadsheet for calculating the Cost Basis.
            Since you wouldn't be converting or cashing out on our platform (we don't offer that service), we
            do not have that data for you. Thus, the best we can do is include the 'Exchange Rate' in the spreadsheet.
          </p>
          <p className={`mx-1 mb-4`}>
            Do note, your records come from our database and we DO believe everything is accurate, but
            because we're human, mistakes can be made, so we recommend comparing our results with the records from your
            HandCash wallet. Their accuracy is automatically superior to ours, as theirs come directly from
            the Blockchain itself.
          </p>
          <p className={`mx-1 mb-1`}>
            Hope this helps.
          </p>
        </>
      },
      {
        title: 'Why is there no delete button?',
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            The delete button ceases to exist because of the cost tied to posting and commenting.
          </p>
          <p className={`mx-1 mb-4`}>
            For example, if you commented on a post and the post creator deletes it afterwards,
            your comment (and money) would be gone too. And in our opinion, that wouldn’t be fair.
            But, we do allow you to edit your posts and comments if you so choose.
            Deletion, no. Editing, yes.
          </p>
          <p className={`mx-1 mb-1`}>
            Keep this in mind when before hitting the Submit button. We hope you understand the logic behind this decision.
          </p>
        </>
      },
      {
        title: 'Current prices',
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            Prices are subject to change. Current prices can be found below:
          </p>
          <div className={`mx-1 mb-1`}>
            <ul>
              <li className={``}>Post Content:<span className={` fifth-text ml-1`}>Varies by category</span></li>
              <li className={``}>
                Follow:<span className={` fifth-text ml-1`}>${globals.followingCost.toFixed(2)}</span>
              </li>
              <li className={``}>
                Thumbs:<span className={` fifth-text ml-1`}>${globals.thumbsCostMin.toFixed(2)} - {globals.thumbsCostMax.toFixed(2)}</span>
              </li>
              <li className={``}>
                Comment:<span className={` fifth-text ml-1`}>${globals.commentCost.toFixed(2)}</span>
              </li>
              <li className={``}>
                Share:<span className={` fifth-text ml-1`}>${globals.shareCost.toFixed(2)}</span>
              </li>
              <li className={``}>Paywall: <span className={` fifth-text ml-1`}>Set by post creator</span></li>
              <li className={``}>
                1 Year Membership:<span className={` fifth-text mx-1`}>${globals.oneYearMembershipPrice.toFixed(2)}</span>
              </li>
              <li className={``}>
                Lifetime Membership:<span className={` fifth-text mx-1`}>${globals.lifeTimeMembershipPrice.toFixed(2)}</span>
              </li>
            </ul>
          </div>
        </>
      },
      {
        title: 'Proper video URL formats in posts',
        text: <>
          <p className={`mx-1 mt-3 mb-3`}>
            The following URLs (links) are the most common for each platform,
            and the ones we recommend using. With that said, there are others that may work too.
            And don’t worry, if you enter ones that don’t work,
            our notification system will be sure to tell you. Hope this helps.
          </p>
          <hr className={`sixth-border outsetBorder border opacity-50`}></hr>
          <div className={`mx-1 my-3`}>
            <p className={`mx-1 mb-1`}>
              <b className='faq-custom-color mr-1'>YouTube:</b> https://www.youtube.com/watch?v=VIDEO_ID
            </p>
            <p className={`mx-1 text-sm`}>
              Example: <span className='underline text-underline-under'>https://www.youtube.com/watch?v=eVSmJ2AAiKE</span>
            </p>
          </div>
          <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
          <div className={`mx-1`}>
            <p className={`mx-1 mb-1`}>
              <b className='faq-custom-color'>Vimeo:</b> https://vimeo.com/VIDEO_ID
            </p>
            <p className={`mx-1 text-sm`}>
              Example: <span className='underline text-underline-under'>https://vimeo.com/413700656</span>
            </p>
          </div>
          <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
          <div className={`mx-1 `}>
            <p className={`mx-1 mb-1`}>
              <b className='faq-custom-color'>Twitch:</b> https://www.twitch.tv/videos/VIDEO_ID
            </p>
            <p className={`mx-1 text-sm`}>
              Example: <span className='underline text-underline-under'>https://www.twitch.tv/videos/1526748678</span>
            </p>
          </div>
          <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
          <div className={`mx-1 `}>
            <p className={`mx-1 mb-1`}>
              <b className='faq-custom-color'>Facebook Watch:</b> https://fb.watch/VIDEO_ID
            </p>
            <p className={`mx-1 text-sm`}>
              Example: <span className='underline text-underline-under'>https://fb.watch/eb5ayeOo1r</span>
            </p>
          </div>
          <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
          <div className={`mx-1 `}>
            <p className={`mx-1 mb-1 `}>
              <b className='faq-custom-color'>Instagram Reels (Reels only):</b> https://www.instagram.com/reel/VIDEO_ID
            </p>
            <p className={`mx-1  text-sm`}>
              Example: <span className='underline text-underline-under'>https://www.instagram.com/reel/Cc59jtvjk_0</span>
            </p>
          </div>
          <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
          <div className={`mx-1 `}>
            <p className={`mx-1 mb-1`}>
              <b className='faq-custom-color'>TikTok:</b> https://www.tiktok.com/@USER_NAME/video/VIDEO_ID
            </p>
            <p className={`mx-1 text-sm`}>
              Example: <span className='underline text-underline-under'>https://www.tiktok.com/@womenofbsv/video/7049460412365982982</span>
            </p>
          </div>
          <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
          <div className={`mx-1 `}>
            <p className={`mx-1 mb-1`}>
              <b className='faq-custom-color'>Spotify:</b> https://open.spotify.com/track/VIDEO_ID
            </p>
            <p className={`mx-1 text-sm`}>
              Example: <span className='underline text-underline-under'>https://open.spotify.com/track/1Egg2iqvGvFVXFBPVIf9tL</span>
            </p>
          </div>
          <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
          <div className={`mx-1 `}>
            <p className={`mx-1 mb-1`}>
              <b className='faq-custom-color'>Apple Podcasts:</b> https://podcasts.apple.com/COUNTRY/podcast/TITLE/VIDEO_ID
            </p>
            <p className={`mx-1 text-sm`}>
              Example: <span className='underline text-underline-under'>https://podcasts.apple.com/us/podcast/324-daniel-negreanu-poker/id1434243584?i=1000580828318</span>
            </p>
          </div>
          <hr className={`sixth-border outsetBorder border my-3 opacity-50`}></hr>
          <div className={`mx-1 `}>
            <p className={`mx-1 mb-1`}>
              <b className='faq-custom-color'>SoundCloud:</b> https://soundcloud.com/ACCOUNT_NAME/SONG_NAME
            </p>
            <p className={`mx-1 mb-4 text-sm`}>
              Example: <span className='underline text-underline-under'>https://soundcloud.com/deftones_official/pink-maggit</span>
            </p>
          </div>
        </>
      },
      {
        title: 'Why do you utilize the Bitcoin-SV (BSV) Blockchain?',
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            Would you build your home on a shaky foundation that erodes over time,
            or would you build it on a concrete base that will stand the test of time?
          </p>
          <p className={`mx-1 mb-4`}>
            We chose the latter, and that’s why we chose Bitcoin-SV.
          </p>
          <p className={`mx-1 mb-1`}>
            For our platform to live a long and healthy life,
            we need to be sure that fees remain miniscule always and forever.
            <span className={` mx-1`}>With a set-in-stone protocol dedicated to scaling on-chain with maximum TPS,
              we have that solid base with Bitcoin-SV.</span>It’s as simple as that. Decentralization in the issuance of money is what we care about here.
          </p>
        </>
      },
      {
        title: 'Our Vision',
        text: <>
          <p className={`mx-1 mt-3 mb-4`}>
            <span className={` mr-1`}>Our vision is for you to have the greatest web, social media,
              and content creation + consumption experience possible.</span>
            A smooth and seamless experience from the moment you create your HandCash account,
            to the inevitable password hiccups along the way, to spending your first post and comment earnings,
            all the way to downloading your Excel spreadsheet so an accountant can account for everything you earned.
          </p>
          <p className={`mx-1 mb-4 underline text-underline-under`}>
            And this is how we’re doing it:
          </p>
          <div className={`mx-1 mb-4 colored-dots`}>
            <ul>
              <li className={``}>
                To best our competition, all creators receive 100% of every cent they earn.
              </li>
              <li className={``}>
                By using Bitcoin-SV’s Blockchain, payment transfer costs are trivial;
                creators get paid instantly and on-demand, and payments come directly from their audience.
              </li>
              <li className={``}>
                For a smooth, automated payment experience, we use the HandCash API and wallet system.
              </li>
              <li className={``}>
                To filter trolls, spam, and negativity seen on many other social media platforms, we added troll-tolls.
              </li>
              <li className={``}>
                For a high quality user experience, we don’t have annoying, in-your-face ads.
              </li>
              <li className={``}>
                By using the cost-to-post business model with optional membership purchases, we don’t have to rely on ads or selling your data to survive.
              </li>
            </ul>
          </div>
          <p className={`mx-1 mb-1`}>
            Evolving the entire experience is the name of the game here, on {globals.platformName}.
          </p>
        </>
      },
    ]
    return (
      <>
        {/* The mx-auto with a width set, enables centering without justify content, which we can't use because
        the flexbox "state" is already in justify-between. */}
        <main className={`flex md:inline-flex -mt-3 sm:mt-1 md:-mt-2 mx-auto w-full sm:max-w-3xl md:max-w-4xl`} id='main-content'>
          <div className={`w-full mb-3 rounded-xl px-1 ${faqOpacity} transition-to-opacity`}>
            <div className={`first-text px-2 pb-2 rounded-xl`}>
              <h2 className={`mb-4 text-center logo-style eleventh-text teko-font text-4xl`}>Frequently Asked Questions (FAQ)</h2>
              <div className={`text-left text-sm`}>
                {FAQInfoObject.map((item, i) => {
                  return (
                    // <AccordionItem
                    <React.Fragment key={i}>
                      {/* Title */}
                      <div className={`${i % 2 !== 0 ? `gradient-background-two-hover` : `gradient-background-eleven`} font-header w-full  eighth-text text-lg 
                        px-2 py-1 rounded-xl shadow-effect-eight`}>
                        <button className='w-full' onClick={(e) => this.toggleAccordion(e, i)}>
                          {item.title}
                        </button>
                      </div>
                      <div className={`description-color ${this.state.selectedFAQAccordionItem === i ? `accordion-content-faq show` : `accordion-content-faq`} mb-1`}>
                        {item.text}
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    faqOpacity: state.opacity.faqOpacity
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQPage);
