import React from 'react'
import { Icon } from '@iconify/react';
import shareSquare from '@iconify/icons-fa-solid/share-square';

const ShareComponent = (props) => {
  const { auth, togglePaymentModal, userAlreadySharedPost, postCreatorHandCashId } = props
  return (
    <>
      {/* If user is logged in */}
      {auth && auth.id
        ?
        // If this is the logged-in user's post
        auth.id === postCreatorHandCashId
          ? <></>
          // If this is not the logged in user's post
          :
          <>
            {/* If the user already shared the post */}
            {userAlreadySharedPost
              ? <></>
              // If they have not shared the post
              :
              <>
              <div className={`mx-2px mb-2`}>
                  <button className={`gradient-background-green text-white border-brand-green
                    items-center flex rounded-xl px-6px font-header button-ripple border-2 offsetBorder`}
                    onClick={(e) => togglePaymentModal(e, 'SHARE_POST')}>
                    <Icon icon={shareSquare} height={13} width={13} /><span className={`ml-2px`}>Share</span>
                  </button>
                </div>
              </>
            }
          </>
        // If user is not logged in
        : <></>
      }
    </>
  )
}

export default ShareComponent;