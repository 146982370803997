import React from 'react'

const SkeletonLoaderForPostDetail = (props) => {
  return (
    <>
      {/* Stats line */}
      <div className={`skeleton-card rounded-xl transition w-full fifth-background shadow-effect-one mb-4 py-1`}>
        {/* Row 1 */}
        <div className={`flex justify-between items-center mt-1`}>
          {/* Needs image in the right corner, then date in top */}
          <div className={`pl-3 flex items-center`}>
            {!props.removeAvatar ? <div className={`skeleton skeleton-fake-image rounded-xl mr-1`}></div> : <></>}
            <div className={`skeleton skeleton-text-large`}></div>
          </div>
          <div className={`pr-3`}>
            <div className={`skeleton skeleton-text-date`}></div>
          </div>
        </div>
        <hr className={`sixth-border outsetBorder border mb-2 mt-2 opacity-50 mx-2`}></hr>
        {/* Row 2 */}
        <div className={`flex justify-between items-center px-3 w-full`}>
          <div className={`skeleton skeleton-split`}></div>
          <div className={`skeleton skeleton-text-date mb-1`}></div>
        </div>
      </div>
      {/* PostDetail */}
      <div className={`skeleton-card rounded-xl transition fifth-background shadow-effect-one mb-4 py-1`}>
        {/* Row 1 */}
        <div className={`flex justify-center items-center my-3`}>
          <div className={`skeleton skeleton-text-title mx-1 mb-2`}></div>
        </div>
        <div className={`px-2`}>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
          <div className={`skeleton skeleton-text`}></div>
        </div>
        <hr className={`sixth-border outsetBorder border mb-2 mt-2 opacity-50 mx-2`}></hr>
      </div>
      <div className={`skeleton-card rounded-xl transition w-full fifth-background shadow-effect-one mb-4 py-1`}>
        <div className={`flex justify-between items-center mt-1 px-3 w-full`}>
          <div className='w-full'>
            <div className={`skeleton skeleton-split`}></div>
          </div>
          <div className='w-full'>
            <div className={`flex`}>
              <div className={`width-20-percent`}></div>
              <div className={`skeleton skeleton-split`}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SkeletonLoaderForPostDetail;
