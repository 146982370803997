import React from 'react';

const RadioButton = (props) => {

  return (
    <>
      {props.mobile
        ?
        <>
          <div className=''>
            <input
              type="radio"
              id={props.id}
              name='selectPoll'
              className={`mb-0 hidden`}
              value={props.value}
              onChange={(e) => props.handleChange(e, props.optionNumber-1)}
              disabled={props.userAlreadyVoted ? true : props.isFrozen ? true : false}
            />
            <label
              className={`w-full text-center text-sm mb-1 cursor-pointer rounded-xl items-center flex justify-center`}
              htmlFor={props.id}>
              <div className={`logo-style text-base word-wrap`}>{props.value}</div>
            </label>
          </div>
        </>
        :
        <>
          <div className='w-full'>
            <input
              type="radio"
              id={props.id}
              name='selectPoll'
              className={`mb-0 hidden`}
              value={props.value}
              onChange={(e) => props.handleChange(e, props.optionNumber-1)}
              disabled={props.userAlreadyVoted ? true : props.isFrozen ? true : false}
            />
            <label
              className={`w-full text-sm mb-1 cursor-pointer rounded-xl items-center flex ${props.optionNumber % 2 ? `justify-end` : `justify-start`}`}
              htmlFor={props.id}>
              <div className={`logo-style text-base word-wrap`}>{props.value}</div>
            </label>
          </div>
        </>
      }
    </>
  );
};

export default RadioButton