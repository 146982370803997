import React, { Component } from 'react';
import LoadingIcon from '../../components/Widgets/LoadingIcon'
import { Icon } from '@iconify/react';
import stampIcon from '@iconify/icons-la/stamp';
import handshakeIcon from '@iconify/icons-fa-regular/handshake';
import postageStamp from '@iconify/icons-mdi/postage-stamp';
import vote20Regular from '@iconify/icons-fluent/vote-24-filled';
import bulletListLine from '@iconify/icons-clarity/bullet-list-line';
import codeLine from '@iconify/icons-clarity/code-line';
import textDocumentEdit from '@iconify/icons-fluent-mdl2/text-document-edit';
import musicInCollection from '@iconify/icons-fluent-mdl2/music-in-collection';
import openBook from '@iconify/icons-vaadin/open-book';
import food16Filled from '@iconify/icons-fluent/food-16-filled';
import educationIcon from '@iconify/icons-fluent-mdl2/education';
import laughSquint from '@iconify/icons-fa-solid/laugh-squint';
import flowerIcon from '@iconify/icons-fluent-mdl2/flower';
import twitchIcon from '@iconify/icons-mdi/twitch';
import catIcon from '@iconify/icons-mdi/cat';
import myMoviesTV from '@iconify/icons-fluent-mdl2/my-movies-t-v';
import brandTiktok from '@iconify/icons-tabler/brand-tiktok';
import briefcaseIcon from '@iconify/icons-vaadin/briefcase';
import colorPaletteSolid from '@iconify/icons-clarity/color-palette-solid';
import barbellIcon from '@iconify/icons-tabler/barbell';
import basketballIcon from '@iconify/icons-mdi/basketball';
import heartbeatIcon from '@iconify/icons-fa-solid/heartbeat';
import bookSolid from '@iconify/icons-clarity/book-solid';
import newspaperIcon from '@iconify/icons-vaadin/newspaper';
import microphoneSolid from '@iconify/icons-clarity/microphone-solid';
import personQuestionMark20Filled from '@iconify/icons-fluent/person-question-mark-20-filled';
import knowledgeArticle from '@iconify/icons-fluent-mdl2/knowledge-article';
import talkBubblesLine from '@iconify/icons-clarity/talk-bubbles-line';
import lightbulbIcon from '@iconify/icons-vaadin/lightbulb';
import smileyO from '@iconify/icons-vaadin/smiley-o';
import helpInfoSolid from '@iconify/icons-clarity/help-info-solid';
import applicationBraces from '@iconify/icons-mdi/application-braces';
import adIcon from '@iconify/icons-fa-solid/ad';
import currencyUsd from '@iconify/icons-mdi/currency-usd';
import podcastIcon from '@iconify/icons-fa-solid/podcast';
import clipboardCheck from '@iconify/icons-la/clipboard-check';
import instagramIcon from '@iconify/icons-la/instagram';
import brainCircuit20Filled from '@iconify/icons-fluent/brain-circuit-20-filled';
import diplomaScroll from '@iconify/icons-vaadin/diploma-scroll';
import atomSolid from '@iconify/icons-clarity/atom-solid';

// Components
import PostListConductor from '../../components/PostList/PostListConductor';
import {
  MainPageContent,
  AllDescription,
  ListsDescription,
  BSVNewsDescription,
  EntrepreneurshipDescription,
  CodeShareDescription,
  WaterCoolerDescription,
  GamingDescription,
  TalentShowcaseDescription,
  AMADescription,
  InterviewsDescription,
  MusicDescription,
  BeautifulNatureDescription,
  StampYouDescription,
  MeetTheStampersDescription,
  StoriesDescription,
  CareerDescription,
  ArticlesDescription,
  QuestionsDescription,
  TutorialsDescription,
  TeachDescription,
  LifeDescription,
  HealthDescription,
  BooksDescription,
  PollsDescription,
  PromptsDescription,
  InTheNewsDescription,
  ComedyDescription,
  TikTokPlaylistDescription,
  CookingDescription,
  FitnessDescription,
  ArtDescription,
  AnimalsDescription,
  SportsDescription,
  LetterForeverDescription,
  PromotionsDescription,
  PodcastsDescription,
  ReviewsDescription,
  PanoramicReelsDescription,
  ChatGPTDescription,
  ScienceDescription,
  PoetryDescription,
  BountiesDescription,
  BitcoinBusinessesDescription,
  CommunitiesDescription
} from '../../config/globals';

/////////////////////////////////////////////////////////////////////
// The Main Page populates the data based upon the selected category. 
// Users are provided with the category name, description, posts,
// and the ability to create a post within the category.
////////////////////////////////////////////////////////

class MainPage extends Component {

  render() {
    const {
      // Singles
      auth,
      toggleLoginModal,
      refCallbackForPostsList,
      goToUserProfilePage,
      userDatabaseData,
      // Category
      categoryIsLoaded,
      categoryOpacity,
      postsOpacity,
      categoryDisplayName,
      categoryName,
      // Posts
      homePosts,
      allTimePosts,
      newestPosts,
      trendingPosts,
      weekPosts,
      followingPosts,
      // Loading
      allTimePostsIsLoaded,
      followingPostsIsLoaded,
      newestPostsIsLoaded,
      trendingPostsIsLoaded,
      homePostsIsLoaded,
      weekPostsIsLoaded,
      homeStartingLoaded,
      allTimeStartingLoaded,
      newestStartingLoaded,
      trendingStartingLoaded,
      weekStartingLoaded,
      followingStartingLoaded,
      postsType,
      // Results
      noMoreResultsHomePosts,
      noMoreResultsAllTimePosts,
      noMoreResultsTrendingPosts,
      noMoreResultsNewestPosts,
      noMoreResultsWeekPosts,
      noMoreResultsFollowingPosts,
      // Sorts
      sortSelections,
      toggleSort,
      clickToPostDetailPage,
      clickToCreatePost,
      // View
      listOrPostView,
      postViewSelected,
      listViewSelected,
      setView,
      toggleCategoryModal
    } = this.props;
    return (
      <>
        {/* Do not put mb here. It creates white space */}
        <main className={`flex md:inline-flex mt-2 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition`}>
          <div className={`w-full rounded px-1`}>
            <div className='mb-3 rounded'>
              {!categoryIsLoaded
                ?
                <LoadingIcon />
                // <></>
                :
                <>
                  <div className={`${categoryOpacity} transition-to-opacity`}>
                    <div className={`p-3 ${categoryName === '' ? `mb-8` : `mb-3`} rounded-xl transition first-text gradient-background-description-two shadow-effect-two`}>
                      {/* If the category does not equal '', we add the categoryName */}
                      {categoryName !== ''
                        ? categoryName === `meet-the-stampers`
                          ?
                          <>
                            {/* Category Name in center */}
                            <div className={`text-center`}>
                              <div className={`relative flex justify-start`}><span className={`absolute mt-2px`}><div className={`font-header stamp text-xs`}>FREE</div></span></div>
                              <h2 className={`mb-1 teko-font text-center flex justify-center items-center text-4xl logo-style eleventh-text`}>
                                {categoryDisplayName}
                                {categoryDisplayName === 'Meet the Stampers' ? <div className={`ml-2 -mt-px `}><Icon icon={handshakeIcon} height={30} width={30} /></div> : <></>}
                              </h2>
                            </div>
                            {/* <hr className="border-brand-green border-2 rounded outsetBorder"></hr>  */}
                            <hr className={`sixth-border outsetBorder border rounded`}></hr>
                            <div className={`sm:text-sm text-xs mt-3 description-color`}><DescriptionSwitchStatement categoryName={categoryName} /></div>
                          </>
                          : <>
                            {/* Category Name in center */}
                            <div className={`text-center`}>
                              <h2 className={`mb-1 teko-font flex items-center justify-center text-center text-4xl logo-style eleventh-text`}>
                                {categoryDisplayName}
                                {/* Stamps */}
                                {categoryDisplayName === 'Stamp You' ? <div className={`ml-2px -mt-1`}><Icon icon={stampIcon} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'All' ? <div className={`ml-1 -mt-7px`}><Icon icon={postageStamp} height={30} width={30} /></div> : <></>}
                                {/* Entertainment + */}
                                {categoryDisplayName === 'Twitch Gaming' ? <div className='ml-1 -mt-px'><Icon icon={twitchIcon} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Spotify Soundcloud' ? <div className='ml-1 -mt-7px'><Icon icon={musicInCollection} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Podcasts' ? <div className='ml-1 -mt-7px'><Icon icon={podcastIcon} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Teach & Learn' ? <div className='ml-2 -mt-7px'><Icon icon={educationIcon} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Comedy Corner' ? <div className='ml-2 -mt-3px'><Icon icon={laughSquint} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Sports' ? <div className='ml-1 -mt-1'><Icon icon={basketballIcon} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Fitness' ? <div className='ml-1 -mt-3px'><Icon icon={barbellIcon} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Cooking' ? <div className='ml-1 -mt-7px'><Icon icon={food16Filled} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Talent Showcase' ? <div className='ml-1 -mt-7px'><Icon icon={myMoviesTV} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Animal Love' ? <div className='ml-1 -mt-1'><Icon icon={catIcon} height={34} width={34} /></div> : <></>}
                                {categoryDisplayName === 'Art' ? <div className='ml-1 -mt-7px'><Icon icon={colorPaletteSolid} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Beautiful Nature' ? <div className='ml-1 -mt-7px'><Icon icon={flowerIcon} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'TikTok Playlist' ? <div className='ml-1 -mt-7px'><Icon icon={brandTiktok} height={32} width={32} /></div> : <></>}
                                {categoryDisplayName === 'Panoramic Reels' ? <div className='ml-1 -mt-2px'><Icon icon={instagramIcon} height={32} width={32} /></div> : <></>}
                                {/* Professional */}
                                {categoryDisplayName === 'Entrepreneurship' ? <div className='ml-1 -mt-7px'><Icon icon={lightbulbIcon} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'AMA' ? <div className='ml-2 -mt-7px'><Icon icon={talkBubblesLine} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Business & Career' ? <div className='ml-2 -mt-7px'><Icon icon={briefcaseIcon} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Interviews' ? <div className='ml-1 -mt-7px'><Icon icon={microphoneSolid} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'In The News' ? <div className='ml-1 -mt-7px'><Icon icon={newspaperIcon} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Bitcoin Businesses' ? <div className='ml-2 -mt-1'><Icon icon={applicationBraces} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Branded Promotions' ? <div className='ml-2 -mt-1'><Icon icon={adIcon} height={30} width={30} /></div> : <></>}
                                {/* Knowledge */}
                                {categoryDisplayName === 'How To Tutorials' ? <div className='ml-px -mt-7px'><Icon icon={helpInfoSolid} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Articles' ? <div className='ml-1 -mt-7px'><Icon icon={knowledgeArticle} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'ChatGPT & AI' ? <div className='ml-1 -mt-7px'><Icon icon={brainCircuit20Filled} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Science' ? <div className='ml-1 -mt-1'><Icon icon={atomSolid} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Reviews' ? <div className='ml-1 -mt-7px'><Icon icon={clipboardCheck} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Life' ? <div className='ml-2 -mt-7px'><Icon icon={smileyO} height={26} width={26} /></div> : <></>}
                                {categoryDisplayName === 'Health & Diet' ? <div className='ml-2 -mt-7px'><Icon icon={heartbeatIcon} height={26} width={26} /></div> : <></>}
                                {categoryDisplayName === 'Code Share' ? <div className='ml-1 -mt-1'><Icon icon={codeLine} height={32} width={32} /></div> : <></>}
                                {categoryDisplayName === 'Books' ? <div className='ml-1 -mt-7px'><Icon icon={bookSolid} height={28} width={28} /></div> : <></>}
                                {/* Bonus */}
                                {categoryDisplayName === 'Polls' ? <div className='ml-1 -mt-7px'><Icon icon={vote20Regular} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Lists' ? <div className='ml-1'><Icon icon={bulletListLine} height={32} width={32} /></div> : <></>}
                                {categoryDisplayName === 'Questions' ? <div className='ml-1 -mt-7px'><Icon icon={personQuestionMark20Filled} height={30} width={30} /></div> : <></>}
                                {categoryDisplayName === 'Stories' ? <div className='ml-2 -mt-7px'><Icon icon={openBook} height={28} width={28} /></div> : <></>}
                                {categoryDisplayName === 'Letter Forever' ? <div className='ml-2 -mt-1'><Icon icon={textDocumentEdit} height={24} width={24} /></div> : <></>}
                                {categoryDisplayName === 'Poetry' ? <div className='ml-2 -mt-1'><Icon icon={diplomaScroll} height={24} width={24} /></div> : <></>}

                                {categoryDisplayName === 'Bounty Challenges' ? <div className='-mt-1'><Icon icon={currencyUsd} height={32} width={32} /></div> : <></>}
                                {/* No longer appears on frontend */}
                                {categoryDisplayName === 'BSV Apps & Business' ? <div className='ml-2 -mt-1'><Icon icon={applicationBraces} height={28} width={28} /></div> : <></>}

                              </h2>
                            </div>
                            {/* <hr className="border-brand-green border-2 rounded outsetBorder"></hr>  */}
                            <hr className={`sixth-border outsetBorder border rounded`}></hr>
                            <div className={`sm:text-sm text-xs mt-3 description-color`}><DescriptionSwitchStatement categoryName={categoryName} /></div>
                          </>
                        :
                        <>
                          {auth && auth.id
                            ?
                            <>
                              <h2 className={`mb-2 flex items-center justify-center teko-font text-center text-4xl logo-style eleventh-text`}>
                                Your Feed
                              </h2>
                              <hr className={`sixth-border outsetBorder border`}></hr>
                            </>
                            :
                            // This is where I will be changing
                            <>
                              <h2 className={`mb-2 teko-font text-center text-4xl logo-style eleventh-text`}>Welcome</h2>
                              <hr className={`sixth-border outsetBorder border rounded`}></hr>
                              <div className={`text-md mt-2`}><MainPageContent /></div>
                              <hr className={`sixth-border outsetBorder border rounded`}></hr>
                            </>
                          }
                          <div className={`flex text-sm justify-between`}>
                            <div className={``}>
                              <button className={`gradient-background-green text-white border-brand-green
                                  text-sm rounded-xl py-1 px-4 mr-4 mt-3 button-ripple border-2 font-header offsetBorder`}
                                onClick={(e) => toggleCategoryModal(e)}>
                                Create Post
                              </button>
                            </div>
                            <div className={`mt-4`}>
                              <button className={`eighth-text gradient-background-twelve py-2px
                              button-ripple transition mx-px px-1 self-end font-header rounded
                              ${postViewSelected ? `${postViewSelected}` : `shadow-effect-seven`} `}
                                onClick={(e) => setView(e, 'postView')}
                              >
                                Post View
                              </button>
                              <button className={`eighth-text gradient-background-twelve py-2px
                              button-ripple transition mx-px px-1 self-end font-header rounded
                              ${listViewSelected ? `${listViewSelected}` : `shadow-effect-seven`}`}
                                onClick={(e) => setView(e, 'listView')}
                              >
                                List View
                              </button>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                  {/* SORT TOGGLER.  */}
                  {categoryName !== '' || null
                    ?
                    <>
                      {/* Small screen version */}
                      <div className={`sm:hidden w-full rounded-lg px-2 mb-4 transition`}>
                        <div className={`${categoryOpacity} transition-to-opacity`}>
                          <div className={`text-center text-sm flex-justify-center flex-1`}>
                            <div className={`text-2xl teko-font flex flex-1 justify-center logo-style eleventh-text pb-px mb-1 -mt-1`}>
                              Sort - Toggle - Create
                            </div>
                            <div className={`flex flex-wrap justify-center mb-1`}>
                              {sortSelections.map((sortType, index) => {
                                return (
                                  <button key={index}
                                    className={`mx-2px button-ripple text-center rounded-xl px-4 my-1 py-2px cursor-pointer content-between first-text gradient-background-twelve 
                                    ${sortType.selectedClass ? `${sortType.selectedClass}` : `shadow-effect-seven`} font-header`}
                                    onClick={(e) => toggleSort(e, sortType.sortName, index)}
                                  >
                                    {sortType.displayName}
                                  </button>
                                )
                              })}
                            </div>
                          </div>
                          <div className={`flex justify-center mt-3`}>
                            <div className={``}>
                              {categoryName !== 'all'
                                ? auth?.id
                                  // If they're in beautiful-nature and not a member
                                  ? categoryName === 'meet-the-stampers' && userDatabaseData?.postedInMeetTheStampers
                                    ? <></>
                                    : <>
                                      <button className={`gradient-background-green text-white border-brand-green
                                        text-sm rounded-xl py-1 px-4 mr-4 mt-px button-ripple border-2 font-header offsetBorder`}
                                        onClick={(e) => clickToCreatePost(e, categoryName)}>
                                        Create Post
                                      </button>
                                    </>
                                  :
                                  <>
                                    <button className={`gradient-background-green text-white border-brand-green
                                    cursor-pointer text-sm rounded-xl py-1 px-2 mr-4 mt-px button-ripple border-2 font-header offsetBorder`}
                                      onClick={() => toggleLoginModal()}
                                    >
                                      Login to Post
                                    </button>
                                  </>
                                // If category name is 'all'
                                :
                                <>
                                  <button className={`gradient-background-green text-white border-brand-green
                                      text-sm rounded-xl py-1 px-4 mr-4 mt-px button-ripple border-2 font-header offsetBorder`}
                                    onClick={() => toggleCategoryModal()}>
                                    Create Post
                                  </button>
                                </>
                              }
                            </div>
                            <div className={`inline-flex flex-wrap text-sm items-center`}>
                              <button className={`gradient-background-twelve first-text py-2px rounded-lg
                                button-ripple transition mx-px my-1 px-2 text-center font-header 
                                ${postViewSelected ? `${postViewSelected} ` : `shadow-effect-seven `}`}
                                onClick={(e) => setView(e, 'postView')}
                              >
                                Post View
                              </button>
                              <button className={`gradient-background-twelve first-text py-2px rounded-lg
                                button-ripple transition px-2 mx-px my-1 items-center text-center font-header 
                                ${listViewSelected ? `${listViewSelected} ` : `shadow-effect-seven `}`}
                                onClick={(e) => setView(e, 'listView')}
                              >
                                List View
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Larger screen version */}
                      <div className={`w-full rounded-lg px-2 mb-4 transition hidden sm:block`}>
                        <div className={`${categoryOpacity} transition-to-opacity flex`}>
                          <div className={`flex justify-start flex-1 items-center pl-px`}>
                            {categoryName !== 'all'
                              ? auth && auth.id
                                ? categoryName === 'meet-the-stampers' && userDatabaseData?.postedInMeetTheStampers
                                  ? <></>
                                  :
                                  <>
                                    <button className={`gradient-background-green text-white border-brand-green
                                      text-sm rounded-xl py-1 px-4 mr-4 mt-1 button-ripple border-2 font-header offsetBorder`}
                                      onClick={(e) => clickToCreatePost(e, categoryName)}>
                                      Create Post
                                    </button>
                                  </>
                                :
                                <>
                                  <button className={`gradient-background-green text-white border-brand-green
                                    text-sm rounded-xl py-1 px-2 mr-4 mt-1 button-ripple border-2 font-header offsetBorder`}
                                    onClick={() => toggleLoginModal()}
                                  >
                                    Login to Post
                                  </button>
                                </>
                              // If category is "all"
                              : <>
                                <button className={`gradient-background-green text-white border-brand-green
                                  text-sm rounded-xl py-1 px-4 mr-4 mt-1 button-ripple border-2 font-header offsetBorder`}
                                  onClick={() => toggleCategoryModal()}>
                                  Create Post
                                </button>
                              </>
                            }
                          </div>
                          <div className={`text-center text-sm flex-1 -mt-1`}>
                            <div className={`text-2xl -mb-1 teko-font flex flex-1 justify-center logo-style eleventh-text`}>
                              Sort Selection
                            </div>
                            <div className={`flex`}>
                              {sortSelections.map((sortType, index) => {
                                return (
                                  <button key={index} className={`mx-2px w-24 my-1 py-2px
                                    eighth-text gradient-background-twelve rounded-xl button-ripple transition text-center 
                                    ${sortType.selectedClass ? `${sortType.selectedClass}` : `shadow-effect-seven`} font-header`}
                                    onClick={(e) => toggleSort(e, sortType.sortName, index)}
                                  >
                                    {sortType.displayName}
                                  </button>
                                )
                              })}
                            </div>
                          </div>
                          <div className={`flex-1 mr-1 -mt-1`}>
                            <div className={`text-2xl mr-2px -mb-1 teko-font flex flex-1 justify-end logo-style eleventh-text`}>
                              Toggle View
                            </div>
                            <div className={`flex justify-end`}>
                              <div className={`inline-flex text-sm`}>
                                <button className={`eighth-text gradient-background-twelve rounded py-2px
                                  transition button-ripple w-47px my-1 mx-px text-center font-header 
                                  ${postViewSelected ? `${postViewSelected} ` : `shadow-effect-seven `}`}
                                  onClick={(e) => setView(e, 'postView')}
                                >
                                  Post
                                </button>
                                <button className={` eighth-text gradient-background-twelve rounded py-2px
                                  transition button-ripple w-47px my-1 mx-px text-center font-header 
                                  ${listViewSelected ? `${listViewSelected} ` : `shadow-effect-seven`}`}
                                  onClick={(e) => setView(e, 'listView')}
                                >
                                  List
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    : null
                  }
                </>
              }
            </div>
            <div className={`${postsOpacity} transition-to-opacity`} id='main-content'>
              <PostListConductor
                auth={auth}
                homePosts={homePosts}
                trendingPosts={trendingPosts}
                allTimePosts={allTimePosts}
                newestPosts={newestPosts}
                weekPosts={weekPosts}
                followingPosts={followingPosts}

                categoryName={categoryName}
                homePostsIsLoaded={homePostsIsLoaded}
                allTimePostsIsLoaded={allTimePostsIsLoaded}
                trendingPostsIsLoaded={trendingPostsIsLoaded}
                newestPostsIsLoaded={newestPostsIsLoaded}
                weekPostsIsLoaded={weekPostsIsLoaded}
                followingPostsIsLoaded={followingPostsIsLoaded}
                homeStartingLoaded={homeStartingLoaded}
                allTimeStartingLoaded={allTimeStartingLoaded}
                newestStartingLoaded={newestStartingLoaded}
                trendingStartingLoaded={trendingStartingLoaded}
                weekStartingLoaded={weekStartingLoaded}
                followingStartingLoaded={followingStartingLoaded}
                noMoreResultsHomePosts={noMoreResultsHomePosts}
                noMoreResultsAllTimePosts={noMoreResultsAllTimePosts}
                noMoreResultsTrendingPosts={noMoreResultsTrendingPosts}
                noMoreResultsNewestPosts={noMoreResultsNewestPosts}
                noMoreResultsWeekPosts={noMoreResultsWeekPosts}
                noMoreResultsFollowingPosts={noMoreResultsFollowingPosts}
                postsType={postsType}
                refCallbackForPostsList={refCallbackForPostsList}
                clickToPostDetailPage={clickToPostDetailPage}
                listOrPostView={listOrPostView}
                goToUserProfilePage={goToUserProfilePage}
              />
            </div>
          </div>
        </main>
      </>
    )
  }
};

const DescriptionSwitchStatement = (props) => {
  switch (props.categoryName) {
    case 'all':
      return <AllDescription />
    case 'communities':
      return <CommunitiesDescription />
    case 'stamp-you':
      return <StampYouDescription />
    case 'meet-the-stampers':
      return <MeetTheStampersDescription />
    // Entertainment +
    case 'gaming':
      return <GamingDescription />
    case 'music':
      return <MusicDescription />
    case 'podcasts':
      return <PodcastsDescription />
    case 'teach':
      return <TeachDescription />
    case 'comedy':
      return <ComedyDescription />
    case 'sports':
      return <SportsDescription />
    case 'fitness':
      return <FitnessDescription />
    case 'cooking':
      return <CookingDescription />
    case 'talent-showcase':
      return <TalentShowcaseDescription />
    case 'animals':
      return <AnimalsDescription />
    case 'art':
      return <ArtDescription />
    case 'beautiful-nature':
      return <BeautifulNatureDescription />
    case 'panoramic-reels':
      return <PanoramicReelsDescription />
    case 'tiktok-playlist':
      return <TikTokPlaylistDescription />
    // Professional
    case 'entrepreneurship':
      return <EntrepreneurshipDescription />
    case 'ama':
      return <AMADescription />
    case 'career':
      return <CareerDescription />
    case 'interviews':
      return <InterviewsDescription />
    case 'in-the-news':
      return <InTheNewsDescription />
    case 'bitcoin-businesses':
      return <BitcoinBusinessesDescription />
    case 'promotions':
      return <PromotionsDescription />
    // Knowledge
    case 'tutorials':
      return <TutorialsDescription />
    case 'articles':
      return <ArticlesDescription />
    case 'chatgpt':
      return <ChatGPTDescription />
    case 'science':
      return <ScienceDescription />
    case 'reviews':
      return <ReviewsDescription />
    case 'life':
      return <LifeDescription />
    case 'health':
      return <HealthDescription />
    case 'code-share':
      return <CodeShareDescription />
    case 'books':
      return <BooksDescription />
    // Bonus
    case 'polls':
      return <PollsDescription />
    case 'lists':
      return <ListsDescription />
    case 'questions':
      return <QuestionsDescription />
    case 'stories':
      return <StoriesDescription />
    case 'letter-forever':
      return <LetterForeverDescription />
    case 'poetry':
      return <PoetryDescription />
    case 'bounties':
      return <BountiesDescription />
    // Water Cooler
    case 'water-cooler':
      return <WaterCoolerDescription />
    // No longer used & Default
    case 'prompts':
      return <PromptsDescription />
    case 'bsv-news':
      return <BSVNewsDescription />
    default:
      return <StampYouDescription />
  }
}

export default MainPage;