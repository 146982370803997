import React from 'react';
import EditorPageForPosts from './EditorPageForPosts';
// import EditorPageForLists from './EditorPageForLists';
import EditorPageForCode from './EditorPageForCode';
import EditorPageForPolls from './EditorPageForPolls';

// Seems excessive, but an if/else statement based upon lumping categoryNames doesn't function properly
// The switch statement based upon each categoryName is required.
// Luckily, this will enable us to easily add different editor properties in the future, if needed.
const EditorPageConductor = props => {
  // Rendering Switch Statement
  switch (props.categoryName) {
    case 'communities':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Communities'} />;
    case 'stamp-you':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Stamp You'} />;
    case 'meet-the-stampers':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Meet the Stampers'} />;
    // Entertainment +
    case 'gaming':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Twitch Gaming`} />;
    case 'music':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Soundcloud Spotify'} />;
    case 'podcasts':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Podcasts'} />;
    case 'teach':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Teach & Learn`} />;
    case 'comedy':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Comedy Corner'} />;
    case 'sports':
      return <EditorPageForPosts {...props} categoryNameDisplay={'YouTube Sports'} />;
    case 'fitness':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Insta Fitness`} />;
    case 'cooking':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Cooking`} />;
    case 'talent-showcase':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Talent Showcase'} />;
    case 'animals':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Animal Love'} />;
    case 'art':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Art'} />;
    case 'beautiful-nature':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Beautiful Nature'} />;
    case 'tiktok-playlist':
      return <EditorPageForPosts {...props} categoryNameDisplay={'TikTok Playlist'} />;
    case 'panoramic-reels':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Panoramic Reels'} />;
    // Professional
    case 'entrepreneurship':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Entrepreneurship'} />;
    case 'ama':
      return <EditorPageForPosts {...props} categoryNameDisplay={`AMA`} />;
    case 'career':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Business & Career'} />;
    case 'interviews':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Interviews`} />;
    case 'in-the-news':
      return <EditorPageForPosts {...props} categoryNameDisplay={'In The News'} />;
    case 'bitcoin-businesses':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Bitcoin Businesses'} />;
    case 'promotions':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Branded Promotions'} />;
    // Knowledge
    case 'tutorials':
      return <EditorPageForPosts {...props} categoryNameDisplay={`How To Tutorials`} />;
    case 'articles':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Articles'} />;
    case 'chatgpt':
      return <EditorPageForPosts {...props} categoryNameDisplay={`ChatGPT & AI`} />;
    case 'science':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Science`} />;
    case 'reviews':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Reviews`} />
    case 'life':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Life`} />
    case 'health':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Health & Diet'} />;
    case 'code-share':
      return <EditorPageForCode {...props} categoryNameDisplay={'Code Share'} />;
    case 'books':
      return <EditorPageForPosts {...props} categoryNameDisplay={`Books`} />;
    // Bonus
    case 'polls':
      return <EditorPageForPolls {...props} categoryNameDisplay={'Polls'} />;
    case 'lists':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Lists'} />;
    case 'questions':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Questions'} />;
    case 'stories':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Stories'} />;
    case 'letter-forever':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Letter Forever'} />;
    case 'poetry':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Poetry'} />;
    case 'bounties':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Bounty Challenges'} />;
    // Water cooler
    case 'water-cooler':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Water Cooler'} />;
    // No longer used
    case 'prompts':
      return <EditorPageForPosts {...props} categoryNameDisplay={'Bounty Challenges'} />;
    case 'bsv-news':
      return <EditorPageForPosts {...props} categoryNameDisplay={'BSV Apps & Business'} />;

    default:
      return <EditorPageForPosts {...props} categoryNameDisplay={'Post Editor'} />;
  }
};

export default EditorPageConductor;