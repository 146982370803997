import React, { Component } from 'react'
import { connect } from 'react-redux';
import { opacityChange } from '../store/reducers/opacitySlice';
import API from '../utils/API';
import { globals } from '../config/globals';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import SkeletonLoaderTwoLines from '../components/Widgets/SkeletonLoaderTwoLines';

class SearchPage extends Component {
  state = {
    searchInputValue: '',
    searchResults: [],
    searchResultsAreLoading: false,
    searchResultsOpacity: 'opacity-0',
    searchType: 'User',
    disableSubmit: true,
    submittedSearchValue: '',
    submittedSearchType: '',
  }
  cancelToken = API.CancelToken.source();

  componentDidMount() {
    setTimeout(() => {
      this.props.opacityChange('addSearchPageOpacity')
    }, 100)
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled')
  };
  goToPost = (e, postSlug) => {
    e.preventDefault()
    this.props.opacityChange('removeSearchPageOpacity')
    setTimeout(() => {
      this.props.history.push(`/posts/${postSlug}`)
    }, 300)
  }
  goToUserProfilePage = (e, userName) => {
    e.preventDefault();
    // Do an opacityChange
    this.props.opacityChange('removeSearchPageOpacity')
    setTimeout(() => {
      this.props.history.push(`/profile/${userName}`)
    }, 300)
  }
  onSearchChange = (e) => {
    this.setState({ searchInputValue: e.target.value })
    if (this.state.disableSubmit) {
      setTimeout(() => {
        if (this.state.searchInputValue.length > 1) {
          this.setState({ disableSubmit: false })
        }
      }, 100)
    }
  }
  onSearchTypeChange = (e) => {
    if (this.state.searchType === e.target.value) return
    this.setState({ searchType: e.target.value })
    if (this.state.disableSubmit) {
      setTimeout(() => {
        this.setState({ disableSubmit: false })
      }, 100)
    }
  }
  handleSubmitSearch = (e) => {
    e.preventDefault()
    // Guard clauses to prevent multiple searches of the same thing (waste of resources)
    if (this.state.searchInputValue.length < 2) return
    if (!['User', 'Tags/Subjects', 'Post Title'].includes(this.state.searchType)) return
    if (this.state.submittedSearchValue === this.state.searchInputValue && this.state.submittedSearchType === this.state.searchType) return
    // Data to include for query
    const searchData = {
      searchType: this.state.searchType,
      searchInputValue: this.state.searchInputValue
    }
    this.setState({
      disableSubmit: true,
      submittedSearchValue: searchData.searchInputValue,
      submittedSearchType: searchData.searchType,
      searchResultsAreLoading: true,
    })
    API.getSearchResults(searchData, this.cancelToken.token)
      .then(res => {
        let searchResult = []
        switch (searchData.searchType) {
          case 'Tags/Subjects':
            if (res.data.length > 0) {
              res.data.forEach(item => {
                item.postsWithTagArray.forEach(newItem => {
                  searchResult.push(newItem)
                })
              })
            }
            else {
              searchResult = []
            }
            break
          case 'Post Title':
            if (res.data.length > 0) searchResult = res.data
            break
          case 'User':
            if (res.data.length > 0) searchResult = res.data
            break
          default: searchResult = []
        }
        this.setState({ searchResults: searchResult, searchResultsOpacity: 'opacity-0' })
        setTimeout(() => {
          this.setState({ searchResultsOpacity: 'opacity-100', searchResultsAreLoading: false, })
        }, 300)
      })
      .catch(error => {
        console.error(error);
        this.setState({ searchResultsAreLoading: false, searchResultsOpacity: 'opacity-100' })
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to find content');
      });
  }
  render() {
    const { searchPageOpacity } = this.props
    return (
      <>
        <main id='main-content' className={`flex rounded-xl md:inline-flex mt-2 mb-4 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition `}>
          <div className={`w-full rounded-xl px-1 ${searchPageOpacity} transition-to-opacity`}>
            <div className={`p-3 mb-2 rounded-xl transition first-text gradient-background-four shadow-effect-eight`}>
              <h2 className={`mb-1 logo-style eleventh-text teko-font text-4xl text-center`}>Search</h2>
              <hr className={`border-brand-green outsetBorder border-2 mx-2 mb-2`}></hr>              
              <div className={`mb-2 italic text-sm text-center`}>
                *Note: We are not a Google search engine, so spell to the best of your ability.
              </div>
              {/* Search Inputs */}
              <form>
                <label htmlFor='search'></label>
                <div className={`flex flex-wrap justify-center items-center`}>
                  <div className={`flex flex-wrap justify-center`}>
                    <select value={this.state.searchType} onChange={this.onSearchTypeChange}
                      className={`rounded p-2 mb-2 w-full sm:w-auto gradient-background-seven shadow-effect-ten border mr-2
                    outline-none rounded-md px-2 text-gray-900 transition
                    `}
                    >
                      <option className={`hover:text-white`} name="user">User</option>
                      <option name="tags">Tags/Subjects</option>
                      <option name="title">Post Title</option>
                    </select>
                    <input type='text' inputMode="search" autoComplete="on" value={this.state.searchInputValue} onChange={this.onSearchChange}
                      placeholder="Enter your search here..."
                      aria-label="Search"
                      className={`rounded p-2 mb-2 gradient-background-seven shadow-effect-ten border mr-2
                      outline-none rounded-md px-2 text-gray-900 transition w-full sm:w-64`}
                    />
                  </div>
                  <button onClick={e => this.handleSubmitSearch(e)}
                    className={`px-5 py-1 mb-2 text-white gradient-background-green border-brand-green 
                    text-sm rounded-xl border font-header offsetBorder`}
                    disabled={this.state.disableSubmit}
                  >
                    Search
                  </button>
                </div>
              </form>
              {/* Search Results */}
              <div className={`${this.state.searchResultsOpacity} transition-to-opacity my-2`}>
                {this.state.searchResultsAreLoading
                  ?
                  <div className={`text-center`}>
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                    <SkeletonLoaderTwoLines />
                  </div>
                  :
                  this.state.submittedSearchValue.length > 0
                    ?
                      <SearchListConductor
                        {...this.state}
                        goToPost={this.goToPost}
                        goToUserProfilePage={this.goToUserProfilePage}
                      />
                    : <></>
                }
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}
const SearchListConductor = props => {
  switch (props.submittedSearchType) {
    case 'Post Title':
      return <SearchResultsForPosts {...props} />
    case 'Tags/Subjects':
      return <SearchResultsForPosts {...props} />
    case 'User':
      return <SearchResultsForUser {...props} />
    default:
      return <SearchResultsForPosts {...props} />
  }
};
const SearchResultsForPosts = props => {
  const { searchResults, goToPost, goToUserProfilePage } = props
  return (
    <>
      {/* Should have a container div for the search results */}
      {searchResults && searchResults.length > 0
        ?
        <>
          {searchResults.map((post, index) => {
            return (
              <React.Fragment key={index}>
                {/* Wrapper */}
                <div className={`first-text gradient-background-four shadow-effect-eight rounded-xl word-wrap transition pl-2 pr-1 mb-3 py-1`}>
                  {/* 1st Row */}
                  <div className={`text-sm flex flex-wrap items-center overflow-hidden mb-1 sm:justify-between`}>
                    {/* Left Side */}
                    <div className={`flex flex-wrap items-center`}>
                      <div className={``}>
                        <img width='20' height='20'
                          alt="Avatar"
                          src={post.postCreatorAvatarURL}
                          className={`rounded-xl mx-auto mr-1`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          >
                        </img>
                      </div>
                      <div className={`mr-1 flex flex-wrap`}>
                        <button className={`hover:text-brandGreen mr-1 underline text-underline-under hover:underline cursor-pointer`}
                          onClick={(e) => goToPost(e, post.slug)}
                        >
                          {post.title}
                        </button>
                        <div className={``}>
                          {/* <span className={`text-medium-gray`}> */}
                            by
                            {/* </span> */}
                          <button className={`ml-1 hover:text-brandGreen hover:underline whitespace-nowrap cursor-pointer`}
                            onClick={(e) => goToUserProfilePage(e, post.postCreatorHandle)}
                          >
                            {post.postCreatorHandle}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Right side */}
                    <div className={`ml-1 text-medium-gray text-sm hidden sm:block mr-1`}>
                      <Moment format="MM/DD/YYYY">{post.createdAt}</Moment>
                    </div>
                  </div>
                  {/* 2nd Row? */}
                  <hr className={`sixth-border outsetBorder border mx-px my-1 opacity-50`}></hr>
                  <div className={`text-sm mb-1 text-medium-gray flex flex-wrap justify-between overflow-hidden`}>
                    <div className={``}>
                      <i>{post.categoryDisplayName}</i>
                    </div>
                    {post.tags && post.tags.length > 0
                      ?
                      <div className={`flex flex-wrap`}>
                        {post.tags.map((tag, index) => {
                          return (
                            <div key={index} className={`mr-1`}>
                              #{tag}
                            </div>
                          )
                        })}
                      </div>
                      : <></>
                    }
                  </div>
                </div>
              </React.Fragment>
            )
          })
          }
        </>
        :
        <>
          <div className={`text-center`}>
            No matching results for the search term and type. Try another combination.
          </div>
        </>
      }
    </>
  )
}
const SearchResultsForUser = props => {
  const { searchResults, goToUserProfilePage } = props
  return (
    <>
      {/* Should have a container div for the search results */}
      {searchResults && searchResults.length > 0
        ?
        <>
          {searchResults.map((user, index) => {
            return (
              <React.Fragment key={index}>
                {/* Wrapper */}
                <div className={`first-text gradient-background-four shadow-effect-eight
                  rounded-xl word-wrap transition pl-2 pr-1 mb-3 py-1`}
                >
                  <div className={`flex flex-wrap overflow-hidden justify-between text-sm`}>
                    {/* Left Side */}
                    <div className={`flex flex-wrap mr-1`}>
                      {/* Avatar */}
                      <div className={``}>
                        <img width='20' height='20'
                          alt="Avatar"
                          src={user.handCashAvatarUrl}
                          className={`rounded-xl mx-auto mr-1`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          >
                        </img>
                      </div>
                      {/* Handle */}
                      <div className={`mr-1`}>
                        <button className={`hover:text-brandGreen whitespace-nowrap cursor-pointer`}
                          onClick={(e) => goToUserProfilePage(e, user.handCashHandle)}
                        >
                          {user.handCashHandle}
                        </button>
                      </div>
                      <div className={``}>
                        with earnings of <span className={`fifth-text font-bold`}>${parseFloat(user.totalValueReceived.$numberDecimal).toFixed(2)}</span>
                      </div>
                    </div>
                    {/* Right Side */}
                    <div className={`flex flex-wrap items-center`}>
                      <div className={`text-medium-gray text-sm mr-1`}>
                        <span className={`mr-1`}>Joined</span><Moment format="MM/DD/YYYY">{user.createdAt}</Moment>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })
          }
        </>
        :
        <>
          <div className={`text-center`}>
            No matching results for the search term and type. Try another combination.
          </div>
        </>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    searchPageOpacity: state.opacity.searchPageOpacity
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)