import React from 'react'
import parse from 'html-react-parser';
import { Icon } from '@iconify/react';
import bxKey from '@iconify/icons-bx/bx-key';
import starSolid from '@iconify/icons-la/star-solid';

const PaywallComponent = (props) => {
  const {
    postHasPaywall,
    auth,
    postCreatorHandCashId,
    userAlreadyPurchasedPaywall,
    paywallContent,
    paywallCost,
    togglePaymentModal,
    toggleLoginModal,
    userRatedPaywall,
    handleStarChange,
    disableRating,
    ratingAverage,
  } = props;
  return (
    <>
      {postHasPaywall
        ?
        // If post has paywall and user is not logged in
        !auth || !auth.id
          // Need to show paywall stats. And "log in to purchase and view hidden content"
          ?
          <>
            <div className={`-mb-1`}>
              <hr className={`sixth-border outsetBorder border mt-6 mb-3 opacity-50`}></hr>
              <div className={`flex justify-evenly items-center mt-4 pt-2px`}>
                <div className={`fifth-text mx-1`}><Icon icon={bxKey} height={17} width={16} /></div>
                <div className={`text-center items-center`}>
                  <button className={`gradient-background-green text-white
                    border-brand-green text-sm rounded-xl py-px px-5
                    button-ripple border-2 font-header offsetBorder`}
                    onClick={toggleLoginModal}
                  >
                    Login to unlock hidden content for ${paywallCost}
                    {/* {ratingAverage > 0 ? <> - {parseFloat(ratingAverage).toFixed(2)} 
                    <i className={`fa fa-star text-yellow-500`}></i>'s</> : <></>} */}
                  </button>
                </div>
                <div className={`fifth-text mx-1`}><Icon icon={bxKey} height={17} width={16} /></div>
              </div>
            </div>
          </>
          // If post has paywall and user is logged in
          :
          // Now if they're the person who posted it
          auth.id === postCreatorHandCashId
            ?
            <>
              <div className={`gradient-background-four -mx-3 my-6`}>
                <hr className={`sixth-border outsetBorder border mt-3 mx-2 opacity-50`}></hr>
                <div className={`flex justify-evenly items-center`}>
                  <div className={`fifth-text mx-1`}><Icon icon={bxKey} height={17} width={16} /></div>
                  <div className={`text-base logo-style text-brand-green py-3`}>Hidden Content</div>
                  <div className={`fifth-text mx-1`}><Icon icon={bxKey} height={17} width={16} /></div>
                </div>
                <hr className={`sixth-border outsetBorder border mb-3 opacity-50`}></hr>
              </div>
              <div className={`parsed-editor-data`}>
                {parse(paywallContent)}
              </div>
            </>
            // If they're not the postee, but they purchased it
            :
            userAlreadyPurchasedPaywall
              ?
              <>
                <div className={`gradient-background-ten -mx-3`}>
                  <div className={`flex justify-evenly items-center my-6`}>
                    <div className={`fifth-text mx-1`}><Icon icon={bxKey} height={17} width={16} /></div>
                    <div className={`text-base text-brand-green flex items-center py-3 logo-style`}>Unlocked Content
                      {ratingAverage > 0 ? 
                      <> - {parseFloat(ratingAverage).toFixed(2)}
                      <div className={`text-yellow-500 ml-2px mr-px mb-px`}><Icon icon={starSolid} height={13} width={13} /></div>
                        's
                        </> 
                        : <></>
                      }
                    </div>
                    <div className={`fifth-text mx-1`}><Icon icon={bxKey} height={17} width={16} /></div>
                  </div>
                </div>
                <div className={`mb-3 parsed-editor-data`}>
                  {parse(paywallContent)}
                </div>
                {/* If the user has not rated the paywall yet */}
                {userRatedPaywall === false
                  ?
                  <>
                    <div className={``}>
                      <hr className={`sixth-border outsetBorder border mt-3 opacity-50`}></hr>
                      <div className={`text-center items-center text-sm`}>
                        <div className={`mt-1 pt-3`}><span className={`border-b border-gray-500 text-base`}>Rate Unlocked Content</span></div>
                        <div className={`py-6 rating -mb-1 ml-3`}>
                          <input aria-label="Rate content 5 stars" type='radio' onChange={handleStarChange} name='star' id='star1' value={5} /><label htmlFor='star1'></label>
                          <input aria-label="Rate content 4 stars" type='radio' onChange={handleStarChange} name='star' id='star2' value={4} /><label htmlFor='star2'></label>
                          <input aria-label="Rate content 3 stars" type='radio' onChange={handleStarChange} name='star' id='star3' value={3} /><label htmlFor='star3'></label>
                          <input aria-label="Rate content 2 stars" type='radio' onChange={handleStarChange} name='star' id='star4' value={2} /><label htmlFor='star4'></label>
                          <input aria-label="Rate content 1 star" type='radio' onChange={handleStarChange} name='star' id='star5' value={1} /><label htmlFor='star5'></label>
                        </div>
                        <div className={``}>
                          <button className={`px-2 mt-1 bg-transparent rounded-xl border-2 text-sm text-white gradient-background-green border-brand-green`}
                            onClick={e => togglePaymentModal(e, 'RATE_PAYWALL')}
                            disabled={disableRating ? true : false}
                          >
                            Submit Rating
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                  :<></>
                }
              </>
              // If they are logged in and have not purchased it yet
              :
              <>
                <div className={`-mb-3 font-header text-sm`}>
                  <hr className={`sixth-border outsetBorder border mt-6 mb-3 opacity-50`}></hr>
                  <div className={`flex justify-evenly items-center`}>
                    <div className={`fifth-text`}><Icon icon={bxKey} height={17} width={16} /></div>
                    <div className={`block`}>
                      <button className={`gradient-background-green text-white border-brand-green rounded-xl py-px px-5 
                      button-ripple border-2 offsetBorder`}
                        onClick={(e) => togglePaymentModal(e, 'PAYWALL_PURCHASE')}
                      >
                        Unlock hidden content for ${paywallCost}
                      </button>
                      {/* {ratingAverage > 0 ? <div className={`mt-1 flex justify-center items-center`}>{parseFloat(ratingAverage).toFixed(2)} <i className={`fa fa-star mx-1 mb-2px text-yellow-500`}></i> rating</div> : <></>} */}
                    </div>
                    <div className={`fifth-text mx-1`}><Icon icon={bxKey} height={17} width={16} /></div>
                  </div>
                </div>
              </>
        // If there is no paywall.
        : <></>
      }
    </>
  )
}

export default PaywallComponent
