import React, { Component } from 'react'
import Moment from 'react-moment';
import parse from 'html-react-parser';
// Icons
import { Icon } from '@iconify/react';
import bitcoinsvIcon from '@iconify-icons/simple-icons/bitcoinsv';
import createOutline from '@iconify-icons/ion/create-outline';
import trashIcon from '@iconify-icons/ion/trash';
import thumbsUp from '@iconify/icons-fa-solid/thumbs-up';
import commentsIcon from '@iconify/icons-fa-regular/comments';
import eyeSolid from '@iconify/icons-clarity/eye-solid';
import bxKey from '@iconify/icons-bx/bx-key';
import shareSquare from '@iconify/icons-fa-solid/share-square';
import starSolid from '@iconify/icons-la/star-solid';
// ProfileIcons
import twitchIcon from '@iconify/icons-la/twitch';
import githubIcon from '@iconify/icons-la/github';
import twitterIcon from '@iconify/icons-la/twitter';
import instagramIcon from '@iconify/icons-la/instagram';
import mediumIcon from '@iconify/icons-la/medium';
import redditIcon from '@iconify/icons-la/reddit';
import linkedinIcon from '@iconify/icons-la/linkedin';
import bxlYoutube from '@iconify/icons-bx/bxl-youtube';
import bxlTiktok from '@iconify/icons-bx/bxl-tiktok';
import tumblrIcon from '@iconify/icons-la/tumblr';
// Components
import SingleCommentInProfilePage from './SingleCommentInProfilePage'
import SkeletonLoaderForPosts from '../../components/Widgets/SkeletonLoaderForPosts';
import SkeletonLoaderTwoLines from '../../components/Widgets/SkeletonLoaderTwoLines';
import OverlayLoadingIcon from '../../components/Widgets/OverlayLoadingIcon'
import ProfileInputModal from '../../components/Modals/ProfileInputModal';

class DataListConductor extends Component {
  // Rendering Switch Statement
  render() {
    switch (this.props.listDataType) {
      case 'GET_PROFILE':
        return <GetProfile {...this.props} />;
      case 'GET_STATS':
        return <GetStats {...this.props} />;
      case 'GET_FOLLOWING':
        return <GetFollowing {...this.props} />;
      case 'GET_FOLLOWERS':
        return <GetFollowers {...this.props} />;
      case 'GET_TIPS':
        return <GetTips {...this.props} />;
      case 'GET_PAYWALLS':
        return <GetPaywalls {...this.props} />;
      case 'GET_THUMBS':
        return <GetThumbs {...this.props} />;
      case 'GET_SHARES':
        return <GetShares {...this.props} />;
      case 'GET_VOTES':
        return <GetVotes {...this.props} />;
      case 'GET_POSTS':
        return <GetPostsConductor {...this.props} />;
      case 'GET_COMMENTS':
        return <GetComments {...this.props} />;
      case 'GET_DRAFTS':
        return <GetDrafts {...this.props} />;
      case 'GET_STAMPSHOTS':
        return <GetStampshots {...this.props} />;
      default:
        return <GetProfile {...this.props} />;
    }
  }
};

class ProfileListItem extends Component {
  render() {
    const {
      goToUserProfilePage,
      goToPost,
      avatarURL,
      itemValue,
      handle,
      postSlug,
      postTitle,
      paywallRating,
      createdAtDate,
      blockchainPaymentData,
      tipMessage,
      pollOptionSelected,
      isTipType // true/false
    } = this.props;
    return (
      <React.Fragment key={this.props.index}>
        {/* Wrapper */}
        <div className={`px-2 mb-1 text-sm w-full rounded-xl first-text gradient-background-four shadow-effect-eight`}>
          {/* Top row */}
          <div className={`flex justify-between w-full py-1`}>
            {/* Left Corner */}
            <div className={`flex items-center rounded whitespace-nowrap overflow-hidden`}>
              {/* Item Value */}
              <div className={`${!isTipType ? `hidden` : `block`} sm:block text-brand-green font-bold mr-1 min-w-10`}>
                ${parseFloat(itemValue).toFixed(2)}
              </div>
              {/* Avatar */}
              <img width='26' height='26'
                alt="Avatar"
                src={avatarURL}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/images/round-handcash-logo.png'
                }}
                className={`rounded-xl mr-1`}>
              </img>
              {/* Handle */}
              <div className={`-mb-px`}>
                <button
                  onClick={(e) => goToUserProfilePage(e, handle)}
                  className={`fifth-hover logo-style-three hover:underline text-underline-under mr-1`}
                >
                  {handle}
                </button>
              </div>
              {paywallRating && paywallRating > 0 && paywallRating < 6
                ?
                <>
                  <span className={`text-medium-gray`}> - </span>
                  <div className={`mx-1 flex items-center`}>{parseFloat(paywallRating)}
                    <div className={`text-yellow-500 mx-px`}><Icon icon={starSolid} height={13} width={13} /></div>
                  </div>
                </>
                : <></>
              }
              {/* If there is a pollOption */}
              {pollOptionSelected
                ?
                <div className={``}>
                  voted: <span className={`logo-style mr-1`}>{pollOptionSelected}</span>
                </div>
                : <></>
              }
              <div className={`hidden sm:block`}>
                {!postSlug || !postTitle
                  ? <></>
                  : <span className={`text-medium-gray`}>-</span>
                }
              </div>
              {/* On small screens this is hidden */}
              <div className={`hidden sm:flex`}>
                {/* Post Link */}
                {!postSlug || !postTitle
                  ? <></>
                  :
                  <>
                    <div className={`mx-1`}>
                      <button onClick={(e) => goToPost(e, postSlug)}
                        className={`fifth-hover text-medium-gray text-left hover:underline text-underline-under`}>
                        {postTitle}
                      </button>
                    </div>
                  </>
                }
              </div>
            </div>
            {/* Right Corner */}
            <div className={`items-center inline-flex ml-1`}>
              <i className='sm:hidden text-xs text-medium-gray align-middle inline-block mx-1'><Moment format="MM/DD/YYYY">{createdAtDate}</Moment></i>
              {/* For tips, bottom line is blank for tipMessages, hence this conditional rendering */}
              {isTipType
                ?
                <>
                  {!blockchainPaymentData
                    ? <i className='sm:hidden text-xs text-medium-gray align-middle inline-block'><Moment format="MM/DD/YYYY">{createdAtDate}</Moment></i>
                    :
                    <>
                      <a className='sm:hidden hover:text-brandGreen text-xs sm:text-base ml-px'
                        href={`https://whatsonchain.com/tx/${blockchainPaymentData.transactionId}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                      >
                        <Icon icon={bitcoinsvIcon} height={18} width={18} />
                      </a>
                    </>
                  }
                </>
                : <></>
              }
              {/* Only for larger screens */}
              <div className={`hidden sm:flex`}>
                {/* Date */}
                <i className='text-medium-gray text-xs mx-1 align-middle inline-block'><Moment format="MM/DD/YYYY">{createdAtDate}</Moment></i>
                {/* Blockchain Payment */}
                {!blockchainPaymentData
                  ? <></>
                  :
                  <>
                    <a className='hover:text-brandGreen text-xs sm:text-base ml-px'
                      href={`https://whatsonchain.com/tx/${blockchainPaymentData.transactionId}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                    >
                      <Icon icon={bitcoinsvIcon} height={18} width={18} />
                    </a>
                  </>
                }
              </div>
            </div>
          </div>
          {/* Divider */}
          <div className={`${isTipType && !tipMessage ? `hidden` : ``}`}>
            <hr className={`sixth-border sm:hidden outsetBorder border mb-1 opacity-50`}></hr>
            {!tipMessage
              ? <></>
              :
              <>
                <hr className={`sixth-border hidden sm:block outsetBorder border mb-1 opacity-50`}></hr>
                <div className={`hidden sm:block pb-1 overflow-hidden word-wrap`}>
                  {tipMessage}
                </div>
              </>
            }
            {/* Bottom Row */}
            <div className={`${!isTipType ? `flex justify-between` : ``} sm:hidden w-full pb-1`}>
              {/* Bottom Left */}
              <div className={`items-center mr-1 overflow-hidden word-wrap`}>
                <span className={`pr-1`}>
                  {/* Post Link */}
                  {!postSlug || !postTitle
                    ? <></>
                    :
                    <>
                      <button onClick={(e) => goToPost(e, postSlug)}
                        className={`fifth-hover text-medium-gray text-left hover:underline text-underline-under`}>
                        {postTitle}
                      </button>
                    </>
                  }
                  {!tipMessage
                    ? <></>
                    :
                    <>
                      <span className={``}>
                        {tipMessage}
                      </span>
                    </>
                  }
                </span>
              </div>
              {/* Bottom right */}
              <div className={`${!isTipType ? `` : `hidden`} text-xs inline-flex items-center`}>
                {/* Item Value */}
                <span className={`text-brand-green mr-1 font-bold`}>${parseFloat(itemValue).toFixed(2)}</span>
                {/* Blockchain Payment */}
                {!blockchainPaymentData
                  ? <></>
                  :
                  <>
                    <a className=' hover:text-brandGreen text-xs sm:text-base ml-px'
                      href={`https://whatsonchain.com/tx/${blockchainPaymentData.transactionId}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                    >
                      <Icon icon={bitcoinsvIcon} height={18} width={18} />
                    </a>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
class GetProfile extends Component {
  state = {
    pageOverlayActive: false,
    // Quill Profile Inputs
    bitcoinStoryInput: this.props.userData.bitcoinStory || '',
    aboutYouInput: this.props.userData.aboutYou || '',
    funFactsInput: this.props.userData.funFacts || '',
    skillsInput: this.props.userData.skills || '',
    hobbiesInput: this.props.userData.hobbies || '',
    goalsInput: this.props.userData.goals || '',
    purposeInput: this.props.userData.purpose || '',
    achievementsInput: this.props.userData.achievements || '',
    qualificationsInput: this.props.userData.qualifications || '',
    satoshiInput: this.props.userData.satoshi || '',
    codingInput: this.props.userData.coding || '',
    // Single Profile Inputs
    occupationInput: this.props.userData.occupation || '',
    locationInput: this.props.userData.location || '',
    linkedInLinkInput: this.props.userData.linkedInLink || '',
    twitterHandleInput: this.props.userData.twitterHandle || '',
    redditUserNameInput: this.props.userData.redditUserName || '',
    twetchNumberInput: this.props.userData.twetchNumber || '',
    twetchUserNameInput: this.props.userData.twetchUserName || '',
    youTubeChannelInput: this.props.userData.youTubeChannel || '',
    mediumUserNameInput: this.props.userData.mediumUserName || '',
    tikTokUserNameInput: this.props.userData.tikTokUserName || '',
    instagramUserNameInput: this.props.userData.instagramUserName || '',
    twitchUserNameInput: this.props.userData.twitchUserName || '',
    githubUserNameInput: this.props.userData.githubUserName || '',
    tumblrUserNameInput: this.props.userData.tumblrUserName || '',
    // preEditedInputs to compare + prevent unnecessary database updates
    bitcoinStoryInputPreEdited: this.props.userData.bitcoinStory || '',
    aboutYouInputPreEdited: this.props.userData.aboutYou || '',
    funFactsInputPreEdited: this.props.userData.funFacts || '',
    skillsInputPreEdited: this.props.userData.skills || '',
    hobbiesInputPreEdited: this.props.userData.hobbies || '',
    goalsInputPreEdited: this.props.userData.goals || '',
    purposeInputPreEdited: this.props.userData.purpose || '',
    achievementsInputPreEdited: this.props.userData.achievements || '',
    qualificationsInputPreEdited: this.props.userData.qualifications || '',
    satoshiInputPreEdited: this.props.userData.satoshi || '',
    codingInputPreEdited: this.props.userData.coding || '',
    occupationInputPreEdited: this.props.userData.occupation || '',
    locationInputPreEdited: this.props.userData.location || '',
    linkedInLinkInputPreEdited: this.props.userData.linkedInLink || '',
    twitterHandleInputPreEdited: this.props.userData.twitterHandle || '',
    redditUserNameInputPreEdited: this.props.userData.redditUserName || '',
    youTubeChannelInputPreEdited: this.props.userData.youTubeChannel || '',
    twetchNumberInputPreEdited: this.props.userData.twetchNumber || '',
    twetchUserNameInputPreEdited: this.props.userData.twetchUserName || '',
    twitchUserNameInputPreEdited: this.props.userData.twitchUserName || '',
    mediumUserNameInputPreEdited: this.props.userData.mediumUserName || '',
    tikTokUserNameInputPreEdited: this.props.userData.tikTokUserName || '',
    instagramUserNameInputPreEdited: this.props.userData.instagramUserName || '',
    githubUserNameInputPreEdited: this.props.userData.githubUserName || '',
    tumblrUserNameInputPreEdited: this.props.userData.tumblrUserName || '',
    // Editing
    selectedInputType: '',
    showModal: false,
    selectedInputData: '',
    selectedPreEdited: '',
    selectedTitle: '',
    selectedTwetchUserName: '',
    selectedPlaceholder: '',
    selectedSingleLineInput: true,
  }
  // Input arrays to conduct validation checks with
  inputTypesArray = [
    'bitcoinStory', 'aboutYou', 'funFacts', 'skills', 'hobbies', 'goals',
    'coding', 'purpose', 'satoshi', 'achievements', 'qualifications',
    'location', 'occupation', 'twetchNumber', 'youTubeChannel', 'linkedInLink', 'twitterHandle', 'redditUserName',
    'mediumUserName', 'tumblrUserName', 'instagramUserName', 'twitchUserName', 'githubUserName',
    'tikTokUserName',
  ]
  singleInputArray = [
    'occupation', 'location', 'linkedInLink', 'twitterHandle',
    'redditUserName', 'mediumUserName', 'twetchNumber', 'youTubeChannel',
    'tikTokUserName',
    'tumblrUserName', 'instagramUserName', 'twitchUserName', 'githubUserName'
  ]
  editorInputArray = [
    'bitcoinStory', 'aboutYou', 'funFacts', 'skills', 'hobbies', 'goals',
    'coding', 'purpose', 'satoshi', 'achievements', 'qualifications',
  ]
  // Input handlechange updates
  handleChange = (selectedInputData) => this.setState({ selectedInputData: selectedInputData })
  onTwetchUserNameChange = (e) => this.setState({ selectedTwetchUserName: e.target.value })
  onSingleInputChange = (e) => this.setState({ selectedInputData: e.target.value })
  validateInput = (inputType) => {
    let validateInput = {}
    if (inputType === 'linkedInLink') {
      // If it includes the linkedIn URL
      if (this.state.selectedInputData.includes('https://www.linkedin.com/in/')) {
        // If there is something AFTER the linkedIn URL
        if (this.state.selectedInputData.length > 28) {
          validateInput.isValid = true
        }
        // If there is nothing after the linkedIn URL
        else {
          validateInput.isValid = false
          validateInput.errorMessage = 'Add the rest of your URL'
        }
      }
      // If it does not contain the linkedIn link...
      else {
        // If it's blank, it's okay.
        if (this.state.selectedInputData === '') {
          validateInput.isValid = true
        }
        // If it's not blank, it's not okay.
        else {
          validateInput.isValid = false
          validateInput.errorMessage = 'Please format URL properly'
        }
      }
      return validateInput
    }
    if (inputType === 'youTubeChannel') {
      if (this.state.selectedInputData.includes('https://www.youtube.com/c/')) {
        if (this.state.selectedInputData.length > 26) {
          validateInput.isValid = true
        }
        else {
          validateInput.isValid = false
          validateInput.errorMessage = 'Add the rest of your URL'
        }
      }
      else {
        // If it's blank, it's okay.
        if (this.state.selectedInputData === '') {
          validateInput.isValid = true
        }
        // If it's not blank, it's not okay.
        else {
          validateInput.isValid = false
          validateInput.errorMessage = 'Please format URL properly'
        }
      }
      return validateInput
    }
    return
  }
  // Submission of input form
  handleProfileInputSubmit = (e, inputType, inputData, preEditedInputData) => {
    // The test is if I can pass in the state from the button
    if (e) e.preventDefault();
    // If submission doesn't include proper inputType or not logged in. Shouldn't get here. Nefarious use if it does.
    if (!inputType || !this.inputTypesArray.includes(inputType) || !this.props.auth) return
    // Validate linkedIn and YouTube inputs for proper URL schema
    if (inputType === 'linkedInLink' || inputType === 'youTubeChannel') {
      let validateInput = this.validateInput(inputType)
      // isValid === boolean. // errorMessage = string
      if (!validateInput.isValid) {
        return this.props.globals.toastError(this.props.toast, validateInput.errorMessage);
      }
    }
    // If submission hasn't changed from preEditedState, setIsEditing to false with function.
    if (inputData.trim() === preEditedInputData && inputType !== 'twetchNumber') return (
      this.closeModalAndChangeStateBack(),
      this.props.globals.toastSuccess(this.props.toast, 'No action taken, no changes were made.')
    )
    // If inputType is twetchNumber, check both input fields for matches.
    if (inputType === 'twetchNumber' && inputData === preEditedInputData && this.state.twetchUserNameInputPreEdited === this.state.selectedTwetchUserName) {
      return this.closeModalAndChangeStateBack()
    }
    // If it's a non-Quill Editor input, check that length is < 60
    if (this.singleInputArray.includes(inputType)) {
      if (inputType.length > 60) return (
        this.props.globals.toastError(this.props.toast, 'Only 60 characters allowed. Decrease then submit.')
      )
    }
    // If they are somehow not the person in the user profile
    if (this.props.paramHandle !== this.props.auth.handle) return (
      this.props.globals.toastError(this.props.toast, 'Unable to edit profile.')
    )
    this.setState({ pageOverlayActive: true })
    let updateProfileFieldObject = {
      inputType: inputType,
      inputData: inputData.trim(),
      handle: this.props.auth.handle,
    }
    if (inputType === 'twetchNumber') updateProfileFieldObject.twetchUserName = this.state.selectedTwetchUserName.trim()
    // Remove blank spaces if applicable, for editor inputs
    if (this.editorInputArray.includes(inputType)) {
      const checkIfEmptyParagraph = updateProfileFieldObject.inputData.endsWith(`<p><br></p>`)
      if (checkIfEmptyParagraph) {
        do updateProfileFieldObject.inputData = updateProfileFieldObject.inputData.slice(0, -11)
        while (updateProfileFieldObject.inputData.endsWith(`<p><br></p>`))
      }
    }
    // Update profile field
    this.props.API.updateProfileField(updateProfileFieldObject)
      .then(res => {
        // Update PreEditedState & "IsEditing" state based upon inputType
        this.updateInputStateAfterChange(inputType, res.data)
        this.props.globals.toastSuccess(this.props.toast, 'Update Success!');
        this.setState({ pageOverlayActive: false, showModal: false })
      })
      .catch(err => {
        console.error('Error in handleProfileInputSubmit function ', err.response.data.message)
        this.props.globals.toastError(this.props.toast, err.response.data.message);
        this.setState({ pageOverlayActive: false, showModal: false })
      })
  }
  updateInputStateAfterChange = (inputType, inputData) => {
    if (!inputType || !this.inputTypesArray.includes(inputType)) return
    switch (inputType) {
      case 'bitcoinStory':
        return this.setState({ bitcoinStoryInput: inputData.bitcoinStory, bitcoinStoryInputPreEdited: inputData.bitcoinStory })
      case 'aboutYou':
        return this.setState({ aboutYouInput: inputData.aboutYou, aboutYouInputPreEdited: inputData.aboutYou })
      case 'funFacts':
        return this.setState({ funFactsInput: inputData.funFacts, funFactsInputPreEdited: inputData.funFacts })
      case 'occupation':
        return this.setState({ occupationInput: inputData.occupation, occupationInputPreEdited: inputData.occupation })
      case 'location':
        return this.setState({ locationInput: inputData.location, locationInputPreEdited: inputData.location })
      case 'skills':
        return this.setState({ skillsInput: inputData.skills, skillsInputPreEdited: inputData.skills })
      case 'hobbies':
        return this.setState({ hobbiesInput: inputData.hobbies, hobbiesInputPreEdited: inputData.hobbies })
      case 'goals':
        return this.setState({ goalsInput: inputData.goals, goalsInputPreEdited: inputData.goals })
      case 'purpose':
        return this.setState({ purposeInput: inputData.purpose, purposeInputPreEdited: inputData.purpose })
      case 'achievements':
        return this.setState({ achievementsInput: inputData.achievements, achievementsInputPreEdited: inputData.achievements })
      case 'qualifications':
        return this.setState({ qualificationsInput: inputData.qualifications, qualificationsInputPreEdited: inputData.qualifications })
      case 'coding':
        return this.setState({ codingInput: inputData.coding, codingInputPreEdited: inputData.coding })
      case 'satoshi':
        return this.setState({ satoshiInput: inputData.satoshi, satoshiInputPreEdited: inputData.satoshi })
      case 'linkedInLink':
        return this.setState({ linkedInLinkInput: inputData.linkedInLink, linkedInLinkInputPreEdited: inputData.linkedInLink })
      case 'twitterHandle':
        return this.setState({ twitterHandleInput: inputData.twitterHandle, twitterHandleInputPreEdited: inputData.twitterHandle })
      case 'redditUserName':
        return this.setState({ redditUserNameInput: inputData.redditUserName, redditUserNameInputPreEdited: inputData.redditUserName })
      case 'mediumUserName':
        return this.setState({ mediumUserNameInput: inputData.mediumUserName, mediumUserNameInputPreEdited: inputData.mediumUserName })
      case 'twetchNumber':
        return this.setState({
          twetchNumberInput: inputData.twetchNumber, twetchNumberInputPreEdited: inputData.twetchNumber,
          twetchUserNameInputPreEdited: inputData.twetchUserName, twetchUserNameInput: this.state.selectedTwetchUserName
        })
      case 'twitchUserName':
        return this.setState({ twitchUserNameInput: inputData.twitchUserName, twitchUserNameInputPreEdited: inputData.twitchUserName })
      case 'githubUserName':
        return this.setState({ githubUserNameInput: inputData.githubUserName, githubUserNameInputPreEdited: inputData.githubUserName })
      case 'youTubeChannel':
        return this.setState({ youTubeChannelInput: inputData.youTubeChannel, youTubeChannelInputPreEdited: inputData.youTubeChannel })
      case 'tikTokUserName':
        return this.setState({ tikTokUserNameInput: inputData.tikTokUserName, tikTokUserNameInputPreEdited: inputData.tikTokUserName })
      case 'tumblrUserName':
        return this.setState({ tumblrUserNameInput: inputData.tumblrUserName, tumblrUserNameInputPreEdited: inputData.tumblrUserName })
      case 'instagramUserName':
        return this.setState({ instagramUserNameInput: inputData.instagramUserName, instagramUserNameInputPreEdited: inputData.instagramUserName })
      default: return null
    }
  }
  closeModalAndChangeStateBack = () => {
    this.setState({ showModal: false })
    setTimeout(() => {
      this.setState({ selectedInputData: '', selectedPreEdited: '', selectedTitle: '', selectedInputType: '', selectedPlaceholder: '', selectedTwetchUserName: '' })
    }, 300)
  }
  onKeyDown = (e, selectedInputType, selectedInputData, selectedPreEdited) => {
    const { key } = e;
    if (key === 'Enter') {
      e.preventDefault()
      this.handleProfileInputSubmit('', selectedInputType, selectedInputData, selectedPreEdited)
    }
    if (key === ' ') {
      if (selectedInputType === 'location' || selectedInputType === 'occupation') return
      else return e.preventDefault();
    }
    return
  }
  toggleInputModal = (e, inputType) => {
    e.preventDefault()
    // If modal is displayed, we close it and update the selectedInputs back to nothing.
    if (this.state.showModal) return this.closeModalAndChangeStateBack()
    if (!inputType || !this.inputTypesArray.includes(inputType)) return
    this.setState({ selectedInputType: inputType, showModal: true })
    switch (inputType) {
      case 'bitcoinStory':
        this.setState({
          selectedInputData: this.state.bitcoinStoryInput,
          selectedPreEdited: this.state.bitcoinStoryInputPreEdited,
          selectedTitle: 'Bitcoin Story',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'aboutYou':
        this.setState({
          selectedInputData: this.state.aboutYouInput,
          selectedPreEdited: this.state.aboutYouInputPreEdited,
          selectedTitle: 'About You',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'funFacts':
        this.setState({
          selectedInputData: this.state.funFactsInput,
          selectedPreEdited: this.state.funFactsInputPreEdited,
          selectedTitle: 'Fun Fact',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'occupation':
        this.setState({
          selectedInputData: this.state.occupationInput,
          selectedPreEdited: this.state.occupationInputPreEdited,
          selectedTitle: 'Occupation',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Enter your occupation'
        })
        break
      case 'location':
        this.setState({
          selectedInputData: this.state.locationInput,
          selectedPreEdited: this.state.locationInputPreEdited,
          selectedTitle: 'Location',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Enter your location'
        })
        break
      case 'skills':
        this.setState({
          selectedInputData: this.state.skillsInput,
          selectedPreEdited: this.state.skillsInputPreEdited,
          selectedTitle: 'Skills/Knowledge',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'hobbies':
        this.setState({
          selectedInputData: this.state.hobbiesInput,
          selectedPreEdited: this.state.hobbiesInputPreEdited,
          selectedTitle: 'Hobbies',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'goals':
        this.setState({
          selectedInputData: this.state.goalsInput,
          selectedPreEdited: this.state.goalsInputPreEdited,
          selectedTitle: 'Goals',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'purpose':
        this.setState({
          selectedInputData: this.state.purposeInput,
          selectedPreEdited: this.state.purposeInputPreEdited,
          selectedTitle: 'Purpose',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'achievements':
        this.setState({
          selectedInputData: this.state.achievementsInput,
          selectedPreEdited: this.state.achievementsInputPreEdited,
          selectedTitle: 'Achievements',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'qualifications':
        this.setState({
          selectedInputData: this.state.qualificationsInput,
          selectedPreEdited: this.state.qualificationsInputPreEdited,
          selectedTitle: 'Qualifications',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'coding':
        this.setState({
          selectedInputData: this.state.codingInput,
          selectedPreEdited: this.state.codingInputPreEdited,
          selectedTitle: 'Coding',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'satoshi':
        this.setState({
          selectedInputData: this.state.satoshiInput,
          selectedPreEdited: this.state.satoshiInputPreEdited,
          selectedTitle: 'Satoshi',
          selectedSingleLineInput: false,
          selectedPlaceholder: ''
        })
        break
      case 'linkedInLink':
        let linkedInInput = this.state.linkedInLinkInput
        if (linkedInInput.length === 0 || linkedInInput === '') {
          linkedInInput = 'https://www.linkedin.com/in/'
        }
        this.setState({
          selectedInputData: linkedInInput,
          selectedPreEdited: this.state.linkedInLinkInputPreEdited,
          selectedTitle: 'LinkedIn URL',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: https://www.linkedin.com/in/USERNAME'
        })
        break
      case 'twitterHandle':
        this.setState({
          selectedInputData: this.state.twitterHandleInput,
          selectedPreEdited: this.state.twitterHandleInputPreEdited,
          selectedTitle: 'Twitter Handle',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: YourTwitterHandle. i.e., kels0la'
        })
        break
      case 'redditUserName':
        this.setState({
          selectedInputData: this.state.redditUserNameInput,
          selectedPreEdited: this.state.redditUserNameInputPreEdited,
          selectedTitle: 'Reddit Username',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: YourUserName. i.e., kels0la'
        })
        break
      case 'mediumUserName':
        this.setState({
          selectedInputData: this.state.mediumUserNameInput,
          selectedPreEdited: this.state.mediumUserNameInputPreEdited,
          selectedTitle: 'Medium.com Username',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: YourUserName. i.e., kels0la'
        })
        break
      case 'youTubeChannel':
        let youTubeInput = this.state.youTubeChannelInput
        if (youTubeInput.length === 0 || youTubeInput === '') {
          youTubeInput = 'https://www.youtube.com/c/'
        }
        this.setState({
          selectedInputData: youTubeInput,
          selectedPreEdited: this.state.youTubeChannelInputPreEdited,
          selectedTitle: 'YouTube Channel URL',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: https://www.youtube.com/c/YOURCHANNEL'
        })
        break
      case 'twetchNumber':
        this.setState({
          selectedInputData: this.state.twetchNumberInput,
          selectedPreEdited: this.state.twetchNumberInputPreEdited,
          selectedTitle: 'Twetch Number and Username',
          selectedSingleLineInput: true,
          selectedTwetchUserName: this.state.twetchUserNameInput,
          selectedPlaceholder: 'Simply enter your Twetch User Number. Not your handle. i.e., 62926'
        })
        break
      case 'twitchUserName':
        this.setState({
          selectedInputData: this.state.twitchUserNameInput,
          selectedPreEdited: this.state.twitchUserNameInputPreEdited,
          selectedTitle: 'Twitch Username',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: YourUserName. i.e., kels0la'
        })
        break
      case 'instagramUserName':
        this.setState({
          selectedInputData: this.state.instagramUserNameInput,
          selectedPreEdited: this.state.instagramUserNameInputPreEdited,
          selectedTitle: 'Instagram Username',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: YourUserName. i.e., kels0la'
        })
        break
      case 'tumblrUserName':
        this.setState({
          selectedInputData: this.state.tumblrUserNameInput,
          selectedPreEdited: this.state.tumblrUserNameInputPreEdited,
          selectedTitle: 'Tumblr Username',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: YourUserName. i.e., kels0la'
        })
        break
      case 'tikTokUserName':
        this.setState({
          selectedInputData: this.state.tikTokUserNameInput,
          selectedPreEdited: this.state.tikTokUserNameInputPreEdited,
          selectedTitle: 'TikTok Username',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: YourUserName. i.e., kels0la'
        })
        break
      case 'githubUserName':
        this.setState({
          selectedInputData: this.state.githubUserNameInput,
          selectedPreEdited: this.state.githubUserNameInputPreEdited,
          selectedTitle: 'Github Username',
          selectedSingleLineInput: true,
          selectedPlaceholder: 'Format: YourUserName. i.e., kels0la'
        })
        break
      default: return null
    }
  }

  render() {
    const {
      paramHandle,
      auth
    } = this.props
    return (
      <>
        <div className={`my-2 pt-2 flex justify-center text-3xl teko-font logo-style eleventh-text text-center items-center`}>
          <div className='relative'>Profile
            {/* If not logged in or viewing other user's profile & no inputs exist */}
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.occupationInput.length === 0 &&
              this.state.locationInput.length === 0 && this.state.linkedInLinkInput.length === 0 &&
              this.state.twitterHandleInput.length === 0 && this.state.redditUserNameInput.length === 0 &&
              this.state.twetchNumberInput.length === 0 && this.state.twetchUserNameInput.length === 0 &&
              this.state.youTubeChannelInput.length === 0 && this.state.instagramUserNameInput.length === 0 &&
              this.state.mediumUserNameInput.length === 0 && this.state.tumblrUserNameInput.length === 0 &&
              this.state.twitchUserNameInput.length === 0 && this.state.githubUserNameInput.length === 0 &&
              this.state.tikTokUserNameInput.length === 0 &&
              this.state.achievementsInput.length === 0 && this.state.qualificationsInput.length === 0 &&
              this.state.codingInput.length === 0 && this.state.satoshiInput.length === 0 &&
              this.state.aboutYouInput.length === 0 && this.state.bitcoinStoryInput.length === 0 &&
              this.state.funFactsInput.length === 0 && this.state.skillsInput.length === 0 &&
              this.state.hobbiesInput.length === 0 && this.state.goalsInput.length === 0 && this.state.purposeInput.length === 0
              ? <span className={`absolute ml-2 text-sm font-header mt-3 text-medium-gray`}>(<span className={`mx-px`}>empty</span>)</span>
              : <></>
            }
          </div>
        </div>
        {/* Overall wrapper div */}
        <div className={`mx-auto w-full sm:max-w-3xl md:max-w-4xl sm:px-2`}>
          {/* If not logged in or viewing other user's profile & no singleLineInputs exist */}
          {(!auth || (auth && auth.handle !== paramHandle)) && this.state.occupationInput.length === 0 &&
            this.state.locationInput.length === 0 && this.state.linkedInLinkInput.length === 0 &&
            this.state.twitterHandleInput.length === 0 && this.state.redditUserNameInput.length === 0 &&
            this.state.twetchNumberInput.length === 0 && this.state.twetchUserNameInput.length === 0 &&
            this.state.youTubeChannelInput.length === 0 && this.state.mediumUserNameInput.length === 0 &&
            this.state.tikTokUserNameInput.length === 0 &&
            this.state.tumblrUserNameInput.length === 0 &&
            this.state.instagramUserNameInput.length === 0 &&
            this.state.twitchUserNameInput.length === 0 && this.state.githubUserNameInput.length === 0
            ?
            <></>
            /* Wrapper div for single line inputs */
            : <div className={`flex justify-center flex-wrap rounded-xl first-text fifth-background shadow-effect-eight transition px-2 pt-4 mb-3 mx-1`}>
              {/* Occupation/Location Row */}
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.occupationInput.length === 0
                ? <></>
                :
                <div className={`max-w-285px pb-2 px-1`}>
                  {/* Occupation */}
                  <div className={`sm:flex sm:justify-center text-center sm:text-left pt-1 gradient-background-four shadow-effect-eleven rounded-xl mb-1 teko-font logo-style eleventh-text text-xl`}>
                    <div className={`relative`}>
                      Occupation
                      {auth?.handle === paramHandle
                        ?
                        <button className={`absolute ml-1 mt-2px text-medium-gray sixth-hover`}
                          onClick={(e) => this.toggleInputModal(e, 'occupation')}
                        >
                          <Icon icon={createOutline} height={20} width={20} />
                        </button>
                        : <></>
                      }
                    </div>
                  </div>
                  <div className={`sm:flex px-2 sm:justify-center text-center text-left word-wrap overflow-hidden text-base tenth-text`}>
                    {this.state.occupationInput}
                  </div>
                </div>
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.locationInput.length === 0
                ? <></>
                :
                <div className={`max-w-285px pb-2 px-1`}>
                  {/* Location */}
                  <div className={`sm:flex sm:justify-center text-center sm:text-left pt-1 gradient-background-four shadow-effect-eleven rounded-xl mb-1 teko-font logo-style eleventh-text text-xl`}>
                    <div className={`relative`}>
                      Location
                      {auth?.handle === paramHandle
                        ?
                        <button className={`absolute ml-1 mt-2px text-medium-gray sixth-hover`}
                          onClick={(e) => this.toggleInputModal(e, 'location')}
                        >
                          <Icon icon={createOutline} height={20} width={20} />
                        </button>
                        : <></>
                      }
                    </div>
                  </div>
                  <div className={`sm:flex sm:justify-center px-2 text-center text-left word-wrap overflow-hidden text-base tenth-text`}>
                    {this.state.locationInput}
                  </div>
                </div>
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.linkedInLinkInput.length === 0
                ? <></>
                :
                // LinkedIn
                <MediaInput
                  icon={linkedinIcon}
                  title={`LinkedIn`}
                  
                  inputType={'linkedInLink'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View LinkedIn profile for user'}
                  href={`${this.state.linkedInLinkInput}`}
                  inputDisplay={this.state.linkedInLinkInput}
                />
              }
              {/* Handles Row #1 */}
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.githubUserNameInput.length === 0
                ? <></>
                :
                // Github
                <MediaInput
                  icon={githubIcon}
                  title={`Github`}
                  
                  inputType={'githubUserName'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View Github profile for user'}
                  href={`https://github.com/${this.state.githubUserNameInput}`}
                  inputDisplay={this.state.githubUserNameInput}
                // tx={this.props.userData.githubUserName?.tx}
                />
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.twetchNumberInput.length === 0
                ? <></>
                :
                // Twetch
                <MediaInput
                  title={`Twetch`}
                  
                  inputType={'twetchNumber'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View Twetch profile for user'}
                  href={`https://twetch.com/u/${this.state.twetchNumberInput}`}
                  inputDisplay={this.state.twetchUserNameInput}
                />
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.youTubeChannelInput.length === 0
                ? <></>
                :
                // YouTube
                <MediaInput
                  icon={bxlYoutube}
                  title={`YouTube`}
                  
                  inputType={'youTubeChannel'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View YouTube Channel for user'}
                  href={`${this.state.youTubeChannelInput}`}
                  inputDisplay={this.state.youTubeChannelInput}
                />
              }
              {/* Handles Row #2 */}
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.redditUserNameInput.length === 0
                ? <></>
                :
                // Reddit
                <MediaInput
                  icon={redditIcon}
                  title={`Reddit`}
                  
                  inputType={'redditUserName'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View Reddit profile for user'}
                  href={`https://reddit.com/user/${this.state.redditUserNameInput}`}
                  inputDisplay={this.state.redditUserNameInput}
                />
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.mediumUserNameInput.length === 0
                ? <></>
                :
                // Medium
                <MediaInput
                  icon={mediumIcon}
                  title={`Medium`}
                  
                  inputType={'mediumUserName'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View Medium.com profile for user'}
                  href={`https://medium.com/@${this.state.mediumUserNameInput}`}
                  inputDisplay={this.state.mediumUserNameInput}
                />
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.twitchUserNameInput.length === 0
                ? <></>
                :
                // Twitch
                <MediaInput
                  icon={twitchIcon}
                  title={`Twitch`}
                  
                  inputType={'twitchUserName'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View Twitch profile for user'}
                  href={`https://twitch.tv/${this.state.twitchUserNameInput}`}
                  inputDisplay={this.state.twitchUserNameInput}
                />
              }
              {/* Handles Row #3 */}
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.twitterHandleInput.length === 0
                ? <></>
                :
                // Twitter
                <MediaInput
                  icon={twitterIcon}
                  title={`Twitter`}
                  
                  inputType={'twitterHandle'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View Twitter profile for user'}
                  href={`https://twitter.com/${this.state.twitterHandleInput}`}
                  inputDisplay={this.state.twitterHandleInput}
                />
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.tikTokUserNameInput.length === 0
                ? <></>
                :
                //TikTok
                <MediaInput
                  icon={bxlTiktok}
                  title={`TikTok`}
                  
                  inputType={'tikTokUserName'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View TikTok profile for user'}
                  href={`https://www.tiktok.com/@${this.state.tikTokUserNameInput}`}
                  inputDisplay={this.state.tikTokUserNameInput}
                />
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.tumblrUserNameInput.length === 0
                ? <></>
                :
                // Tumblr
                <MediaInput
                  icon={tumblrIcon}
                  title={`Tumblr`}
                  
                  inputType={'tumblrUserName'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View Tumblr profile for user'}
                  href={`https://www.tumblr.com/blog/view/${this.state.tumblrUserNameInput}`}
                  inputDisplay={this.state.tumblrUserNameInput}
                />
              }
              {(!auth || (auth && auth.handle !== paramHandle)) && this.state.instagramUserNameInput.length === 0
                ? <></>
                :
                // Instagram
                <MediaInput
                  icon={instagramIcon}
                  title={`Instagram`}
                  
                  inputType={'instagramUserName'}
                  paramHandle={paramHandle}
                  toggleInputModal={this.toggleInputModal}
                  auth={auth}
                  ariaLabel={'View Instagram profile for user'}
                  href={`https://www.instagram.com/${this.state.instagramUserNameInput}`}
                  inputDisplay={this.state.instagramUserNameInput}
                />
              }
            </div>
          }
          {/* HTML long-form inputs */}
          <div className={`${this.state.occupationInput.length === 0 &&
            this.state.locationInput.length === 0 && this.state.linkedInLinkInput.length === 0 &&
            this.state.twitterHandleInput.length === 0 && this.state.redditUserNameInput.length === 0 &&
            this.state.twetchNumberInput.length === 0 && this.state.twetchUserNameInput.length === 0 &&
            this.state.youTubeChannelInput.length === 0 && this.state.instagramUserNameInput.length === 0 &&
            this.state.mediumUserNameInput.length === 0 && this.state.tumblrUserNameInput.length === 0 &&
            this.state.twitchUserNameInput.length === 0 && this.state.githubUserNameInput.length === 0 &&
            this.state.tikTokUserNameInput.length === 0 &&
            this.state.achievementsInput.length === 0 && this.state.qualificationsInput.length === 0 &&
            this.state.codingInput.length === 0 && this.state.satoshiInput.length === 0 &&
            this.state.aboutYouInput.length === 0 && this.state.bitcoinStoryInput.length === 0 &&
            this.state.funFactsInput.length === 0 && this.state.skillsInput.length === 0 &&
            this.state.hobbiesInput.length === 0 && this.state.goalsInput.length === 0 && this.state.purposeInput.length === 0
            ? ``
            : `mx-1 fifth-background shadow-effect-eight transition px-2 py-2 mb-1 rounded-xl mt-6`}`}
          >
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.purposeInput.length === 0
              ? <></>
              : <LongFormInput inputType={'purpose'} title={'Purpose'} input={this.state.purposeInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.aboutYouInput.length === 0
              ? <></>
              : <LongFormInput inputType={'aboutYou'} title={'About You'} input={this.state.aboutYouInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.funFactsInput.length === 0
              ? <></>
              : <LongFormInput inputType={'funFacts'} title={'Fun Fact'} input={this.state.funFactsInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.goalsInput.length === 0
              ? <></>
              : <LongFormInput inputType={'goals'} title={'Goals'} input={this.state.goalsInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.skillsInput.length === 0
              ? <></>
              : <LongFormInput inputType={'skills'} title={'Skills/Knowledge'} input={this.state.skillsInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.achievementsInput.length === 0
              ? <></>
              : <LongFormInput inputType={'achievements'} title={'Achievements'} input={this.state.achievementsInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.qualificationsInput.length === 0
              ? <></>
              : <LongFormInput inputType={'qualifications'} title={'Qualifications'} input={this.state.qualificationsInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.codingInput.length === 0
              ? <></>
              : <LongFormInput inputType={'coding'} title={'Coding'} input={this.state.codingInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.hobbiesInput.length === 0
              ? <></>
              : <LongFormInput inputType={'hobbies'} title={'Hobbies'} input={this.state.hobbiesInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.bitcoinStoryInput.length === 0
              ? <></>
              : <LongFormInput inputType={'bitcoinStory'} title={'Bitcoin Story'} input={this.state.bitcoinStoryInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
            {(!auth || (auth && auth.handle !== paramHandle)) && this.state.satoshiInput.length === 0
              ? <></>
              : <LongFormInput inputType={'satoshi'} title={'Satoshi'} input={this.state.satoshiInput}
                 toggleInputModal={this.toggleInputModal} auth={auth} paramHandle={paramHandle} />
            }
          </div>
          {/* Ending Wrapper Div */}
        </div>
        {/* Overlay */}
        {this.state.pageOverlayActive
          ? <OverlayLoadingIcon  nonPaymentOverlay={this.props.userDatabaseData?.membership ? true : false} />
          : <></>
        }
        {/* If they're not logged in, and handle doesn't equal paramHandle then modal can't be viewed */}
        {auth?.id && auth.handle === paramHandle
          ?
          <ProfileInputModal
            
            handleChange={this.handleChange}
            toggleInputModal={this.toggleInputModal}
            handleProfileInputSubmit={this.handleProfileInputSubmit}
            selectedInputType={this.state.selectedInputType}
            selectedInputData={this.state.selectedInputData}
            selectedTitle={this.state.selectedTitle}
            selectedPreEdited={this.state.selectedPreEdited}
            selectedSingleLineInput={this.state.selectedSingleLineInput}
            selectedPlaceholder={this.state.selectedPlaceholder}
            showModal={this.state.showModal}
            onSingleInputChange={this.onSingleInputChange}
            onTwetchUserNameChange={this.onTwetchUserNameChange}
            twetchUserNameInputData={this.state.twetchUserNameInputData}
            selectedTwetchUserName={this.state.selectedTwetchUserName}
            onKeyDown={this.onKeyDown}
          />
          : <></>
        }

      </>
    )
  }
}

/* <div className={`sm:flex`}>
<ShortFormInput title={'LinkedIn URL'} input={this.state.linkedInLinkInput} 
  href={`${this.state.linkedInLinkInput}`}  inputType={`linkedInLink`}
  label={`View LinkedIn profile for user`} toggleInputModal={this.toggleInputModal}
/>
<ShortFormInput title={'Medium.com'} input={this.state.mediumUserNameInput} inputType={`mediumUserName`}
  href={`https://medium.com/@${this.state.mediumUserNameInput}`}  
  label={`View Medium.com profile for user`} toggleInputModal={this.toggleInputModal}
/>
</div>
<div className={`sm:flex`}>
<ShortFormInput title={'Reddit'} input={this.state.redditUserNameInput} inputType={`linkedInLink`}
  href={`https://reddit.com/user/${this.state.redditUserNameInput}`}  
  label={`View Reddit profile for user`} toggleInputModal={this.toggleInputModal}
/>
<ShortFormInput title={'Twitter'} input={this.state.redditUserNameInput} 
  href={`https://twitter.com/${this.state.twitterHandleInput}`}  
  label={`View Twitter profile for user`} toggleInputModal={this.toggleInputModal}
/>
</div> */

const MediaInput = (props) => {
  const { icon, href, inputDisplay, title, auth, paramHandle, inputType, toggleInputModal, ariaLabel } = props
  return (
    <div className={`pb-2 max-w-285px justify-center px-1`}>
      <div className={`sm:flex sm:justify-center text-center sm:text-left mb-1 pt-1 gradient-background-four shadow-effect-eleven rounded-xl`}>
        <div className={`relative`}>
          <span className={`absolute -ml-5 mt-1`}>{icon ? <Icon icon={icon} height={18} width={18} /> : <></>}</span>
          <span className={`teko-font logo-style eleventh-text text-xl`}>
            {title}
          </span>
          {auth?.handle === paramHandle
            ? <button className={`absolute ml-1 mt-2px text-medium-gray sixth-hover`}
              onClick={(e) => toggleInputModal(e, inputType)}
            >
              <Icon icon={createOutline} height={20} width={20} />
            </button>
            : <></>
          }
        </div>
        {/* <div className={`float-right`}>
          {!tx
            ? <></>
            :
            <>
              <a className=' hover:text-brandGreen text-xs sm:text-base ml-px'
                href={`https://whatsonchain.com/tx/${tx.blockchainPaymentData.transactionId}`}
                rel="noopener noreferrer"
                target="_blank"
                aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
              >
                <Icon icon={bitcoinsvIcon} height={18} width={18} />
              </a>
            </>
          }
        </div> */}
      </div>
      <div className={`sm:flex sm:justify-center px-2 text-center sm:text-left text-base tenth-text`}>
        <a className={`fifth-hover text-left hover:underline word-wrap overflow-hidden`}
          href={href}
          rel="noopener noreferrer"
          target="_blank"
          aria-label={ariaLabel}
        >
          {!['linkedInLink', 'youTubeChannel'].includes(inputType)
            ? <>{inputDisplay}</>
            : inputType === 'youTubeChannel'
              ? inputDisplay !== ''
                ? <>View Channel</>
                : <></>
              : inputType === 'linkedInLink'
                ? inputDisplay !== ''
                  ? <>View Profile</>
                  : <></>
                : <></>
          }
        </a>
      </div>
    </div>
  )
}
class LongFormInput extends Component {
  state = {
    viewMore: false
  }
  componentDidMount() {
    this.checkForOverflow()
  }
  componentDidUpdate() {
    this.checkForOverflow()
  }
  toggleViewMoreOrLess = (e) => {
    const expandableElement = e.target.closest('[data-expandable]')
    if (!this.state.viewMore) {
      expandableElement.classList.toggle('expanded')
      this.setState({ viewMore: true })
    }
    else {
      expandableElement.classList.remove('expanded')
      this.setState({ viewMore: false })
    }
  }
  setExpandButtonText(button) {
    const expandableElement = button.closest('[data-expandable]')
    const expanded = expandableElement.classList.contains('expanded')
    if (expanded) this.setState({ viewMore: false })
    else this.setState({ viewMore: false })
  }
  checkForOverflow() {
    const expandableElements = document.querySelectorAll('[data-expandable]')
    expandableElements.forEach(expandableElement => {
      if (expandableElement.classList.contains('expanded')) return
      const expandableText = expandableElement.querySelector('[data-expand-profiletext]')
      const overflowing = expandableText.scrollHeight > expandableText.clientHeight
      expandableElement.dataset.overflow = overflowing
    })
  }
  render() {
    const { title, input, inputType, toggleInputModal, auth, paramHandle } = this.props
    return (
      <>
        <div className={`mb-3 first-text transition`}>
          <div className={`pb-3`}>
            <div className={`flex teko-font logo-style text-2xl eleventh-text justify-center text-center`}>
              <div className={`relative`}>
                {title}
                {auth && auth.handle === paramHandle
                  ?
                  <button className={`absolute text-sm text-medium-gray font-header ml-2 pt-1 mt-px  sixth-hover`}
                    onClick={(e) => toggleInputModal(e, inputType)}
                  >
                    <Icon icon={createOutline} height={22} width={22} />
                  </button>
                  : <></>
                }
              </div>

            </div>
          </div>
          <div data-expandable className={`w-full p-2 sm:p-3 gradient-background-four shadow-effect-seven rounded-xl`}>
            <div data-expand-profiletext className={`w-full parsed-editor-data`}>
              {parse(input)}
            </div>
            <button onClick={this.toggleViewMoreOrLess} data-expand-button
              className={`flex justify-center mx-auto text-medium-gray mt-2 mb-1 fifth-hover`}>
              {this.state.viewMore ? `[ View Less ]` : `[ View More ]`}
            </button>
          </div>
        </div>
      </>
    )
  }
}
class GetDrafts extends Component {
  render() {
    const {
      drafts,
      goToDraftEditingPage,
      toggleDeleteDraftModal,
      draftsOpacity,
      draftsAreLoaded
    } = this.props;
    return (
      <>
        {!draftsAreLoaded
          ? <div className='pt-6 mx-2'>
            <SkeletonLoaderTwoLines  />
            <SkeletonLoaderTwoLines  />
            <SkeletonLoaderTwoLines  />
            <SkeletonLoaderTwoLines  />
            <SkeletonLoaderTwoLines  />
          </div>
          : drafts && drafts.length < 1
            ?
            <div className={`${draftsOpacity} transition-to-opacity w-full`}>
              <div className={`my-2 pt-2 flex justify-center`}>No Drafts yet</div>
            </div>
            :
            <div className={`${draftsOpacity} transition-to-opacity w-full`}>
              <div className={`my-2 pt-2 flex justify-center text-3xl teko-font logo-style eleventh-text`}>Drafts ({drafts.length})</div>
              <div className={`px-2 mb-3`}>
                {drafts && drafts.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {/* Container div, with title , body, createdAt */}
                      <div className={`w-full rounded-xl first-text gradient-background-four shadow-effect-five word-wrap transition px-2 mb-2 py-1`}>
                        {/* Top row */}
                        <div className={`flex justify-between w-full py-1`}>
                          {/* Left */}
                          <div className={`items-center overflow-hidden rounded w-full`}>
                            <div className={`mb-1 word-wrap`}>
                              <span className={`second-text text-base`}>Title: </span>{item.title}
                            </div>
                          </div>
                          {/* Right */}
                          <div className={`text-xs text-medium-gray`}>
                            <div className={`inline-flex`}>
                              <i className='mr-1 align-middle inline-block'>
                                <Moment format="MM/DD/YYYY">{item.createdAt}</Moment>
                              </i>
                            </div>
                          </div>
                        </div>
                        {/* Bottom */}
                        <div className={`flex`}>
                          <button className={`flex py-px px-2 gradient-background-green text-sm rounded-xl border-2 font-header category-hover
                          text-white border-brand-green`}
                            onClick={(e) => goToDraftEditingPage(e, item)}
                          >
                            Edit
                            <div className={`ml-1`}>
                              <Icon icon={createOutline} height={18} width={18} />
                            </div>
                          </button>
                          <button className={`flex py-px px-2 gradient-background-red text-sm rounded-xl border-2 font-header ml-1 category-hover
                          text-white border-red-700 `}
                            onClick={(e) => toggleDeleteDraftModal(e, item._id, index)}
                          >
                            Delete
                            <div className={`ml-px mt-2px`}>
                              <Icon icon={trashIcon} height={16} width={16} />
                            </div>
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
        }
      </>
    )
  }
}
class GetPostsConductor extends Component {

  render() {
    switch (this.props.newestViewForPosts) {
      case true:
        // If user is logged-in and viewing their own account
        if (this.props.auth && this.props.paramHandle === this.props.auth.handle) {
          return <GetPosts {...this.props} posts={this.props.newestPosts} />
        }
        // If user is not viewing their account
        return <GetPosts {...this.props} posts={this.props.publicUserNewestPosts} />
      case false:
        // If user is logged-in and viewing their own account
        if (this.props.auth && this.props.paramHandle === this.props.auth.handle) {
          return <GetPosts {...this.props} posts={this.props.mostEarnedPosts} />
        }
        // If user is not viewing their account
        return <GetPosts {...this.props} posts={this.props.publicUserMostEarnedPosts} />
      default:
        return <GetPosts {...this.props} posts={this.props.publicUserNewestPosts} />
    }
  }
}

class GetPosts extends Component {

  render() {
    const {
      goToPost,
      posts,
      clickToEditPost,
      postsSelection,
      postsOpacity,
      togglePostType,
      postsAreLoaded,
      userData,
      auth,
      refCallbackForArray,
      noMorePostResults,
      paginatedPostsAreLoaded
    } = this.props;
    return (
      <>
        <div className={`my-2 pt-2 pb-2px flex items-center font-header`}>
          {/* Left of center */}
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-right
              ${postsSelection.newest} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => togglePostType(e, "NEWEST_POSTS")}
            >
              Newest Posts
            </button>
          </div>
          {/* Center */}
          <div className={`inline-block`}>
            <div className={`text-3xl teko-font logo-style eleventh-text`}>Posts ({userData && userData.totalPostsCreated ? `${userData.totalPostsCreated}` : '0'})</div>
          </div>
          {/* Right of center */}
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-left
              ${postsSelection.mostEarned} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => togglePostType(e, "MOST_EARNED")}
            >
              Most Earned
            </button>
          </div>
        </div>
        <div className={`flex md:inline-flex mx-auto w-full sm:max-w-3xl md:max-w-4xl px-3`}>
          {!postsAreLoaded
            ? <div className={`block w-full`}>
              <SkeletonLoaderTwoLines removeAvatar={true}  />
              <SkeletonLoaderTwoLines removeAvatar={true}  />
              <SkeletonLoaderTwoLines removeAvatar={true}  />
              <SkeletonLoaderTwoLines removeAvatar={true}  />
              <SkeletonLoaderTwoLines removeAvatar={true}  />
              <SkeletonLoaderTwoLines removeAvatar={true}  />
              <SkeletonLoaderTwoLines removeAvatar={true}  />
              <SkeletonLoaderTwoLines removeAvatar={true}  />
            </div>
            :
            <div className={`${postsOpacity} transition-to-opacity w-full`}>
              {posts && posts.length < 1
                ?
                <>
                  <div className={`flex justify-center`}>No posts thus far!</div>
                </>
                :
                <>
                  {posts && posts.map((post, index) => {
                    return (
                      <React.Fragment key={index}>
                        {/* Wrapper div */}
                        <div key={index} className={`w-full rounded-xl first-text gradient-background-four shadow-effect-eight word-wrap transition px-2 mb-2 py-1`}>
                          {/* Top row */}
                          <div className={`flex justify-between flex-wrap items-center w-full`}>
                            {/* Left Corner */}
                            <div className={`flex items-center overflow-hidden flex-wrap rounded mr-1`}>
                              <button onClick={(e) => goToPost(e, post.slug)}
                                className={`text-base text-left hover:underline text-underline-under word-wrap ${post.categoryDisplayName ? `mr-1` : ''} fifth-hover`}>
                                {post.title}
                              </button>
                            </div>
                            {/* Right Corner */}
                            <div className={`flex flex-wrap text-xs text-medium-gray`}>
                              <i><Moment format="MM/DD/YYYY">{post.createdAt}</Moment></i>
                              {auth && post.postCreatorHandCashId === auth.id
                                ?
                                <>
                                  <button className={`sixth-hover ml-1 -mt-1`}
                                    onClick={(e) => clickToEditPost(e, post.slug, post)}>
                                    <Icon icon={createOutline} height={18} width={18} />
                                  </button>
                                </>
                                : <></>
                              }
                            </div>
                          </div>
                          <hr className={`sixth-border outsetBorder border mt-1 opacity-50`}></hr>
                          {/* Bottom Wrapper */}
                          <div className={`flex flex-wrap justify-between w-full text-xs text-medium-gray pt-1`}>
                            {/* Bottom left */}
                            <div className={`flex flex-wrap`}>
                              {/* Amount earned */}
                              <div className={`flex`}>
                                <div className={`sm:hidden fifth-text font-bold pointer-events-none mr-2`}>
                                  ${post.totalValueReceived}
                                </div>
                                <div className={`hidden sm:inline-block tooltip mr-2`} data-tooltip="Earned">
                                  <span className={`fifth-text font-bold pointer-events-none`}>
                                    ${post.totalValueReceived}
                                  </span>
                                </div>
                              </div>
                              {/* Views */}
                              <div className={`flex`}>
                                <div className={`sm:hidden inline-block mr-px`}>
                                  <Icon icon={eyeSolid} height={17} width={16} />
                                </div>
                                <div className={`hidden sm:inline-block tooltip mr-px`} data-tooltip="Views">
                                  <Icon icon={eyeSolid} height={17} width={16} />
                                </div>
                                <div className={`mr-2 pointer-events-none`}>{post.numViews}</div>
                              </div>
                              {/* Comments */}
                              {/* Do not change. It is centered correctly right now */}
                              <div className={`flex`}>
                                <span className={`sm:hidden inline-block mr-1 mt-2px`}>
                                  <Icon icon={commentsIcon} height={13} />
                                </span>
                                <span className={`tooltip hidden sm:inline-block mr-1 mt-2px`} data-tooltip="Comments">
                                  <Icon icon={commentsIcon} height={13} />
                                </span>
                                <div className={`mr-2 pointer-events-none`}>{post.numComments}</div>
                              </div>
                              {/* Thumbs */}
                              <div className={`flex`} >
                                <div className={`sm:hidden inline-block mr-1 mt-3px`}>
                                  <Icon icon={thumbsUp} height={11} />
                                </div>
                                <div className={`tooltip hidden sm:inline-block mr-1 mt-3px`} data-tooltip="Thumbs">
                                  <Icon icon={thumbsUp} height={11} />
                                </div>
                                <div className={`mr-2 pointer-events-none`}>{post.numThumbs}</div>
                              </div>
                              {/* If the post has a paywall */}
                              {post.postHasPaywall
                                ?
                                <>
                                  <div className={`flex`} >
                                    <div className={`sm:hidden inline-block`}>
                                      <Icon icon={bxKey} height={17} width={16} />
                                    </div>
                                    <div className={`tooltip hidden sm:inline-block`} data-tooltip="Unlocks">
                                      <Icon icon={bxKey} height={17} width={16} />
                                    </div>
                                    <div className={`mr-6px pointer-events-none`}>{post.numPaywallPurchases}</div>
                                  </div>
                                  {post.numberOfPaywallRatings > 0
                                    ?
                                    <>
                                      {/* Large Screen */}
                                      <div className='flex'>
                                        <div className={`mr-6px hidden sm:flex items-center`}>
                                          <div className={`text-yellow-500 mx-px mb-px`}><Icon icon={starSolid} height={13} width={13} /></div>
                                          <div className={`tooltip pointer-events-none`} data-tooltip="Rating">
                                            {parseFloat(parseInt(post.paywallRatingTotal) / parseInt(post.numberOfPaywallRatings)).toFixed(2)}
                                          </div>
                                        </div>
                                        {/* Small Screen */}
                                        <div className={`mr-6px flex items-center sm:hidden`}>
                                          <div className={`text-yellow-500 mx-px mb-px`}><Icon icon={starSolid} height={13} width={13} /></div>
                                          <div className={`pointer-events-none`} >
                                            {parseFloat(parseInt(post.paywallRatingTotal) / parseInt(post.numberOfPaywallRatings)).toFixed(2)}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    : <></>
                                  }
                                </>
                                : <></>
                              }
                              {/* Shares */}
                              <div className={`flex`}>
                                <div className={`sm:hidden inline-block mr-2px mt-2px`}>
                                  <Icon icon={shareSquare} height={13} width={13} />
                                </div>
                                <div className={`tooltip hidden sm:inline-block mr-2px mt-2px`} data-tooltip="Shares">
                                  <Icon icon={shareSquare} height={13} width={13} />
                                </div>
                                <div className={`mr-1 pointer-events-none`}>{post.numShares}</div>
                              </div>
                              {/* Category */}
                              {!post.categoryDisplayName
                                ? <></>
                                :
                                <div className={`hidden sm:block text-xs text-medium-gray`}>
                                  in #{post.categoryDisplayName}
                                </div>
                              }
                            </div>
                            {/* Bottom right */}
                            <div className={`items-center flex flex-wrap`}>
                              {/* Tags */}
                              {post.tags && post.tags.length > 0
                                ? post.tags.map((tag, index) => {
                                  return <div key={index} className={`mr-1 text-medium-gray hidden sm:block`}>#{tag}</div>
                                })
                                : <></>
                              }
                              {/* Blockchain tx */}
                              {!post.blockchainPaymentData
                                ? <></>
                                :
                                <>
                                  <a className={`first-text hover:text-brandGreen text-xs sm:text-base`}
                                    href={`https://whatsonchain.com/tx/${post.blockchainPaymentData.transactionId}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                                  >
                                    <Icon icon={bitcoinsvIcon} height={18} width={18} />
                                  </a>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        {post.ref
                          ? <div ref={refCallbackForArray}></div>
                          : <></>
                        }
                        {post.lastItem
                          ? noMorePostResults
                            ?
                            <div className={`roundedtext-sm text-center mt-3 p-1 text-medium-gray-lighter bg-transparent`}>
                              {this.props.globals.noContentToDisplay}
                            </div>
                            : paginatedPostsAreLoaded
                              ? <></>
                              :
                              <>
                                <SkeletonLoaderTwoLines removeAvatar={true}  />
                                <SkeletonLoaderTwoLines removeAvatar={true}  />
                                <SkeletonLoaderTwoLines removeAvatar={true}  />
                                <SkeletonLoaderTwoLines removeAvatar={true}  />
                                <SkeletonLoaderTwoLines removeAvatar={true}  />
                                <SkeletonLoaderTwoLines removeAvatar={true}  />
                                <SkeletonLoaderTwoLines removeAvatar={true}  />
                                <SkeletonLoaderTwoLines removeAvatar={true}  />
                              </>
                          : <></>
                        }
                      </React.Fragment>
                    )
                  })}
                </>
              }
            </div>
          }
        </div>
      </>
    )
  }
}

class GetComments extends Component {
  state = {
    commentView: '',
    showCommentEditor: false,
  }
  render() {
    const {
      comments,
      commentsOpacity,
      commentsAreLoaded,
      userData,
    } = this.props;
    return (
      <>
        {!commentsAreLoaded
          ? <div className='pt-6'>
            <SkeletonLoaderForPosts  removeAvatar={true} />
            <SkeletonLoaderForPosts  removeAvatar={true} />
            <SkeletonLoaderForPosts  removeAvatar={true} />
            <SkeletonLoaderForPosts  removeAvatar={true} />
            <SkeletonLoaderForPosts  removeAvatar={true} />
          </div>
          : comments && comments.length < 1
            ?
            <div className={`${commentsOpacity} transition-to-opacity w-full`}>
              <div className={`my-2 pt-4 flex justify-center`}>No comments thus far!</div>
            </div>
            :
            <div className={`${commentsOpacity} transition-to-opacity w-full`}>
              <div className={`my-2 pt-2 flex justify-center text-3xl teko-font logo-style eleventh-text`}>
                Comments ({userData && userData.totalCommentsPosted ? `${userData.totalCommentsPosted}` : '0'})
              </div>
              <div className={`flex md:inline-flex mx-auto w-full sm:max-w-3xl md:max-w-4xl transition px-3`}>
                <div className={`w-full`}>
                  {comments.map((item, index) => {
                    return <SingleCommentInProfilePage key={index} item={item} index={index} {...this.props}
                    />
                  })}
                </div>
              </div>
            </div>
        }
      </>
    )
  }
}

class GetThumbs extends Component {
  state = {
    thumbView: 'postReceived',
    thumbsOpacity: 'opacity-100',
    selection: {
      postsReceived: 'highlight-border',
      postsGiven: '',
      commentsReceived: '',
      commentsGiven: ''
    }
  }
  setListSelectionState = (value) => {
    switch (value) {
      case 'postReceived':
        this.setState({
          thumbsOpacity: 'opacity-0',
          selection: {
            postsReceived: 'highlight-border',
            postsGiven: '',
            commentsReceived: '',
            commentsGiven: ''
          }
        })
        break
      case 'postSent':
        this.setState({
          thumbsOpacity: 'opacity-0',
          selection: {
            postsReceived: '',
            postsGiven: 'highlight-border',
            commentsReceived: '',
            commentsGiven: ''
          }
        })
        break
      case 'commentReceived':
        this.setState({
          thumbsOpacity: 'opacity-0',
          selection: {
            postsReceived: '',
            postsGiven: '',
            commentsReceived: 'highlight-border',
            commentsGiven: ''
          }
        })
        break
      case 'commentSent':
        this.setState({
          thumbsOpacity: 'opacity-0',
          selection: {
            postsReceived: '',
            postsGiven: '',
            commentsReceived: '',
            commentsGiven: 'highlight-border'
          }
        })
        break
      default: this.setState({
        thumbsOpacity: 'opacity-0',
        selection: {
          postsReceived: 'highlight-border',
          postsGiven: '',
          commentsReceived: '',
          commentsGiven: ''
        }
      })
        break
    }
  }
  toggleThumbView = (e, value) => {
    e.preventDefault();
    this.props.globals.createRipple(e)
    // Guard clauses
    if (value === this.state.thumbView) return
    if (value === "postReceived" || value === "postSent" || value === 'commentReceived' || value === 'commentSent') {
      this.setListSelectionState(value)
      setTimeout(() => {
        this.setState({ thumbView: value, thumbsOpacity: 'opacity-100' })
      }, 300)
    }
    else return
  }

  render() {
    return (
      <>
        {/* Large Screen */}
        <div className={`hidden my-2 pt-2 pb-2px sm:flex items-center font-header`}>
          <div className={`flex-1 inline-block`}>
            <button className={`mr-2 my-1 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition category-hover float-right
              ${this.state.selection.commentsReceived} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleThumbView(e, "commentReceived")}
            >
              Comments Received
            </button>
            <button className={`my-1 mr-2 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition category-hover float-right
              ${this.state.selection.postsReceived} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleThumbView(e, "postReceived")}
            >
              Posts Received
            </button>
          </div>
          <div className={`inline-block`}>
            <div className={`teko-font logo-style eleventh-text text-3xl px-2`}>Thumbs</div>
          </div>
          <div className={`flex-1 inline-block`}>
            <button className={`ml-2 py-px px-2 rounded-xl my-1 text-xs sm:text-sm button-ripple transition category-hover float-left
              ${this.state.selection.commentsGiven} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleThumbView(e, "commentSent")}
            >
              Comments Given
            </button>
            <button className={`ml-2 my-1 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition category-hover float-left
              ${this.state.selection.postsGiven} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleThumbView(e, "postSent")}
            >
              Posts Given
            </button>
          </div>
        </div>
        {/* Small Screen */}
        <div className={`flex justify-center sm:hidden mt-2 mb-1 pt-2`}>
          <div className={`teko-font logo-style eleventh-text text-3xl`}>Thumbs</div>
        </div>
        <div className={`flex justify-center flex-wrap button-ripple sm:hidden items-center font-header mb-4`}>
          <div className={``}>
            <button className={`m-1 py-px px-2 rounded-xl text-xs sm:text-sm transition category-hover
              ${this.state.selection.postsGiven} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleThumbView(e, "postSent")}
            >
              Posts Given
            </button>
            <button className={`m-1 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition category-hover
              ${this.state.selection.postsReceived} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleThumbView(e, "postReceived")}
            >
              Posts Received
            </button>
          </div>
          <div className={``}>
            <button className={`m-1 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition category-hover 
              ${this.state.selection.commentsReceived} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleThumbView(e, "commentReceived")}
            >
              Comments Received
            </button>
            <button className={`m-1 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition category-hover 
              ${this.state.selection.commentsGiven} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleThumbView(e, "commentSent")}
            >
              Comments Given
            </button>
          </div>
        </div>
        {/* Both Screens */}
        <div className={`${this.state.thumbsOpacity} transition-to-opacity`}>
          <ThumbsViewConductor thumbView={this.state.thumbView} {...this.props} />
        </div>
      </>
    )
  }
}
class ThumbsViewConductor extends Component {
  render() {
    switch (this.props.thumbView) {
      case 'postReceived':
        return <ThumbsView {...this.props} mappedData={this.props.userData.postThumbsReceived} />
      case 'postSent':
        return <ThumbsView {...this.props} mappedData={this.props.userData.postThumbsGiven} />
      case 'commentReceived':
        return <ThumbsView {...this.props} mappedData={this.props.userData.commentThumbsReceived} />
      case 'commentSent':
        return <ThumbsView {...this.props} mappedData={this.props.userData.commentThumbsGiven} />
      default:
        return <ThumbsView {...this.props} mappedData={this.props.userData.postThumbsReceived} />
    }
  }
}

class ThumbsView extends Component {

  render() {
    const {
      goToUserProfilePage,
      mappedData,
      goToPost
    } = this.props;
    return (
      <>
        <div className={`mx-auto w-full sm:max-w-3xl md:max-w-4xl px-1 sm:px-3`}>
          {/* Small Screen view */}
          {mappedData && mappedData.length < 1
            ?
            <>
              <div className={`my-2 flex justify-center`}>Nothing to find here, yet...</div>
            </>
            :
            <>
              {mappedData && mappedData.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {/* Wrapper */}
                    <div className={`px-2 mb-1 text-sm w-full rounded-xl first-text gradient-background-four shadow-effect-eight`}>
                      {/* Top row */}
                      <div className={`flex justify-between w-full py-1`}>
                        {/* Left Corner */}
                        <div className={`inline-flex items-center overflow-hidden rounded`}>
                          {/* Thumb Value */}
                          <div className={`hidden sm:block text-brand-green font-bold mr-1 min-w-10`}>
                            ${parseFloat(item.valueOfThumbs).toFixed(2)}
                          </div>
                          {/* Avatar */}
                          <img width='26' height='26'
                            alt="Avatar"
                            src={
                              this.props.thumbView === 'postSent'
                                ? item.postCreatorAvatarURL
                                : this.props.thumbView === 'commentSent'
                                  ? item.commentCreatorAvatarURL
                                  : item.thumbGiverAvatarURL
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = '/images/round-handcash-logo.png'
                            }}
                            className={`rounded-xl mx-auto mr-1`}>
                          </img>
                          {/* Handle */}
                          <div className={`-mb-px mr-1`}>
                            {this.props.thumbView === 'postSent'
                              ?
                              <button
                                onClick={(e) => goToUserProfilePage(e, item.postCreatorHandle)}
                                className={`fifth-hover logo-style-three hover:underline text-underline-under`}
                              >
                                {item.postCreatorHandle}
                              </button>
                              : this.props.thumbView === 'commentSent'
                                ?
                                <button
                                  onClick={(e) => goToUserProfilePage(e, item.commentCreatorHandle)}
                                  className={`fifth-hover logo-style-three hover:underline text-underline-under`}
                                >
                                  {item.commentCreatorHandle}
                                </button>
                                :
                                <button
                                  onClick={(e) => goToUserProfilePage(e, item.thumbGiverHandle)}
                                  className={`fifth-hover logo-style-three hover:underline text-underline-under`}
                                >
                                  {item.thumbGiverHandle}
                                </button>
                            }
                          </div>
                          {/* On small screens this is hidden */}
                          <div className={`hidden sm:flex items-center `}>
                            {/* Post Link */}
                            {!item.postSlug || !item.postTitle
                              ? <></>
                              :
                              <>
                                <span className={`mr-1 text-medium-gray`}>-</span>
                                <div className={`overflow-hidden items-center text-medium-gray`}>
                                  <button onClick={(e) => goToPost(e, item.postSlug)} className={`fifth-hover whitespace-nowrap hover:underline`}>
                                    {item.postTitle}
                                  </button>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                        {/* Right Corner */}
                        <div className={`items-center inline-flex ml-1`}>
                          <i className='sm:hidden text-medium-gray text-xs align-middle inline-block'><Moment format="MM/DD/YYYY">{item.createdAt}</Moment></i>
                          {/* Only for larger screens */}
                          <div className={`hidden sm:flex`}>
                            {/* Date */}
                            <i className='text-medium-gray text-xs mx-1 align-middle inline-block'><Moment format="MM/DD/YYYY">{item.createdAt}</Moment></i>
                            {/* Blockchain Payment */}
                            {!item.blockchainPaymentData
                              ? <></>
                              :
                              <>
                                <a className=' hover:text-brandGreen text-xs sm:text-base ml-px'
                                  href={`https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                                >
                                  <Icon icon={bitcoinsvIcon} height={18} width={18} />
                                </a>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                      {/* Bottom level on small screens */}
                      {/* Divider */}
                      <hr className={`sixth-border sm:hidden outsetBorder border mb-1 opacity-50`}></hr>
                      {/* Bottom Row */}
                      <div className={`flex sm:hidden justify-between w-full pb-1`}>
                        {/* Bottom Left */}
                        <div className={`items-center text-medium-gray mr-1 overflow-hidden word-wrap`}>
                          <span className={`pr-1`}>
                            {/* Post Link */}
                            {!item.postSlug || !item.postTitle
                              ? <></>
                              :
                              <>
                                <button onClick={(e) => goToPost(e, item.postSlug)} className={`fifth-hover text-left hover:underline`}>
                                  {item.postTitle}
                                </button>
                              </>
                            }
                          </span>
                        </div>
                        {/* Bottom right */}
                        <div className={`text-xs inline-flex items-center`}>
                          {/* Thumb Value */}
                          <span className={`text-brand-green font-bold mr-1`}>${parseFloat(item.valueOfThumbs).toFixed(2)}</span>
                          {/* Blockchain Payment */}
                          {!item.blockchainPaymentData
                            ? <></>
                            :
                            <>
                              <a className=' hover:text-brandGreen text-xs sm:text-base ml-px'
                                href={`https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`}
                                rel="noopener noreferrer"
                                target="_blank"
                                aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                              >
                                <Icon icon={bitcoinsvIcon} height={18} width={18} />
                              </a>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
            </>
          }
        </div>
      </>
    )
  }
}

class GetPaywalls extends Component {
  state = {
    userPaywallReceivedView: true,
    paywallOpacity: 'opacity-100',
    selection: {
      received: 'highlight-border',
      given: ''
    }
  }
  togglePaywallView = (e, value) => {
    e.preventDefault();
    this.props.globals.createRipple(e)
    // Guard clauses
    if (value === "PAYWALL_RECEIVED" || value === "PAYWALL_SENT") {
      // Toggles
      if (value === "PAYWALL_RECEIVED") {
        this.setState({ paywallOpacity: 'opacity-0', selection: { received: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ userPaywallReceivedView: true, paywallOpacity: 'opacity-100' })
        }, 300)
      }
      if (value === "PAYWALL_SENT") {
        this.setState({ paywallOpacity: 'opacity-0', selection: { given: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ userPaywallReceivedView: false, paywallOpacity: 'opacity-100' })
        }, 300)
      }
    }
    else return
  }

  render() {
    const {
      userData,
      goToUserProfilePage,
      goToPost
    } = this.props;

    return (
      <>
        <div className={`my-2 pt-2 pb-2px flex items-center font-header`}>
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-right
              ${this.state.selection.received} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.togglePaywallView(e, "PAYWALL_RECEIVED")}
            >
              Paywalls Received
            </button>
          </div>
          <div className={`inline-block`}>
            <div className={`teko-font logo-style eleventh-text text-3xl`}>Purchases</div>
          </div>
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-left
              ${this.state.selection.given} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.togglePaywallView(e, "PAYWALL_SENT")}
            >
              Paywalls Purchased
            </button>
          </div>
        </div>
        <div className={`${this.state.paywallOpacity} transition-to-opacity`}>
          <div className={`mx-auto w-full sm:max-w-3xl md:max-w-4xl px-1 sm:px-3`}>
            {this.state.userPaywallReceivedView === true
              ? userData && userData.paywallsReceived.length < 1
                ?
                <>
                  <div className={`mb-1 flex justify-center`}>
                    No content purchases for user yet
                  </div>
                </>
                :
                <>
                  {userData && userData.paywallsReceived.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        
                        goToUserProfilePage={goToUserProfilePage}
                        goToPost={goToPost}
                        avatarURL={item.purchaserAvatarURL}
                        itemValue={item.valueOfPurchase}
                        handle={item.purchaserHandle}
                        postSlug={item.postSlug}
                        postTitle={item.postTitle}
                        paywallRating={item.paywallRating}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                      />
                    )
                  })
                  }
                </>
              // If this is false, it means the view is paywalls given
              : userData && userData.paywallsPurchased.length < 1
                ?
                <>
                  <div className={`mb-1 flex justify-center`}>No content purchases at the moment!</div>
                </>
                :
                <>
                  {userData && userData.paywallsPurchased.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        
                        goToUserProfilePage={goToUserProfilePage}
                        goToPost={goToPost}
                        avatarURL={item.postCreatorAvatarURL}
                        itemValue={item.valueOfPurchase}
                        handle={item.postCreatorHandle}
                        postSlug={item.postSlug}
                        postTitle={item.postTitle}
                        paywallRating={item.paywallRating}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                      />
                    )
                  })
                  }
                </>
            }
          </div>
        </div>
      </>
    )
  }
}
class GetStampshots extends Component {
  state = {

  }
  render() {
    const {
      userData,
      goToPost,
      goToUserProfilePage
    } = this.props;
    return (
      <>
        <div className={`my-2 pt-2 pb-2px flex justify-center items-center font-header`}>
          <div className={`teko-font logo-style eleventh-text text-3xl`}>Stampshots</div>
        </div>
        <div className={`mx-auto w-full sm:max-w-3xl md:max-w-4xl px-1 sm:px-3`}>
          {!userData?.stampshotsConducted
            ? <></>
            : userData.stampshotsConducted.length === 0
              ?
              <>
                <div className={`mb-1 flex justify-center`}>
                  No Stampshots at the moment!
                </div>
              </>
              :
              <>
              {userData && userData.stampshotsConducted.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        
                        goToUserProfilePage={goToUserProfilePage}
                        goToPost={goToPost}
                        avatarURL={item.stampShotCreatorAvatarURL}
                        itemValue={item.valueOfStampshot}
                        handle={item.stampShotCreatorHandle}
                        postSlug={item.postSlug}
                        postTitle={item.postTitle}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                      />
                    )
                  })}
              </>
          }
        </div>
      </>
    )
  }
}

class GetShares extends Component {

  state = {
    userSharesReceivedView: true,
    shareOpacity: 'opacity-100',
    selection: {
      received: 'highlight-border',
      given: ''
    }
  }
  toggleShareView = (e, value) => {
    e.preventDefault();
    this.props.globals.createRipple(e)
    // Guard clauses
    if (value === "SHARES_RECEIVED" || value === "SHARES_GIVEN") {
      // Toggles
      if (value === "SHARES_RECEIVED" && this.state.userSharesReceivedView === false) {
        this.setState({ shareOpacity: 'opacity-0', selection: { received: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ userSharesReceivedView: true, shareOpacity: 'opacity-100' })
        }, 300)
      }
      if (value === "SHARES_GIVEN" && this.state.userSharesReceivedView === true) {
        this.setState({ shareOpacity: 'opacity-0', selection: { given: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ userSharesReceivedView: false, shareOpacity: 'opacity-100' })
        }, 300)
      }
    }
    else return
  }
  render() {
    const {
      userData,
      goToUserProfilePage,
      goToPost
    } = this.props;
    return (
      <>
        <div className={`my-2 pt-2 pb-2px flex items-center font-header`}>
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-right
              ${this.state.selection.received} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleShareView(e, "SHARES_RECEIVED")}
            >
              Shares Received
            </button>
          </div>
          <div className={`inline-block`}>
            <div className={`teko-font logo-style eleventh-text text-3xl`}>Shares</div>
          </div>
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-left
              ${this.state.selection.given} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleShareView(e, "SHARES_GIVEN")}
            >
              Shares Given
            </button>
          </div>
        </div>
        <div className={`${this.state.shareOpacity} transition-to-opacity`}>
          <div className={`mx-auto w-full sm:max-w-3xl md:max-w-4xl px-1 sm:px-3`}>
            {/* Shares Received view */}
            {this.state.userSharesReceivedView
              // If there are 0 shares received
              ? userData && userData.postSharesReceived.length < 1
                ?
                <>
                  <div className={`mb-1 flex justify-center`}>
                    No shares received at the moment!
                  </div>
                </>
                // If there are shares received
                :
                <>
                  {userData && userData.postSharesReceived.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        goToUserProfilePage={goToUserProfilePage}
                        goToPost={goToPost}
                        avatarURL={item.shareGiverAvatarURL}
                        itemValue={item.valueOfShare}
                        handle={item.shareGiverHandle}
                        postSlug={item.postSlug}
                        postTitle={item.postTitle}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                      />
                    )
                  })}
                </>
              : userData && userData.postSharesGiven.length < 1
                ?
                <>
                  <div className={`mb-1 flex justify-center`}>No shares given at the moment!</div>
                </>
                :
                <>
                  {userData && userData.postSharesGiven.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        
                        goToUserProfilePage={goToUserProfilePage}
                        goToPost={goToPost}
                        avatarURL={item.postCreatorAvatarURL}
                        itemValue={item.valueOfShare}
                        handle={item.postCreatorHandle}
                        postSlug={item.postSlug}
                        postTitle={item.postTitle}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                      />
                    )
                  })}
                </>
            }
          </div>
        </div>
      </>
    )
  }
}
class GetVotes extends Component {

  state = {
    userVotesReceivedView: true,
    voteOpacity: 'opacity-100',
    selection: {
      received: 'highlight-border',
      given: ''
    }
  }
  toggleShareView = (e, value) => {
    e.preventDefault();
    this.props.globals.createRipple(e)
    // Guard clauses
    if (value === "VOTES_RECEIVED" || value === "VOTES_GIVEN") {
      // Toggles
      if (value === "VOTES_RECEIVED" && this.state.userVotesReceivedView === false) {
        this.setState({ voteOpacity: 'opacity-0', selection: { received: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ userVotesReceivedView: true, voteOpacity: 'opacity-100' })
        }, 300)
      }
      if (value === "VOTES_GIVEN" && this.state.userVotesReceivedView === true) {
        this.setState({ voteOpacity: 'opacity-0', selection: { given: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ userVotesReceivedView: false, voteOpacity: 'opacity-100' })
        }, 300)
      }
    }
    else return
  }
  render() {
    const {
      userData,
      goToUserProfilePage,
      goToPost
    } = this.props;
    return (
      <>
        <div className={`my-2 pt-2 pb-2px flex items-center font-header`}>
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-right
              ${this.state.selection.received} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleShareView(e, "VOTES_RECEIVED")}
            >
              Votes Received
            </button>
          </div>
          <div className={`inline-block`}>
            <div className={`teko-font logo-style eleventh-text text-3xl`}>Votes</div>
          </div>
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-left
              ${this.state.selection.given} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleShareView(e, "VOTES_GIVEN")}
            >
              Votes Given
            </button>
          </div>
        </div>
        <div className={`${this.state.voteOpacity} transition-to-opacity`}>
          <div className={`mx-auto w-full sm:max-w-3xl md:max-w-4xl px-1 sm:px-3`}>
            {/* Shares Received view */}
            {this.state.userVotesReceivedView
              // If there are 0 shares received
              ? userData && userData.votesReceived.length < 1
                ?
                <>
                  <div className={`mb-1 flex justify-center`}>
                    No Votes received at the moment!
                  </div>
                </>
                // If there are shares received
                :
                <>
                  {userData && userData.votesReceived.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        
                        goToUserProfilePage={goToUserProfilePage}
                        goToPost={goToPost}
                        avatarURL={item.pollVoterAvatarURL}
                        itemValue={item.valueOfVote}
                        handle={item.pollVoterHandle}
                        postSlug={item.postSlug}
                        postTitle={item.postTitle}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                        pollOptionSelected={item.pollOptionSelected}
                      />
                    )
                  })}
                </>
              : userData && userData.votesGiven.length < 1
                ?
                <>
                  <div className={`mb-1 flex justify-center`}>No votes at the moment!</div>
                </>
                :
                <>
                  {userData && userData.votesGiven.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        
                        goToUserProfilePage={goToUserProfilePage}
                        goToPost={goToPost}
                        avatarURL={item.postCreatorAvatarURL}
                        itemValue={item.valueOfVote}
                        handle={item.postCreatorHandle}
                        postSlug={item.postSlug}
                        postTitle={item.postTitle}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                        pollOptionSelected={item.pollOptionSelected}
                      />
                    )
                  })}
                </>
            }
          </div>
        </div>
      </>
    )
  }
}

class GetTips extends Component {
  state = {
    userTipsReceivedView: true,
    tipOpacity: 'opacity-100',
    selection: {
      received: 'highlight-border',
      given: ''
    }
  }
  toggleTipView = (e, value) => {
    e.preventDefault();
    this.props.globals.createRipple(e)
    // Guard clauses
    if (value === "TIPS_RECEIVED" || value === "TIPS_SENT") {
      // Toggles
      if (value === "TIPS_RECEIVED" && this.state.userTipsReceivedView === false) {
        this.setState({ tipOpacity: 'opacity-0', selection: { received: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ userTipsReceivedView: true, tipOpacity: 'opacity-100' })
        }, 300)
      }
      if (value === "TIPS_SENT" && this.state.userTipsReceivedView === true) {
        this.setState({ tipOpacity: 'opacity-0', selection: { given: 'highlight-border' } })
        setTimeout(() => {
          this.setState({ userTipsReceivedView: false, tipOpacity: 'opacity-100' })
        }, 300)
      }
    }
    else return
  }
  render() {
    const {
      userData,
      goToUserProfilePage
    } = this.props;
    return (
      <>
        <div className={`my-2 pt-2 pb-2px flex items-center font-header`}>
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-right
              ${this.state.selection.received} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleTipView(e, "TIPS_RECEIVED")}
            >
              Tips Received
            </button>
          </div>
          <div className={`inline-block`}>
            <div className={`teko-font logo-style eleventh-text text-3xl`}>Tip Tallies</div>
          </div>
          <div className={`flex-1 inline-block`}>
            <button className={`mx-3 py-px px-2 rounded-xl text-xs sm:text-sm button-ripple transition  category-hover float-left
              ${this.state.selection.given} shadow-effect-five eighth-text gradient-background-twelve`}
              onClick={(e) => this.toggleTipView(e, "TIPS_SENT")}
            >
              Tips Given
            </button>
          </div>
        </div>
        <div className={`${this.state.tipOpacity} transition-to-opacity`}>
          <div className={`mx-auto w-full sm:max-w-3xl md:max-w-4xl px-1 sm:px-3`}>
            {this.state.userTipsReceivedView === true
              // If there are or aren't tips received by this user
              ? userData && userData.tipsReceived.length < 1
                ?
                <>
                  <div className={`mb-1 flex justify-center`}>
                    No tips received yet. You could be the first!
                  </div>
                </>
                :
                <>
                  {userData && userData.tipsReceived.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        
                        goToUserProfilePage={goToUserProfilePage}
                        avatarURL={item.tipperHandCashAvatarURL}
                        itemValue={item.tipAmount}
                        handle={item.tipperHandCashHandle}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                        tipMessage={item.tipMessage}
                        isTipType={true}
                      />
                    )
                  })}
                </>
              // If this is false, it means the view is tips given
              : userData && userData.tipsGiven.length < 1
                ?
                <>
                  <div className={`mb-1 flex justify-center`}>No tips have been sent!</div>
                </>
                :
                <>
                  {userData && userData.tipsGiven.map((item, index) => {
                    return (
                      <ProfileListItem
                        key={index}
                        
                        goToUserProfilePage={goToUserProfilePage}
                        avatarURL={item.recipientHandCashAvatarURL}
                        itemValue={item.tipAmount}
                        handle={item.recipientHandCashHandle}
                        createdAtDate={item.createdAt}
                        blockchainPaymentData={item.blockchainPaymentData}
                        tipMessage={item.tipMessage}
                        isTipType={true}
                      />

                    )
                  })}
                </>
            }
          </div>
        </div>
      </>
    )
  }
}

class GetFollowers extends Component {

  render() {
    const {
      userData,
      goToUserProfilePage,
      refCallbackForArray,
      paginatedFollowsAreLoaded,
      noMoreFollowerResults
    } = this.props;
    return (
      <>
        {userData && userData?.followers.length < 1
          ?
          <>
            <div className={`mt-3 pt-2 flex justify-center`}>Lacking followers at the moment! You could be the first!</div>
          </>
          :
          <>
            <div className={`mt-2 pt-2 mb-1 flex justify-center text-3xl teko-font logo-style eleventh-text`}>Followers</div>
            <div className={`mb-3 flex flex-wrap flex-row mx-3 justify-center`}>
              {userData && userData?.followers.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={`first-text gradient-background-four
                      following-card-width text-center rounded-xl p-3 shadow-effect-nine m-2 transition`}>
                      <div className={`flex`}>
                        <div className={`flex-1`}></div>
                        <img width='50' height='50'
                          alt="Avatar"
                          src={item.followerHandCashAvatarURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          className={`rounded-2xl mx-auto flex-1 shadow-effect-five`}>
                        </img>
                        <div className={`flex-1`}>
                          {/* Blockchain Payment */}
                          {!item.blockchainPaymentData
                            ? <></>
                            :
                            <>
                              <div className={`flex justify-end -mt-2px -mr-2px`}>
                                <a className={` hover:text-brandGreen text-xs sm:text-base`}
                                  href={`https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                                >
                                  <Icon icon={bitcoinsvIcon} height={18} width={18} />
                                </a>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      <div className={`overflow-hidden`}>
                        <button onClick={(e) => goToUserProfilePage(e, item.followerHandCashHandle)}
                          className={`fifth-hover mt-1 hover:underline text-underline-under sm:text-base text-sm overflow-hidden`}
                        >
                          {item.followerHandCashHandle}
                        </button>
                      </div>
                      <div className="px-1 text-xs text-medium-gray">
                        <i><Moment fromNow>{item.createdAt}</Moment></i>
                      </div>
                    </div>
                    {item.ref
                      ? <div ref={refCallbackForArray}></div>
                      : <></>
                    }
                    {item.lastItem
                      ? noMoreFollowerResults
                        ? <></>
                        : paginatedFollowsAreLoaded
                          ? <></>
                          :
                          <>
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                          </>
                      : <></>
                    }
                  </React.Fragment>
                )
              })}
            </div>
          </>
        }
      </>
    )
  }
}

class GetFollowing extends Component {
  render() {
    const {
      userData,
      goToUserProfilePage,
      refCallbackForArray,
      paginatedFollowsAreLoaded,
      noMoreFollowingResults
    } = this.props;
    return (
      <>
        {userData && userData?.following.length < 1
          ?
          <>
            <div className={`mt-3 pt-2 flex justify-center`}>Not following anybody at the moment</div>
          </>
          :
          <>
            <div className={`mt-2 pt-2 mb-1 flex justify-center text-3xl teko-font logo-style eleventh-text`}>Following</div>
            <div className={`mb-3 flex flex-wrap flex-row mx-3 justify-center`}>
              {userData && userData.following.map((item, index) => {
                return (
                  <React.Fragment key={index} >
                    <div className={`first-text gradient-background-four
                      following-card-width text-center rounded-xl p-3 shadow-effect-nine transition m-2`}>
                      <div className={`flex`}>
                        <div className={`flex-1`}></div>
                        <img width='50' height='50'
                          alt="Avatar"
                          src={item.recipientHandCashAvatarURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          className={`rounded-2xl mx-auto flex-1 shadow-effect-five`}>
                        </img>
                        <div className={`flex-1`}>
                          {/* Blockchain Payment */}
                          {!item.blockchainPaymentData
                            ? <></>
                            :
                            <>
                              <div className={`flex justify-end -mt-px -mr-px`}>
                                <a className={` hover:text-brandGreen text-xs sm:text-base`}
                                  href={`https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                                >
                                  <Icon icon={bitcoinsvIcon} height={18} width={18} />
                                </a>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      <div className={`overflow-hidden`}>
                        <button onClick={(e) => goToUserProfilePage(e, item.recipientHandCashHandle)}
                          className={`fifth-hover logo-style mt-1 hover:underline text-underline-under sm:text-base text-sm overflow-hidden`}
                        >
                          {item.recipientHandCashHandle}
                        </button>
                      </div>
                      <div className="px-1 text-xs text-medium-gray">
                        <i><Moment fromNow>{item.createdAt}</Moment></i>
                      </div>
                    </div>
                    {item.ref
                      ? <div ref={refCallbackForArray}></div>
                      : <></>
                    }
                    {item.lastItem
                      ? noMoreFollowingResults
                        ? <></>
                        : paginatedFollowsAreLoaded
                          ? <></>
                          :
                          <>
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                            <SkeletonLoaderForPosts  removeAvatar={true} />
                          </>
                      : <></>
                    }
                  </React.Fragment>
                )
              })}
            </div>
          </>
        }
      </>
    )
  }
}
const GetStats = (props) => {
  let totalValue = (parseFloat(props.userData.totalValueReceived).toFixed(2) - parseFloat(props.userData.totalValueSent).toFixed(2))
  let {
    totalCommentValuePaid,
    totalCommentValueReceived,
    totalCommentsReceivedOnComments,
    totalCommentsReceivedOnPosts,
    membershipPrice,
    totalPaywallValuePaid,
    totalPaywallValueReceived,
    totalPaywallsPaid,
    totalPaywallsReceived,
    totalPostsValuePaid,
    totalThumbValueGiven,
    totalThumbsGiven,
    totalThumbsReceived,
    totalThumbsValuedReceived,
    totalSharesGiven,
    totalSharesReceived,
    totalShareValueReceived,
    totalShareValueGiven,
    totalTipValueGiven,
    totalTipsGiven,
    totalTipsReceived,
    totalTipsValueReceived,
    totalPostsCreated,
    totalCommentsPosted,
    totalFollowers,
    totalFollowing,
    totalFollowValuePaid,
    totalFollowValueReceived,
    totalStampshotValueSent,
    totalStampshotsConducted,
    // Votes
    totalValueReceived,
    totalValueSent,
    totalVoteValueReceived,
    totalVoteValueGiven,
    totalVotesGiven,
    totalVotesReceived,
    // Mass Tips
    totalMassTipsConducted,
    totalMassTipsReceived,
    totalMassTipValueSent,
    totalMassTipValueReceived,
    // 
  } = props.userData;
  // Actions
  const totalActions = (
    totalPostsCreated + totalCommentsPosted + totalCommentsReceivedOnPosts + totalCommentsReceivedOnComments +
    totalThumbsReceived + totalThumbsGiven + totalTipsReceived + totalTipsGiven + totalPaywallsReceived +
    totalPaywallsPaid + totalFollowers + totalFollowing + totalSharesGiven + totalMassTipsConducted + totalMassTipsReceived
    + totalSharesReceived + totalVotesGiven + totalVotesReceived + totalStampshotsConducted
  )
  return (
    <>
      <div className={`my-2 pt-2 flex text-3xl justify-center teko-font logo-style eleventh-text`}>Statistics</div>
      <div className={`flex justify-center flex-wrap mt-2`}>
        <div className={`px-2 mb-3 w-full sm:w-auto`}>
          <table aria-label='Statistics Table' className={`shadow-effect-seven text-sm w-full sm:w-auto rounded-xl fifteenthBackground`}>
            <thead><tr>
              <th className={`text-lg first-text gradient-background-three-no-hover rounded-tl-xl text-left px-2 py-2`}># Stats</th>
              <th className={`text-lg first-text gradient-background-three-no-hover rounded-tr-xl text-center sm:text-left px-2 py-2`}>Amount</th>
            </tr></thead>
            <tbody><tr><td className={`h-px table-fill`}></td><td className={`h-px table-fill`}></td></tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Posts Created</td>
              <td className={`px-2 py-2 text-center`}>{totalPostsCreated}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Comments Created</td>
              <td className={`px-2 py-2 text-center`}>{totalCommentsPosted}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Comments on Posts</td>
              <td className={`px-2 py-2 gradient-background-stats text-center`}>{totalCommentsReceivedOnPosts}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Comments on Comments</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center`}>{totalCommentsReceivedOnComments}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Content Purchases</td>
              <td className={`px-2 py-2 text-center`}>{totalPaywallsReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Content Purchased</td>
              <td className={`px-2 py-2 text-center`}>{totalPaywallsPaid}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Thumbs Received</td>
              <td className={`px-2 py-2 gradient-background-stats text-center`}>{totalThumbsReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Thumbs Given</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center`}>{totalThumbsGiven}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Tips Received</td>
              <td className={`px-2 py-2 text-center`}>{totalTipsReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Tips Given</td>
              <td className={`px-2 py-2 text-center`}>{totalTipsGiven}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Shares Received</td>
              <td className={`px-2 py-2 gradient-background-stats text-center`}>{totalSharesReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Shares Given</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center`}>{totalSharesGiven}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Votes Received</td>
              <td className={`px-2 py-2 text-center`}>{totalVotesReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Votes Given</td>
              <td className={`px-2 py-2 text-center`}>{totalVotesGiven}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Follows</td>
              <td className={`px-2 py-2 gradient-background-stats text-center`}>{totalFollowers}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Following</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center`}>{totalFollowing}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Mass Tips Received</td>
              <td className={`px-2 py-2 text-center`}>{totalMassTipsReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Mass Tips Sent</td>
              <td className={`px-2 py-2 text-center`}>{totalMassTipsConducted}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Stampshots Conducted</td>
              <td className={`px-2 py-2 text-center`}>{totalStampshotsConducted}</td>
            </tr></tbody>
            <tbody><tr><td className={`h-px table-fill`}></td><td className={`h-px table-fill`}></td></tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-three-no-hover text-lg rounded-bl-xl first-text font-bold`}>Total Actions</td>
              <td className={`px-2 py-2 gradient-background-three-no-hover text-lg text-center rounded-br-xl text-brand-green`}>{totalActions.toString()}</td>
            </tr></tbody>
          </table>
        </div>
        {/* Table 2 */}
        <div className={`px-2 w-full sm:w-auto`}>
          <table className={`shadow-effect-seven text-sm w-full sm:w-auto rounded-xl fifteenthBackground`}>
            <thead><tr>
              <th className={`text-lg first-text gradient-background-three-no-hover rounded-tl-xl text-left px-2 py-2`}>$ Stats</th>
              <th className={`text-lg first-text gradient-background-three-no-hover rounded-tr-xl text-left text-center sm:text-left px-2 py-2`}>Amount</th>
            </tr></thead>
            <tbody><tr><td className={`h-px table-fill`}></td><td className={`h-px table-fill`}></td></tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Membership</td>
              <td className={`px-2 py-2 text-center text-red-600`}>${membershipPrice}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Post Spends</td>
              <td className={`px-2 py-2 text-center text-red-600`}>${totalPostsValuePaid}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Comment Earnings</td>
              <td className={`px-2 py-2 gradient-background-stats text-center text-brand-green`}>${totalCommentValueReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Comment Spends</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center text-red-600`}>${totalCommentValuePaid}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Content Earnings</td>
              <td className={`px-2 py-2 text-center text-brand-green`}>${totalPaywallValueReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Content Spends</td>
              <td className={`px-2 py-2 text-center text-red-600`}>${totalPaywallValuePaid}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Thumb Earnings</td>
              <td className={`px-2 py-2 gradient-background-stats text-center text-brand-green`}>${totalThumbsValuedReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Thumbs Sent</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center text-red-600`}>${totalThumbValueGiven}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Tip Earnings</td>
              <td className={`px-2 py-2 text-center text-brand-green`}>${totalTipsValueReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Tips Sent</td>
              <td className={`px-2 py-2 text-center text-red-600`}>${totalTipValueGiven}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Share Earnings</td>
              <td className={`px-2 py-2 gradient-background-stats text-center text-brand-green`}>${totalShareValueReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Shares Sent</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center text-red-600`}>${totalShareValueGiven}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Vote Earnings</td>
              <td className={`px-2 py-2 text-center text-brand-green`}>${totalVoteValueReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Vote Spends</td>
              <td className={`px-2 py-2 text-center text-red-600`}>${totalVoteValueGiven}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Follow Earnings</td>
              <td className={`px-2 py-2 gradient-background-stats text-center text-brand-green`}>${totalFollowValueReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Follow Spends</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center text-red-600`}>${totalFollowValuePaid}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Mass Tip Earnings</td>
              <td className={`px-2 py-2 text-center text-brand-green`}>${totalMassTipValueReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Mass Tip Spends</td>
              <td className={`px-2 py-2 text-center text-red-600`}>${totalMassTipValueSent}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2`}>Stampshot Spends</td>
              <td className={`px-2 py-2 text-center text-red-600`}>${totalStampshotValueSent}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats`}>Total Earned</td>
              <td className={`px-2 py-2 gradient-background-stats text-center text-brand-green`}>${totalValueReceived}</td>
            </tr></tbody>
            <tbody><tr>
              <td className={`px-2 py-2 gradient-background-stats-two`}>Total Spent</td>
              <td className={`px-2 py-2 gradient-background-stats-two text-center text-red-600`}>${totalValueSent}</td>
            </tr></tbody>
            <tbody><tr><td className={`h-px table-fill`}></td><td className={`h-px table-fill`}></td></tr></tbody>
            <tbody><tr>
              <td className={`px-2 gradient-background-three-no-hover rounded-bl-xl py-2 text-lg font-bold`}>Grand Total</td>
              {totalValue >= 0
                ? <td className={`px-2 gradient-background-three-no-hover py-2 rounded-br-xl text-center text-brand-green text-lg`}>${totalValue.toFixed(2)}</td>
                : <td className={`px-2 gradient-background-three-no-hover py-2 rounded-br-xl text-center text-red-600 text-lg`}>${totalValue.toFixed(2)}</td>
              }
            </tr></tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default DataListConductor;