import React, { Component } from 'react'

class RankingPage extends Component {
  state = {
    commentView: '',
    commentOpacity: 'opacity-100'
  }
  render() {
    const {
      array,
      title,
      goToUserProfilePage,
      listDataType,
      auth
    } = this.props;
    return (
      <>
        <div className={`mb-3 pt-3 flex justify-center text-3xl teko-font logo-style eleventh-text`}>{title}</div>
        <div className={`px-2 mb-3 flex justify-center`}>
          <table aria-label='Ranking by Statistics Table' className={`shadow-effect-five text-sm items-center w-full sm:w-auto`}>
            <thead><tr>
              <th className={`text-sm sm:text-base first-text eighth-background border lightBorder text-center px-1 sm:px-3 py-2`}>Rank</th>
              <th className={`text-sm sm:text-base first-text eighth-background border lightBorder text-center px-1 sm:px-3 py-2`}>Amount</th>
              <th className={`text-sm sm:text-base first-text eighth-background border lightBorder text-center px-1 sm:px-3 py-2`}>Avatar</th>
              <th className={`text-sm sm:text-base first-text eighth-background border lightBorder text-center px-1 sm:px-3 py-2`}>Handle</th>
            </tr></thead>
            {array & array.length === 0
              ?
              <tbody>
                <tr>
                  <td colSpan='5' className={`border lightBorder px-2 py-2 text-center`}>
                    No results to display at the moment
                  </td>
                </tr>
              </tbody>
              :
              <>
                {array && array.map((item, index) => {
                  return (
                    <tbody key={index}>
                      <tr className={`${index % 2 !== 0 ? `gradient-background-two`: ``}`}>
                        {/* Rank */}
                        <td className={`border lightBorder px-2 py-2 text-center`}>
                          <div>
                            #{index + 1}
                          </div>
                        </td>
                        {/* Amount: Conditionally Rendered based upon listDataType */}
                        <td className={`border lightBorder px-1 py-2 text-center`}>
                          {/* Most earned */}
                          {listDataType === "GET_MOST_EARNED"
                            ?
                            <div className={`text-brand-green font-bold`}>
                              ${item.totalValueReceived}
                            </div>
                            // Sent
                            : listDataType === "GET_MOST_SENT"
                              ?
                              <div className={`text-brand-green font-bold`}>
                                ${item.totalValueSent}
                              </div>
                              // Thumbs
                              : listDataType === "GET_MOST_THUMBS"
                                ?
                                <div className={`text-brand-green font-bold`}>
                                  {item.totalThumbsReceived}
                                </div>
                                // Posts
                                : listDataType === "GET_MOST_POSTS"
                                  ?
                                  <div className={`text-brand-green font-bold`}>
                                    {item.totalPostsCreated}
                                  </div>
                                  // Paywalls
                                  : listDataType === "GET_MOST_PAYWALLS"
                                    ?
                                    <div className={`text-brand-green font-bold`}>
                                      ${item.totalPaywallValueReceived}
                                    </div>
                                    // Followers
                                    : listDataType === "GET_MOST_FOLLOWERS"
                                      ?
                                      <div className={`text-brand-green font-bold`}>
                                        {item.totalFollowers}
                                      </div>
                                      // Donations
                                      : listDataType === "GET_MOST_DONATED"
                                        ?
                                        <div className={`text-brand-green font-bold`}>
                                          ${item.totalValueOfDonations}
                                        </div>
                                        // Won't hit this...
                                        : <div>$</div>
                          }
                        </td>
                        {/* Avatar Image */}
                        <td className={`border lightBorder px-2 py-2`}>
                          <div className={`h-auto text-center`}>
                            <span className={`${item.membership && auth?.handle !== item.handCashHandle ? `member-tooltip-large ` : ``}`} data-member='large' data-tooltip='Member'>
                              <div className={`p-2 -m-2 ${item.membership ? `flip-hover` : ``}`}>
                                <img width='35' height='35'
                                  alt="Avatar"
                                  src={item.handCashAvatarUrl}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = '/images/round-handcash-logo.png'
                                  }}
                                  className={`rounded-xl mx-auto ${item.membership ? `shadow-effect-six` : ``}`}>
                                </img>
                              </div>
                            </span>
                          </div>
                        </td>
                        {/* Handle */}
                        <td className={`border lightBorder px-2 py-2 text-center break-all`}>
                          <button
                            onClick={(e) => goToUserProfilePage(e, item.handCashHandle)}
                            className={`fifth-hover hover:underline`}
                          >
                            {item.handCashHandle}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  )
                })}
              </>
            }

          </table>
        </div>

      </>
    )
  }
}

export default RankingPage;