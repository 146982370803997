import { createSlice } from '@reduxjs/toolkit'

let initState = { quillTheme: 'snow' };
document.documentElement.setAttribute('data-quill', 'SNOW')

const quillThemeSlice = createSlice({
  name: 'quillTheme',
  initialState: initState,
  reducers: {
    handleQuillThemeChange(state, action) {
      switch (action.payload) {
        case 'bubble':
          state.quillTheme = 'bubble'
          return
        case 'snow':
          state.quillTheme = 'snow'
          return
        default:
          state.quillTheme = 'snow'
          return
      }
    }
  }
})

export const { handleQuillThemeChange } = quillThemeSlice.actions;
export default quillThemeSlice.reducer;