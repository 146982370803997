import React, { Component } from 'react';
import { connect } from 'react-redux';
import API from '../../utils/API';
import { opacityChange } from '../../store/reducers/opacitySlice';
import { storeUserDatabaseData } from '../../store/reducers/authSlice';
import PostDetailPage from './PostDetailPage';
import smoothscroll from 'smoothscroll-polyfill';
import { globals, iframeUpdate } from '../../config/globals';
import { toast } from 'react-toastify';
import PaymentModal from '../../components/Modals/PaymentModal';
import OverlayLoadingIcon from '../../components/Widgets/OverlayLoadingIcon';
import LoginModal from '../../components/Modals/LoginModal';
import ArrayModal from '../../components/Modals/ArrayModal';
import TipModal from '../../components/Modals/TipModal';
import ConfirmCancelModal from '../../components/Modals/ConfirmCancelModal';

class PostDetailPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginModal: false,
      showPaymentModal: false,
      // commentsOpacity is the list of comments
      commentsOpacity: 'opacity-0',
      // tierOneCommentOpacity = the Creation of a comment, not the comment list
      tierOneCommentOpacity: 'opacity-0',
      addExpandedClass: '',
      showConfirmCancelModal: false,
      tierOneEditorVisible: false,
      transactionType: '',
      pageOverlayActive: false,
      postDetailPageIsLoading: false,
      costToCommentIsFree: false,
      nonPaymentOverlay: false,
      // Post
      post: {
        id: '',
        title: '',
        category: '',
        categoryDisplayName: '',
        postCreatorHandle: '',
        postCreatorHandCashId: '',
        postCreatorAvatarURL: '',
        postCreatorPaymail: '',
        actionTakerIsMember: false,
        freeContent: '',
        paywallContent: '',
        description: '',
        slug: '',
        codeEditorContent: '',
        codeEditorLanguage: '',
        themeforCodeEditor: '',
        createdAt: '',
        // Values
        numComments: 0,
        numThumbs: 0,
        numShares: 0,
        numViews: 0,
        numPaywallPurchases: 0,
        paywallCost: 0,
        totalValueReceived: 0,
        tipValueReceived: 0,
        paywallValueRecieved: 0,
        numPollVotes: 0,
        numStampshots: 0,
        // Arrays
        thumberHandCashIds: [],
        paywallPurchasers: [],
        purchaserHandCashIds: [],
        sharerHandCashIds: [],
        pollVoterHandCashIds: [],
        // Paywall
        paywallRaterHandCashIds: [],
        postHasPaywall: false,
        ratingAverage: 0,
        paywallRatingTotal: 0,
        numberOfPaywallRatings: 0,
        // Stampshots
        isFrozen: false,
        stampshotTime: '',
        stampshots: [],
      },
      // Comments
      commentsAreLoading: true,
      commentsArray: [],
      comment: {
        commentBody: '',
      },
      commentIndexNumber: 0,
      commentPageNumber: 1,
      moreCommentsLoading: false,
      noMoreComments: false,
      commentBodyError: '',
      overallError: '',
      // User toggles
      userAlreadyThumbsPost: false,
      userAlreadyPurchasedPaywall: false,
      userAlreadySharedPost: false,
      userRatedPaywall: true, // this begins as true and only changes to false upon conditions, because if it's true, the display is <></>
      paywallOpacity: 'opacity-100',
      selectedStar: '',
      disableRating: true,
      // Array Modal State
      showArrayModal: false,
      shareArray: [],
      thumbsArray: [],
      purchaserArray: [],
      votersArray: [],
      stampshotsArray: [],
      modalArrayType: '',
      arrayModalOpacity: 'opacity-0',
      arrayModalIsLoading: true,
      pageNumberShareArray: 1,
      pageNumberThumbsArray: 1,
      pageNumberPurchaserArray: 1,
      pageNumberVotersArray: 1,
      pageNumberStampshotsArray: 1,
      noMoreResultsShareArray: false,
      noMoreResultsThumbsArray: false,
      noMoreResultsPurchaserArray: false,
      noMoreResultsVotersArray: false,
      noMoreResultsStampshotsArray: false,
      shareArrayIsLoading: false,
      thumbsArrayIsLoading: false,
      purchaserArrayIsLoading: false,
      votersArrayIsLoading: false,
      stampshotsArrayIsLoading: false,
      // thumbs/tipping
      showTipModal: false,
      thumbsAmount: globals.thumbsCostMin.toFixed(2),
      dateInterval: this.dateInterval,
      // Voting
      viewVotes: false,
      userAlreadyVoted: false,
      isRadioButtonSelected: false,
      pollOptionSelected: '',
      voteIndexInPollsArray: 0,
      barChartData: {
        labels: [],
        datasets: [
          {
            axis: 'y',
            data: [],
            fill: false,
            label: 'Votes',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          }
        ]
      },
      pieChartData: {
        labels: [],
        datasets: [
          {
            label: 'Votes',
            data: [],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          }
        ]
      },
      // View for List Comments
      listOrCommentView: 'commentView',
    }
  }

  // timeout variable enables clearing the setTimeout when page unmounts (so it doesn't count towards stats)
  timeout = '';
  cancelToken = API.CancelToken.source();
  observer = React.createRef();
  commentsPerQuery = 20;
  modalObserver = React.createRef()
  dateInterval = '00:00:00';

  componentDidMount() {
    this.fetchPost()
    this.fetchPostComments();
    this.checkViewType()
  };
  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearInterval(this.dateInterval)
    this.cancelToken.cancel('Operation canceled');
  };
  // Checks to see what the viewType is for the user.
  checkViewType = () => {
    // If on mobile, we don't display listOrCommentView
    const width = window.innerWidth;
    if (width < 640) return this.setState({ listOrCommentView: 'commentView', commentViewSelected: 'highlight-border' })
    let localStorageViewType = localStorage.getItem('viewTypeListComments')
    if (localStorageViewType === 'listView') {
      this.setState({ listOrCommentView: 'listView', listViewSelected: 'highlight-border' })
    } else if (localStorageViewType === 'commentView') {
      this.setState({ listOrCommentView: 'commentView', commentViewSelected: 'highlight-border' })
    } else {
      localStorage.setItem('viewType', 'commentView')
      this.setState({ listOrCommentView: 'commentView', commentViewSelected: 'highlight-border' })
    }
  }
  setView = (e, viewType) => {
    // If it's already what the user clicked on, return.
    globals.createRipple(e)
    if (this.state.listOrCommentView === 'listView' && viewType === 'listView') return
    if (this.state.listOrCommentView === 'commentView' && viewType === 'commentView') return
    if (viewType === 'listView') {
      localStorage.setItem('viewTypeListComments', viewType)
      this.setState({ listOrCommentView: 'listView', commentViewSelected: '', listViewSelected: 'highlight-border' })
    } else {
      localStorage.setItem('viewTypeListComments', 'commentView')
      this.setState({ listOrCommentView: 'commentView', commentViewSelected: 'highlight-border', listViewSelected: '' })
    }
  }
  commentPaginationLogic = (node) => {
    // Disconnects the current ref observer when it hits.
    if (this.observer.current) this.observer.current.disconnect()
    // Creates a new observer and in the grabMoreComments function, it updates the div where the refCallback lands on
    this.observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !this.state.noMoreComments) {
        this.grabMoreComments()
      }
    })
    // Observe the current node.
    if (node) this.observer.current.observe(node)
  }
  // This is my callback function for when the ref becomes .current (on the screen)
  refCallback = this.commentPaginationLogic.bind(this); // <- this is essentially `useCallback`

  arrayListPaginationLogic = (node) => {
    // Disconnects the current ref observer when it hits.
    if (this.modalObserver.current) this.modalObserver.current.disconnect()
    // Creates a new observer and in the grabMoreComments function, it updates the div where the refCallback lands on
    this.modalObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && this.state.modalArrayType === 'THUMBS_ARRAY' && !this.state.noMoreResultsThumbsArray) {
        this.paginateByArrayType('THUMBS_ARRAY')
      }
      if (entries[0].isIntersecting && this.state.modalArrayType === 'SHARE_ARRAY' && !this.state.noMoreResultsShareArray) {
        this.paginateByArrayType('SHARE_ARRAY')
      }
      if (entries[0].isIntersecting && this.state.modalArrayType === 'PURCHASERS_ARRAY' && !this.state.noMoreResultsPurchaserArray) {
        this.paginateByArrayType('PURCHASERS_ARRAY')
      }
      if (entries[0].isIntersecting && this.state.modalArrayType === 'VOTERS_ARRAY' && !this.state.noMoreResultsVotersArray) {
        this.paginateByArrayType('VOTERS_ARRAY')
      }
      if (entries[0].isIntersecting && this.state.modalArrayType === 'STAMPSHOTS_ARRAY' && !this.state.noMoreResultsStampshotsArray) {
        this.paginateByArrayType('STAMPSHOTS_ARRAY')
      }
    })
    // Observe the current node.
    if (node) this.modalObserver.current.observe(node)
  }
  // This is my callback function for when the ref becomes .current (on the screen)
  refCallbackForArrayList = this.arrayListPaginationLogic.bind(this); // <- this is essentially `useCallback`
  // Should be called after the post is set, not on mount. 
  incrementTotalViews(slug) {
    API.incrementViewsOfPost(slug)
      .then(res => {
        let localStorageSlugViews = localStorage.getItem(`viewsForPosts`);
        // If there are no viewsForPosts yet in localStorage
        if (!localStorageSlugViews) {
          const slugObject = { alreadyViewed: [slug] }
          localStorage.setItem('viewsForPosts', JSON.stringify(slugObject))
        }
        // If there are already views for posts in localStorage
        if (localStorageSlugViews) {
          localStorageSlugViews = JSON.parse(localStorageSlugViews)
          let slugsArray = localStorageSlugViews.alreadyViewed
          slugsArray.push(slug)
          const slugObject = { alreadyViewed: slugsArray }
          localStorage.setItem('viewsForPosts', JSON.stringify(slugObject))
        }
        clearTimeout(this.timeout)
      })
      .catch(error => {
        // User does not need to be notified of this
        clearTimeout(this.timeout)
      });
  };
  matchThemeModeForCodeTheme = () => {
    let localStorageTheme = localStorage.getItem('theme')
    if (localStorageTheme === 'LIGHT_THEME') {
      this.setState({ themeforCodeEditor: 'elegant' })
    }
    else this.setState({ themeforCodeEditor: 'tomorrow-night-bright' })
  };
  fetchPost = async () => {
    this.setState({ postDetailPageIsLoading: true })
    API.findPostBySlug(this.props.match.params.slug, this.cancelToken.token)
      .then(res => {
        if (res.data === 'NO_POST') {
          return (
            globals.toastError(toast, 'Unable to locate post'),
            this.props.opacityChange('removePostDetailOpacity'),
            setTimeout(() => {
              this.props.history.push(`/`)
            }, 300)
          )
        }
        let paywallContent = '';
        if (res.data[0].paywallContent) paywallContent = res.data[0].paywallContent;
        // Will need to not add in the paywall content unless the person is logged in and they purchased
        let ratingAverage
        ratingAverage = parseFloat((parseInt(res.data[0].paywallRatingTotal) / parseInt(res.data[0].numberOfPaywallRatings))).toFixed(2)
        this.setState({
          post: {
            id: res.data[0]._id,
            title: res.data[0].title,
            slug: res.data[0].slug,
            tags: res.data[0].tags,
            description: res.data[0].description,
            category: res.data[0].category,
            readTime: res.data[0].readTime,
            categoryDisplayName: res.data[0].categoryDisplayName,
            freeContent: res.data[0].freeContent,
            codeEditorContent: res.data[0].codeEditorContent,
            codeEditorLanguage: res.data[0].codeEditorLanguage,
            createdAt: res.data[0].createdAt,
            actionTakerIsMember: res.data[0].actionTakerIsMember,
            // Stampshots
            stampshotTime: res.data[0].stampshotTime,
            stampshots: res.data[0].stampshots,
            isFrozen: res.data[0].isFrozen,
            numStampshots: res.data[0].numStampshots,
            // paywall
            paywallContent: paywallContent,
            postHasPaywall: res.data[0].postHasPaywall,
            purchaserHandCashIds: res.data[0].purchaserHandCashIds,
            paywallPurchasers: res.data[0].paywallPurchasers,
            numPaywallPurchases: res.data[0].numPaywallPurchases,
            ratingAverage: ratingAverage,
            paywallRatingTotal: res.data[0].paywallRatingTotal,
            numberOfPaywallRatings: res.data[0].numberOfPaywallRatings,
            // Integer numbers
            numComments: res.data[0].numComments,
            numThumbs: res.data[0].numThumbs,
            numShares: res.data[0].numShares,
            numViews: res.data[0].numViews,
            // Values
            totalValueReceived: res.data[0].totalValueReceived,
            tipValueReceived: res.data[0].tipValueReceived,
            paywallValueRecieved: res.data[0].paywallValueRecieved,
            paywallCost: res.data[0].paywallCost,
            // Info
            thumberHandCashIds: res.data[0].thumberHandCashIds,
            sharerHandCashIds: res.data[0].sharerHandCashIds,
            postCreatorHandle: res.data[0].postCreatorHandle,
            postCreatorPaymail: res.data[0].postCreatorPaymail,
            postCreatorAvatarURL: res.data[0].postCreatorAvatarURL,
            postCreatorHandCashId: res.data[0].postCreatorHandCashId,
            paywallRaterHandCashIds: res.data[0].paywallRaterHandCashIds,
            // Polls
            pollVoterHandCashIds: res.data[0].pollVoterHandCashIds,
            voteViewersHandCashIds: res.data[0].voteViewersHandCashIds,
            pollData: res.data[0].pollData,
            numPollVotes: res.data[0].numPollVotes,
            pollValueReceived: res.data[0].pollValueReceived
          }
        }, () => {
          // If it's a list category, don't display comment immediately.
          if (globals.listCategoryArray.includes(res.data[0].category)) this.setState({ addExpandedClass: '' })
          // If Poll category, check localStorage for slug to see if user viewed more & setState for votes
          if (res.data[0].category === 'polls') {
            this.checkLocalStorageForSlug()
            const newPollData = res.data[0].pollData.map(item => { return item.numOptionVotes })
            const pollOptions = res.data[0].pollData.map(item => { return item.pollOption })
            this.setBarAndPieChartState(newPollData, pollOptions)
          }
          // If codeEditorContent, check for theme.
          if (res.data[0].codeEditorContent?.length > 0) this.matchThemeModeForCodeTheme();
          // Check if there is a logged in user. if so, see if they've already added a thumbs.
          let localStorageAuth = localStorage.getItem('handCashAuthData');
          if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth)
          if (localStorageAuth && this.props.auth?.id && this.state.post.thumberHandCashIds.length > 0) {
            if (this.state.post.thumberHandCashIds.includes(localStorageAuth.id)) {
              this.setState({ userAlreadyThumbsPost: true })
            }
          }
          // Check if user is logged in and if they are in the paywall purchasers array
          if (localStorageAuth && this.state.post.postHasPaywall && this.props.auth?.id && this.state.post.purchaserHandCashIds.length > 0) {
            if (this.state.post.purchaserHandCashIds.includes(localStorageAuth.id)) {
              this.setState({ userAlreadyPurchasedPaywall: true })
              // Check if user has rated the paywall. If not, we enable the display.
              if (!this.state.post.paywallRaterHandCashIds.includes(localStorageAuth.id)) {
                this.setState({ userRatedPaywall: false })
              }
            }
          }
          // Check if user is logged in and if they voted or viewed results
          if (res.data[0].category === 'polls' && localStorageAuth && this.props.auth?.id) {
            // Determine if user already voted on the poll
            if (this.state.post.pollVoterHandCashIds.length > 0 && this.state.post.pollVoterHandCashIds.includes(localStorageAuth.id)) {
              this.setState({ userAlreadyVoted: true })
            }
            // Determine if user viewed the poll
            if (this.state.post.voteViewersHandCashIds.length > 0 && this.state.post.voteViewersHandCashIds.includes(localStorageAuth.id)) {
              this.setState({ viewVotes: true })
            }
          }
          // Check if user is logged in and if they are in the sharer's array
          if (localStorageAuth && this.props.auth?.id && this.state.post.sharerHandCashIds.length > 0) {
            if (this.state.post.sharerHandCashIds.includes(localStorageAuth.id)) {
              this.setState({ userAlreadySharedPost: true })
            }
          }
        });
        // Check if it's a member's only category
        if (res.data[0].category === 'water-cooler') {
          if (this.props.userDatabaseData?.membership) {
            this.setState({ postDetailPageIsLoading: false })
            setTimeout(() => {
              this.props.opacityChange('addPostDetailOpacity')
            }, 300)
          }
          else {
            // If they're not logged in, they get the boot
            if (!this.props.auth?.id) return (
              globals.toastError(toast, `Members-only post. Redirecting to the home page.`),
              this.props.history.push(`/`)
            )
            // If the userDatabaseData exists and they are not a member
            if (this.props.userDatabaseData && !this.props.userDatabaseData?.membership) return (
              globals.toastError(toast, `Members-only post. Redirecting to the home page.`),
              this.props.history.push(`/`)
            )
            // If there is no userDatabaseData yet
            if (!this.props.userDatabaseData) {
              API.checkForMembership(this.cancelToken.token)
                .then(res => {
                  if (res.data !== true) return (
                    globals.toastError(toast, `Members-only post. Redirecting to the home page.`),
                    this.props.history.push(`/`)
                  )
                  this.setState({ postDetailPageIsLoading: false })
                  setTimeout(() => {
                    this.props.opacityChange('addPostDetailOpacity')
                  }, 300)
                })
                .catch(error => {
                  console.error(error);
                  if (error.message === 'Operation canceled') return
                  globals.toastError(toast, 'Error retrieving data. Redirecting to home page');
                  this.props.history.push(`/`)
                });
            }
          }
        }
        // If the category isn't water-cooler, we can display 
        else {
          this.setState({ postDetailPageIsLoading: false })
          setTimeout(() => {
            this.props.opacityChange('addPostDetailOpacity')
          }, 300)
        }
        // Needed for the setTimeout Fade In of 100 seconds
        setTimeout(() => {
          iframeUpdate();
        }, 750)
        // Stampshots
        if (!this.state.post.isFrozen && this.state.post.stampshots.length === 0 && this.state.post.stampshotTime &&
          (this.state.post.category === 'polls' || this.state.post.category === 'lists' || this.state.post.category === 'questions')
        ) {
          setTimeout(() => {
            this.setCountDownClock()
          }, 100)
        }
        // SetTimeout for views, if applicable
        let localStorageSlugViews = localStorage.getItem(`viewsForPosts`);
        if (localStorageSlugViews) {
          localStorageSlugViews = JSON.parse(localStorageSlugViews)
          // Check alreadyViewed array for slug
          if (localStorageSlugViews.alreadyViewed.includes(`${this.props.match.params.slug}`)) return
        }
        this.timeout = setTimeout(() => {
          this.incrementTotalViews(this.props.match.params.slug)
        }, 30000);
      })
      .catch(error => {
        console.error(error)
        if (error.message === 'Operation canceled') return
        return (
          globals.toastError(toast, 'Unable to locate post'),
          this.props.opacityChange('removePostDetailOpacity'),
          setTimeout(() => {
            this.props.history.push(`/`)
          }, 300)
        )
      });
  };
  setCountDownClock = () => {
    // Found on Stackoverflow
    if (!this.state.post.stampshotTime || this.state.post.isFrozen) return
    this.dateInterval = setInterval(() => {
      // Protects against app crash if someone changes state somehow
      if (!this.state.post.stampshotTime || this.state.post.isFrozen) return clearInterval(this.dateInterval)
      // This will be switched to this.state.post.stampshotTime, and function to be removed
      const countDownDate = new Date(this.state.post.stampshotTime)
      // Get today's date and time
      const now = new Date()
      // Calculate the clock
      let seconds = Math.floor((countDownDate - (now)) / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      hours = hours - (days * 24);
      // This adds a zero if there is less than 9
      minutes = minutes - (days * 24 * 60) - (hours * 60);
      minutes = minutes <= 9 ? '0' + minutes : minutes;
      seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
      seconds = seconds <= 9 ? '0' + seconds : seconds;
      // Have to convert back to hours just to get the display correct via this logic
      hours = hours + (days * 24)
      hours = hours <= 9 ? '0' + hours : hours;
      // if current time equals countdown date
      if (now >= countDownDate) return (
        clearInterval(this.dateInterval),
        this.setState({
          dateInterval: `Stampshot Submissions Frozen`,
          post: {
            ...this.state.post,
            isFrozen: true
          }
        })
      )
      if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)
        || (hours === 0 && minutes === 0 && seconds === 0)) return (
          clearInterval(this.dateInterval),
          this.setState({
            post: {
              ...this.state.post,
              isFrozen: true,
              stampshotTime: '',
            }
          })
        )
      this.setState({ dateInterval: `${hours}:${minutes}:${seconds}` })
    }, 1000)
  }
  // getCountdownDateInSeconds = () => {
  //   // Main functionality is to fire a function that posts to the Blockchain at 12 midnight PT or 4 AM ET.
  //   // We store the time in our database, to be used as a countdown clock on the frontend.
  //   // Grab current date, and create UTC date
  //   const basicDate = new Date()
  //   const currentUtcDateString = basicDate.toUTCString()
  //   // Create comparision date, to check if it's before or after 7 o'clock UTC, because midnight PT = 7 UTC time
  //   const comparisonDate = basicDate.setUTCHours(7, 0, 0, 0);
  //   const comparisonDateString = new Date(comparisonDate).toUTCString()
  //   // UTC @ 7 date to use, for creating the actual countdown date
  //   const convertedComparisionDate = new Date(comparisonDate)
  //   let actualCountDownDate
  //   let seconds
  //   // If current time is after 7 UTC, we add 2 days to the 7 UTC date, to use as our trigger
  //   if (currentUtcDateString >= comparisonDateString) {
  //     // Add 2 days from comparisonDate
  //     actualCountDownDate = new Date(convertedComparisionDate.setUTCDate(convertedComparisionDate.getUTCDate() + 2))
  //     // Get how many seconds in between the dates
  //     seconds = Math.abs((new Date(actualCountDownDate) - new Date()) / 1000)
  //     // Add the UNIX seconds of our current time, to the seconds between dates
  //     seconds = seconds + (Date.now() / 1000)
  //   }
  //   else {
  //     // Add 1 day from comparisonDate
  //     actualCountDownDate = new Date(convertedComparisionDate.setUTCDate(convertedComparisionDate.getUTCDate() + 1))
  //     seconds = Math.abs((new Date(actualCountDownDate) - new Date()) / 1000)
  //     seconds = seconds + (Date.now() / 1000)
  //   }
  //   // May not need the .toUTCString, but it was helpful to see it work. The database may not need it.
  //   return actualCountDownDate
  // }
  clickToEditPost = (e, slug, post) => {
    e.preventDefault();
    if (this.props.auth.id !== post.postCreatorHandCashId) {
      return globals.toastError(toast, `Unable to edit other's posts!`);
    }
    let updatedPost = post;
    updatedPost._id = post.id;
    delete post.id;
    this.props.opacityChange('removePostDetailOpacity')
    setTimeout(() => {
      this.props.history.push({
        pathname: `/edit/${slug}`,
        state: updatedPost
      })
    }, 300)
  }
  checkCommentsForUserThumbs = (commentsArray) => {
    let updatedCommentsArray = commentsArray;
    for (let i = 0; i < updatedCommentsArray.length; i++) {
      if (updatedCommentsArray[i].thumberHandCashIds) {
        updatedCommentsArray[i].thumberHandCashIds.forEach(id => {
          if (id === this.props.auth.id) {
            updatedCommentsArray[i].userAlreadyThumbsComment = true;
          }
        })
      }
    }
    return updatedCommentsArray;
  }
  fetchPostComments = () => {
    API.fetchPostComments(this.props.match.params.slug, this.state.commentPageNumber, this.cancelToken.token)
      .then(res => {
        if (res.data.length === 0) {
          this.setState({ commentsAreLoading: false, noMoreComments: true }, () => {
            setTimeout(() => {
              this.setState({ commentsOpacity: 'opacity-100' })
            }, 300)
          })
        }
        else if (res.data.length < this.commentsPerQuery) {
          let commentsArray = res.data;
          if (this.props.auth && this.props.auth.id) commentsArray = this.checkCommentsForUserThumbs(commentsArray);
          this.setState({
            commentsArray: commentsArray,
            commentsAreLoading: false,
            noMoreComments: true
          }, () => {
            setTimeout(() => {
              this.setState({ commentsOpacity: 'opacity-100' })
            }, 300)
            setTimeout(() => {
              iframeUpdate();
            }, 750)
          })
        }
        else {
          let addingToArray = [];
          res.data.map(comment => {
            return addingToArray.push(comment)
          })
          // For Pagination
          let itemFive = addingToArray[4];
          itemFive.ref = true;
          let lastItem = addingToArray[addingToArray.length - 1]
          lastItem.lastItem = true;
          if (this.props.auth && this.props.auth.id) addingToArray = this.checkCommentsForUserThumbs(addingToArray);
          this.setState({
            commentsArray: addingToArray,
            commentsAreLoading: false
          }, () => {
            setTimeout(() => {
              this.setState({ commentsOpacity: 'opacity-100' })
            }, 300)
            setTimeout(() => {
              iframeUpdate();
            }, 750)
          })
        }
      })
      .catch(error => {
        console.error(error);
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to locate comments');
      });
  }

  // Function that triggers when the person gets to the certain spot on the screen
  grabMoreComments = () => {
    this.setState({ commentPageNumber: this.state.commentPageNumber + 1, moreCommentsLoading: true }, () => {
      API.fetchPostComments(this.props.match.params.slug, this.state.commentPageNumber, this.cancelToken.token)
        .then(res => {
          // Add a setTimeout here if you want to see the loading icon
          // If the data length is less than the comments query, we don't have any more comments to retrieve.
          if (res.data.length < this.commentsPerQuery) {
            this.setState({ noMoreComments: true })
          }

          // This adds a ref property to each array element so we can mark the particular item with the ref
          let addingToArray = this.state.commentsArray
          addingToArray.forEach(comment => {
            comment.ref = false
            comment.lastItem = false
          })
          // This adds the data on top of the existing savedHomePostsArray data.
          let arrayAdditions = res.data;
          if (this.props.auth && this.props.auth.id) arrayAdditions = this.checkCommentsForUserThumbs(arrayAdditions);
          arrayAdditions.map(comment => {
            return addingToArray.push(comment)
          })
          // This switches the last item in the area from having a .ref property of false to true.
          let itemFive = addingToArray[addingToArray.length - 15]
          itemFive.ref = true
          let lastItem = addingToArray[addingToArray.length - 1]
          lastItem.lastItem = true
          // So this should take the index of the addingToArray, grab one item, and replace it with lastItem.
          // So I'll do addingToArray.length - 8 or so.
          addingToArray.splice(addingToArray.length - 15, 1, itemFive)
          addingToArray.splice(addingToArray.length - 1, 1, lastItem)
          this.setState({ commentsArray: addingToArray }, () => {
            this.setState({ moreCommentsLoading: false })
          })
          setTimeout(() => {
            iframeUpdate()
          }, 750)
        })
        .catch(error => {
          console.error(error);
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to locate comments');
        });
    })
  }

  // Deletes the post based upon the slug of the post.
  // Currently users are unable to delete posts.
  // handleDeletePost = (e, id) => {
  //   e.preventDefault();
  //   return API.deletePost(id)
  //     .then(res => {
  //       this.setState({
  //         post: {
  //           id: '',
  //           title: '',
  //           freeContent: '',
  //           codeEditorContent: '',
  //           codeEditorLanguage: '',
  //           paywallContent: '',
  //           slug: '',
  //         }
  //       });
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       globals.toastError(toast, 'Unable to delete post');
  //     });
  // };
  handleCommentChange = (data) => {
    this.setState({
      comment: {
        commentBody: data,
      }
    })
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.comment.commentBody.length >= 1) {
        this.setState({ commentBodyError: '', overallError: '' })
      }
    }, 100)
  }
  validationChecks = () => {
    let allChecksValid;
    // commentBody Validation
    let commentBodyIsValid;
    if (this.state.comment.commentBody.length <= 1) {
      this.setState({
        commentBodyError: '*Your comment could use some material*'
      })
      commentBodyIsValid = false
    } else commentBodyIsValid = true;

    // This allChecksValid will return true or false to initiate the function in handleSubmitPost
    if (!commentBodyIsValid) {
      allChecksValid = false
      this.setState({ overallError: `*Psst, fix any errors above and try again*` })
    }
    else allChecksValid = true;
    return allChecksValid;
  }
  handleSubmitComment = (e) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // LocalStorage check. If it doesn't exist, we return out.
    if (!localStorageAuth?.authToken || !this.props.auth?.id) {
      globals.toastError(toast, 'Login with HandCash to post');
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
      // May want to redirect since they're not logged in. Will update App component and remove login
      return this.props.history.push(`/posts/${this.state.post.slug}`);
    }
    let validationReturnsTrue = this.validationChecks()
    if (validationReturnsTrue) {
      const postCreatorHandle = this.state.post.postCreatorHandle;
      const postCreatorHandCashId = this.state.post.postCreatorHandCashId
      const postCreatorAvatarURL = this.state.post.postCreatorAvatarURL
      let commentBody = this.state.comment.commentBody;
      commentBody.trim()
      // Check commentBody if there are any @tags.
      let taggedHandlesArray = globals.getTaggedHandles(commentBody, this.props.auth.handle)

      let hasIframeInComment = false
      if (commentBody.includes('<iframe')) hasIframeInComment = true
      // Remove empty paragraphs
      const checkIfEmptyParagraph = commentBody.endsWith(`<p><br></p>`)
      if (checkIfEmptyParagraph) {
        do commentBody = commentBody.slice(0, -11)
        while (commentBody.endsWith(`<p><br></p>`))
      }
      commentBody = commentBody.replaceAll(new RegExp('iframe', 'g'), 'bdi');
      commentBody = commentBody.replaceAll(`contenteditable="false"`, '')
      // Comment cost
      let commentCost = globals.commentCost
      if (this.state.post.category === 'lists') commentCost = globals.listCommentCost
      if (this.props.userDatabaseData?.membership) commentCost = globals.memberCommentCost
      // Creating req.body
      const newTierOneComment = {
        taggedHandlesArray: taggedHandlesArray,
        commentBody: commentBody,
        postSlug: this.state.post.slug,
        postTitle: this.state.post.title,
        categoryDisplayName: this.state.post.categoryDisplayName,
        postId: this.state.post.id,
        commentCost: commentCost,
        postCreatorHandle: postCreatorHandle,
        postCreatorHandCashId: postCreatorHandCashId,
        postCreatorAvatarURL: postCreatorAvatarURL,
        actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
        hasIframeInComment: hasIframeInComment
      }
      // This API call needs to be fed two parameters:
      // [1] the param slug or postID
      // [2] Comment Body: this.state.comment.commentBody
      // [3] Eventually a user ID too.
      if (this.props.auth && this.props.auth.handle === postCreatorHandle) this.setState({ nonPaymentOverlay: true })
      this.toggleOverlay()
      API.createTierOneComment(this.state.post.id, newTierOneComment)
        .then(res => {
          // Database response objects
          let incrementedNumComments = res.data.incrementComments.numComments;
          let tierOneComment = {...res.data.tierOneComment};
          const commentDoc = res.data.tierOneComment;
          const updateTierOneCommentCreator = res.data.updateTierOneCommentCreator;
          // Updating logged-in user
          const totalCommentValuePaid = updateTierOneCommentCreator.totalCommentValuePaid
          const totalValueSent = updateTierOneCommentCreator.totalValueSent
          const totalCommentsPosted = updateTierOneCommentCreator.totalCommentsPosted
          this.props.storeUserDatabaseData({ type: 'updateForCommentCreation', totalCommentValuePaid, totalValueSent, totalCommentsPosted, commentDoc })
          // The below 5 lines are to readjust the element with the ref. Without, it displays improperly
          tierOneComment.ref = true;
          // Clone the existing array to create a new one
          let addingToArray = [...this.state.commentsArray];
          addingToArray.forEach(comment => { comment.ref = false })
          // Adds the comment to the array.
          addingToArray.push(tierOneComment)
          setTimeout(() => {
            iframeUpdate()
          }, 750)
          // If commenter is also the poster, we do not add the totalValueReceived update to the post.
          if (tierOneComment.postCreatorHandle !== this.props.auth.handle) {
            let updatedValue = (parseFloat(this.state.post.totalValueReceived) + newTierOneComment.commentCost).toFixed(2)
            this.setState(prevState => ({
              commentsArray: addingToArray,
              comment: { commentBody: '' },
              post: { ...prevState.post, numComments: incrementedNumComments, totalValueReceived: updatedValue }
            }))
            setTimeout(() => {
              this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
            }, 400)
          }
          else {
            this.setState(prevState => ({
              commentsArray: addingToArray,
              comment: { commentBody: '' },
              post: { ...prevState.post, numComments: incrementedNumComments }
            }))
            setTimeout(() => {
              this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
            }, 400)
          }
          // [1] This function scrolls to the particular comment based upon the comment ID
          // [2] The smoothscroll.polyfill() function enables smooth scrolling on multiple browsers
          this.smoothScroll(tierOneComment._id)
          // this.toggleEditor()
          this.setState({ tierOneCommentOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({ addExpandedClass: '', tierOneEditorVisible: false })
            }, 400)
          })
          globals.toastSuccess(toast, 'Comment successfully created!');
        })
        .catch(error => {
          console.error(error);
          globals.toastError(toast, error.response.data.message);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
          }, 400)
        });
      // If validation checks fail
    } else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
      globals.toastError(toast, 'Fix error and try again');
    }
  }
  // Function is used to scroll to comments smoothly within handleSubmitComment function
  smoothScroll = (tierTwoCommentId) => {
    smoothscroll.polyfill();
    let scrollToComment = document.getElementById(`${tierTwoCommentId}`);
    scrollToComment.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    });
  }
  // Adds a class to the particular div ID, and this class triggers a transition based upon height
  toggleEditor = (e) => {
    if (e) e.preventDefault()
    if (this.state.addExpandedClass === '') {
      this.setState({ addExpandedClass: 'expanded', tierOneEditorVisible: true, showConfirmCancelModal: false }, () => {
        setTimeout(() => {
          this.setState({ tierOneCommentOpacity: 'opacity-100' })
        }, 100)
      })
    } else if (this.state.addExpandedClass === 'expanded') {
      this.setState({ tierOneCommentOpacity: 'opacity-0', showConfirmCancelModal: false }, () => {
        setTimeout(() => {
          this.setState({ addExpandedClass: '', tierOneEditorVisible: false })
        }, 400)
      })
    }
  }
  updatePostAndCommentDataAfterT2Comment = (postData, tierTwoComment, index) => {
    // If t1 comment author is not the user
    if (tierTwoComment.parentCommentUserHandle !== this.props.auth.handle) {
      let updatedCommentsArray = this.state.commentsArray
      updatedCommentsArray[index].totalValueReceived = (
        parseFloat(updatedCommentsArray[index].totalValueReceived)
        +
        parseFloat(tierTwoComment.commentCost)
      ).toFixed(2)
      this.setState({ commentsArray: updatedCommentsArray })
    }
    // Update post
    this.setState(prevState => ({
      post: { ...prevState.post, numComments: postData.numComments }
    }))
  }
  // Sets the number for "View More" in the <CommentListItem> Component after a tierTwoComment is added
  // Need to make sure this triggers AFTER the state is set for the commentsArray because that gets looped and I need the index.
  setTierTwoCommentNumber = (index, commentData) => {
    const numChildComments = commentData.numChildComments;
    const updatedArray = this.state.commentsArray;
    updatedArray[index].numChildComments = numChildComments
    this.setState({ commentsArray: updatedArray })
  }
  postThumbsValidation = () => {
    let thumbsValidated = { valueReturned: true, errorMessage: '', }
    if (this.props.auth._id === this.state.post.postCreatorHandCashId) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Cannot Thumbs yourself!`;
      return thumbsValidated
    }
    if (this.state.post.thumberHandCashIds.includes(this.props.auth.id)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Already Thumbs'ed post!`;
      return thumbsValidated;
    };
    if ((this.state.thumbsAmount < globals.thumbsCostMin) || (this.state.thumbsAmount > globals.thumbsCostMax)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Thumbs value must be between $${globals.thumbsCostMin.toFixed(2)} and $${globals.thumbsCostMax.toFixed(2)}.`;
      return thumbsValidated;
    };
    return thumbsValidated;
  }
  sharePostValidation = () => {
    let shareValidated = { valueReturned: true, errorMessage: '', }
    if (this.props.auth._id === this.state.post.postCreatorHandCashId) {
      shareValidated.valueReturned = false;
      shareValidated.errorMessage = `Post is already shared to your followers`;
      return shareValidated
    }
    if (this.state.post.sharerHandCashIds.includes(this.props.auth.id)) {
      shareValidated.valueReturned = false;
      shareValidated.errorMessage = `Post is already shared`;
      return shareValidated;
    };
    return shareValidated;
  }
  stampshotValidation = () => {
    let stampshotValidated = { valueReturned: true, errorMessage: '' }
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    if (!localStorageAuth?.authToken || !this.props.auth?.id) {
      stampshotValidated = { valueReturned: false, errorMessage: 'Login with HandCash to vote', }
      return stampshotValidated
    }
    return stampshotValidated
  }
  voteValidation = () => {
    let voteValidated = { valueReturned: true, errorMessage: '', }
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    if (!localStorageAuth?.authToken || !this.props.auth?.id) {
      voteValidated = { valueReturned: false, errorMessage: 'Login with HandCash to vote', }
      return voteValidated
    }
    if (this.state.userAlreadyVoted || this.state.post.pollVoterHandCashIds.includes(this.props.auth.id)) {
      voteValidated = { valueReturned: false, errorMessage: 'Already voted', }
      return voteValidated
    }
    let pollOptions = this.state.post.pollData.map(item => { return item.pollOption })
    // If somehow they selected a non-poll option.
    if (!pollOptions.includes(this.state.pollOptionSelected)) {
      voteValidated = { valueReturned: false, errorMessage: 'Not a valid poll option selection', }
      return voteValidated
    }
    return voteValidated
  }
  submitUserStampshot = (e) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (this.props.auth?.id && localStorageAuth) {
      const stampshotValidated = this.stampshotValidation();
      if (!stampshotValidated.valueReturned) {
        return (
          globals.toastError(toast, stampshotValidated.errorMessage),
          this.setState({ showPaymentModal: false }, () => {
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400);
          })
        )
      }
      localStorageAuth = JSON.parse(localStorageAuth);
    };
    let valueOfStampshot = globals.nonMemberStampshotCost
    if (this.props.userDatabaseData?.membership) valueOfStampshot = globals.memberStampshotCost
    const userStampshotData = {
      valueOfStampshot: valueOfStampshot,
      // Post stuff
      postTitle: this.state.post.title,
      postSlug: this.state.post.slug,
      postCategory: this.state.post.category,
      postDatabaseId: this.state.post.id,
      postCreatorHandle: this.state.post.postCreatorHandle,
      postCreatorAvatarURL: this.state.post.postCreatorAvatarURL,
      postCreatorHandCashId: this.state.post.postCreatorHandCashId,
      // User
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false
    }
    this.toggleOverlay()
    API.submitUserStampshot(userStampshotData)
      .then(res => {
        const postUpdate = res.data.updatePost
        const userUpdate = res.data.addStampshotToHandCashUser
        const stampshotDoc = res.data.stampshot
        // Update Post
        let stampshotsArray = this.state.stampshotsArray
        stampshotsArray.unshift(stampshotDoc)
        this.setState(prevState => ({
          stampshotsArray: stampshotsArray,
          post: {
            ...prevState.post,
            numStampshots: postUpdate.numStampshots,
          }
        }))
        // Updating logged-in user data
        const totalStampshotsConducted = userUpdate.totalStampshotsConducted
        const totalStampshotValueSent = userUpdate.totalStampshotValueSent
        const totalValueSent = userUpdate.totalValueSent
        this.props.storeUserDatabaseData({ type: 'updateStampshotInfo', totalStampshotsConducted, totalStampshotValueSent, totalValueSent, stampshotDoc })
        // Finalizing the "transaction"
        globals.toastSuccess(toast, 'Blockchain Stampshot Success!')
        setTimeout(() => {
          this.setState({ pageOverlayActive: false })
        }, 400)
      })
      .catch(err => {
        console.error(err);
        globals.toastError(toast, err.response.data.message);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false })
        }, 400)
      });
  }
  sharePostToFollowers = (e) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (this.props.auth?.id && localStorageAuth) {
      const shareValidated = this.sharePostValidation();
      if (!shareValidated.valueReturned) {
        return (
          globals.toastError(toast, shareValidated.errorMessage),
          this.setState({ showPaymentModal: false }, () => {
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400);
          })
        )
      }
      localStorageAuth = JSON.parse(localStorageAuth);
    };
    if (this.props.auth?.id && localStorageAuth?.authToken && this.props.userDatabaseData?.followerHandCashIds) {
      const postCreatorHandCashId = this.state.post.postCreatorHandCashId
      let followerHandCashIds = this.props.userDatabaseData.followerHandCashIds
      // Remove the author of post if they're a follower, so they're not seeing their own post in their own feed
      followerHandCashIds = followerHandCashIds.filter(id => id !== postCreatorHandCashId);
      const shareData = {
        valueOfShare: globals.shareCost,
        postTitle: this.state.post.title,
        postSlug: this.state.post.slug,
        postCategory: this.state.post.category,
        postDatabaseId: this.state.post.id,
        postCreatorHandle: this.state.post.postCreatorHandle,
        postCreatorAvatarURL: this.state.post.postCreatorAvatarURL,
        postCreatorHandCashId: this.state.post.postCreatorHandCashId,
        followerHandCashIdsArray: followerHandCashIds,
        actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false
      }
      this.toggleOverlay()
      API.addShareToPost(this.state.post.id, shareData)
        .then(res => {
          const shareGiverUpdate = res.data.shareGiverUpdate
          const postUpdate = res.data.postUpdate
          const shareDoc = res.data.createShare
          this.setState(prevState => ({
            userAlreadySharedPost: true,
            post: {
              ...prevState.post,
              numShares: postUpdate.numShares,
              sharerHandCashIds: postUpdate.sharerHandCashIds,
              shareValueReceived: postUpdate.shareValueReceived,
              totalValueReceived: postUpdate.totalValueReceived
            }
          }))
          // Updating logged-in user data
          const sharesGiven = shareGiverUpdate.totalSharesGiven
          const totalShareValueGiven = shareGiverUpdate.totalShareValueGiven
          const totalValueSent = shareGiverUpdate.totalValueSent
          this.props.storeUserDatabaseData({ type: 'updateForPostShare', sharesGiven, totalShareValueGiven, totalValueSent, shareDoc })
          // Finalizing the "transaction"
          globals.toastSuccess(toast, 'Sharing success!')
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
        })
        .catch(err => {
          console.error(err);
          globals.toastError(toast, err.response.data.message);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
        });
    }
    // If not authorized for some reason
    else {
      globals.toastError(toast, 'Unable to share post with followers');
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
    }
  }
  setBarAndPieChartState = (newPollData, pollOptions) => {
    this.setState({
      barChartData: {
        labels: pollOptions,
        datasets: [
          {
            axis: 'y',
            data: newPollData,
            fill: false,
            label: 'Votes',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          }
        ]
      },
      pieChartData: {
        labels: pollOptions,
        datasets: [
          {
            label: 'Votes',
            data: newPollData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      },
    })
  }
  submitVoteOnPost = (e) => {
    e.preventDefault();
    // Validate vote
    const voteValidated = this.voteValidation()
    if (!voteValidated.valueReturned) {
      return (
        globals.toastError(toast, voteValidated.errorMessage),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400);
        })
      )
    }
    // Create req.body object with pertinent details
    let voteObject = {
      pollData: this.state.post.pollData,
      valueOfVote: globals.voteCost,
      pollOptionSelected: this.state.pollOptionSelected,
      voteIndexInPollsArray: this.state.voteIndexInPollsArray, //Probably doesn't matter, but for good measure
      // Post Stuff
      postSlug: this.state.post.slug,
      postId: this.state.post.id,
      postTitle: this.state.post.title,
      postCreatorHandle: this.state.post.postCreatorHandle,
      postCreatorAvatarURL: this.state.post.postCreatorAvatarURL,
      postCreatorHandCashId: this.state.post.postCreatorHandCashId,
      // Member or not
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
    }
    if (this.props.auth?.handle === voteObject.postCreatorHandle) this.setState({ nonPaymentOverlay: true })
    this.toggleOverlay()
    API.submitVote(voteObject)
      .then(res => {
        // Database response objects
        const voteDoc = res.data.createVoteDoc
        const postUpdate = res.data.postUpdate
        const voteGiverUpdate = res.data.voteGiverUpdate
        // Update Poll Data
        const newPollData = postUpdate.pollData.map(item => { return item.numOptionVotes })
        const pollOptions = postUpdate.pollData.map(item => { return item.pollOption })
        this.setBarAndPieChartState(newPollData, pollOptions)
        // Set state
        this.setState(prevState => ({
          userAlreadyVoted: true,
          isRadioButtonSelected: false,
          post: {
            ...prevState.post,
            numPollVotes: postUpdate.numPollVotes,
            pollVoterHandCashIds: postUpdate.pollVoterHandCashIds,
            pollValueReceived: postUpdate.pollValueReceived,
            totalValueReceived: postUpdate.totalValueReceived
          }
        }))
        // Updating logged-in user data
        const totalVotesGiven = voteGiverUpdate.totalVotesGiven
        const totalVoteValueGiven = voteGiverUpdate.totalVoteValueGiven
        const totalValueSent = voteGiverUpdate.totalValueSent
        this.props.storeUserDatabaseData({ type: 'updateForVoteGiven', totalVotesGiven, totalVoteValueGiven, totalValueSent, voteDoc })
        // Conclusion to function
        globals.toastSuccess(toast, 'Thanks for stamping your vote')
        globals.toastSuccess(toast, 'Refresh page to see updated charts')
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        }, 400)
      })
      .catch(err => {
        console.error(err);
        globals.toastError(toast, err.response.data.message);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        }, 400)
      });
  }
  addThumbsToPost = (e) => {
    e.preventDefault();
    // globals.createRipple(e); ---> Looks dumb, needs a reconfigure.
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (this.props.auth?.id && localStorageAuth) {
      const thumbsValidated = this.postThumbsValidation();
      if (!thumbsValidated.valueReturned) {
        return (
          globals.toastError(toast, thumbsValidated.errorMessage),
          this.setState({ showPaymentModal: false, thumbsAmount: .05 }, () => {
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400);
          })
        )
      }
      localStorageAuth = JSON.parse(localStorageAuth);
    };
    const thumbsAmount = parseFloat(this.state.thumbsAmount).toFixed(2)
    if (this.props.auth?.id && localStorageAuth?.authToken) {
      const thumbsData = {
        thumbType: 'post',
        valueOfThumbs: thumbsAmount,
        postTitle: this.state.post.title,
        postSlug: this.state.post.slug,
        postCreatorHandle: this.state.post.postCreatorHandle,
        postCreatorPaymail: this.state.post.postCreatorPaymail,
        postCreatorAvatarURL: this.state.post.postCreatorAvatarURL,
        postCreatorHandCashId: this.state.post.postCreatorHandCashId,
        commentCreatorHandle: '',
        commentCreatorHandCashId: '',
        thumbsCommentId: '',
        actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      }
      const userData = {
        costOfThumbs: thumbsAmount,
        thumbsData: thumbsData
      }
      // Here is where I close the modal and add the overlay
      // What if they click a bunch? I see why Ryan wanted swipe.
      // I'll also need to do a check so I take away the user's ability to thumbs again.
      this.toggleOverlay()
      API.addThumbsToPost(this.state.post.id, userData)
        .then(res => {
          // Database response objects
          const thumbsDoc = res.data.createThumbs
          const postUpdate = res.data.postUpdate
          const thumbGiverUpdate = res.data.thumbGiverUpdate
          this.setState(prevState => ({
            userAlreadyThumbsPost: true,
            post: {
              ...prevState.post,
              numThumbs: postUpdate.numThumbs,
              thumberHandCashIds: postUpdate.thumberHandCashIds,
              thumbValueReceived: postUpdate.thumbValueReceived,
              totalValueReceived: postUpdate.totalValueReceived
            }
          }))
          // Updating logged-in user data
          const totalThumbsGiven = thumbGiverUpdate.totalThumbsGiven
          const totalThumbValueGiven = thumbGiverUpdate.totalThumbValueGiven
          const totalValueSent = thumbGiverUpdate.totalValueSent
          this.props.storeUserDatabaseData({ type: 'updateForPostThumbs', totalThumbsGiven, totalThumbValueGiven, totalValueSent, thumbsDoc })
          // Conclusion to function
          globals.toastSuccess(toast, 'Thumbs up success!')
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
        })
        .catch(err => {
          console.error(err);
          globals.toastError(toast, err.response.data.message);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
        });
    }
    // If not authorized for some reason
    else {
      globals.toastError(toast, 'Unable to add thumbs');
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
    }
  }
  validateThumbsCost = () => {
    let thumbsValidated = { valueReturned: true, errorMessage: '' }
    if ((this.state.thumbsAmount < globals.thumbsCostMin) || (this.state.thumbsAmount > globals.thumbsCostMax)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Thumbs value must be between $${globals.thumbsCostMin.toFixed(2)} and $${globals.thumbsCostMax.toFixed(2)}.`;
      return thumbsValidated;
    };
    return thumbsValidated;
  }
  commentThumbsValidation = (index) => {
    let thumbsValidated = { valueReturned: true, errorMessage: '' }
    if (this.props.auth.id === this.state.commentsArray[index].commentCreatorHandCashId) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Cannot Thumbs yourself!!`;
      return thumbsValidated
    }
    if (this.state.commentsArray[index].thumberHandCashIds.includes(this.props.auth.id)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Already Thumbs'ed comment!`;
      return thumbsValidated;
    };
    if ((this.state.thumbsAmount < globals.thumbsCostMin) || (this.state.thumbsAmount > globals.thumbsCostMax)) {
      thumbsValidated.valueReturned = false;
      thumbsValidated.errorMessage = `Thumbs value must be between $${globals.thumbsCostMin.toFixed(2)} and $${globals.thumbsCostMax.toFixed(2)}.`;
      return thumbsValidated;
    };
    return thumbsValidated;
  }
  paywallValidation = () => {
    let paywallValidated = { valueReturned: true, errorMessage: '' }
    if (!this.props.auth?.id) {
      paywallValidated.valueReturned = false;
      paywallValidated.errorMessage = `Must login to purchase hidden content`
      return paywallValidated
    }
    if (this.props.auth.id === this.state.post.postCreatorHandCashId) {
      paywallValidated.valueReturned = false;
      paywallValidated.errorMessage = `Cannot purchase your own content`
      return paywallValidated
    }
    if (this.state.post.userAlreadyPurchasedPaywall || this.state.post.purchaserHandCashIds.includes(this.props.auth.id)) {
      paywallValidated.valueReturned = false;
      paywallValidated.errorMessage = `Already purchased hidden content`
      return paywallValidated
    }
    return paywallValidated
  }
  updateCommentThumbsData = (index, updatedCommentData) => {
    let updatedArray = this.state.commentsArray;
    updatedArray[index] = updatedCommentData
    updatedArray[index].userAlreadyThumbsComment = true;
    this.setState({ commentsArray: updatedArray })
  }
  updateTier1CommentsAndPostNumbersAfterT3Creation = (index) => {
    // I need the index for the t1 comment
    let updatedArray = this.state.commentsArray;
    updatedArray[index].numChildComments = updatedArray[index].numChildComments + 1;
    this.setState(prevState => ({
      commentsArray: updatedArray,
      post: { ...prevState.post, numComments: this.state.post.numComments + 1 }
    }))
  }
  addThumbsToTierOneComment = (e, index) => {
    e.preventDefault();
    // index param = where in the comments index I need to update.
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (this.props.auth?.id && localStorageAuth) {
      const thumbsValidated = this.commentThumbsValidation(index);
      if (!thumbsValidated.valueReturned) {
        return (
          globals.toastError(toast, thumbsValidated.errorMessage),
          this.setState({ showPaymentModal: false }, () => {
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400)
          })
        )
      }
      localStorageAuth = JSON.parse(localStorageAuth);
    };
    if (this.props.auth?.id && localStorageAuth?.authToken) {
      const commentId = this.state.commentsArray[index]._id
      const thumbsData = {
        thumbType: 'comment',
        valueOfThumbs: this.state.thumbsAmount,
        postTitle: this.state.post.title,
        postSlug: this.state.post.slug,
        postId: this.state.post.id,
        postCreatorHandle: this.state.post.postCreatorHandle,
        postCreatorPaymail: this.state.post.postCreatorPaymail,
        postCreatorAvatarURL: this.state.post.postCreatorAvatarURL,
        postCreatorHandCashId: this.state.post.postCreatorHandCashId,
        commentCreatorHandle: this.state.commentsArray[index].commentCreatorHandle,
        commentCreatorHandCashId: this.state.commentsArray[index].commentCreatorHandCashId,
        commentCreatorAvatarURL: this.state.commentsArray[index].commentCreatorAvatarURL,
        thumbsCommentId: commentId,
        actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
      }
      this.toggleOverlay()
      API.addThumbsToTierOneComment(commentId, thumbsData)
        .then(res => {
          // Database response objects
          const thumbGiverUpdate = res.data.thumbGiverUpdate;
          const commentUpdate = res.data.commentUpdate;
          const thumbsDoc = res.data.createThumbs
          // Updating comment data
          this.updateCommentThumbsData(index, commentUpdate)
          // Updating logged-in user data
          const totalThumbsGiven = thumbGiverUpdate.totalThumbsGiven
          const totalThumbValueGiven = thumbGiverUpdate.totalThumbValueGiven
          const totalValueSent = thumbGiverUpdate.totalValueSent
          this.props.storeUserDatabaseData({ type: 'updateForCommentThumbs', totalThumbsGiven, totalThumbValueGiven, totalValueSent, thumbsDoc })
          // Conclusion of the function
          globals.toastSuccess(toast, 'Thumbs up success!');
          setTimeout(() => {
            this.setState({ pageOverlayActive: false, })
          }, 400)
        })
        .catch(error => {
          console.error(error);
          globals.toastError(toast, error.response.data.message);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false, })
          }, 400)
        });
    }
    // If not authorized for some reason
    else {
      globals.toastError(toast, 'Unable to add thumbs');
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '' })
        }, 400)
      })
    }
  }
  // This one is specifically for the comment
  togglePaymentModal = (e, typeOfTransaction) => {
    e.preventDefault();
    if (typeOfTransaction === 'CREATE_COMMENT') {
      // Set comment cost as free if the post author is the same as the comment creator
      if (this.props.auth && this.props.auth.handle === this.state.post.postCreatorHandle) this.setState({ costToCommentIsFree: true })
      const validationReturnsTrue = this.validationChecks()
      if (!validationReturnsTrue) {
        return globals.toastError(toast, 'Fix error(s) and try again');
      }
    }
    if (typeOfTransaction === 'THUMBS_FOR_COMMENT') {
      const thumbsValidated = this.validateThumbsCost();
      if (!thumbsValidated.valueReturned) return globals.toastError(toast, thumbsValidated.errorMessage)
    }
    if (typeOfTransaction === "SHARE_POST") {
      const shareValidated = this.sharePostValidation();
      if (!shareValidated.valueReturned) {
        return (
          globals.toastError(toast, shareValidated.errorMessage),
          this.setState({ showPaymentModal: false }, () => {
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400);
          })
        )
      }
    }
    if (typeOfTransaction === 'THUMBS_FOR_POST') {
      const thumbsValidated = this.postThumbsValidation()
      if (!thumbsValidated.valueReturned) return globals.toastError(toast, thumbsValidated.errorMessage)
    }
    if (typeOfTransaction === 'STAMPSHOT_POST') {
      const stampshotValidated = this.stampshotValidation()
      if (!stampshotValidated.valueReturned) return globals.toastError(toast, stampshotValidated.errorMessage)
    }
    // If they're somehow toggling the modal when it's disabled
    if (typeOfTransaction === 'RATE_PAYWALL') {
      if (this.state.disableRating === true) return
      let star = parseInt(this.state.selectedStar)
      if (star === 1 || star === 2 || star === 3 || star === 4 || star === 5) { }
      else return
    }
    if (typeOfTransaction === 'SUBMIT_VOTE') {
      // Validations: Already voted, not logged in, voteOption is not there. (this is only if someone hacks the UI)
      if (!this.props.auth?.id || this.state.userAlreadyVoted || this.state.pollOptionSelected === '') {
        return globals.toastError(toast, 'Unable to submit vote')
      }
    }
    // Okay, I can't do prevState, because I need to slow down the state change when it closes. Otherwise, no smooth transition.
    if (this.state.showPaymentModal === false) {
      this.setState({ showPaymentModal: true, transactionType: typeOfTransaction, showTipModal: false })
    }
    else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: typeOfTransaction, costToCommentIsFree: false })
        }, 400)
      })
    }
  };
  purchasePaywall = (e) => {
    e.preventDefault();
    // Have to make sure they're logged in, haven't purchased, isn't their own. Even if frontend has it.
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (!this.props.auth?.id && !localStorageAuth) {
      return (
        globals.toastError(toast, 'Login to purchase hidden content'),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        })
      )
    }
    // Validate paywall data such as auth, own content, already purchased
    const paywallValidated = this.paywallValidation();
    if (!paywallValidated.valueReturned) {
      return (
        globals.toastError(toast, paywallValidated.errorMessage),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        })
      )
    }
    localStorageAuth = JSON.parse(localStorageAuth);
    this.toggleOverlay()
    const paywallDataForSchema = {
      postId: this.state.post.id,
      paywallCost: this.state.post.paywallCost,
      postTitle: this.state.post.title,
      postSlug: this.state.post.slug,
      postCreatorHandle: this.state.post.postCreatorHandle,
      postCreatorAvatarURL: this.state.post.postCreatorAvatarURL,
      postCreatorHandCashId: this.state.post.postCreatorHandCashId,
      purchaserHandle: localStorageAuth.handle,
      purchaserHandCashId: localStorageAuth.id,
      purchaserAvatarURL: localStorageAuth.avatarUrl,
      actionTakerIsMember: this.props.userDatabaseData?.membership ? true : false,
    }
    API.purchasePaywall(paywallDataForSchema)
      .then(res => {
        // Database response objects
        const postUpdate = res.data.postUpdate
        const purchaserUpdate = res.data.purchaserUpdate
        const paywallDoc = res.data.createPaywallDocument
        // Updating logged-in user data
        const totalPaywallsPaid = purchaserUpdate.totalPaywallsPaid
        const totalPaywallValuePaid = purchaserUpdate.totalPaywallValuePaid
        const totalValueSent = purchaserUpdate.totalValueSent
        this.props.storeUserDatabaseData({ type: 'updateForPaywallPurchase', totalPaywallsPaid, totalPaywallValuePaid, totalValueSent, paywallDoc })
        // Conclusion to function
        setTimeout(() => {
          // For the transition
          this.setState({ pageOverlayActive: false, paywallOpacity: 'opacity-0' })
          setTimeout(() => {
            // Updating post state
            this.setState(prevState => ({
              userAlreadyPurchasedPaywall: true,
              userRatedPaywall: false,
              post: {
                ...prevState.post,
                paywallContent: postUpdate.paywallContent,
                numPaywallPurchases: postUpdate.numPaywallPurchases,
                paywallValueRecieved: postUpdate.paywallValueRecieved,
                purchaserHandCashIds: postUpdate.purchaserHandCashIds,
                paywallPurchasers: postUpdate.paywallPurchasers,
                totalValueReceived: postUpdate.totalValueReceived
              },
              paywallOpacity: 'opacity-100'
            }))
          }, 750)
        }, 300)
        globals.toastSuccess(toast, 'Hidden content unlocked!')
      })
      .catch(error => {
        console.error(error);
        globals.toastError(toast, error.response.data.message);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false })
        }, 300)
      });
  }
  goToUserProfilePage = (e, userName) => {
    e.preventDefault();
    this.props.opacityChange('removePostDetailOpacity')
    setTimeout(() => {
      this.props.history.push(`/profile/${userName}`)
    }, 300)
  }
  updateT1CommentsArray = (commentBody, index) => {
    if (!index || !commentBody) return
    let updatingTierOneComments = this.state.commentsArray;
    updatingTierOneComments[index].body = commentBody;
    this.setState({ commentsArray: updatingTierOneComments })
  }
  /////////////////
  // Login Modal //
  /////////////////
  toggleLoginModal = () => {
    this.setState(prevState => ({ showLoginModal: !prevState.showLoginModal }));
  };
  closeLoginModal = () => this.setState({ showLoginModal: false })
  authenticateWithHandCash = (e) => {
    e.preventDefault();
    API.authenticateWithHandCash(this.cancelToken.token)
      .then(res => {
        const pathNameString = window.location.pathname
        const redirectionLoginUrl = res.data.redirectionLoginUrl
        const redirectWithPath = `${redirectionLoginUrl}&redirectLink=${pathNameString}`
        window.location.assign(redirectWithPath)
      })
      .catch(error => {
        console.error(error);
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, error.response.data.message);
      });
  }
  toggleOverlay = () => {
    this.setState({ showPaymentModal: false }, () => {
      setTimeout(() => {
        this.setState({ transactionType: '', pageOverlayActive: true })
      }, 400)
    })
  }
  handleStarChange = (e) => {
    this.setState({ selectedStar: e.target.value });
    if (this.state.disableRating === true) this.setState({ disableRating: false })
  }
  validatePaywallRating = () => {
    let paywallRatingValidated = { valueReturned: true, errorMessage: '' }
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    if (!this.props.auth?.id && !localStorageAuth?.authToken) {
      paywallRatingValidated.valueReturned = false;
      paywallRatingValidated.errorMessage = `Must login to rate hidden content`
      return paywallRatingValidated
    }
    if (this.props.auth.id === this.state.post.postCreatorHandCashId) {
      paywallRatingValidated.valueReturned = false;
      paywallRatingValidated.errorMessage = `Cannot rate your own content`
      return paywallRatingValidated
    }
    if (this.state.post.userRatedPaywall || this.state.post.paywallRaterHandCashIds.includes(this.props.auth.id)) {
      paywallRatingValidated.valueReturned = false;
      paywallRatingValidated.errorMessage = `Already rated hidden content`
      return paywallRatingValidated
    }
    const star = parseInt(this.state.selectedStar)
    if (this.state.disableRating === true) {
      paywallRatingValidated.valueReturned = false;
      paywallRatingValidated.errorMessage = `Unable to rate Unlocked Content. Try again later.`
      return paywallRatingValidated
    }
    if (star === 1 || star === 2 || star === 3 || star === 4 || star === 5) { }
    else {
      paywallRatingValidated.valueReturned = false;
      paywallRatingValidated.errorMessage = `Unable to rate Unlocked Content. Try again later.`
    }
    return paywallRatingValidated
  }
  ratePaywall = (e) => {
    e.preventDefault();
    let paywallRatingValidated = this.validatePaywallRating()
    if (!paywallRatingValidated.valueReturned) {
      return (
        globals.toastError(toast, paywallRatingValidated.errorMessage),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        })
      )
    }
    const paywallRatingData = {
      postId: this.state.post.id,
      paywallRating: parseInt(this.state.selectedStar)
    }
    this.setState({ nonPaymentOverlay: true })
    this.toggleOverlay()
    API.ratePaywall(paywallRatingData)
      .then(res => {
        const updatePost = res.data.updatePostedContentSchema;
        let ratingAverage
        ratingAverage = parseFloat((this.state.post.paywallRatingTotal + paywallRatingData.paywallRating) / (this.state.post.numberOfPaywallRatings + 1)).toFixed(2)
        this.setState(prevState => ({
          userRatedPaywall: true,
          post: {
            ...prevState.post,
            paywallRatingTotal: this.state.post.paywallRatingTotal + paywallRatingData.paywallRating,
            numberOfPaywallRatings: this.state.post.numberOfPaywallRatings + 1,
            ratingAverage: ratingAverage,
            paywallRaterHandCashIds: updatePost.paywallRaterHandCashIds,
          }
        }))
        globals.toastSuccess(toast, 'Thank you for rating!');
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        }, 300)
      })
      .catch(error => {
        console.error(error);
        globals.toastError(toast, error.response.data.message);
        setTimeout(() => {
          this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        }, 300)
      });
  }
  toggleArrayModal = (e, arrayType) => {
    e.preventDefault();
    if (!['SHARE_ARRAY', 'THUMBS_ARRAY', 'PURCHASERS_ARRAY', 'VOTERS_ARRAY', 'STAMPSHOTS_ARRAY'].includes(arrayType)) return
    switch (arrayType) {
      case 'SHARE_ARRAY':
        this.getPostSharers(arrayType)
        break;
      case 'THUMBS_ARRAY':
        this.getPostThumbers(arrayType)
        break;
      case 'PURCHASERS_ARRAY':
        this.getPaywallPurchasers(arrayType)
        break;
      case 'VOTERS_ARRAY':
        this.getPollVoters(arrayType)
        break;
      case 'STAMPSHOTS_ARRAY':
        this.getStampshots(arrayType)
        break;
      default: return
    }
  }
  getStampshots = (arrayType) => {
    if (arrayType !== 'STAMPSHOTS_ARRAY') return
    if (this.state.noMoreResultsStampshotsArray || this.state.stampshotsArray.length > 0) {
      return (
        this.setState({ arrayModalOpacity: 'opacity-0', showArrayModal: true, modalArrayType: 'STAMPSHOTS_ARRAY', arrayModalIsLoading: false }),
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      )
    }
    this.setState({
      modalArrayType: 'STAMPSHOTS_ARRAY',
      showArrayModal: true,
      stampshotsArrayIsLoading: true,
      arrayModalOpacity: 'opacity-100',
      arrayModalIsLoading: true,
    })
    API.getStampshots(this.state.post.id, this.state.pageNumberStampshotsArray, this.cancelToken.token)
      .then(res => {
        let stampshotsArray = res.data.stampshotsArray
        if (stampshotsArray.length === 0) {
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({ noMoreResultsStampshotsArray: true, stampshotsArrayIsLoading: false, arrayModalIsLoading: false, arrayModalOpacity: 'opacity-100' })
            }, 300)
          })
        }
        // If it's less than 20
        else if (stampshotsArray.length < globals.fetchTwenty) {
          const updatedArray = this.firstLoadUpdateArray(stampshotsArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({ stampshotsArray: updatedArray, arrayModalIsLoading: false }, () => {
                this.setState({ noMoreResultsStampshotsArray: true, stampshotsArrayIsLoading: false, arrayModalOpacity: 'opacity-100' })
              })
            }, 300)
          })
        }
        else {
          const updatedArray = this.firstLoadUpdateArray(stampshotsArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({ stampshotsArray: updatedArray, arrayModalIsLoading: false }, () => {
                this.setState({ noMoreResultsStampshotsArray: false, stampshotsArrayIsLoading: false, arrayModalOpacity: 'opacity-100' })
              })
            }, 300)
          })
        }
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          this.setState({ showArrayModal: false, stampshotsArrayIsLoading: false, modalArrayType: '' })
        }, 300)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve data');
      });
  }
  getPaywallPurchasers = (arrayType) => {
    if (arrayType !== 'PURCHASERS_ARRAY') return
    if (this.state.noMoreResultsPurchaserArray || this.state.purchaserArray.length > 0) {
      return (
        this.setState({ arrayModalOpacity: 'opacity-0', showArrayModal: true, modalArrayType: 'PURCHASERS_ARRAY', arrayModalIsLoading: false }),
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      )
    }
    this.setState({
      modalArrayType: 'PURCHASERS_ARRAY',
      showArrayModal: true,
      purchaserArrayIsLoading: true,
      arrayModalOpacity: 'opacity-100',
      arrayModalIsLoading: true,
    })
    API.getPaywallPurchasers(this.state.post.id, this.state.pageNumberPurchaserArray, this.cancelToken.token)
      .then(res => {
        let purchaserArray = res.data.paywallPurchasers
        // Check length
        if (purchaserArray.length === 0) {
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                noMoreResultsPurchaserArray: true, purchaserArrayIsLoading: false,
                arrayModalIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
        // If it's less than 20
        else if (purchaserArray.length < globals.fetchTwenty) {
          const updatedArray = this.firstLoadUpdateArray(purchaserArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                purchaserArray: updatedArray, arrayModalIsLoading: false, arrayModalOpacity: 'opacity-100',
                noMoreResultsPurchaserArray: true, purchaserArrayIsLoading: false
              })
            }, 300)
          })
        }
        else {
          const updatedArray = this.firstLoadUpdateArray(purchaserArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                purchaserArray: updatedArray, arrayModalIsLoading: false,
                noMoreResultsPurchaserArray: false, purchaserArrayIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          this.setState({ showArrayModal: false, purchaserArrayIsLoading: false, modalArrayType: '' })
        }, 300)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve data');
      });
  }
  getPollVoters = (arrayType) => {
    if (arrayType !== 'VOTERS_ARRAY') return
    // Here we need to check if there is existing already, or not. As well as if noMoreResults
    if (this.state.noMoreResultsVotersArray || this.state.votersArray.length > 0) {
      return (
        this.setState({
          arrayModalOpacity: 'opacity-0',
          showArrayModal: true,
          modalArrayType: 'VOTERS_ARRAY',
          arrayModalIsLoading: false
        }),
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      )
    }
    this.setState({
      modalArrayType: 'VOTERS_ARRAY',
      showArrayModal: true,
      shareArrayIsLoading: true,
      arrayModalOpacity: 'opacity-100',
      arrayModalIsLoading: true,
    })
    API.getPollVoters(this.state.post.id, this.state.pageNumberVotersArray, this.cancelToken.token)
      .then(res => {
        let votersArray = res.data.pollVoters
        // Check length
        if (votersArray.length === 0) {
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                noMoreResultsVotersArray: true, votersArrayIsLoading: false,
                arrayModalIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
        // If it's less than 20
        else if (votersArray.length < globals.fetchTwenty) {
          const updatedArray = this.firstLoadUpdateArray(votersArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                votersArray: updatedArray, arrayModalIsLoading: false, arrayModalOpacity: 'opacity-100',
                noMoreResultsVotersArray: true, votersArrayIsLoading: false
              })
            }, 300)
          })
        }
        else {
          const updatedArray = this.firstLoadUpdateArray(votersArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                votersArray: updatedArray, arrayModalIsLoading: false,
                noMoreResultsVotersArray: false, votersArrayIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          this.setState({ showArrayModal: false, votersArrayIsLoading: false, modalArrayType: '' })
        }, 300)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve data');
      });
  }
  getPostSharers = (arrayType) => {
    if (arrayType !== 'SHARE_ARRAY') return
    // Here we need to check if there is existing already, or not. As well as if noMoreResults
    if (this.state.noMoreResultsShareArray || this.state.shareArray.length > 0) {
      return (
        this.setState({
          arrayModalOpacity: 'opacity-0',
          showArrayModal: true,
          modalArrayType: 'SHARE_ARRAY',
          arrayModalIsLoading: false
        }),
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      )
    }
    this.setState({
      modalArrayType: 'SHARE_ARRAY',
      showArrayModal: true,
      shareArrayIsLoading: true,
      arrayModalOpacity: 'opacity-100',
      arrayModalIsLoading: true,
    })
    API.getPostSharers(this.state.post.id, this.state.pageNumberShareArray, this.cancelToken.token)
      .then(res => {
        let shareArray = res.data.sharers
        // Check length
        if (shareArray.length === 0) {
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                noMoreResultsShareArray: true, shareArrayIsLoading: false,
                arrayModalIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
        // If it's less than 20
        else if (shareArray.length < globals.fetchTwenty) {
          const updatedArray = this.firstLoadUpdateArray(shareArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                shareArray: updatedArray, arrayModalIsLoading: false,
                noMoreResultsShareArray: true, shareArrayIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
        else {
          const updatedArray = this.firstLoadUpdateArray(shareArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                shareArray: updatedArray, arrayModalIsLoading: false,
                noMoreResultsShareArray: false, shareArrayIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          this.setState({ showArrayModal: false, shareArrayIsLoading: false, modalArrayType: '' })
        }, 300)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve data');
      });
  }
  getPostThumbers = (arrayType) => {
    if (!arrayType || arrayType !== 'THUMBS_ARRAY') return
    // Here we need to check if there is existing already, or not. As well as if noMoreResults
    if (this.state.noMoreResultsThumbsArray || this.state.thumbsArray.length > 0) {
      return (
        this.setState({
          arrayModalOpacity: 'opacity-0',
          showArrayModal: true,
          modalArrayType: 'THUMBS_ARRAY',
          arrayModalIsLoading: false
        }),
        setTimeout(() => {
          this.setState({ arrayModalOpacity: 'opacity-100' })
        }, 300)
      )
    }
    this.setState({
      modalArrayType: 'THUMBS_ARRAY',
      showArrayModal: true,
      thumbsArrayIsLoading: false,
      arrayModalOpacity: 'opacity-100',
      arrayModalIsLoading: true,
    })
    API.getPostThumbers(this.state.post.id, this.state.pageNumberThumbsArray, this.cancelToken.token)
      .then(res => {
        let thumbsArray = res.data.thumbers
        // Check length
        if (thumbsArray.length === 0) {
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                noMoreResultsThumbsArray: true, thumbsArrayIsLoading: false,
                arrayModalIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
        // If it's less than 20
        else if (thumbsArray.length < globals.fetchTwenty) {
          const updatedArray = this.firstLoadUpdateArray(thumbsArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                thumbsArray: updatedArray, arrayModalIsLoading: false, noMoreResultsThumbsArray: true,
                thumbsArrayIsLoading: false, arrayModalOpacity: 'opacity-100'
              })
            }, 300)
          })
        }
        else {
          const updatedArray = this.firstLoadUpdateArray(thumbsArray)
          this.setState({ arrayModalOpacity: 'opacity-0' }, () => {
            setTimeout(() => {
              this.setState({
                thumbsArray: updatedArray, arrayModalIsLoading: false, arrayModalOpacity: 'opacity-100',
                noMoreResultsThumbsArray: false, thumbsArrayIsLoading: false
              })
            }, 300)
          })
        }
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          this.setState({
            showArrayModal: false,
            thumbsArrayIsLoading: false,
            modalArrayType: '',
            arrayModalOpacity: 'opacity-0'
          })
        }, 300)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve data');
      });
  }
  updateExistingArray = (newResults, arrayType) => {
    // arrayType is this.state.TYPEOFARRAY
    // newResults gets added to that array and assigned properties, when applicable.
    let addingToArray = arrayType
    addingToArray.forEach(arrayItem => {
      arrayItem.ref = false
      arrayItem.lastItem = false
    })
    newResults.map(item => {
      return addingToArray.push(item)
    })
    if (newResults.length === globals.fetchTwenty) {
      let itemFive = addingToArray[addingToArray.length - 15]
      itemFive.ref = true
      addingToArray.splice(addingToArray.length - 15, 1, itemFive)
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    // So this takes the index of the addingToArray, grabs one item, and replaces it with lastItem.
    addingToArray.splice(addingToArray.length - 1, 1, lastItem)
    return addingToArray;
  }
  paginateByArrayType = (arrayType) => {
    if (!['SHARE_ARRAY', 'THUMBS_ARRAY', 'PURCHASERS_ARRAY', 'VOTERS_ARRAY', 'STAMPSHOTS_ARRAY'].includes(arrayType)) return
    switch (arrayType) {
      case 'SHARE_ARRAY':
        // Add loading icon at the bottom and update pageNumber
        this.setState({ shareArrayIsLoading: true, pageNumberShareArray: this.state.pageNumberShareArray + 1 }, () => {
          API.getPostSharers(this.state.post.id, this.state.pageNumberShareArray, this.cancelToken.token)
            .then(res => {
              let shareArray = res.data.sharers
              if (shareArray.length === 0) {
                // Update existing array so there aren't any refs.
                let updatedArray = this.state.shareArray
                updatedArray.forEach(item => { item.ref = false })
                this.setState({ shareArrayIsLoading: false, noMoreResultsShareArray: true, shareArray: updatedArray })
              }
              else if (shareArray.length < globals.fetchTwenty) {
                // Case if the search has some results, but not all.
                const updatedArray = this.updateExistingArray(shareArray, this.state.shareArray)
                this.setState({ shareArray: updatedArray }, () => {
                  this.setState({ shareArrayIsLoading: false, noMoreResultsShareArray: true })
                })
              }
              // Case if max items retrieved
              else {
                const updatedArray = this.updateExistingArray(shareArray, this.state.shareArray)
                this.setState({ shareArray: updatedArray }, () => {
                  this.setState({ shareArrayIsLoading: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      case 'THUMBS_ARRAY':
        this.setState({ thumbsArrayIsLoading: true, pageNumberThumbsArray: this.state.pageNumberThumbsArray + 1 }, () => {
          API.getPostThumbers(this.state.post.id, this.state.pageNumberThumbsArray, this.cancelToken.token)
            .then(res => {
              let thumbsArray = res.data.thumbers
              if (thumbsArray.length === 0) {
                // Update existing array so there aren't any refs.
                let updatedArray = this.state.thumbsArray
                updatedArray.forEach(item => { item.ref = false })
                this.setState({ thumbsArrayIsLoading: false, noMoreResultsThumbsArray: true, thumbsArray: updatedArray })
              }
              else if (thumbsArray.length < globals.fetchTwenty) {
                // Case if the search has some results, but not all.
                const updatedArray = this.updateExistingArray(thumbsArray, this.state.thumbsArray)
                this.setState({ thumbsArray: updatedArray }, () => {
                  this.setState({ thumbsArrayIsLoading: false, noMoreResultsThumbsArray: true })
                })
              }
              // Case if max items retrieved
              else {
                const updatedArray = this.updateExistingArray(thumbsArray, this.state.thumbsArray)
                this.setState({ thumbsArray: updatedArray }, () => {
                  this.setState({ thumbsArrayIsLoading: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      case 'PURCHASERS_ARRAY':
        this.setState({ purchaserArrayIsLoading: true, pageNumberPurchaserArray: this.state.pageNumberPurchaserArray + 1 }, () => {
          API.getPaywallPurchasers(this.state.post.id, this.state.pageNumberPurchaserArray, this.cancelToken.token)
            .then(res => {
              let purchaserArray = res.data.paywallPurchasers
              if (purchaserArray.length === 0) {
                // Update existing array so there aren't any refs.
                let updatedArray = this.state.purchaserArray
                updatedArray.forEach(item => { item.ref = false })
                this.setState({ purchaserArrayIsLoading: false, noMoreResultsPurchaserArray: true, purchaserArray: updatedArray })
              }
              else if (purchaserArray.length < globals.fetchTwenty) {
                // Case if the search has some results, but not all.
                const updatedArray = this.updateExistingArray(purchaserArray, this.state.purchaserArray)
                this.setState({ purchaserArray: updatedArray }, () => {
                  this.setState({ purchaserArrayIsLoading: false, noMoreResultsPurchaserArray: true })
                })
              }
              // Case if max items retrieved
              else {
                const updatedArray = this.updateExistingArray(purchaserArray, this.state.purchaserArray)
                this.setState({ purchaserArray: updatedArray }, () => {
                  this.setState({ purchaserArrayIsLoading: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      case 'VOTERS_ARRAY':
        this.setState({ votersArrayIsLoading: true, pageNumberVotersArray: this.state.pageNumberVotersArray + 1 }, () => {
          API.getPollVoters(this.state.post.id, this.state.pageNumberVotersArray, this.cancelToken.token)
            .then(res => {
              let votersArray = res.data.pollVoters
              if (votersArray.length === 0) {
                // Update existing array so there aren't any refs.
                let updatedArray = this.state.votersArray
                updatedArray.forEach(item => { item.ref = false })
                this.setState({ votersArrayIsLoading: false, noMoreResultsVotersArray: true, votersArray: updatedArray })
              }
              else if (votersArray.length < globals.fetchTwenty) {
                // Case if the search has some results, but not all.
                const updatedArray = this.updateExistingArray(votersArray, this.state.votersArray)
                this.setState({ votersArray: updatedArray }, () => {
                  this.setState({ votersArrayIsLoading: false, noMoreResultsVotersArray: true })
                })
              }
              // Case if max items retrieved
              else {
                const updatedArray = this.updateExistingArray(votersArray, this.state.votersArray)
                this.setState({ votersArray: updatedArray }, () => {
                  this.setState({ votersArrayIsLoading: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      case 'STAMPSHOTS_ARRAY':
        this.setState({ stampshotsArrayIsLoading: true, pageNumberStampshotsArray: this.state.pageNumberStampshotsArray + 1 }, () => {
          API.getStampshots()
            .then(res => {
              let stampshotsArray = res.data.stampshotsArray
              if (stampshotsArray.length === 0) {
                // Update existing array so there aren't any refs.
                let updatedArray = this.state.stampshotsArray
                updatedArray.forEach(item => { item.ref = false })
                this.setState({ stampshotsArrayIsLoading: false, noMoreResultsStampshotsArray: true, stampshotsArray: updatedArray })
              }
              else if (stampshotsArray.length < globals.fetchTwenty) {
                // Case if the search has some results, but not all.
                const updatedArray = this.updateExistingArray(stampshotsArray, this.state.stampshotsArray)
                this.setState({ stampshotsArray: updatedArray }, () => {
                  this.setState({ stampshotsArrayIsLoading: false, noMoreResultsStampshotsArray: true })
                })
              }
              // Case if max items retrieved
              else {
                const updatedArray = this.updateExistingArray(stampshotsArray, this.state.stampshotsArray)
                this.setState({ stampshotsArray: updatedArray }, () => {
                  this.setState({ stampshotsArrayIsLoading: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      default: return
    }
  }
  // This update adds the ref and the lastItem properties
  firstLoadUpdateArray = (dataArray) => {
    let addingToArray = dataArray;
    if (dataArray.length === globals.fetchTwenty) {
      let itemFive = addingToArray[4];
      itemFive.ref = true;
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    return addingToArray;
  }
  closeArrayModal = () => {
    this.setState({ arrayModalOpacity: 'opacity-0' })
    setTimeout(() => {
      this.setState({ showArrayModal: false })
    }, 300)
  }
  handleThumbsAmountChange = (e) => {
    // This ensures 2 decimal places
    this.setState({ thumbsAmount: e.target.value });
    let source = document.querySelector('.updatingTipValue')
    source.addEventListener('change', () => {
      source.value = parseFloat(source.value).toFixed(2)
    });
  }
  toggleTipModal = (e, transactionType, index) => {
    e.preventDefault();
    if (this.state.showTipModal === false) {
      this.setState({ showTipModal: true, transactionType: transactionType, commentIndexNumber: index })
    }
    else {
      this.setState({ showTipModal: false, transactionType: '' })
    }
  }
  confirmPaymentModal = (e, typeOfTransaction) => {
    e.preventDefault();
    if (typeOfTransaction === 'TIP_USER') {
      const validationReturnsTrue = this.tipValidationCheck()
      if (!validationReturnsTrue) return globals.toastError(toast, 'Correct any errors and try again')
    }
    this.setState({ showTipModal: false }, () => {
      setTimeout(() => {
        this.setState({ showPaymentModal: true, transactionType: typeOfTransaction })
      }, 400)
    })
  }
  // This checks the length and changes color of the character # count to notify how many characters a person has.
  checkLengthOnDescription = (type) => {
    if (type === 'TIP') {
      const tipMessageLength = this.state.tipMessage.length;
      if (tipMessageLength <= 200) {
        return this.setState({ charCounterClass: `fifth-text` })
      } else if (tipMessageLength >= 200 && tipMessageLength <= 280) {
        return this.setState({ charCounterClass: 'text-orange-500' })
      } else {
        return this.setState({ charCounterClass: 'text-red-600' })
      }
    }
  }
  toggleConfirmCancelModal = (e) => {
    e.preventDefault();
    if (this.state.showConfirmCancelModal === false) {
      this.setState({ showConfirmCancelModal: true })
    }
    else this.setState({ showConfirmCancelModal: false })
  }
  closeTipModal = (e) => {
    e.preventDefault();
    this.setState({ showTipModal: false, transactionType: '' })
  }
  closeConfirmCancelModal = (e) => {
    e.preventDefault();
    this.setState({ showConfirmCancelModal: false })
  }
  toggleViewVotes = async (e) => {
    e.preventDefault()
    this.setState({ viewVotes: true })
    const slug = `post-${this.props.match.params.slug}`
    const slugObject = { viewVotes: true }
    localStorage.setItem(slug, JSON.stringify(slugObject))
    // Save to database, if user is logged in
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (!localStorageAuth) return
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth)
    if (localStorageAuth?.authToken) {
      try {
        API.addHandCashIdToVoteViewersArray({ slug: this.props.match.params.slug })
      }
      catch (err) {
        console.error('Error Adding to VoteViewersArray: ', err)
      }
    }
  }
  // Based upon the Radio Button selected, this.state.pollOptionSelected is changed
  handleRadioButtonSelection = (event, index) => {
    const value = event.target.value;
    if (value === this.state.pollOptionSelected) return
    this.setState({ pollOptionSelected: value, voteIndexInPollsArray: index });
    // If logged in and they haven't voted, we can remove disablement of button
    if (this.props.auth?.id && !this.state.userAlreadyVoted) this.setState({ isRadioButtonSelected: true })
  }
  // Currently only used to check for "viewVotes" but can be used to capture all post data and display before loading to save time
  checkLocalStorageForSlug = () => {
    const slug = this.props.match.params.slug
    let localStorageSlug = localStorage.getItem(`post-${slug}`);
    if (localStorageSlug) {
      localStorageSlug = JSON.parse(localStorageSlug)
      if (localStorageSlug.viewVotes) this.setState({ viewVotes: true })
    }
  }
  render() {
    const { postDetailOpacity, auth, userDatabaseData } = this.props;
    return (
      <>
        <PostDetailPage
          {...this.state}
          refCallback={this.refCallback}
          handleCommentChange={this.handleCommentChange}
          handleSubmitComment={this.handleSubmitComment}
          toggleEditor={this.toggleEditor}
          updatePostAndCommentDataAfterT2Comment={this.updatePostAndCommentDataAfterT2Comment}
          setTierTwoCommentNumber={this.setTierTwoCommentNumber}
          postDetailOpacity={postDetailOpacity}
          clickToEditPost={this.clickToEditPost}
          auth={auth}
          userDatabaseData={userDatabaseData}
          togglePaymentModal={this.togglePaymentModal}
          goToUserProfilePage={this.goToUserProfilePage}
          history={this.props.history}
          updateT1CommentsArray={this.updateT1CommentsArray}
          toggleLoginModal={this.toggleLoginModal}
          updateTier1CommentsAndPostNumbersAfterT3Creation={this.updateTier1CommentsAndPostNumbersAfterT3Creation}
          handleStarChange={this.handleStarChange}
          toggleArrayModal={this.toggleArrayModal}
          globals={globals}
          toggleTipModal={this.toggleTipModal}
          toggleConfirmCancelModal={this.toggleConfirmCancelModal}
          toggleViewVotes={this.toggleViewVotes}
          handleRadioButtonSelection={this.handleRadioButtonSelection}
          setView={this.setView}
        />
        {this.state.pageOverlayActive
          ? <OverlayLoadingIcon nonPaymentOverlay={this.state.nonPaymentOverlay} />
          : <></>
        }
        {this.props.auth?.id
          ?
          <>
            <PaymentModal
              showPaymentModal={this.state.showPaymentModal}
              toggleModal={this.togglePaymentModal}
              triggerPaymentFunction={
                // If
                this.state.transactionType === 'CREATE_COMMENT'
                  ? this.handleSubmitComment
                  // Else if thumbs for comment
                  : this.state.transactionType === 'THUMBS_FOR_COMMENT'
                    ? this.addThumbsToTierOneComment
                    // Else if thumbs for post
                    : this.state.transactionType === 'THUMBS_FOR_POST'
                      ? this.addThumbsToPost
                      : this.state.transactionType === 'SHARE_POST'
                        ? this.sharePostToFollowers
                        : this.state.transactionType === 'RATE_PAYWALL'
                          ? this.ratePaywall
                          : this.state.transactionType === 'SUBMIT_VOTE'
                            ? this.submitVoteOnPost
                            : this.state.transactionType === 'STAMPSHOT_POST'
                              ? this.submitUserStampshot
                              : this.purchasePaywall
              }
              auth={this.props.auth}
              transactionType={this.state.transactionType}
              postTitle={this.state.post.title}
              handCashPostCreator={this.state.post.postCreatorHandle}
              thumbsCost={this.state.thumbsAmount}
              commentsArray={this.state.commentsArray}
              commentIndex={this.state.commentIndexNumber}
              paywallCost={this.state.post.paywallCost}
              costToCommentIsFree={this.state.costToCommentIsFree}
              selectedStar={this.state.selectedStar}
              category={this.state.post.category}
              pollOptionSelected={this.state.pollOptionSelected}
              userDatabaseData={this.props.userDatabaseData}
            />
          </>
          : <></>
        }
        {/* For Post Thumbs & Tier 1 Comment Thumbs */}
        <TipModal
          showModal={this.state.showTipModal}
          closeModal={this.closeTipModal}
          confirmPaymentModal={this.togglePaymentModal}
          handleChange={this.handleThumbsAmountChange}
          amount={this.state.thumbsAmount}
          transactionType={this.state.transactionType}
          min={globals.thumbsCostMin}
          max={globals.thumbsCostMax}
          step={`0.01`}
          isThumbsModal={true}
        />
        <LoginModal
          showLoginModal={this.state.showLoginModal}
          closeLoginModal={this.closeLoginModal}
          authenticateWithHandCash={this.authenticateWithHandCash}
        />
        <ArrayModal
          closeArrayModal={this.closeArrayModal}
          showModal={this.state.showArrayModal}
          modalArrayType={this.state.modalArrayType}
          refCallbackForArrayList={this.refCallbackForArrayList}
          goToUserProfilePage={this.goToUserProfilePage}
          modalDataArray={
            this.state.modalArrayType === 'SHARE_ARRAY'
              ? this.state.shareArray
              : this.state.modalArrayType === 'THUMBS_ARRAY'
                ? this.state.thumbsArray
                : this.state.modalArrayType === 'PURCHASERS_ARRAY'
                  ? this.state.purchaserArray
                  : this.state.modalArrayType === 'VOTERS_ARRAY'
                    ? this.state.votersArray
                    : this.state.modalArrayType === 'STAMPSHOTS_ARRAY'
                      ? this.state.stampshotsArray
                      : this.state.shareArray
          }
          noMoreResults={
            this.state.modalArrayType === 'SHARE_ARRAY'
              ? this.state.noMoreResultsShareArray
              : this.state.modalArrayType === 'THUMBS_ARRAY'
                ? this.state.noMoreResultsThumbsArray
                : this.state.modalArrayType === 'PURCHASERS_ARRAY'
                  ? this.state.noMoreResultsPurchaserArray
                  : this.state.modalArrayType === 'VOTERS_ARRAY'
                    ? this.state.noMoreResultsVotersArray
                    : this.state.modalArrayType === 'STAMPSHOTS_ARRAY'
                      ? this.state.noMoreResultsStampshotsArray
                      : this.state.noMoreResultsShareArray
          }
          arrayIsLoading={
            this.state.modalArrayType === 'SHARE_ARRAY'
              ? this.state.shareArrayIsLoading
              : this.state.modalArrayType === 'THUMBS_ARRAY'
                ? this.state.thumbsArrayIsLoading
                : this.state.modalArrayType === 'PURCHASERS_ARRAY'
                  ? this.state.purchaserArrayIsLoading
                  : this.state.modalArrayType === 'VOTERS_ARRAY'
                    ? this.state.votersArrayIsLoading
                    : this.state.modalArrayType === 'STAMPSHOTS_ARRAY'
                      ? this.state.stampshotsArrayIsLoading
                      : this.state.shareArrayIsLoading
          }
          arrayModalIsLoading={this.state.arrayModalIsLoading}
          arrayModalOpacity={this.state.arrayModalOpacity}
        />
        <ConfirmCancelModal
          showConfirmCancelModal={this.state.showConfirmCancelModal}
          closeConfirmCancelModal={this.closeConfirmCancelModal}
          confirmCancel={this.toggleEditor}
        />
      </>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    postDetailOpacity: state.opacity.postDetailOpacity,
    auth: state.auth.handCashLogInData,
    userDatabaseData: state.auth.userDatabaseData,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue)),
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostDetailPageContainer);
