import React, { Component } from 'react'
import { connect } from 'react-redux'
import { opacityChange } from '../../store/reducers/opacitySlice';
import { storeUserDatabaseData } from '../../store/reducers/authSlice';
import NotificationPage from './NotificationPage';
import API from '../../utils/API';
import { toast } from 'react-toastify';
import { globals } from '../../config/globals';

class NotificationContainer extends Component {
  state = {
    notificationsArray: [],
    pageNumber: 1,
    noMoreResults: false,
    startingNotificationsAreLoaded: false,
    newNotificationsAreLoaded: false,
    notificationListOpacity: 'opacity-0'
  }
  cancelToken = API.CancelToken.source()
  observer = React.createRef()

  componentDidMount() {
    this.getNotifications()
    setTimeout(() => {
      this.props.opacityChange('addNotificationOpacity')
    }, 100)
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled')
  }
  // Ref stuff
  notificationListPaginationLogic = (node) => {
    // Disconnects the current ref observer when it hits.
    if (this.observer.current) this.observer.current.disconnect()
    // Creates a new observer and in the grabMoreComments function, it updates the div where the refCallback lands on
    this.observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !this.state.noMoreResults) {
        this.paginateNotifications()
      }
    })
    // Observe the current node.
    if (node) this.observer.current.observe(node)
  }
  // This is my callback function for when the ref becomes .current (on the screen)
  refCallbackForPostsList = this.notificationListPaginationLogic.bind(this); // <- this is essentially `useCallback`
  paginateNotifications = () => {
    // I can add a Loading thing for when the pagination is happening... Should I? It made a jerky thing if I recall...
    // Maybe a dot dot dot would be good. I should build it in though.
    this.setState({ newNotificationsAreLoaded: false, pageNumber: this.state.pageNumber + 1 }, () => {
      API.getNotifications(this.state.pageNumber, this.cancelToken.token)
        .then(res => {
          const notifications = res.data.notifications
          this.updateNotificationViewedProperty(notifications)
          // If there are no new notifications
          if (notifications.length === 0) {
            let updateNotificationsArray = this.state.notificationsArray
            updateNotificationsArray.forEach(notification => { notification.ref = false })
            this.setState({ noMoreResults: true, newNotificationsAreLoaded: true, notificationsArray: updateNotificationsArray })
          }
          else if (notifications.length < globals.fetchFifty) {
            // Case if the search has some results, but not all.
            const updatedArray = this.updateExistingArray(notifications)
            this.setState({ notificationsArray: updatedArray }, () => {
              this.setState({ newNotificationsAreLoaded: true, noMoreResults: true })
            })
          }
          else {
            const updatedArray = this.updateExistingArray(notifications)
            this.setState({ notificationsArray: updatedArray }, () => {
              this.setState({ newNotificationsAreLoaded: true, noMoreResults: false })
            })
          }
        })
        .catch(error => {
          console.error(error);
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to retrieve notifications');
        });
    })
  }

  getNotifications = () => {
    // Check to see if user is logged in. If not, redirect. If so, fetch notifications.
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    if (!localStorageAuth || !localStorageAuth.authToken) return (
      globals.toastError(toast, 'Must login to view notifications'),
      this.props.opacityChange('removeNotificationOpacity'),
      this.props.history.push(`/`)
    )
    // Fetching notifications if user is logged in
    API.getNotifications(this.state.pageNumber, this.cancelToken.token)
      .then(res => {
        const notifications = res.data.notifications
        this.updateNotificationViewedProperty(notifications)
        // If user has no more notifications
        if (notifications.length === 0) {
          this.setState({ noMoreResults: true, startingNotificationsAreLoaded: true })
        }
        // If user has some notifications, but less than the preset amount
        else if (notifications.length < globals.fetchFifty) {
          const updatedArray = this.firstLoadUpdateArray(notifications)
          this.setState({ notificationsArray: updatedArray }, () => {
            this.setState({ noMoreResults: true, startingNotificationsAreLoaded: true })
          })
        }
        else {
          const updatedArray = this.firstLoadUpdateArray(notifications)
          this.setState({ notificationsArray: updatedArray }, () => {
            this.setState({ noMoreResults: false, startingNotificationsAreLoaded: true })
          })
        }
        // Updating the user's number of new notifications to 0 in the database and frontend
        if (this.props.userDatabaseData && this.props.userDatabaseData.numNewNotifications > 0) {
          setTimeout(() => {
            // Updates number of Notifications in the Nav
            this.props.storeUserDatabaseData({ type: 'updateNumNotifications' })
          }, 1000)
          // Updates actual number of new notifications in the database (to 0)
          API.updateNumNotifications().catch(error => console.error(error))
        }
        // Update opacity
        setTimeout(() => {
          this.setState({ notificationListOpacity: 'opacity-100' })
        }, 300)
      })
      .catch(error => {
        console.error(error);
        if (error.message === 'Operation canceled') return
        setTimeout(() => {
          this.props.history.push(`/`);
        }, 300);
        globals.toastError(toast, 'Notifications not found. Redirecting to home page.');
      })
  }
  updateNotificationViewedProperty = (notifications) => {
    const updatingArray = notifications
    let arrayToUpdateNotifications = []
    for (let index = 0; index < updatingArray.length; index++) {
      // If the alreadyViewed property is false, we put them in an array
      if (!updatingArray[index].alreadyViewed) {
        arrayToUpdateNotifications.push(updatingArray[index]._id)
      }
    }
    // If all the notifications have been viewed, we return out.
    if (arrayToUpdateNotifications.length === 0) return
    // Send the array of objectID's to the database, to switch the alreadyViewed property to True
    return API.updateNotificationViewedProperty(arrayToUpdateNotifications)
      .catch(err => {
        console.error(err);
      })
  }
  firstLoadUpdateArray = (notifications) => {
    let addingToArray = notifications;
    if (notifications.length === globals.fetchFifty) {
      let itemTen = addingToArray[9];
      itemTen.ref = true;
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    return addingToArray;
  }
  updateExistingArray = (notifications) => {
    let addingToArray = this.state.notificationsArray
    addingToArray.forEach(notification => {
      notification.ref = false
      notification.lastItem = false
    })
    notifications.map(notification => {
      return addingToArray.push(notification)
    })
    if (notifications.length === globals.fetchFifty) {
      let itemTen = addingToArray[addingToArray.length - 39]
      itemTen.ref = true
      addingToArray.splice(addingToArray.length - 39, 1, itemTen)
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    // So this takes the index of the addingToArray, grabs one item, and replaces it with lastItem.
    addingToArray.splice(addingToArray.length - 1, 1, lastItem)
    return addingToArray;
  }
  goToUserProfilePage = (e, userName) => {
    e.preventDefault();
    // Do an opacityChange
    this.props.opacityChange('removeNotificationOpacity')
    setTimeout(() => {
      this.props.history.push(`/profile/${userName}`)
    }, 300)
  }
  render() {

    return (
      <>
        <NotificationPage
          {...this.state}
          notificationOpacity={this.props.notificationOpacity}
          refCallbackForPostsList={this.refCallbackForPostsList}
          goToUserProfilePage={this.goToUserProfilePage}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.handCashLogInData,
  userDatabaseData: state.auth.userDatabaseData,
  notificationOpacity: state.opacity.notificationOpacity
})

const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue)),
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer)