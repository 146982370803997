import React from 'react'
import Modal from "react-modal";
import { Icon } from '@iconify/react';
import closeCircleO from '@iconify/icons-vaadin/close-circle-o';

const DeleteModal = props => {
  const {
    deleteDraft,
    toggleDeleteDraftModal,
    showDeleteDraftModal,
    draftId,
    draftIndex
  } = props;
  const styles = {
    base: `modal-content first-text gradient-background-four shadow-effect-five
    mt-32 w-full px-1 md:w-3/5 lg:w-3/5 xl:w-2/5 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showDeleteDraftModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        className={styles}
        overlayClassName="modal-overlay"
        onRequestClose={toggleDeleteDraftModal}
        closeTimeoutMS={300}
        contentLabel={'Delete Modal'}
      >
        <div className={`text-center pb-2`}>
          <button onClick={toggleDeleteDraftModal} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
              <Icon icon={closeCircleO} height={18} width={18} />
            </button>
          <div className={`teko-font text-3xl sm:text-3xl mb-2 logo-style ml-6`}>
            Are you sure you would like to delete this draft?
          </div>
          <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
          {/* Confirmation */}
          <div className={`flex justify-between mt-5 mb-1`}>
            <button className={`text-sm font-header border-2 rounded-xl p-2 mr-2 
            gradient-background-green shadow-effect-seven text-white border-brand-green`}
              onClick={e => deleteDraft(e, draftId, draftIndex)}
            >
              Confirm
            </button>
            <button className={`text-white gradient-background-red shadow-effect-seven
            text-sm rounded-xl border-2 font-header border-red-700 p-2 ml-2`}
              onClick={toggleDeleteDraftModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DeleteModal;